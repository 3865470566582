import React,{useState} from 'react';
import { Col, Card,Row } from 'reactstrap';
import { useDispatch } from "react-redux";
import { updateData } from '../../../actions/AllActions';
import { confirmAlert } from 'react-confirm-alert'; 
import Header from '../header/header';
import '../finalOffering/finalOffering.scss';
import {returnError} from "../../../shared/common_logic";

const FinalRemark = ({props,camData,metaData}) => {

  const [isfinalRemark, setfinalRemark] = useState(false);
  const [remarkData,setRemarkData] = useState(camData.final_remarks);
  const dispatch = useDispatch();
  const editable = metaData.is_editable;  

  const handleChange = e => {
    setRemarkData(e.target.value)
  }

    const updateFinalRemark = () =>{ 
        if(remarkData !== null && remarkData !== ""){
        dispatch(updateData({final_remarks:remarkData},props.match.params.id)).then(
            function(res) {
              let search = props.location.search;
              let params = new URLSearchParams(search);
              var newurl = window.location.origin+props.location.pathname+"?user_id="+params.get('user_id')+"&version_id="+res.version+"&token="+params.get('token');
              confirmAlert({
                title: '',
                message: 'Cam updated successfully.',
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => window.location.href = newurl
                  }
                ]
              });
      
            }
            ,function(err) {
              confirmAlert({
                title: 'Error',
                message: returnError(err),
                buttons: [
                  {
                    label: 'OK',
                    onClick: null
                  }
                ]
              });
            });
          }
          else{
            confirmAlert({
              title: '',
              message: 'Please Enter Remarks.',
              buttons: [
                {
                  label: 'Cancel'                  
                }
              ]
            });
          }
        }
    return (
        <>
            <Col md="12 pb-4">
                <Card>
                    <Header headerText="Final Remarks by Approving Authority" headerImg="/images/cam_version_3/edit.svg" />
                </Card>
            </Col>
            <Col md="12 pb-4" className="finalRemark">
                <Card className="p-4 card">
                    <Col md="12" className="col d-flex p-2 tabPrintAttach">
                      
                       { metaData?.is_editable === true ?
                          <textarea rows="4"  
                          // name="business_experience" 
                          className="inputEditText" 
                          onChange={handleChange} 
                          value={remarkData}
                          />
                       :
                       <div className="inputEditDiv">
                         {remarkData || "-"}
                       </div>
                       }
                    </Col>
                    <Col md="12" className="col d-flex p-2 tabPrint">
                      <div className="inputEditDiv">
                           {remarkData || "-"}
                        </div>
                    </Col>
                    <Col md="12" className="col p-2">
                      {metaData?.is_editable === true &&
                        <div className="float-right ">
                             <button className="btn btn-danger btnExit ml-2"
                             onClick={()=>updateFinalRemark()}
                             >Submit</button>
                          
                        </div>
                      }
                    </Col>
                </Card>
            </Col>
        </>
    )
}
export default FinalRemark;