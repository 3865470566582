import React, { useState } from "react";
import { Modal, ModalBody, Tooltip } from "reactstrap";

const dateToDMY = (date) => {
  const DATE = new Date(date).getDate();
  const MONTH = new Date(date).getMonth() + 1;
  const YEAR = new Date(date).getFullYear();
  return (
    "" +
    (DATE <= 9 ? "0" + DATE : DATE) +
    "-" +
    (MONTH <= 9 ? "0" + MONTH : MONTH) +
    "-" +
    YEAR
  );
};

const PerfiosBankStatement = (props) => {
  const [modal, setModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggletool = () => setTooltipOpen(!tooltipOpen);

  const toggle = () => setModal(!modal);
  const { report } = props;
  return (
    <div className="report-square row m-0 w-100 border px-3 py-2 rounded d-flex align-items-center">
      <div className="date  pb-sm-3 pb-xl-0 float-left">
        <ul className="m-0 d-flex">
          {report.status === "initiated" ? (
            <div className="m-0 fixDivWidth185 mb10 border-right">
              <button className="inprogressBtn pt-1 cpDefault bfNone proges-btn">
                In progress
                <img
                  className="ml-3"
                  src="/images/Group 2181.png"
                  alt="loading"
                />
              </button>
            </div>
          ) : report.status === "completed" ? (
            <div className="m-0 fixDivWidth185 mb10 border-right">
              <button className="completedBtn pt-1 cpDefault bfNone proges-btn">
                Completed
                {/* <img
                  className="ml-3"
                  src="/images/Group 2181.png"
                  alt="loading"
                /> */}
                <svg
                  fill="#608341"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 26 26"
                  width="24px"
                  height="24px"
                >
                  <path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z" />
                </svg>
              </button>
            </div>
          ) : (
            <div className="m-0 fixDivWidth185 mb10 border-right">
              <button className="failedBtn pt-1 cpDefault bfNone proges-btn">
                Failed
                <img
                  className="mr-1"
                  src="/images/error_icon.png"
                  alt="loading"
                  id="TooltipExample"
                  width="20px"
                />
                <Tooltip
                  className="ErrtoolTip"
                  placement="right"
                  isOpen={tooltipOpen}
                  target="TooltipExample"
                  toggle={toggletool}
                >
                  {report.remark}
                </Tooltip>
              </button>
            </div>
          )}
          <div className="border-right cal-date fixDivWidth185">
            <li className="px-4">
              <p className="dt-year position-relative">
                {dateToDMY(report.analysed_on)}
              </p>
            </li>
          </div>
        </ul>
      </div>
      <div className="no-report  pb-sm-3 pb-xl-0  float-right">
        <ul className="report-data m-0 d-flex align-items-center float-right">
          <li className=" no-avail fixDivWidth500">
            {report.reference_id !== null ? (
              <>
                <div className="inblock">
                  <span>Report</span>
                </div>
                {report.reference_id}
              </>
            ) : null}
          </li>
          {report.status === "completed" ? (
            <>
              <div className="fixDivWidth185 viewSection">
                <span className="reportText mr-3">View</span>
                <span onClick={toggle} className="reportText mr-5 cp">
                  <img src="/images/Group 2186.png" alt="view" />
                </span>
              </div>
              {modal && (
                <div>
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalBody>
                      <iframe
                        title="document"
                        src={
                          "https://drive.google.com/viewerng/viewer?embedded=true&url=" +
                          report.document_url
                        }
                        style={{ width: 600, height: 500 }}
                        frameBorder="0"
                      ></iframe>
                    </ModalBody>
                  </Modal>
                </div>
              )}
              <a
                download
                href={report.document_url}
                className="ulNone fixDivWidth185"
              >
                <span className="downloadText mr-3">Download</span>
                <span className="reportText">
                  <img src="/images/Group 2184.png" alt="download" />
                </span>
              </a>
            </>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default PerfiosBankStatement;
