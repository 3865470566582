import React, {useState,useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col } from 'reactstrap'
import GSTDetails from "./GSTDetails";
import Demographics from "../Demographics";
import GSTRules from "./GSTRule";
import GSTReturn from "./ReturnStatus";
import { useSelector, useDispatch } from 'react-redux';
import { getReport } from '../../../../actions/AllActions';
import './GST.scss';


const GST = ({gst_profile_details, loanId}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [activeTab, setActiveTab] = useState(0);

    const dispatch = useDispatch();
    const [gstUser,setGstUser] = useState('');
    const [gstKey,setGstKey] = useState('');

    const [gstUser1,setGstUser1] = useState('');
    const [gstKey1,setGstKey1] = useState('');

    const defaultGst = gst_profile_details && Object.keys(gst_profile_details);

    useEffect(()=>{
        gst_profile_details && ( Object.keys(gst_profile_details).map((key,i) => {
            if(gst_profile_details[key].profile.is_active){
                setGstUser(gst_profile_details[key]);
                setGstKey(key);
                setGstUser1(gst_profile_details[key]);
                setGstKey1(key);
            }else{
                setGstUser(gst_profile_details[defaultGst[0]]);
                setGstKey(defaultGst[0]);
                setGstUser1(gst_profile_details[defaultGst[0]]);
                setGstKey1(defaultGst[0]);
            }
        }))
    },[]);
    const error = useSelector(state => state.all.error); 
    const rulesData = useSelector(state => state.all.report);        
    const toggle2 = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            if(tab === 2 && !rulesData.id) {     
                dispatch(getReport(loanId));
            }
        }
    }

    const Comps = [
        <GSTDetails gstUser={gstUser} gstKey={gstKey}/>,
        <GSTReturn gstUser={gstUser} gstKey={gstKey}  />,
        <GSTRules gstKey={gstKey} error={error} />
        ];

    const Tabsss = [
        {
            name: "GST Details",
            component: Comps[0],
        },
        {
            name: "Return Status",
            component: Comps[1],
        },
        {
            name: "GST Rule",
            component: Comps[2],

        }

    ]


    const activeGst = (gstKey) => {
        setGstUser1(gst_profile_details[gstKey]);
        setGstKey1(gstKey);
    }

    const confirmGst = (gstUser,gstKey) =>{
        setGstUser(gst_profile_details[gstKey]);
        setGstKey(gstKey);
    }
    return (
        
        <div className="col-md-12 gstmain gst">
            <div className="py-2 tabPrint text-center text-uppercase">
                GST
            </div>
          <div className="row m-0 card" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "30px 10px" }}>
                <div className="col-md-12">
           
            { gst_profile_details && defaultGst.length > 0 ?
            <>            
            <Nav tabs style={{ border: "none" }} className="printScreenTab">
                {
                    Tabsss.map((Tab, index) =>
                    (
                        <NavItem key={index} className="pointer">
                            
                            <NavLink
                                className={`navLink p-3 ${activeTab === index ? 'active' : ""}`}
                                onClick={() => { toggle2(index); }} >
                                {Tab.name}
                            </NavLink>                           
                        </NavItem>
                    )
                    )
                }


            </Nav>
            {gst_profile_details &&
            <div className="row mx-0 my-4">
               
                
                <div className="col-md-7 px-0 ">
                    <button className="loanIDbtn">Loan ID {loanId}</button>
                </div>
                {gst_profile_details &&
                <div className="col-md-3 text-right" >
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                        <DropdownToggle caret>
                            {gstKey1}
                        </DropdownToggle>
                        <DropdownMenu>
                            {defaultGst.map((data,i)=>(
                              <DropdownItem key={i} onClick={()=>activeGst(defaultGst[i])}>{defaultGst[i]}</DropdownItem>  
                            ))}                            
                        </DropdownMenu>
                    </Dropdown>

                </div>
                }
                <div className="col-md-2 text-right confirmbtn" >
                    <Button color="secondary" onClick={()=>confirmGst(gstUser1,gstKey1)}>Confirm</Button>
                </div>
            </div>
            }
            <TabContent activeTab={activeTab}>
                {
                    Tabsss.map((Tab, index) =>
                    (
                        <TabPane key={index} tabId={index}>

                            <Row>
                                {Tab.component}
                            </Row>
                        </TabPane>
                    )
                    )
                }

            </TabContent>
            </>
            :
            <div className="text-center">
                <p style={{fontSize: "15px",color: "#666C72"}}>No GST Data Found</p>
            </div>
            }
           </div>
           </div>
            </div>
            
    );
};
export default GST;
