import React from 'react';
import './header.scss';
const Header =(props)=>{
 return(
        <div className="headerSection d-flex p-4 bg-white">
            <div className="pr-4">
                <img src={props.headerImg}/>
            </div>
            <div className="header-text pt-1">
                <h3>{props.headerText}</h3>
            </div>
        </div>
    )
}
export default Header;