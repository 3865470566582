import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'reactstrap';
import RoundProcess from './roundProcess'
import './summarySection.scss';
import {useSelector,useDispatch} from 'react-redux';
import {getCibilSummaryData} from '../../../actions/AllActions';

function CibilSummary({camData,metaData,ID}) {

    const cibil_summary_data = useSelector(state => state.all.cibil_summary_data);    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCibilSummaryData(ID));
    }, []);

    const ValueCheck = ( x) => {
        if(x !== null){
            
            var stringValue = String(x).toLowerCase();
            if(stringValue === "true") {
                return "Yes";
            }
            else if(stringValue === "false") {
                return "No";
            }
            else {
                return x;
            }

        } else {
            return "-";
        }
    }

    const BocData =  {
        name: "CIBIL",
        icon: "/images/cam_version_3/cibil_icon.svg",
        list: {
            "Applicant Name" :[metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_applicant_name'),
                ValueCheck(cibil_summary_data &&cibil_summary_data.data && cibil_summary_data.data.attributes.applicant_name)],
            "CIBIL Score" :[metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_cibil_score'),
                ValueCheck(cibil_summary_data.data && cibil_summary_data.data.attributes.cibil_score)],
            "Suit Filled/ Willful Default":[metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_suit_filed'), 
                ValueCheck(cibil_summary_data.data && cibil_summary_data.data.attributes.suit_file_or_wilful_default)],
            "Vintage with Bureau (In Months)": [metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_vintage_with_bureau'),
                ValueCheck(cibil_summary_data.data && cibil_summary_data.data.attributes.vintage_with_bureau)],
            "Overdue greater than Rs.1000 in trade line other than credit card" : [metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_trade_line'),
                ValueCheck(cibil_summary_data.data && cibil_summary_data.data.attributes.overdue_greater_than_1000_in_trade_line_other_than_credit_card)],
            "Last 3 month count & amount of unsecured borrowing" : [metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_unsecured_borrowing_last_3_months'),
                ValueCheck(cibil_summary_data.data && cibil_summary_data.data.attributes.last_3_month_count_and_amount_of_unsecured_borrowing)],
            "Last 6 month count & amount of unsecured borrowing" : [metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_unsecured_borrowing_last_6_months'),
                ValueCheck(cibil_summary_data.data && cibil_summary_data.data.attributes.last_6_month_count_and_amount_of_unsecured_borrowing)],
            "Last 3 month count & amount of secured borrowing" : [metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_secured_borrowing_last_3_months'),
                ValueCheck(cibil_summary_data.data && cibil_summary_data.data.attributes.last_3_month_count_and_amount_of_secured_borrowing)],
            "Last 6 month count & amount of secured borrowing" :  [metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_secured_borrowing_last_6_months'),
                ValueCheck(cibil_summary_data.data && cibil_summary_data.data.attributes.last_6_month_count_and_amount_of_secured_borrowing)],
            "Any Write Off and settlement in the past" : [metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel_writeoff_status'),
                ValueCheck(cibil_summary_data.data && cibil_summary_data.data.attributes.any_write_off_and_settlement_in_the_past)],
        },        
        // score: camData && camData.actyv_scores && camData.actyv_scores.cibil_score && camData.actyv_scores.cibil_score.score || "N/A"
    }

    return (
        <Col md="12" lg="6" className="mb-3" >
            <Card className="summarySection">
                <Row className="summarySectionHead">
                    <Col md="12">
                        <Row className='align-items-center' style={{height:'90px'}}>
                            <Col md="4" sm="4">
                                <div className="float-left p-4 ">
                                    <div className="d-flex headerText">
                                        <img src={BocData.icon} />
                                        <p className="pl-2">{BocData.name}</p>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col md="8" sm="8">
                                <div className="float-right">
                                    <div className=" d-flex flex-row-reverse bd-highlight">
                                        <RoundProcess width="70" height="70" scx="37%" scy="62%" x="50" y="45" score={BocData.score} hml={BocData.hml} />
                                    </div>                                    
                                </div>
                            </Col> */}
                            
                        </Row>
                    </Col>
                </Row>
                <hr className="mb-2 mt-0" />
                <Row>
                    <Col md="12">
                        {(cibil_summary_data.data || cibil_summary_data.error) &&
                        <div className="dataFont">
                            {Object.keys(BocData.list).map((key, i) => (
                                <>
                                {BocData.list[key][0] && 
                                <p key={i} className="d-flex px-3 py-2 ">
                                    <span className="mr-auto cibilKey">{key}</span>
                                    <span className="cibilValue">{BocData.list[key][1]}</span>
                                </p>
                                }
                                </>
                            ))
                            }
                        </div>
                        }                                         
                        
                        
                    </Col>
                </Row>
                {cibil_summary_data.loading &&
                    <div className="Loadergif pt-4">
                        <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                    </div>
                }
            </Card>
        </Col>
    )
}

export default CibilSummary;
