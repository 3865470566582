import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'reactstrap';
import RoundProcess from './roundProcess'
import './summarySection.scss';
import {useSelector,useDispatch} from 'react-redux';
import {getTriangulationSummaryData} from '../../../actions/AllActions';

function TriangulationSummary({camData,metaData, ID}) {

    const get_triangulation_data = useSelector(state => state.all.get_triangulation_summary);
    const get_triangulation_summary = get_triangulation_data && get_triangulation_data.data;
    const dispatch = useDispatch();    

    useEffect(() => {
        dispatch(getTriangulationSummaryData(ID));
    }, []);


    const ValueCheck = ( x) => {
        if(x !== null){
            
            var stringValue = String(x).toLowerCase();
            if(stringValue === "true") {
                return "Yes";
            }
            else if(stringValue === "false") {
                return "No";
            }
            else {
                return x;
            }

        } else {
            return "-";
        }
    }

    return (
        <Col md="12" lg="6" className="mb-3" >
            <Card className="summarySection">
                <Row className="summarySectionHead">
                    <Col md="12">
                        <Row className='align-items-center' style={{height:'90px'}}>
                            <Col>
                                <div className="float-left p-4 ">
                                    <div className="d-flex headerText">
                                        <img src="/images/cam_version_3/file.svg" />
                                        <p className="pl-2">Triangulation of data sets</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className="mb-2 mt-0" />
                <Row>
                    <Col md="12">
                        
                        {(get_triangulation_summary || get_triangulation_data.error) &&
                        <div className="dataFont">
                            {metaData.program_details.program_band_enabled_cam_components.includes('triangulation_panel_turnover_as_per_gst') && 
                            <p className="d-flex px-3 py-2 ">
                                <span className="mr-auto cibilKey">Turnover as per GST (if GST available) for the last 12 months and if Not then Turnover as per last filed ITR</span>
                                <div className="cibilValue"><span className="gstGreen">GST</span>{get_triangulation_summary?.attributes.turnover ? ValueCheck(get_triangulation_summary.attributes.turnover) : "-"}</div>
                            </p>
                            }
                            {metaData.program_details.program_band_enabled_cam_components.includes('triangulation_panel_average_net_working_capital_requirement') && 
                             <p className="d-flex px-3 py-2 ">
                                <div className="mr-auto cibilKey">Average Net Working Capital Requirement<br/>
                                <span className="gstGreen2">(GST Turnover / Last 12 Months) * 2</span>
                                </div>
                                 <span className="cibilValue">{get_triangulation_summary?.attributes.average_net_working_capital_requirement ? ValueCheck(get_triangulation_summary.attributes.average_net_working_capital_requirement) : "-"}</span>
                            </p>
                            }
                            {metaData.program_details.program_band_enabled_cam_components.includes('triangulation_panel_od_sanctioned_limit_and_existing_business_loans') && 
                             <p className="d-flex px-3 py-2 ">
                                <span className="mr-auto cibilKey">Less: Current OD Facility and or Current Business Loan Availed </span>
                                 <span className="cibilValue">{get_triangulation_summary?.attributes.od_sanctioned_limit_and_existing_business_loans ? ValueCheck(get_triangulation_summary.attributes.od_sanctioned_limit_and_existing_business_loans) : "-"}</span>
                            </p>
                            }
                            {metaData.program_details.program_band_enabled_cam_components.includes('triangulation_panel_balance_working_capital_requirement_to_be_funded') && 
                             <p className="d-flex px-3 py-2 ">
                                <span className="mr-auto cibilKey gstGreen3">Balance Working Capital Requirement to be Funded </span>
                                 <div className="cibilValue"><span className="gstGreen1">{get_triangulation_summary?.attributes.balance_working_capital_requirement_to_be_funded ? ValueCheck(get_triangulation_summary.attributes.balance_working_capital_requirement_to_be_funded) : "-"}</span></div>
                            </p>
                            }
                            {metaData.program_details.program_band_enabled_cam_components.includes('triangulation_panel_emi_abb_ratio') && 
                             <p className="d-flex px-3 py-2 ">
                                <span className="mr-auto cibilKey">Total EMI as per CIBIL (Secured + Unsecured)/ ABB </span>
                                 <span className="cibilValue">{get_triangulation_summary?.attributes.emi_abb_ratio ? ValueCheck(get_triangulation_summary.attributes.emi_abb_ratio) : "-"}</span>
                            </p>
                            }
                            {metaData.program_details.program_band_enabled_cam_components.includes('triangulation_panel_average_purchases_and_average_payments_ratio_of_12_months') &&    
                             <p className="d-flex px-3 py-2 ">
                                <span className="mr-auto cibilKey">Average Purchases from Anchors in the Last 12 Months/ Average Payments as per Bank in 12 months </span>
                                 <span className="cibilValue">{get_triangulation_summary?.attributes.average_purchases_and_average_payments_ratio_of_12_months ? ValueCheck(get_triangulation_summary.attributes.average_purchases_and_average_payments_ratio_of_12_months) : "-"}</span>
                            </p>
                            }
                            {metaData.program_details.program_band_enabled_cam_components.includes('triangulation_panel_average_sales_and_average_payments_ratio_in_12_months') && 
                             <p className="d-flex px-3 py-2 ">
                                <span className="mr-auto cibilKey">Average Sales as per GST in last 12 months/ Average Payments received as per Bank in 12 months</span>
                                 <span className="cibilValue">{get_triangulation_summary?.attributes.average_sales_and_average_payments_ratio_in_12_months ? ValueCheck(get_triangulation_summary.attributes.average_sales_and_average_payments_ratio_in_12_months) : "-"}</span>
                            </p>
                            }   
                        </div>
                        }
                    </Col>
                </Row>
                {get_triangulation_data && get_triangulation_data.loading &&
                        <div className="Loadergif">
                            <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                        </div> 
                        }
            </Card>
        </Col>
    )
}

export default TriangulationSummary;
