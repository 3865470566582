import React, { Fragment } from 'react';
import './CoApplicant.css';
import Loader from '../Loader/Loader';

const CoApplicant = ({ camReport, error,insideCurrent }) => {
  if (camReport && Object.keys(camReport).length === 0) {
    return <Loader />;
  }
  if (error && Object.keys(error).length) {
    return <span style={{ color: "crimson", fontSize: "16px" }}>Request failed </span>
  }

  const isLast = (a,node) => {

    let b = ["property_status_residence","experian_bureau_score","experian_bureau_score_confid_level","experian_leverage_score"];
    let currentFlag = "";
    for(var data in b) {
      if(a[b[data]]) {
        currentFlag = b[data];
      }
    }

    if(node === currentFlag) {
      return true;
    }
    else {
      return false;
    }

  };

  const { co_applicants } = camReport;

  return (
    <div style={{ background: 'white' }} className="borderLeft">
      {
        co_applicants.map((coApplicant, index) => {
          return (
            <Fragment key={index}>
              {insideCurrent === index &&
              <>
              <div className="co-applicant-flex co-applicant-header">
                <div className="col-md-4 pl-4 pt-3 pb-3 co-applicant-header-text">Parameter</div>
                <div className="col-md-7 pt-3 pb-3 co-applicant-header-text">Value</div>
              </div>
              <div className="co-applicant-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">{coApplicant.is_primary ? "Primary Applicant's" : "CO-Applicant's"} name</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.name}</div>
                </div>
              </div>
              <div className="co-applicant-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">{coApplicant.is_primary ? "Primary Applicant's" : "Co-Applicant's"} age</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.age}</div>
                </div>
              </div>
              <div className="co-applicant-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">Shareholding</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.shareholding}</div>
                </div>
              </div>
              <div className="co-applicant-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">CIBIL Score Ver - 2</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.bureau_score}</div>
                </div>
              </div>
              {/*<div className="co-applicant-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">Business Address</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.business_address}</div>
                </div>
              </div>*/}
              <div className="co-applicant-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">Residence Address</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.residence_address}</div>
                </div>
              </div>
              <div className={isLast(coApplicant,"property_status_residence") ? "co-applicant-flex bordertopBottom707070" : "co-applicant-flex bordertop707070"}>
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">Residence Address Stability</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.property_status_residence}</div>
                </div>
              </div>

              {coApplicant.experian_bureau_score &&
              <div className={isLast(coApplicant,"experian_bureau_score") ? "co-applicant-flex bordertopBottom707070" : "co-applicant-flex bordertop707070"}>
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">Experian Bureau Score</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.experian_bureau_score}</div>
                </div>
              </div>
              }


              {coApplicant.experian_bureau_score_confid_level &&
              <div className={isLast(coApplicant,"experian_bureau_score_confid_level") ? "co-applicant-flex bordertopBottom707070" : "co-applicant-flex bordertop707070"}>
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">Experian Bureau Score Confid Level</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.experian_bureau_score_confid_level}</div>
                </div>
              </div>
              }

              {coApplicant.experian_leverage_score &&
              <div className={isLast(coApplicant,"experian_leverage_score") ? "co-applicant-flex bordertopBottom707070" : "co-applicant-flex bordertop707070"}>
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="co-applicant-text">Experian Leverage Score</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pb-3">
                  <div className="col-md-12 co-applicant-text">{coApplicant.experian_leverage_score}</div>
                </div>
              </div>
              }

              </>
              }
            </Fragment>
          )
        })
      }
    </div>
  )
}

export default CoApplicant;