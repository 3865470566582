import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import RoundProcess from './roundProcess'
import './summarySection.scss';
import {useSelector,useDispatch} from 'react-redux';
import {getBankingDataSummary} from '../../../actions/AllActions';
//api/v1/banking/banking_data?loan_application_id=6341&account_number
function BankingAnalysis({camData,metaData, ID}) {

    const banking_data = useSelector(state => state.all.banking_data_summary);    
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle1 = () => setDropdownOpen(prevState => !prevState);
    const [lastClicked, setLastClicked] = useState({BankName: '', index: 0, AccNumber:0});
    const [flag, setFlag] = useState(false);


    var defaultBank = banking_data && banking_data.data && banking_data.data.meta && banking_data.data.meta[0] && banking_data.data.meta[0].bank_name;
    var defaultAccount = banking_data && banking_data.data && banking_data.data.meta && banking_data.data.meta[0] && banking_data.data.meta[0].account_number;
    var AccountNumber = banking_data && banking_data.data && banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[0] && Object.keys(banking_data.data.attributes.bank_data[0]);

    useEffect(() => {
        dispatch(getBankingDataSummary(ID));
    }, []);

    const AccountNoCheck = () => {
        if(lastClicked.AccNumber === 0){
            return AccountNumber;
        }else {
            return lastClicked.AccNumber;
        }
    }

    const ValueCheck = ( x) => {
        if(x !== null){
            
            var stringValue = String(x).toLowerCase();
            if(stringValue === "true") {
                return "Yes";
            }
            else if(stringValue === "false") {
                return "No";
            }
            else {
                return x;
            }

        } else {
            return "-";
        }
    }

    const setMe = (a) => {
        setLastClicked(a);
        setFlag(!flag);
    }

    var BoxDataObj = [];

    var PennyDrop = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_penny_drop_status'),
                        banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].penny_drop_status !== undefined  && { title: "Penny Drop Status", value: ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].penny_drop_status)}];
    var Last6Month = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_avg_last_6_months'),
                        banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].avg_last_6_months !== undefined && {title: "ABB for last 6 months", value: ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].avg_last_6_months)}];
    var Last3Month = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_avg_last_3_months'),
                        banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].avg_last_3_months !== undefined && {title:"ABB for last 3 months", value: ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].avg_last_3_months)}];
    var Last12Month = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_avg_last_12_months'),
                        banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].avg_last_12_months !== undefined && {title: "ABB for last 12 months", value: ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].avg_last_12_months)}];
    var LimitSanctioned = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_sactioned_limit'),
                        banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].sactioned_limit !== undefined && {title:"Limit Sanctioned:", value : ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].sactioned_limit)}];
    var AvgAmntUtilised = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_average_amount_utilised'),
                        banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].average_amount_utilised !== undefined && {title:"Average Amount Utilised", value : ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].average_amount_utilised)}];
    var Breached95 = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_utilisation_of_95_percent'),
                        banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].utilisation_of_95_percent !== undefined && {title: "Number of times the customer has breached over 95% utilisation", value : ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].utilisation_of_95_percent)}];
    var ChequeIssued = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_percent_of_cheques_issued'),
                        banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].percent_of_cheques !== undefined && {title:"Cheques dishonored as a % of cheques issued", value : ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].percent_of_cheques)}];
    var PerTotalDebit = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_percent_of_total_debites'),
                        banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].percent_of_total_debites !== undefined && {title:"Payments Made to Anchor as a % of total debits in the Bank", value : ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].percent_of_total_debites)}];
    var AvgPurchaseGST = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_percent_of_avg_purchases'),
                            banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].percent_of_avg_purchases !== undefined && {title:"Payments made to Anchor as a % of Average Purchases from GST for the anchor", value : ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].percent_of_avg_purchases)}];
    var TotalEMICibil = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_total_emi_per_cibil'),
                            banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].total_emi_per_cibil !== undefined && {title:"Total EMI as per CIBIL", value : ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].total_emi_per_cibil)}];
    var EMIBankStatement = [metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel_total_emi_per_bank_statement'),
                            banking_data && banking_data.data &&  banking_data.data.attributes && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data[lastClicked.index] && banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].total_emi_per_bank_statement !== undefined && { title: "Total EMI as per Bank Statement", value: ValueCheck(banking_data.data.attributes.bank_data[lastClicked.index][AccountNoCheck()].total_emi_per_bank_statement) }];    

    BoxDataObj.push(PennyDrop,
        LimitSanctioned,AvgAmntUtilised,Breached95,Last12Month,Last6Month,Last3Month,ChequeIssued,PerTotalDebit,AvgPurchaseGST,TotalEMICibil,EMIBankStatement)

    const BocData =  {
        name: "Banking Analysis",
        icon: "/images/cam_version_3/banking.svg",
        list: BoxDataObj,
        // score: camData && camData.actyv_scores && camData.actyv_scores.bank_statement_score && camData.actyv_scores.bank_statement_score.score || "N/A"
    }
    return (
        <Col md="12" lg="6" className="mb-3" >
            <Card className="summarySection">
                <Row className="summarySectionHead">
                    <Col md="12">
                        <Row className='align-items-center' style={{height:'90px'}}>
                            <Col md="4" sm="4">
                                <div className="float-left p-4 ">
                                    <div className="d-flex headerText">
                                        <img src={BocData.icon} />
                                        <p className="pl-2">{BocData.name}</p>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col md="8" sm="8">
                                <div className="float-right">
                                    <div className=" d-flex flex-row-reverse bd-highlight">
                                        <RoundProcess width="70" height="70" scx="37%" scy="62%" x="50" y="45" score={BocData.score} hml={BocData.hml} />
                                    </div>                                    
                                </div>
                            </Col>                             */}
                        </Row>
                    </Col>
                </Row>
                <hr className="mb-2 mt-0" />                
                <Row>                    
                    <Col md="12">
                        {!banking_data.loading &&
                        <>
                        {banking_data && banking_data.data && banking_data.data.attributes.bank_data && banking_data.data.attributes.bank_data.length > 0 ?
                        <div className="dataFont">                           
                            
                                <p className="d-flex px-3 py-2 align-items-center buttonCss">
                                    <span className="mr-auto cibilKey">Bank Name</span>
                                    <div className="text-center" >                                        
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle1} >
                                            <DropdownToggle caret className="bbankName"> 
                                            {lastClicked.BankName ? lastClicked.BankName+" ("+lastClicked.AccNumber+")" : defaultBank+" ("+defaultAccount+")"}                                              
                                            </DropdownToggle>
                                            <DropdownMenu container="body" className="bbankNameITem" >
                                                {banking_data && banking_data.data && banking_data.data.meta.map((data, i) => (
                                                    <DropdownItem key={i} onClick={() => setMe({BankName:data.bank_name, index:i, AccNumber:data.account_number})}>{`${data.bank_name} (${data.account_number})`}</DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </p>
                                {BoxDataObj.map((key, i) => (                                  
                                   <>
                                   {key && key[0]=== true && key && key[1].title &&
                                    <p key={i} className="d-flex px-3 py-2 ">
                                            <span className="mr-auto cibilKey">{key && key[1].title}</span>
                                            <span className="cibilValue">{key && key[1].value}</span>
                                        </p>
                                      
                                    }
                                    </>
                                ))
                                }
                                
                        </div>
                        :
                        <div style={{padding:"30px 10px", textAlign: "center",color: "#666c72"}}>No Banking Data Found</div>

                    }
                    </>
                    }
                    
                    
                    </Col>
                    
                    
                   
                    
                </Row>
                {banking_data && banking_data.loading &&
                <div className="Loadergif" >
                            <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                        </div>
                         }
            </Card>
        </Col>
    )
}

export default BankingAnalysis;
