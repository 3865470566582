import React from 'react';
import './Loader.scss';

const Loader = () => {
    return (
        <div className="content">
            <div className="column">
                <div className="container animation-5">
                    <div className="shape shape1"></div>
                    <div className="shape shape2"></div>
                    <div className="shape shape3"></div>
                    <div className="shape shape4"></div>
                </div>
            </div>
        </div>
    )
}

export default Loader;