import React, { Component } from 'react';
import * as d3 from "d3";
import { connect } from 'react-redux';
import { getMlScore } from '../../actions/AllActions';
import './MLScore.css';

class MLScore extends Component {

    state = {
        businessName: "",
        loanId: "",
        businessType: "",
        ReportDate: "",
        score: ""
    }

    componentDidMount() {
        this.props.getMlScore();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
         const { ml_score } = nextProps.all;
         if (Object.keys(ml_score).length)
             this.displayChart(ml_score)
    }

    displayChart = mlScore => {
        const margin = {
            top: 20,
            right: 100,
            bottom: 50,
            left: 170
        },
        adjustWidth = 250,
        width = window.innerWidth - margin.right - margin.left - adjustWidth,
        height = 330;

        const data = [];
        mlScore.included.forEach(inc => {
            const { attributes } = inc;
            if (attributes.expression_name === "intercept")
                return
            const tempObj = {};
            
            tempObj['customer_input'] = attributes.expression_name === "agreement_type" ? attributes.customer_input : attributes.expression_name === "age_of_oldest_tdlines_open" ? attributes.customer_input + "months" : attributes.expression_name === "age_of_users" ? attributes.customer_input.split('.')[0] + "years" : attributes.expression_name === "qualification" ? attributes.customer_input : attributes.expression_name === "tot_enq_12_months" ? attributes.customer_input + "enqueries" : attributes.expression_name === "tot_num_busi_loan_open_cbal_gt_0" ? attributes.customer_input + "business loans" : attributes.expression_name === "cibil_score" ? attributes.customer_input : "";

            tempObj['expression_name'] = attributes.expression_name === "agreement_type" ? "Agreement Type" : attributes.expression_name === "age_of_oldest_tdlines_open" ? "Age of the Oldest Open Tradeline" : attributes.expression_name === "age_of_users" ? "Age of the User" : attributes.expression_name === "qualification" ? "Qualification of the User" : attributes.expression_name === "tot_enq_12_months" ? "Total Enquries in 12 Months" : attributes.expression_name === "tot_num_busi_loan_open_cbal_gt_0" ? "Total Number of Open Business Loans" : attributes.expression_name === "cibil_score" ? "Cibil Score of the User" : "";
            
            tempObj['resultant_weightage'] = attributes.resultant_weightage;
            
            data.push(tempObj);
        })

        //Add svg to
        const svg = d3.select('#charts').append('svg').attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom).style('overflow', 'visible').append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
        const tooltip = d3.select("#charts").append("div").attr("class", "toolTip");
        // set the ranges
        const y = d3.scaleBand()
            .range([height, 0])
            .padding(0.7);

        const x = d3.scaleLinear()
            .range([0, width]);

        const y1 = d3.scaleBand()
            .range([height, 0])
            .padding(0.4);

        // Scale the range of the data in the domains
        x.domain(d3.extent(data, function (d) {
            return d.resultant_weightage;
        }));
        y.domain(data.map(function (d) {
            return d.expression_name;
        }));
        y1.domain(data.map(function (d) {
            return d.customer_input;
        }));

        // Add dots
        svg.append('g')
            .selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", function (d) {
                if (d.resultant_weightage <= 0)
                    return Math.floor(x(0)) - 1
                else
                    return Math.floor(x(0)) - 1
            })
            .attr("cy", function (d) { return Math.floor(y(d.expression_name)) + 6 })
            .attr("r", 10)
            .style("fill", function (d) {
                if (d.resultant_weightage === 0)
                    return "#00FF00"
                else if (d.resultant_weightage < 0)
                    return "#ff0000a6"
                else
                    return "#0000ffad"
            })
            .on("mousemove", function (d) {
                tooltip
                    .style("left", d3.event.pageX - 5 + "px")
                    .style("top", d3.event.pageY - 30 + "px")
                    .style("display", "inline-block")
                    .html(d.resultant_weightage);
            })
            .on("mouseout", function () { tooltip.style("display", "none") });

        // add the Y gridlines
        svg.append("g")
            .attr("class", "grid")
            .call(d3.axisLeft(y)
                .ticks(5)
                .tickSize(-width)
                .tickFormat("")
            )
            .style("stroke-dasharray", "1 1")

        // append the rectangles for the bar chart
        svg.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            .attr("class", function (d) {
                return "bar bar--" + (d.resultant_weightage < 0 ? "negative" : "positive");
            })
            .attr("x", function (d) {
                if (d.resultant_weightage === 0)
                    return x(Math.min(0, d.resultant_weightage));
                else if (d.resultant_weightage < 0)
                    return x(Math.min(0, d.resultant_weightage));
                else
                    return x(Math.min(1, d.resultant_weightage));
            })
            .attr("y", function (d) {
                return y(d.expression_name)+ 3.5;
            })
            .attr("width", function (d) {
                if (d.resultant_weightage < 0)
                    return Math.abs(x(d.resultant_weightage+1.5) - x(0));
                else if (d.resultant_weightage === 0)
                    return 0;
                else 
                    return Math.abs(x(d.resultant_weightage) - x(0)) > 1150 ? 1150 : Math.abs(x(d.resultant_weightage-1) - x(0));
            })
            .attr("height", 6)
            .on("mousemove", function (d) {
                tooltip
                    .style("left", d3.event.pageX - 5 + "px")
                    .style("top", d3.event.pageY - 30 + "px")
                    .style("display", "inline-block")
                    .html(d.resultant_weightage);
            })
            .on("mouseout", function () { tooltip.style("display", "none") });

        // add the x Axis
        svg.append("g")
            .attr("transform", "translate(" + 0 + "," + height + ")")
            .call(d3.axisBottom(x));
        
        function wrap(text, width) {
            text.each(function() {
            var text = d3.select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                lineHeight = 1.1, // ems
                y = text.attr("y"),
                dy = parseFloat(text.attr("dy")),
                tspan = text.text(null).append("tspan").attr("x", -15).attr("y", y).attr("dy", dy + "em");
                while (word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node().getComputedTextLength() > 150) {
                    line.pop();
                    tspan.text(line.join(" "));
                    line = [word];
                    tspan = text.append("tspan").attr("x", -15).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                    }
                }
            });
        }

        // add the y Axis
        svg.append("g")
            .attr("class", "y-axis")
            .call(d3.axisLeft(y))
            .selectAll(".tick text")
            .call(wrap);

        // add the y1 Axis
        svg.append("g")
            .attr("class", "y-axis")
            .attr("transform", 'translate('+width+',0)')
            .call(d3.axisRight(y1));

        const { attributes } = mlScore.data;
        const { business_name, loan_application_id, business_type, created_at, score } = attributes;
        this.setState({ businessName: business_name, loanId: loan_application_id, businessType: business_type, ReportDate: created_at.split(' ')[0], score })
    }

    render() {
        const { businessName, loanId, ReportDate, businessType, score } = this.state;
        return (
            <div className="main-div">
                <header>
                </header>
                <section className="borrow-detail">
                    <div className="borrow-business d-flex justify-content-between align-items-center">
                        <div className="detail-heading">
                            <p className="m-0">Borrower Business Details</p>
                        </div>
                        <div className="minitif-score d-flex align-items-center">
                            <p className="m-0">Mintifi-score</p>
                            <a href="#"> {`${score}/900`}</a>
                        </div>
                    </div>
                    <div className="business-menu d-flex align-items-center">
                        <div className="d-flex yatra-type align-items-center">
                            <label className="m-0">Business Name</label>
                            <p className="m-0">{businessName}</p>
                        </div>
                        <div className="d-flex yatra-type align-items-center">
                            <label className="m-0">Load Id</label>
                            <p className="m-0">{loanId}</p>
                        </div>
                        <div className="d-flex yatra-type align-items-center">
                            <label className="m-0">Business Reg Type</label>
                            <p className="m-0">{businessType}</p>
                        </div>
                        <div className="d-flex yatra-type align-items-center">
                            <label className="m-0">Date Of Report</label>
                            <p className="m-0">{ReportDate}</p>
                        </div>
                    </div>
                    <div className="chart-body">
                        <div id="charts" ></div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => ({
     all: state.all
});

const mapDispatchToProps = {
     getMlScore
};

export default connect(mapStateToProps, mapDispatchToProps)(MLScore);   