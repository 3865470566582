import React, { Component } from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import { connect } from "react-redux";
import "react-dates/lib/css/_datepicker.css";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import {
  postParseStatements,
  getAnalysisReports,
  getBankList,
} from "../../actions/AllActions";
import PerfiosBankStatement from "../../components/PerfiosBankStatement/PerfiosBankStatement";
import PerfiosBankStatement1 from "../../components/PerfiosBankStatement/PerfiosBankStatement1";
import Loading from "../../components/Loading/Loading";
import "./Perfios.css";
import reactM from "moment";

class Perfios extends Component {
  state = {
    initiate: false,
    startDate: null,
    endDate: null,
    focused1: null,
    focused2: null,
    sisterCompanyTags: [],
    sisterCompanyTag: "",
    companyTags: [],
    companyTag: "",
    FacilityOptions: [
      { label: "Current", value: "AC" },
      { label: "Saving", value: "SB" },
      { label: "OD", value: "OD" },
      { label: "CC", value: "CC" },
    ],
    selectedFacility: "",
    SanctionOptions: [
      { label: "FIXED", value: "FIXED" },
      { label: "VARIABLE", value: "VARIABLE" },
    ],
    selectedSanction: "",
    tempstartDate: null,
    tempFocused: null,
    tempSelection: "",
    monthDiff: [],
    selectedId: [],
    showModal: false,
    ReductionOptions: [],
    selectedReduction: "",
    tempAmount: "",
    sanctionKey: [],
    drawingKey: [],
    showStatement: 1,
    expanded: false,
    checkErrors: {},
    dateError: {},
    documentLink: "",
    selectedBankId: {},
    bankListOption: [],
    bankError: {},
    SB_AC: ["SB", "AC"],
  };

  handleDelete = (companyType, i) => {
    if (companyType === "company") {
      const tags = [...this.state.companyTags];
      const newTags = tags.filter((_, index) => index !== i);
      this.setState({ companyTags: newTags });
    } else {
      const tags = [...this.state.sisterCompanyTags];
      const newTags = tags.filter((_, index) => index !== i);
      this.setState({ sisterCompanyTags: newTags });
    }
  };

  handleAddition = (companyType, tag) => {
    if (companyType === "company" && tag !== "") {
      const tags = [...this.state.companyTags];
      tags.push(tag);
      this.setState({ companyTags: tags, companyTag: "" });
    } else if (companyType === "sisterCompany" && tag !== "") {
      const tags = [...this.state.sisterCompanyTags];
      tags.push(tag);
      this.setState({ sisterCompanyTags: tags, sisterCompanyTag: "" });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.state;
    if (startDate !== null && endDate !== null)
      if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
        const monthDiff = [];
        const monthNumber = this.CalDateDiff(
          new Date(endDate),
          new Date(startDate)
        );
        for (let i = 0; i < monthNumber; i++) monthDiff.push(i);
        this.setState({ monthDiff });
      }
  }

  CalDateDiff = (date1, date2) => {
    const errors = {};
    if (date1 >= date2) errors["dateRange"] = "";
    else
      errors["dateRange"] =
        "End date should be greater than equal to Start Date";
    this.setState({ dateError: errors });

    let months;
    months = Math.abs(date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date2.getMonth();
    months += date1.getMonth();
    return months <= 0 ? 1 : months;
  };

  resetState = () => {
    this.setState({
      initiate: false,
      startDate: null,
      endDate: null,
      focused1: null,
      focused2: null,
      sisterCompanyTags: [],
      sisterCompanyTag: "",
      companyTags: [],
      companyTag: "",
      FacilityOptions: [
        { label: "Current", value: "CA" },
        { label: "Saving", value: "SB" },
        { label: "OD", value: "OD" },
        { label: "CC", value: "CC" },
      ],
      selectedFacility: "",
      SanctionOptions: [
        { label: "FIXED", value: "FIXED" },
        { label: "VARIABLE", value: "VARIABLE" },
      ],
      selectedSanction: "",
      tempstartDate: null,
      tempFocused: null,
      tempSelection: "",
      monthDiff: [],
      selectedId: [],
      showModal: false,
      ReductionOptions: [],
      selectedReduction: "",
      tempAmount: "",
      sanctionKey: [],
      drawingKey: [],
      showStatement: 1,
      expanded: false,
      checkErrors: {},
      dateError: {},
      selectedBankId: {},
      bankError: {},
    });
  };

  dateToDMY = (date) => {
    const DATE = new Date(date).getDate();
    const MONTH = new Date(date).getMonth() + 1;
    const YEAR = new Date(date).getFullYear();
    return (
      "" +
      (DATE <= 9 ? "0" + DATE : DATE) +
      "-" +
      (MONTH <= 9 ? "0" + MONTH : MONTH) +
      "-" +
      YEAR
    );
  };

  selectedBankIdFn = (document_id, selectedBank) => {
    var state = this.state;
    state.selectedBankId[document_id] = selectedBank;
    state.bankError = {};
    this.setState(state);
  };

  handleCheck = (id) => {
    const errors = {};
    const ids = [...this.state.selectedId];
    if (ids.includes(id)) ids.splice(ids.indexOf(id), 1);
    else ids.push(id);

    if (ids.length === 0) {
      errors["statements"] = "Statements is required";
      this.setState({ selectedId: ids, checkErrors: errors, initiate: false });
    } else {
      errors["statements"] = "";
      this.setState({ selectedId: ids, checkErrors: errors });
    }
  };

  showMore = () => {
    const { showStatement } = this.state;
    const { analyseReport, paramId, getAnalysisReports } = this.props;
    showStatement === 1
      ? this.setState(
        {
          showStatement: analyseReport && analyseReport.length,
          expanded: true,
        },
        () => {
          getAnalysisReports(paramId);
        }
      )
      : this.setState({ showStatement: 1, expanded: false });
  };

  submitStatement = () => {
    const {
      selectedId,
      selectedFacility,
      tempSelection,
      startDate,
      endDate,
      selectedReduction,
      sanctionKey,
      drawingKey,
      selectedSanction,
      tempAmount,
      tempStartDate,
      tempEndDate,
      companyTags,
      sisterCompanyTags,
      dateError,
      selectedBankId,
      SB_AC,
    } = this.state;
    let { bankError } = this.state;
    const { paramId } = this.props;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const errors = {};
    const sanctionValue = [];
    sanctionKey.map((sk) => sanctionValue.push(this.state[sk]));

    const drawingValue = [];
    drawingKey.map((sk) => drawingValue.push(this.state[sk]));

    if (paramId === undefined || paramId === "" || paramId === null) {
      errors["loan_application_id"] = "Loan Application Id is required";
    } else {
      errors["loan_application_id"] = "";
    }

    if (selectedId.length === 0) {
      errors["statements"] = "Statements is required";
    } else {
      errors["statements"] = "";
    }

    if (startDate === null || endDate === null) {
      dateError["dateRange"] = "Date Range is required";
    } else {
      dateError["dateRange"] = "";
    }

    if (
      selectedId.length > 0 &&
      Object.keys(selectedBankId).length !== selectedId.length
    ) {
      for (var data in selectedId) {
        if (selectedBankId[selectedId[data]] === undefined) {
          bankError[selectedId[data]] = "Select Bank";
        }
      }
    } else {
      bankError = {};
    }

    if (selectedFacility === "") {
      errors["facility"] = "Facility is required";
    } else {
      errors["facility"] = "";
    }

    if (tempSelection === "") {
      errors["temp"] = "Temporary Over Draft is required";
    } else {
      errors["temp"] = "";
    }

    if (Object.values(errors).some((er) => er !== "")) {
      this.setState({ checkErrors: errors });
      return;
    }
    if (Object.values(dateError).some((er) => er !== "")) return;

    if (Object.values(bankError).some((er) => er !== "")) {
      this.setState({ bankError: bankError });
      return;
    }

    const selectedDocsID = selectedId.map((si) => ({
      document_id: si,
      institution_id: Number(selectedBankId[si].value),
    }));
    const statements = {
      app_id: "1",
      loan_application_id: paramId,
      statements: selectedDocsID,
      facility:
        SB_AC.indexOf(selectedFacility.value) === -1
          ? selectedFacility.value
          : "NONE",
      is_tod_allowed: tempSelection === "yes" ? "true" : "false",
      from_date: startDate !== null ? this.dateToDMY(startDate) : "",
      to_date: endDate !== null ? this.dateToDMY(endDate) : "",
      is_sanction_limit_fixed: selectedSanction.value === "FIXED" ? true : false,
      day_of_drawing_power_reduction: selectedReduction.value,
      tod_amount: tempAmount,
      company_names: companyTags,
      sister_company_names: sisterCompanyTags,
    };

    if (selectedFacility.value === "OD" && selectedSanction.value === "FIXED") {
      statements["sanction_limit_fixed_amount"] = sanctionValue[0];
      statements["drawing_power_fixed_amount"] = drawingValue[0];
    } else {
      statements["sanction_limit_variable_amounts"] = sanctionValue;
      statements["drawing_power_variable_amounts"] = drawingValue;
    }

    if (tempSelection === "yes") {
      statements["tod_start_date"] = this.dateToDMY(tempStartDate);
      statements["tod_end_date"] = this.dateToDMY(tempEndDate);
    }
    this.props.postParseStatements(statements, params.get("token"));
    this.resetState();
  };

  componentDidMount() {
    var now = new Date().getTime();
    var endDate = reactM(now);
    var startDate = reactM(now).subtract(6, "months");
    const updateReduction = [];
    for (let i = 1; i <= 28; i++) {
      updateReduction.push({ label: i, value: i });
    }
    this.setState({
      ReductionOptions: updateReduction,
      startDate: startDate,
      endDate: endDate,
    });
    var that = this;
    this.props.getBankList().then((res) => {
      if (res.data) {
        var bankList = res.data;
        let bankListOption = [];
        for (var data in bankList) {
          bankListOption.push({
            label: bankList[data].attributes.name,
            value: bankList[data].attributes.institution_id,
          });
        }
        that.setState({ bankListOption: bankListOption });
      }
    });
  }

  handleSanction = (e, type) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (type === "sanction") {
      const sanctionKey = [...this.state.sanctionKey];
      if (!sanctionKey.includes(name)) {
        sanctionKey.push(name);
      }
      this.setState({ sanctionKey });
    } 
    else if (type === "drawing") {
      const drawingKey = [...this.state.drawingKey];
      if (!drawingKey.includes(name)) {
        drawingKey.push(name);
      }
      this.setState({ drawingKey });
    }
    this.setState({ [name]: value });
  };

  validateStatement = () => {
    const errors = {};
    if (this.state.selectedId.length === 0) {
      errors["statements"] = "Statements is required";
      this.setState({ checkErrors: errors });
    } else {
      errors["statements"] = "";
      this.setState({ initiate: true, checkErrors: errors });
    }
  };

  isOutsideRange = (day) =>
    day.isAfter(moment()) || day.isBefore(moment().subtract(12, "months"));


  isOutsideRange10yrs = (day) =>
    day.isAfter(moment()) || day.isBefore(moment().subtract(120, "months"));

  render() {
    const { statements, analysisReports, all, paramId } = this.props;
    const { isFetching, error } = all;
    const {
      initiate,
      companyTags,
      companyTag,
      sisterCompanyTags,
      sisterCompanyTag,
      FacilityOptions,
      selectedFacility,
      SanctionOptions,
      selectedSanction,
      focused1,
      focused2,
      startDate,
      endDate,
      tempStartDate,
      tempEndDate,
      tempFocused1,
      tempFocused2,
      tempSelection,
      monthDiff,
      selectedId,
      showModal,
      documentLink,
      ReductionOptions,
      selectedReduction,
      tempAmount,
      expanded,
      showStatement,
      checkErrors,
      dateError,
      bankError,
      SB_AC,
    } = this.state;

    const customStyles = {
      valueContainer: (provided, state) => ({
        ...provided,
        height: 34,
        "font-size": 'Bold 16px/20px Lato',
      }),
      control: (provided, state) => ({
        ...provided,
        border: "1px solid rgb(40 160 173)",
        "border-radius": 4,
        "padding-bottom": "5px",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        "font-size": 'Bold 16px/20px Lato',
        "margin-top": 5,
      }),
      container: (provided, state) => ({
        ...provided,
        "z-index": 2,
      }),

      indicatorsContainer: (provided, state) => ({
        ...provided,
        "padding-top": "5px",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        "margin-top": "5px",
      }),
    };
    return (
      <>
        {error && Object.keys(error).length
          ? error.map((err) => {
            return (
              <div key={err} style={{ textAlign: "center" }}>
                {
                  <div key={err} className="col-md-12 d-flex mb-2">
                    <div className="col-md-4 m-auto">
                      <span
                        className="col-md-4 m-auto"
                        style={{ color: "crimson", fontSize: "22px" }}
                      >
                        {err.detail ||
                          "Technical server error! Please try after some time."}
                      </span>
                    </div>
                  </div>
                }
              </div>
            );
          })
          : null}
        <div style={{ background: "white" }} className="plr45">
          <ToastContainer autoClose={2000} />
          <div className="report">
            <p className="m-0 pb-1 bnk-rpt">Bank Statement Reports</p>
            {analysisReports &&
              analysisReports.analysis_reports &&
              analysisReports.analysis_reports.length ? (
                analysisReports.analysis_reports
                  .slice(0, showStatement)
                  .map((report, index) => (
                    <PerfiosBankStatement key={index} report={report} />
                  ))
              ) : (
                <div className="report-square row m-0 w-100 border p-3 rounded d-flex align-items-center">
                  <div className="date col-12 pb-sm-3 pb-xl-0 col-xl-6 float-left">
                    <ul className="m-0 d-flex">
                      <div className="m-0 fixDivWidth185 border-right">
                        <button className="inprogressBtn pt-1 cpDefault bfNone">
                          <span style={{ float: "left" }}>NO REPORT</span>
                          <span style={{ float: "right" }}>
                            <img
                              className="ml-2"
                              src="/images/Group 2181.png"
                              alt="loading"
                            />
                          </span>
                        </button>
                      </div>
                    </ul>
                  </div>
                  <div className="no-report col-12 pb-sm-3 pb-xl-0 col-xl-6 float-right">
                    <ul className="report-data m-0 d-flex align-items-center float-left">
                      <li className="px-1 no-avail">
                        <span>No Reports Available</span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
          </div>
        </div>
        {analysisReports &&
          analysisReports.analysis_reports &&
          analysisReports.analysis_reports.length &&
          analysisReports.analysis_reports.length > 1 ? (
            <div className="mt-2 mb-2 center cpDefault">
              <div className="loadMoreText cpDefault">
                <a className="loadMoreText" onClick={this.showMore}>
                  <span className="loadMoreText">
                    {`${expanded ? "Show less" : "Load more"}`}{" "}
                  </span>
                  {expanded ? (
                    <p>
                      <img
                        src="/images/Group 2196.png"
                        alt="plus"
                        className="rotateimg180"
                      />
                    </p>
                  ) : (
                      <p className="loadMoreText">
                        <img src={"/images/Group 2196.png"} alt="plus" />
                      </p>
                    )}
                </a>
              </div>
            </div>
          ) : null}
        {isFetching && <Loading />}
        <section className="bank-stmnt {/*border*/}">
          <div className="container-fluid">
            <div className="stmnt-data d-inline-block w-100 ">
              <div className="upload-data d-table w-100">
                <div className="stmnt-head float-left border-dotted">
                  <p className="text-left pb-2 m-0">Bank Statements</p>
                </div>
                {/* <div className="upload float-right">
                                    <ul className="m-0">
                                        <li className="d-inline-block"><i className="fa fa-arrow-up" aria-hidden="true"></i></li>
                                        <li className="d-inline-block"><p>Upload</p></li>
                                    </ul>
                                </div> */}
              </div>
              {statements && statements.length
                ? statements.map((statement) => {
                  return (
                    <PerfiosBankStatement1
                      bankError={
                        bankError && bankError[statement.document_id]
                      }
                      selectedBankIdFn={this.selectedBankIdFn}
                      selectedBankId={
                        this.state.selectedBankId &&
                        this.state.selectedBankId[statement.document_id]
                      }
                      bankListOption={this.state.bankListOption}
                      selectedId={selectedId}
                      dateToDMY={this.dateToDMY}
                      handleCheck={this.handleCheck}
                      key={statement.document_id}
                      statement={statement}
                    />
                  );
                })
                : null}
              {checkErrors["statements"] &&
                checkErrors["statements"].length > 0 && (
                  <span style={{ color: "crimson", fontSize: "16px" }}>
                    {checkErrors["statements"]}
                  </span>
                )}
            </div>
            <div className="invite-btn stmnt-data float-right pt-5">
              <button onClick={() => this.validateStatement()}>INITIATE</button>
            </div>
            {initiate === true ? (
              <div className="app-div d-inline-block w-100 AppDivBank">
                <div className="loan-id ">
                  {/* className="loan-id row m-0 d-inline-block" */}
                  <div className="company-nm  row m-0">
                    <div className="app-id col-lg-6 col-md-12 float-left d-flex flex-column py-4 pl30" style={{ 'padding-right': '30px' }}>
                      <label>
                        Application ID<span>*</span>
                      </label>
                      <input
                        className="m-0 w-100 "
                        style={{ color: "#6d7383" }}
                        type="text"
                        placeholder="LOAN ID"
                        value={paramId}
                        readOnly
                      // style={{ paddingTop: 15 }}
                      />
                      {checkErrors["app_id"] &&
                        checkErrors["app_id"].length > 0 && (
                          <span style={{ color: "crimson", fontSize: "16px" }}>
                            {checkErrors["app_id"]}
                          </span>
                        )}
                    </div>
                    <div className="app-id last-div3 date dateRangeCol col-lg-6 col-md-12 m-0 float-left pr-0" style={{ 'padding-top': "6px", 'padding-right': '30px' }}>
                      <div
                        className="position-relative float-left d-flex flex-column"
                        style={{ width: "45%", 'margin-top': '17px' }}
                      >
                        <label>Date range<span>*</span></label>
                        <SingleDatePicker
                          date={startDate}
                          id="date3"
                          onDateChange={(date) =>
                            this.setState({ startDate: date })
                          }
                          focused={focused1}
                          numberOfMonths={1}
                          onFocusChange={({ focused }) =>
                            this.setState({ focused1: focused })
                          }
                          displayFormat="DD/MM/YYYY"
                          isOutsideRange={this.isOutsideRange10yrs}
                          readOnly
                        />
                      </div>
                      <div
                        className="cal d-inline-flex w100"
                        style={{ margin: "37px 0 0 0" }}
                      >
                        <div className="arrow1" style={{ width: "30px", height: "30px" }}>
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ width: "25px", height: "10px" }}
                          ></i>
                          <i
                            className="fa fa-long-arrow-left"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div
                        className=" float-right d-flex flex-column"
                        style={{ width: "45%", margin: "37px 0 0 0" }}
                      >
                        <SingleDatePicker
                          date={endDate}
                          id="date4"
                          onDateChange={(date) =>
                            this.setState({ endDate: date })
                          }
                          focused={focused2}
                          numberOfMonths={1}
                          onFocusChange={({ focused }) =>
                            this.setState({ focused2: focused })
                          }
                          displayFormat="DD/MM/YYYY"
                          isOutsideRange={this.isOutsideRange10yrs}
                          readOnly
                        />
                      </div>
                    </div>

                  </div>
                  <hr
                    style={{
                      "border-top": "1px solid rgb(109, 115, 131)",
                    }}
                  />
                  <div className="company-nm row m-0">
                    <div className="app-id col-lg-6 col-12 float-left d-flex flex-column py-4 icon pl30" style={{ 'padding-right': '30px' }}>
                      <label>Company Name</label>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        className="position-relative m-0 bg-color"
                        value={companyTag}
                        onChange={(event) => {
                          const checkErrors = {};
                          let value = event.target.value;
                          const re = /^[a-z\d\-_\s]+$/i;
                          if (value === "" || re.test(value))
                            this.setState({ companyTag: value });
                          if (companyTags.length < 10) {
                            checkErrors["maxEntries"] = "";
                            this.setState({ checkErrors });
                          } else {
                            checkErrors["maxEntries"] =
                              "Max 10 entries are allowed";
                            this.setState({ checkErrors });
                          }
                        }}
                      ></input>
                      <img
                        className="pls-img"
                        style={{ right: '30px', top: '40px', width: '24px' }}
                        src="/images/Group 2187.png"
                        onClick={() =>
                          this.handleAddition("company", companyTag)
                        }
                      />
                      <div id="textarea">
                        <div className="area-contain">
                          {companyTags.map((ct, i) => (
                            <div className="data">
                              <p>
                                {ct}
                                <a
                                  className="text-pointer"
                                  onClick={() =>
                                    this.handleDelete("company", i)
                                  }
                                >
                                  X
                                </a>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      {checkErrors["maxEntries"] &&
                        checkErrors["maxEntries"].length > 0 && (
                          <span style={{ color: "crimson", fontSize: "16px" }}>
                            {checkErrors["maxEntries"]}
                          </span>
                        )}
                    </div>
                    <div className="app-id col-lg-6 col-12 float-left d-flex flex-column py-4 icon pr-0" style={{ 'padding-left': '30px' }}>
                      <label>Sister Company Name</label>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        className="position-relative m-0 bg-color"
                        value={sisterCompanyTag}
                        onChange={(event) => {
                          const checkErrors = {};
                          let value = event.target.value;
                          const re = /^[a-z\d\-_\s]+$/i;
                          if (value === "" || re.test(value))
                            this.setState({ sisterCompanyTag: value });
                          if (sisterCompanyTags.length < 10) {
                            checkErrors["maxEntries"] = "";
                            this.setState({ checkErrors });
                          } else {
                            checkErrors["maxEntries"] =
                              "Max 10 entries are allowed";
                            this.setState({ checkErrors });
                          }
                        }}
                      />
                      <img
                        className="pls-img"
                        style={{ right: '0px', top: '40px', width: '24px' }}
                        src="/images/Group 2187.png"
                        onClick={() =>
                          this.handleAddition("sisterCompany", sisterCompanyTag)
                        }
                      />
                      <div id="textarea">
                        <div className="area-contain">
                          {sisterCompanyTags.map((ct, i) => (
                            <div className="data">
                              <p>
                                {ct}
                                <a
                                  className="text-pointer"
                                  onClick={() =>
                                    this.handleDelete("sisterCompany", i)
                                  }
                                >
                                  X
                                </a>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr
                    style={{
                      "border-top": "1px solid rgb(109, 115, 131)",
                    }}
                  />
                  <div className="facility row m-0">
                    <div className="app-id col-lg-6 col-md-12 float-left d-flex flex-column py-4  pl30" style={{ 'padding-right': '30px' }}>
                      <label className="pb-2">
                        Facility<span className="red-color">*</span>
                      </label>
                      <Select
                        styles={customStyles}
                        value={selectedFacility}
                        onChange={(selected) =>
                          this.setState({ selectedFacility: selected })
                        }
                        options={FacilityOptions}
                        isSearchable={false}
                      />
                      {checkErrors["facility"] &&
                        checkErrors["facility"].length > 0 && (
                          <span style={{ color: "crimson", fontSize: "16px" }}>
                            {checkErrors["facility"]}
                          </span>
                        )}
                    </div>
                    {selectedFacility &&
                      selectedFacility.value &&
                      SB_AC.indexOf(selectedFacility.value) === -1 ? (
                        <div className="app-id col-lg-6 col-md-12 float-left d-flex flex-column py-4   pr-0" style={{ 'padding-left': '30px' }}>
                          <label className="pb-2">Sanction Limit type</label>
                          <Select
                            styles={customStyles}
                            value={selectedSanction}
                            onChange={(selected) =>
                              this.setState({ selectedSanction: selected })
                            }
                            options={SanctionOptions}
                            isSearchable={false}
                          />
                        </div>
                      ) : null}
                  </div>
                  <div>
                    {selectedFacility &&
                      selectedFacility.value &&
                      SB_AC.indexOf(selectedFacility.value) === -1 &&
                      selectedSanction !== "" ? (
                        <div className="drawing row ">
                          <div className="col-12 dr-power ">
                            <h5>Sanction Limit</h5>
                          </div>
                          <div className="row col-12 m-0 pt-3 pb-3 pr-0 pl-0">
                            {(selectedFacility.value === "OD" && selectedSanction.value === "FIXED"
                              ? [0]
                              : monthDiff
                            ).map((m) => {
                              return (
                                <div
                                  key={m}
                                  className="limit-type col-xl-4 col-lg-6 float-left d-flex flex-column "
                                  style={{
                                    padding: "35px 0"

                                  }}
                                >
                                  <span className="value border-bottom-0 pt-3">
                                    {m[1] === undefined ? `0${m + 1}` : m}
                                  </span>
                                  <input
                                    type="number"
                                    name={`sanction${m + 1}`}
                                    onChange={(event) =>
                                      this.handleSanction(event, "sanction")
                                    }
                                    value={this.state[`sanction${m + 1}`]}
                                    placeholder="Enter Amount"
                                    className="text-left pl70 position-relative"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                  </div>
                  {selectedFacility &&
                    selectedFacility.value &&
                    SB_AC.indexOf(selectedFacility.value) === -1 &&
                    selectedSanction !== "" ? (
                      <div className="drawing row ">
                        <div className="col-12 dr-power ">
                          <h5>Drawing Power</h5>
                        </div>
                        <div className="row col-12 m-0  dr-amount">
                          {(selectedFacility.value === "OD" && selectedSanction.value === "FIXED"
                            ? [0]
                            : monthDiff
                          ).map((m) => {
                            return (
                              <div
                                key={m}
                                className="limit-type col-xl-4 col-lg-6 float-left d-flex flex-column "
                                style={{
                                  padding: "35px 0",
                                }}
                              >
                                <span className="value border-bottom-0 pt-3">
                                  {m[1] === undefined ? `0${m + 1}` : m}
                                </span>
                                <input
                                  type="number"
                                  name={`drawing${m + 1}`}
                                  onChange={(event) =>
                                    this.handleSanction(event, "drawing")
                                  }
                                  value={this.state[`drawing${m + 1}`]}
                                  placeholder="Enter Amount"
                                  className="text-left pl70 position-relative"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  <hr
                    style={{
                      "border-top": "1px solid rgb(109, 115, 131)",
                    }}
                  />
                  <div className="company-nm  row m-0">
                    <div className="app-id col-lg-6 col-md-12 float-left d-flex flex-column py-4 pl30" style={{ 'padding-right': '30px' }}>
                      <div
                        className="last-div3 float-left d-flex flex-column  "
                        style={{
                          padding: "14px 0px 0px ",
                        }}
                      >
                        <label
                          className="position-relative d-inline-block "
                          style={{
                            font: "Bold 16px/20px Lato",
                            color: "#6d7383",
                          }}
                        >
                          Temporary Over Draft
                          <span className="red-color">*</span>
                        </label>
                        <div
                          className="radio-button position-relative d-inline-block "
                          style={{ float: "right" }}
                          onChange={(event) =>
                            this.setState(
                              { tempSelection: event.target.value },
                              () =>
                                tempSelection === "no" &&
                                this.setState({
                                  tempStartDate: null,
                                  tempEndDate: null,
                                })
                            )
                          }
                        >
                          <input
                            type="radio"
                            id="1"
                            name="radio"
                            value="yes"
                            className="m-0"
                          />
                          <label htmlFor="1" className="m-0 pr-3">
                            YES
                          </label>
                          <input
                            type="radio"
                            id="2"
                            name="radio"
                            value="no"
                            className="m-0"
                          />
                          <label htmlFor="2" className="m-0">
                            NO
                          </label>
                        </div>
                        <div
                          style={{
                            "border-top": "1px solid rgb(40 160 173)",
                            "margin-top": "15px"
                          }}
                        >
                        </div>
                        {checkErrors["temp"] && checkErrors["temp"].length > 0 && (
                          <span
                            className="over-click"
                            style={{ color: "crimson", fontSize: "16px" }}
                          >
                            {checkErrors["temp"]}
                          </span>
                        )}
                        {tempSelection === "yes" ? (
                          <div className="date dateRangeCol col-lg-12 col-md-12 m-0 float-left pt-4 pl30 pr-0">
                            <div
                              className="position-relative float-left d-flex flex-column"
                              style={{ width: "45%" }}
                            >
                              <label>Date range</label>
                              <SingleDatePicker
                                date={tempStartDate}
                                id="date3"
                                onDateChange={(date) =>
                                  this.setState({ tempStartDate: date })
                                }
                                focused={tempFocused1}
                                numberOfMonths={1}
                                onFocusChange={({ focused }) =>
                                  this.setState({ tempFocused1: focused })
                                }
                                displayFormat="DD/MM/YYYY"
                                isOutsideRange={this.isOutsideRange}
                                readOnly
                              />
                            </div>
                            <div
                              className="cal d-inline-flex w100"
                              style={{ margin: "20px 0 0 0" }}
                            >
                              <div className="arrow1" style={{ width: "30px", height: "30px" }}>
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                  style={{ width: "25px", height: "10px" }}
                                ></i>
                                <i
                                  className="fa fa-long-arrow-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div
                              className=" float-right d-flex flex-column"
                              style={{ width: "45%", margin: "20px 0 0 0" }}
                            >
                              <SingleDatePicker
                                date={tempEndDate}
                                id="date4"
                                onDateChange={(date) =>
                                  this.setState({ tempEndDate: date })
                                }
                                focused={tempFocused2}
                                numberOfMonths={1}
                                onFocusChange={({ focused }) =>
                                  this.setState({ tempFocused2: focused })
                                }
                                displayFormat="DD/MM/YYYY"
                                isOutsideRange={this.isOutsideRange}
                                readOnly
                              />
                            </div>
                            <div className="app-id  float-left d-flex flex-column pt-4 pl30 pr-0" style={{ width: "100%" }}>
                              <label>Amount</label>
                              <input
                                type="number"
                                value={tempAmount}
                                min="0"
                                onChange={(event) =>
                                  this.setState({
                                    tempAmount: event.target.value,
                                  })
                                }
                                placeholder="Enter Amount"
                                className="input-block"
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="app-id col-lg-6 col-md-12 float-left py-4  pr-0" style={{ 'padding-left': '30px' }}>
                      <label>Product Type</label>
                      <input
                        type="text"
                        value="SME"
                        readOnly
                        placeholder="Enter text"
                        className="m-0 w-100"
                        style={{ color: "#6d7383" }}
                      />
                    </div>
                  </div>
                  {/* <hr
                    style={{
                      "border-top": "1px dotted rgb(109, 115, 131)",
                    }}
                  /> */}
                  <div className="company-nm  row m-0">
                    <div className="app-id col-lg-6 col-md-12 float-left d-flex flex-column py-4  pl30" style={{ 'padding-right': '30px' }}>
                      <div
                        className="col-12 p-0 "
                        style={
                          {
                            // padding: "20px",
                          }
                        }
                      >
                        <label className="pb-2">Day of Reduction</label>
                        <Select
                          styles={customStyles}
                          value={selectedReduction}
                          onChange={(selected) =>
                            this.setState({ selectedReduction: selected })
                          }
                          options={ReductionOptions}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </section>
        {initiate ? (
          <div className="submit-btn container-fluid">
            <button onClick={this.submitStatement}>SUBMIT</button>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  all: state.all,
});

const mapDispatchToProps = {
  postParseStatements,
  getAnalysisReports,
  getBankList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Perfios);
