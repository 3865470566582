import React,{ useEffect, useState } from "react";
import "./kyc.scss";
import { getKYCFile } from '../../../actions/AllActions';
import { useDispatch } from 'react-redux';

const Financials = ({token, docSelected }) => {
  const dispatch = useDispatch();
  const useToken = "?"+token;
  const [groupSelected,setGroupSelected] = useState([]);

  
  useEffect(()=>{
    const data = docSelected.obj.finance;
    const result = {};
    const groubyfiled = 'document_category';
    data && data.forEach((item) => {
      const gname = item[groubyfiled];
      result[gname] = result[gname] || {};
      result[gname].items = result[gname].items || [];
      result[gname].items.push(item);
   });
    setGroupSelected(result);
  },[docSelected]);

  const uperCaselize = (str) => {
    return str && str.replace(/[_]+(.)/g, function (match, chr) {
      return " " + chr.toUpperCase();
    })
  }

  const downloadFile = (url, filename) => {
    dispatch(getKYCFile(url, filename));
  }

  return (
    <div className="card bg-white p-2 kyc kycf">
      <div className="d-flex px-2 font-weight-bold mt-2 align-items-center">
        <img src="/images/KYC/Financials.svg" height="25px" />
        <h1 className="ml-2 pHead">
          Financials
        </h1>
      </div>
      <hr className="my-2 mx-1" />
      <div className="row m-0 document-list" style={{height:'100%'}}>
        {docSelected && docSelected.obj && docSelected.obj.finance && docSelected.obj.finance.length > 0 ? Object.keys(groupSelected).map((data,index) =>
        <>
        <div className="col-md-12 text-capitalize d-flex" style={{fontSize:"16px",color:"#5F6D7A",textDecoration: "underline",paddingTop: "5px" ,alignItems: "center"}}>
             {uperCaselize(data)}: {(groupSelected[data].items.filter((n) => n.verified === true).length > 0 ) ? <img src="/images/cam_version_3/check.svg" width="25px" /> :""}
          </div>
           {groupSelected[data] && groupSelected[data].items.map((index)=>
           index.verified &&
          <div className="col-md-4 p-1  ">
            <div className="d-flex align-items-center px-2 py-3 bg-light card_bg position-relative">
              <div className="">
                <img src="/images/cam_version_3/file_green.svg" width="35px" />
              </div>
              <div className="bg-light w-75">
                <p className="pl-2 big_font text-capitalize">{uperCaselize(index.document_type)}</p>
                {index.url && 
                  <a href={index.url + useToken} target="_blank">
                    <img src="/images/KYC/view.svg" className="position-absolute printNotShow" style={{bottom:"10px", right:"10px",cursor:"pointer"}} />
                  </a>
                }
              </div>
            </div>
          </div>
          )}
          {(groupSelected[data].items.filter((n) => n.verified === true).length > 0 ) === false &&
             <div className="col-md-12" style={{display:'flex', justifyContent:"center", alignItems:"center",minHeight:'50px'}}>
              <p className="text-center" style={{fontSize: "15px",color: "#666C72"}}>No verified document yet</p>
             </div>
          }
        </>
        ) : <div className="col-md-12" style={{ display: 'flex', justifyContent: "center", alignItems: "center",minHeight:'50px'}}>
          <p className="text-center" style={{ fontSize: "15px", color: "#666C72" }}>No Documents</p>
        </div>
        }
      </div>
    </div>
  );
};
export default Financials;
