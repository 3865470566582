import React from 'react';
import './LoanEligibility.css';
import Loader from '../Loader/Loader';
import {
  rupeeDesign
} from "../../shared/common_logic";

const LoanEligibility = ({ camReport, error }) => {
  if (camReport && Object.keys(camReport).length === 0) {
    return <Loader />;
  }
  if (error && Object.keys(error).length) {
    return <span style={{ color: "crimson", fontSize: "16px" }}>Request failed </span>
  }
  const { eligibility_offer } = camReport;

  var cnt = 0;

  var arrayLookup = ['bureau','merchant','banking','eurokids','covid','aip'];

  for(var data in arrayLookup) {
    if(eligibility_offer[arrayLookup[data]] !== undefined) {
      cnt++;
    }
  }

  return (
    <div style={{ background: 'white' }}>
      <div className="flex col-md-12">
        <div className="width20 loan-content-text pt-4 pb-3">
          &nbsp;
        </div>
        
          { eligibility_offer.hasOwnProperty('bureau') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorderBtm loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">Bureau eligibility</div>
            </div>
          }        
          {eligibility_offer.hasOwnProperty('merchant') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorderBtm loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">Merchant eligibility</div>
            </div>
          }
        
          {eligibility_offer.hasOwnProperty('banking') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorderBtm loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">Banking eligibility</div>
            </div>
          }
          { eligibility_offer.hasOwnProperty('eurokids') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorderBtm loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">Eurokids eligibility</div>
            </div>
          }
          {eligibility_offer.hasOwnProperty('covid') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorderBtm loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">COVID 19  eligibility</div>
            </div>
          }
          {eligibility_offer.hasOwnProperty('aip') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorderBtm loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">AIP eligibility</div>
            </div>
          }
      </div>
      <div className="flex col-md-12">
        <div className="width20 loan-content-text pt-4 pb-3">
          CAM Eligibility
        </div>
        
          { eligibility_offer.hasOwnProperty('bureau') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.bureau.eligibility_amount)}</div>
            </div>
          }        
          {eligibility_offer.hasOwnProperty('merchant') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.merchant.eligibility_amount)}</div>
            </div>
          }
        
          {eligibility_offer.hasOwnProperty('banking') &&
           <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.banking.eligibility_amount)}</div>
            </div>
          }
          { eligibility_offer.hasOwnProperty('eurokids') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.eurokids.eligibility_amount)}</div>
            </div>
          }
          {eligibility_offer.hasOwnProperty('covid') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.covid.eligibility_amount)}</div>
            </div>
          }
          {eligibility_offer.hasOwnProperty('aip') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.aip.eligibility_amount)}</div>
            </div>
          }
      </div>
      <div className="flex col-md-12 borderBottom707070">
        <div className="width20 loan-content-text pt-3 pb-4">
          Max Cap Amount
        </div>
        { eligibility_offer.hasOwnProperty('bureau') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.bureau.max_cap_amount)}</div>
            </div>
          }        
          {eligibility_offer.hasOwnProperty('merchant') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.merchant.max_cap_amount)}</div>
            </div>
          }
        
          {eligibility_offer.hasOwnProperty('banking') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.banking.max_cap_amount)}</div>
            </div>
          }
          { eligibility_offer.hasOwnProperty('eurokids') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.eurokids.max_cap_amount)}</div>
            </div>
          }
          {eligibility_offer.hasOwnProperty('covid') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.covid.max_cap_amount)}</div>
            </div>
          }
          {eligibility_offer.hasOwnProperty('aip') &&
            <div className={'widthTemp'+cnt+' p-0 loan-leftBorder pt-4 pb-3'}>
                <div className="col-md-12 loan-content-text">{rupeeDesign(eligibility_offer.aip.max_cap_amount)}</div>
            </div>
          }
      </div>
      <div className="flex col-md-12 bordertop707070 mt-5">
        <div className="col-md-4 loan-content-text pt-3 pb-3 pl-0">
          <p className="col-md-12">Final Eligibility Based On</p>
        </div>
        <div className="col-md-8 p-0">
          <div className='flex col-md-6'>
            <div className="col-md-12 loan-content-text pt-4 pb-4 loan-leftBorder">
              <div className="col-md-12">{eligibility_offer.based_on}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex col-md-12 bordertopBottom707070">
        <div className="col-md-4 loan-content-text pt-3 pb-3 pl-0">
          <p className="col-md-12">Final Eligibility</p>
        </div>
        <div className="col-md-8 p-0">
          <div className='flex col-md-6'>
            <div className="col-md-12 loan-content-text pt-4 pb-4 loan-leftBorder">
              <div className="col-md-12">{rupeeDesign(eligibility_offer.eligibility_amount)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoanEligibility;
