import React,{useEffect,useState} from 'react';
import RoundProcess from '../dynamicSvg/roundProcess';
import {Row, Col,Card,Tooltip} from 'reactstrap';
import './scoreboard.scss';
import RoundProcessP2 from '../dynamicSvg/roundProcessP2';
import RoundProcess3 from '../summarySection/roundProcess'
import {useSelector,useDispatch} from 'react-redux';
import {getAnchorIndustryScoreboard} from '../../../actions/AllActions';

const Scoreboard = ({loanId,camData}) =>{
    const dispatch = useDispatch();
    const [ir,setiR] = useState(null);
    const [ar,setaR] = useState(null);
    const [colorir,setcolorir] = useState('#000000');
    const [colorar,setcolorar] = useState('#000000');
    const [tooltipOpen,setTooltipOpen] = useState(false);
    const dataScoreboard = useSelector(state => state.all.anchor_industry_scoreboard);
    const anchor_industry_scoreboard = dataScoreboard & dataScoreboard

    console.log("anchor_industry_scoreboard",anchor_industry_scoreboard)

    const svg1 = "/images/cam_version_3/circle_icon2.svg";
    const svg2 = "/images/cam_version_3/circle_icon3.svg";
    const svg3 = "/images/cam_version_3/circle_icon4.svg";
    const svg4 = "/images/cam_version_3/circle_icon5.svg";
    const orgScore = camData && camData.origination_score && camData.origination_score.origination_risk_calculation && camData.origination_score.origination_risk_calculation.covid19_value ? camData.origination_score.origination_risk_calculation.covid19_value.toLowerCase().replace('risk','') : camData.origination_score.origination_risk_calculation.value ? camData.origination_score.origination_risk_calculation.value.toLowerCase().replace('risk',''): "-";
    let color1 = "";

    useEffect(()=>{
        dispatch(getAnchorIndustryScoreboard(loanId));
    },[]);

    useEffect(()=>{
        
        if(dataScoreboard && dataScoreboard.anchorFetching && (dataScoreboard.anchorFetching.attributes && dataScoreboard.anchorFetching.attributes.industry_risk && dataScoreboard.anchorFetching.attributes.industry_risk["industry_risk_grading_status"])) {
            var ir = dataScoreboard && dataScoreboard.anchorFetching && (dataScoreboard.anchorFetching.attributes && dataScoreboard.anchorFetching.attributes.industry_risk && dataScoreboard.anchorFetching.attributes.industry_risk["industry_risk_grading_status"]).toLowerCase().replace('risk','');

            var coloriR = colorir;
            if(ir && ir.trim() === "high") {
                coloriR = "#EE5C5C";
            }
            else if(ir && ir.trim() === "medium") {
                coloriR = "#ee964f";
            }
            else if(ir && ir.trim() === "low") {
                coloriR = "#3ED68D";
            }
            else {
                coloriR = "#000000";
            }
            setiR(ir);
            setcolorir(coloriR)

        }else{
            setiR("NA");
            setcolorir("#000000")
        }

        if(dataScoreboard && dataScoreboard.anchorFetching && (dataScoreboard.anchorFetching.attributes && dataScoreboard.anchorFetching.attributes.anchor_risk && dataScoreboard.anchorFetching.attributes.anchor_risk["anchor_risk_grading_status"])) {
            var colorar = colorar;

             var ar = dataScoreboard && dataScoreboard.anchorFetching && ( dataScoreboard.anchorFetching.attributes && dataScoreboard.anchorFetching.attributes.anchor_risk && dataScoreboard.anchorFetching.attributes.anchor_risk["anchor_risk_grading_status"]).toLowerCase().replace('risk','');
            if(ar && ar.trim() === "high") {
                colorar = "#EE5C5C";
            }
            else if(ar && ar.trim() === "medium") {
                colorar = "#ee964f";
            }
            else if(ar && ar.trim() === "low") {
                colorar = "#3ED68D";
            }
            else {
                colorar = "#000000";
            }
            setaR(ar);
            setcolorar(colorar)

        }else{
            setaR("NA");
            setcolorar("#000000") 
        }

    },[dataScoreboard]);

    const toggle = () => {
        setTooltipOpen(!tooltipOpen);
    }

    const colorSet = (value) => {
        if(value && value.toLowerCase() === "high risk"){
            return "#EE5C5C";  
        }
        else if(value && value.toLowerCase() === "medium risk"){
            return "#ee964f";  
        }
        else if(value && value.toLowerCase() === "low risk"){
            return "#3ED68D"; 
        }else{
            return "#000000";
        }
    }
    if(orgScore.trim() === "high") {
        color1 = "#EE5C5C";
    }
    else if(orgScore.trim() === "medium") {
        color1 = "#ee964f";
    }
    else if(orgScore.trim() === "low") {
        color1 = "#3ED68D";
    }
    else {
        color1 = "#000000";
    }

    return(
        <Col md="6" className="scoreboard p-3 pb-4 col-mds-12" >
            {camData &&
            <Card className="bg-white scoreboard_card" style={{ backgroundColor: "white !important"}}>
                
                    <Row>
                        <Col md="12">
                            <div className="float-left d-flex px-3 pt-2">
                                <img className="pr-2" src="/images/cam_version_3/scoreboard.svg" />
                                <p className="pt-2 pHead">Scoreboard</p>
                            </div>
                        </Col>
                    </Row>
                    <hr className="my-2 mx-3" />
                    
                    <Row className="pt-3">
                        <Col md="6" className="border-right industry">
                            <div className="text-center pt-2 pb-1">
                                <p className=" pb-2 big-font">Industry Risk</p>
                                <RoundProcess color={colorir} scx="39%" scy="63%" value={ir} imageType={2} imageSrc={svg2} x="50" y="45"/>
                            </div>             
                            
                            
                        </Col>
                        
                        <Col md="6" className="anchor">
                        <div className="text-center pt-2 pb-1">
                                <h5 className="font-weight-bold pb-2 big-font">Anchor Risk</h5>
                                <RoundProcess color={colorar} scx="39%" scy="63%" value={ar} imageType={2} imageSrc={svg2} x="50" y="45"/>
                            </div>                            
                        </Col>
                    </Row>
                 {dataScoreboard.loading &&
                     <div className="Loadergif pt-4" style={{height:"163px"}}>
                         <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                     </div>
                 }
                    
                    <hr className="my-3 mx-3" />

                    <Row  className="text-center prospect-color mr-0 ml-0">
                        <Col className="border-right col-sm-6 col-sm-6 col-md-2-0 mt-2 mb-3">
                            <div className="h-75">
                                <p className="small_score_font h-50">Prospect Score</p>
                                <RoundProcessP2 mx={29} width="90" height="90" color="#3ED68D" scx="37%" scy="62%" x="50" y="45"  value={orgScore} scoreP2={camData && camData.p2_scores && camData.p2_scores.score} scoreP2RiskGrading={camData && camData.p2_scores && camData.p2_scores.risk_grading} />
                            </div>
                        </Col>
                        <Col className="border-right col-sm-6 col-md-2-0 mt-2 mb-3">
                        <div className="h-75  ">
                            <p className="small_score_font h-50">Origination Risk</p>
                            <RoundProcess color={color1} scx="39%" scy="63%" value={orgScore} imageType={2} imageSrc={svg2} x="50" y="45"/>
                            </div>
                        </Col>
                        <Col className="border-right col-sm-6 col-md-2-0 mt-2 mb-3">
                            <div className="h-75 ">
                            <p className="small_score_font h-50">Mintifi Score</p>
                            <RoundProcessP2 mx={29} width="90" height="90" color="#3ED68D" scx="37%" scy="62%" x="50" y="45"  value={orgScore} imageSrc={svg2} scoreP2={camData && camData.p3_model_score && camData.p3_model_score.probability && String(camData.p3_model_score.probability).substring(0,4)} scoreP3RiskGrading={camData && camData.p3_model_score && camData.p3_model_score.tiers} />
                            </div>

                        </Col>
                        <Col className="border-right col-sm-6 col-md-2-0 mt-2 mb-3">
                        <div className="h-75 ">
                            <p className="small_score_font h-50">CIBIL Score</p>
                            <RoundProcess color={camData.origination_score && camData.origination_score.cibil_score.value >= 650 ? (camData.origination_score && camData.origination_score.cibil_score.value >= 750 ? "#3ED68D" :"#ee964f") :"red"} value={camData && camData.origination_score && camData.origination_score.cibil_score.value ? camData.origination_score.cibil_score.value : "N/A"} x="50" y="45" imageType={1} value2="C"/>
                            </div>
                        </Col>
                        <Col className="col-sm-6 col-md-2-0 mt-2 mb-3">
                            <div className="h-75">
                            <p className="small_score_font h-50">Experian Leverage</p>
                            <RoundProcess color={camData.leverage_score ? camData.leverage_score > 40 ? (camData.leverage_score > 80 ? "#3ED68D" :"#ee964f") :"red" : "#000"} value={camData && camData.leverage_score ? camData.leverage_score : "N/A"} x="50" y="45" imageType={1} value2="E" />
                            </div>
                        </Col>
                        <Col className="border-right col-sm-6 col-md-2-0 mt-2 mb-3">
                            <div className="h-75">
                            <p className="small_score_font h-50">Transaction Score </p>
                            <RoundProcess3 width="90" height="90" scx="37%" scy="62%" x="50" y="45" score={camData?.transaction_risk?.transaction_risk?.value !== null ? camData?.transaction_risk?.transaction_risk?.value : "N/A"} hml={camData?.transaction_risk?.transaction_risk?.result || "-"}/>
                            </div>
                        </Col>

                    </Row>
                                       
                
            </Card>
            }
        </Col>
    )
}

export default Scoreboard;
