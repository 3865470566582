export const preventFloat = event => {
  if (
    event !== undefined &&
    event !== "" &&
    event.which !== undefined &&
    event.which !== null
  ) {
    let abcd =
      event.which != 101 &&
      event.which != 0 &&
      (event.which < 43 || event.which > 57)
        ? true
        : !isNaN(Number(event.key));
    if (!abcd) {
      return event.preventDefault();
    }
  }
};

// Removes trimming slashes
export const regexTrim = regex => {
  var str = `${regex}`;
  return str.split("/")[1];
};

// Validates all the fields
export const fieldValidationHandler = props => {
  const { showAlert, validations, localState, fieldAlert } = props;
  const lomo = Object.entries(validations).some((val, key) => {
    if (val[1].required) {
      let regexTest = val[1].pattern.test(localState[val[1].slug]);
      if (!regexTest) {
        if (localState[val[1].slug])
          fieldAlert({ showError: true, slug: val[1].slug });
        return val[1];
      } else {
        fieldAlert({ showError: false, slug: val[1].slug });
      }
    }
  });

  if (!lomo) {
    fieldAlert({ showError: false });
    return false;
    // showAlert();
  }
  return lomo;
};

export const validateKeyStrokes = event => {
  var inputValue = event.which;
  // allow letters and whitespaces only.
  if (
    !(inputValue >= 65 && inputValue <= 120) &&
    (inputValue != 32 && inputValue != 0)
  ) {
    event.preventDefault();
  }
};

export const  checkData = (a) => {
    if(a !== "" && a !== null) {
      return a;
    }
    return '';
}

export const returnError = (a) => {
    var returnStr = '';
    for(var data in a) {
      returnStr += returnStr === "" ? a[data].detail : ", "+a[data].detail;
    }
    return returnStr;
};

export const rupeeDesign = value => {
  return value && Number(value) ? "₹"+Number(value).toLocaleString("en-IN") : value;
};

export const commaSeparatRs = (value) => {
  if(typeof(value) === 'string'){
      return value;
  }
  else if(typeof(value) === 'number'){
      return "₹ "+Number(value).toLocaleString('en-IN', { minimumFractionDigits: 2 })
  }
 
}
