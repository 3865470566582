import React from "react";
import './GST.scss';

const GSTDetails = ({gstUser,gstKey}) => {
    return (
        <div className="col-md-12 gstdetails ">
           
            <div className="py-2 tabPrint text-center text-uppercase">
                GST Details
            </div>

            <div className="gstin p-3">
                <span className="">GSTIN:</span>
                <span className="ml-3" style={{ fontWeight: '400' }}>{gstKey}</span>
            </div>
            <div className="table-responsive">
                {gstUser &&
            <table >
            <tbody>
                <tr>
                    <td>Entity Profile</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Legal Name of Business</td>
                    <td>{gstUser.profile.legal_business_name !== null && gstUser.profile.legal_business_name !== " "? gstUser.profile.legal_business_name : "-" }</td>
                </tr>
                <tr>
                    <td>Trade Name</td>
                    <td>{gstUser.profile.trade_name !== null ? gstUser.profile.trade_name:"-"}</td>
                </tr>
                <tr>
                    <td>status</td>
                    <td className={gstUser.profile.is_active === true ? "active":"deactivate"} >{gstUser.profile.is_active===true ? "Active" :"Deactivate"}</td>
                </tr>
                <tr>
                    <td>Business Category</td>
                    <td>{gstUser.profile.business_category !== null ? gstUser.profile.business_category :"-"}</td>
                </tr>
                {/* <tr>
                    <td>Company ID</td>
                    <td>{gstUser.profile.company_id !== null ? gstUser.profile.company_id :"-"}</td>
                </tr>
                <tr>
                    <td>Compliance Rating</td>
                    <td>{gstUser.profile.compliance_rating !== null ? gstUser.profile.compliance_rating :"-"}</td>
                </tr> */}
                <tr>
                    <td>Authorized Signatories</td>
                    <td style={{color:"#1572CF"}}>{(gstUser.profile.authorised_signatories !== null) && (gstUser.profile.authorised_signatories !== " " && gstUser.profile.authorised_signatories !== "") ? gstUser.profile.authorised_signatories :"-"}</td>
                </tr>
                <tr>
                    <td>Nature Of Business</td>
                    <td>{gstUser.profile.nature_of_business !== null && gstUser.profile.nature_of_business !== " "? gstUser.profile.nature_of_business :"-"}</td>
                </tr>
                {/* <tr>
                    <td>Taxpayer Name</td>
                    <td>{gstUser.profile.taxpayer_name !== null ? gstUser.profile.taxpayer_name :"-"}</td>
                </tr> */}
                <tr>
                    <td>Taxpayer Type</td>
                    <td>{gstUser.profile.taxpayer_type !== null ? gstUser.profile.taxpayer_type :"-"}</td>
                </tr>
                {/* <tr>
                    <td>Compliance Rating</td>
                    <td>{gstUser.profile.compliance_rating !== null ? gstUser.profile.compliance_rating :"-"}</td>
                </tr>
                <tr>
                    <td>State Jurisdiction Code</td>
                    <td style={{color:'#1572CF'}}>{gstUser.profile.state_juridiction_code !== null ? gstUser.profile.state_juridiction_code :"-"}</td>
                </tr>
                <tr>
                    <td>State Jurisdiction</td>
                    <td>{gstUser.profile.state_juridiction !== null ? gstUser.profile.state_juridiction :"-"}</td>
                </tr>
                <tr>
                    <td>Central Jurisdiction Code</td>
                    <td>{gstUser.profile.central_juridiction_code !== null ? gstUser.profile.central_juridiction_code :"-"}</td>
                </tr>
                <tr>
                    <td>Cental Jurisdiction</td>
                    <td>{gstUser.profile.central_juridiction !== null ? gstUser.profile.central_juridiction :"-"}</td>
                </tr>
                <tr>
                    <td>Date Of Registration</td>
                    <td>{gstUser.profile.registration_date !== null ? gstUser.profile.registration_date :"-"}</td>
                </tr>
                <tr>
                    <td>Date Of Cancellation</td>
                    <td>{gstUser.profile.date_of_cancelation !== null ? gstUser.profile.date_of_cancelation :"-"}</td>
                </tr>
                <tr>
                    <td>Last Update</td>
                    <td>{gstUser.profile.updated_at !== null ? new Date(gstUser.profile.updated_at).toDateString() :"-"}</td>
                </tr> */}
                </tbody>
            </table>
                }
            </div>
            {/* {gstUser &&
            <div className="bottomtable">
                <div className="bottompara">
                    <p>Registered Address</p>
                    <p>{gstUser.profile.address !== null ? gstUser.profile.address :"-"}</p>
                </div>
                <div className="table-responsive">
                
                <table className="overflow-hidden">
                    <thead>
                    <tr>
                        <td>Nature of Business</td>
                        <td>Mobile Number</td>
                        <td>Registered Email</td>
                        <td>Last update</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{gstUser.profile.nature_of_business !== null ? gstUser.profile.nature_of_business :"-"}</td>
                        <td>{gstUser.profile.primary_mobile_number !== null ? gstUser.profile.primary_mobile_number :"-"}</td>
                        <td>{gstUser.profile.primary_email !== null ? gstUser.profile.primary_email :"-"}</td>
                        <td>{gstUser.profile.updated_at !== null ? new Date(gstUser.profile.updated_at).toDateString() :"-"}</td>
                    </tr>
                    </tbody>
                </table>
                
                </div>
            </div>
            } */}
            </div>
       
    );
};
export default GSTDetails;
