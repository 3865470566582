import React, { useEffect, useState } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import './newCam.scss';
import { getCamReport,getMlScore} from '../../actions/AllActions';
import { Container, Row, Col } from 'reactstrap';
import Proposal from '../../components/newCam/proposal/proposal';
import ScoreCard from "../../components/newCam/ScoreCard/ScoreCard";
import Scoreboard from '../../components/newCam/scoreboard/scoreboard';
import Summary from '../../components/newCam/summary/summary';
import CreditManager from '../../components/newCam/creditManager/creditManager';
import Deviations from '../../components/newCam/deviations/deviations';
import MintifiLEC from '../../components/newCam/mintifiLEC/mintifiLEC';
import FinalOffering from '../../components/newCam/finalOffering/finalOffering';
import FinalRemark from '../../components/newCam/finalRemarks/finalRemarks';
import LoanEligibility from '../../components/newCam/LoanEligibilty/index';
import OrgRisk from "../../components/newCam/OriginationRisk/OriginationRisk";
import KYCFin from '../../components/newCam/KYC/index';
import Loader from '../../components/Loader/Loader';

const NewCam = (props) => {
    const url = document.getElementById("frame_id") && document.getElementById("frame_id").src;
    const [loanId,setLoanId] = useState(null);
    const metaData = useSelector(state => state.all.cam_report.meta);
    const camReport = useSelector(state => state.all.cam_report.data);
    const error = useSelector(state => state.all.error);
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(props.location.search);
    const source = queryParams.get('source');
    const token = "token="+queryParams.get('token');
    useEffect(() => {
        const { match } = props;
        const ID = match.params.id;
              
        setLoanId(ID);
        dispatch(getCamReport(ID));
    },[]);
    const summaryCheck = (metaData 
                            && (metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel')
                            || metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel')
                            || metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel')
                            || metaData.program_details.program_band_enabled_cam_components.includes('gst_data_panel')
                            || metaData.program_details.program_band_enabled_cam_components.includes('itr_panel')
                            || metaData.program_details.program_band_enabled_cam_components.includes('triangulation_panel')
                        ));
    
    const loanEligibilityCheck = (metaData 
            && (metaData.program_details.program_band_enabled_cam_components.includes('demographic_panel')
            || metaData.program_details.program_band_enabled_cam_components.includes('loan_eligibility_cibil_loan_eligibility')
            || metaData.program_details.program_band_enabled_cam_components.includes('anchor_transaction_data')
            || metaData.program_details.program_band_enabled_cam_components.includes('banking_data_panel')
            || metaData.program_details.program_band_enabled_cam_components.includes('loan_eligibility_gst_loan_eligibility')
            || metaData.program_details.program_band_enabled_cam_components.includes('loan_eligibility_itr_loan_eligibility')
        ));

    if(camReport === undefined && error === undefined){
        return(<Loader/>)
    }    
    return (
        
        <div className="newCam">
            
            <Container className="themed-container" fluid={true}>
                <Row>
                 <Col sm="12" md="12">
                        <div className="d-flex justify-content-between">
                            <div className="logo-position">
                                <img className="" src="https://mintifi.com/media/logo.png" />
                            </div>
                            {/*<div className="form-group has-search search-size" >
                                <img className="form-control-feedback" src="/images/cam_version_3/search.svg" width="48px" />
                                <input type="text" className="form-control searchInput" placeholder="Enter any parameter here" />
                            </div>*/}
                            <div className="pull-right">
                                <button onClick={() => window.print()} className="btn btn-danger">Download</button>
                            </div>
                        </div>
                    </Col>
                </Row>
                {error?.response?.data && error.response.data.errors.map((data,i)=>(
                         <div key={i} className="text-center py-5" style={{color:"red"}}>
                            <div>{data.detail} !</div>                         
                         </div>
                  ))
                }
                {error && error.isAxiosError === true && !error.response &&
                    <div className="text-center py-5" style={{color:"red"}}>
                        <div>An Error Occurred While Loading CAM!</div> 
                        <button className="btn btn-danger mt-3" onClick={() => window.location.reload()}>Reload CAM</button>                        
                    </div>
                }
                {camReport &&
                <>
                <Row>
                    <Proposal camData={camReport.attributes} loanId={props.match.params.id} metaData={metaData}/>
                    <Scoreboard loanId={loanId} camData={camReport.attributes}/>
                </Row>
                <Row className="pagebreak">
                    <KYCFin token= {token} loanId={loanId} camData={camReport.attributes} metaData={metaData}/>
                </Row>
                {summaryCheck && 
                <Row className="pagebreak">
                    <Summary camData={camReport.attributes} metaData={metaData}  loanId={loanId} />
                </Row>
                }
                {loanEligibilityCheck && 
                <Row className="pagebreak">
                    <LoanEligibility camData={camReport.attributes} loanId={props.match.params.id} metaData={metaData}/>
                </Row>
                }                
                <Row className="pagebreak">
                    <CreditManager camData={camReport.attributes} metaData={metaData} props={props}/>
                </Row>
                <Row className="pagebreak">
                    <Deviations camData={camReport.attributes} loanId={loanId} props={props}/>
                </Row>
                {(source !== "lender" || camReport?.attributes?.summary?.lender_id === 17) &&
                <Row className="pagebreak">
                    <MintifiLEC camData={camReport.attributes} loanId={props.match.params.id} />
                </Row>
                }
                <Row>
                    <FinalOffering camData={camReport.attributes} loanId={props.match.params.id}/>
                </Row>
                {(metaData.is_editable === true || camReport.attributes.final_remarks !== null) && 
                <Row className="pagebreak">
                    <FinalRemark camData={camReport.attributes} metaData={metaData} props={props}/>
                </Row>
                }
                </>
                }

            </Container>
            
        </div>
    )
}
export default NewCam;