import React from "react";
import RoleResult from '../ruleResult/ruleResult';
import './ITR.scss';
import {commaSeparatRs} from '../../../../shared/common_logic'

const WorkingCapital = ({lastClicked,working_capital}) => {
    var year = lastClicked.split('-');
    var nextYear =  `${Number(year[0])+1}-${Number(year[1])+1}`; 
    
    return (
        <div className="col-md-12 itrdetails">
           
        <div className="row m-0 card" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "10px 10px 30px" }}>
        <div className="col-md-12">
            <div className="table-responsive">
                 
                <table className="overflow-hidden">
                    <thead>
                    <tr >
                        <th className="tablehead font-weight-bold">Program Conditions ( Year on Year Comparison )</th>
                        <th className="font-weight-bold text-center">{nextYear}</th>
                        <th className="font-weight-bold text-center">{lastClicked}</th>
                        <th className="font-weight-bold text-center">Percentage Change</th>
                       
                    </tr>
                    </thead>
                    <tbody>
                            {working_capital !== undefined ? 
                            <>
                            {Object.keys(working_capital).map((data,index)=>(
                                
                                <tr key={index}>                                   
                                    <td className="tablehead">{working_capital[data].title || "-"}</td>
                                    <td className="text-center">{working_capital[data].value1 || "-"}</td>
                                    <td className="text-center">{working_capital[data].value2 || "-"}</td>
                                    <td className="text-center">{working_capital[data].value !== null ? String(working_capital[data].value) :"-"}</td>
                                    {/* <RoleResult rule={working_capital[data].result} id={index}/>  */}
                                    
                                    
                                </tr>
                            ))}
                            </>
                            :
                            <tr>
                            <td>-</td>
                            <td>-</td>    
                            <td>-</td>     
                        </tr>
                            }
                        </tbody>
                </table>
                
                </div>
            </div>
        </div>
    </div>

    );
};
export default WorkingCapital;
