import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from "react-switch";
import FileViewer from 'react-file-viewer';
import { getRiskCompliances } from '../../actions/AllActions';
import Loader from '../../components/Loader/Loader';
import Modal from '../../components/Modal/Modal';
import './RiskCompliances.css';

const modalStyle = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0,0.5)",
    }
};

class RiskCompliances extends Component {

    constructor(props) {
        super(props)
        this.state = {
            checked: false,
            showModal: false,
            category: "Applicant",
            riskCompliances: [],
            currentName: "",
            entityIds: "",
            errors: {},
            documentLink: "",
            businessName: "",
            percentCircle: [],
            applicationValue: [],
            complianceRequired: [],
            attributeIndex: [0],
            applicantInformation: { 'Mintifi Loan Form Data': ["Name", "Dob Verification", "Gender", "Email", "Mobile", "Employment", "Education"], 'Identity Verification': ["Pan", "Aadhar", "Driving License", "Passport", "Voter ID"], 'Address Verification': ["Home Address - Owned", "Home Address - Rented"], 'Credit Analysis': ["Credit Analysis"] },
            businessInformation: { 'Mintifi Loan Form Data': ["Business Name", "Business PAN", "Business Address"], 'Business Type': ["Sole Proprietorship", "Private Limited", "Partnership Firm"], 'Credit Analysis': ["Bank Statement Analysis"], 'KYC Report': ["GST Return Status", "GST Analysis Report"], 'Financials': ["ITR Acknowledgement", "Auditor Report", "Profit & Loss, Balance Sheet"], 'Post Sanction Letter Requirements': ["CPV - Office", "Sanction Letter"] },
        }
        this.showFile = React.createRef();
    }

    handleChange = checked => {
        this.setState({ checked });
    }

    getAttributeName = ({ appValue, entityId }) => {
        this.setState({ currentName: appValue, entityIds: entityId })
    }

    componentWillMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        const errors = {}
        if (params.get('token') === "" || params.get('token') === null)
            errors['token'] = "Token Missing"
        if (params.get('loan_application_id') === "" || params.get('loan_application_id') === null)
            errors['loan_application_id'] = "Loan ID Missing"
        if (Object.values(errors).some(err => err !== "")) {
            this.setState({ errors })
        }
    }

    componentWillReceiveProps(nextProps) {
        const { risk_compliances } = nextProps.all;
        let businessName = ""
        if (Object.keys(risk_compliances).length) {
            this.setState({ riskCompliances: risk_compliances }, () => {
                const applicationValue = [];
                this.state.riskCompliances.data.find(rc => {
                    const { attributes } = rc;
                    if (attributes.attribute_name === "Business Name") {
                        businessName = attributes.application_value
                    }
                })
                this.state.riskCompliances.data.filter(rc => {
                    const { attributes } = rc;
                    if (attributes.attribute_name === "Name") {
                        applicationValue.push({ appValue: attributes.application_value, entityId: attributes.entity_id });
                    }
                })
                this.setState({ applicationValue, currentName: applicationValue[0].appValue, entityIds: applicationValue[0].entityId, businessName }, () => {
                    this.handleComplianceCheck();
                })
            })
        }
    }

    handleComplianceCheck = () => {
        const complianceRequired = [], colorCircles = [], businessType = [], newCircles = [];
        const { applicantInformation, businessInformation, category, currentName, entityIds, riskCompliances } = this.state;
        Object.keys(category === "Applicant" ? applicantInformation : category === "Business" && businessInformation).forEach(elements => {
            let entityID = "";
            const tempArr = [];
            const percentCircles = [];
            (category === "Applicant" ? applicantInformation[elements] : businessInformation[elements]).forEach(element => {
                if (elements === "Business Type" && businessType.length)
                    return
                riskCompliances && Object.keys(riskCompliances).length && riskCompliances['data'].forEach(k => {
                    const { attributes, relationships } = k;
                    if (category === "Applicant" && attributes.application_value === currentName || attributes.entity_id === entityIds) {
                        entityID = attributes.entity_id;
                    }
                    if (category === "Business")
                        entityID = attributes.entity_id;
                    const entityType = category === "Applicant" ? "User" : "Company";
                    if (attributes.entity_type === entityType && attributes.attribute_name === element && attributes.entity_id === entityID && currentName !== "" && entityID !== "") {
                        elements === "Business Type" && businessType.push(element)
                        tempArr.push(attributes.is_compliances_required);
                        const tempA = [];
                        relationships.validation_secondary_informations.data.forEach(d => {
                            const { id } = d;
                            const tempObj = {};
                            const result = riskCompliances['included'].find(include => include.id === id);
                            const { attributes } = result;
                            if (attributes.match_percentage !== null) {
                                if (attributes.match_percentage !== "")
                                    tempObj['percent'] = +attributes.match_percentage
                            }
                            else if (attributes.match_result !== null || attributes.verified_by !== null) {
                                if (attributes.match_result !== "" || attributes.verified_by !== null)
                                    attributes.match_result === "Yes" || attributes.verified_by !== null ? tempObj['yes'] = "yes"
                                        : tempObj['no'] = "no"
                            } else {
                                tempObj['empty'] = ""
                            }
                            tempA.push(tempObj);
                        })
                        percentCircles.push(tempA.length ? tempA : [{ empty: "" }]);
                    }
                })
            });
            newCircles.push(percentCircles)
            complianceRequired.push(tempArr);
        })
        const newColorCircles = []
        newCircles.map(nc => {
            const colorCircles = [];
            nc.map(pc => {
                const tempArrays = [];
                pc.map(p => 'percent' in p ? tempArrays.push(p.percent) : 'yes' in p ? tempArrays.push(p.yes) : 'no' in p ? tempArrays.push(p.no) : 'empty' in p ? tempArrays.push(p.empty) : tempArrays.push(""))
                colorCircles.push(tempArrays.every(ta => ta === 100) ?
                    <>
                        <div className="greenFillCircle mr-3" />
                        <div className="redCircle mr-3" />
                        <div className="yellowCircle mr-3" />
                    </> :
                    tempArrays.every(ta => ta === "") ?
                        <>
                            <div className="redFillCircle mr-3" />
                            <div className="greenCircle mr-3" />
                            <div className="yellowCircle mr-3" />
                        </> :
                        tempArrays.every(ta => ta > 0) ?
                            <>
                                <div className="redCircle mr-3" />
                                <div className="greenCircle mr-3" />
                                <div className="yellowFillCircle mr-3" />
                            </> :
                            tempArrays.every(ta => ta === "yes") ?
                                <>
                                    <div className="greenFillCircle mr-3" />
                                    <div className="redCircle mr-3" />
                                    <div className="yellowCircle mr-3" />
                                </> :
                                tempArrays.every(ta => ta === "no") ?
                                    <>
                                        <div className="redFillCircle mr-3" />
                                        <div className="greenCircle mr-3" />
                                        <div className="yellowCircle mr-3" />
                                    </> :
                                    <>
                                        <div className="redFillCircle mr-3" />
                                        <div className="greenCircle mr-3" />
                                        <div className="yellowCircle mr-3" />
                                    </>
                )
            })
            newColorCircles.push(colorCircles)
        })
        this.setState({ complianceRequired, percentCircle: newColorCircles })
    }

    componentDidUpdate(prevProps, prevState) {
        this.showFile && this.showFile.current !== null && this.showFile.current.setState({
            height: "auto",
            width: 462
        })
        if (prevState.category !== this.state.category || prevState.checked !== this.state.checked || prevState.currentName !== this.state.currentName) {
            this.handleComplianceCheck();
        }
    }

    componentDidMount() {
        this.props.getRiskCompliances();
    }

    showAttributes = index => {
        const { attributeIndex } = this.state;
        const attributeIndexs = [...attributeIndex];
        if (attributeIndexs.includes(index)) {
            attributeIndexs.splice(attributeIndexs.indexOf(index), 1)
        } else {
            attributeIndexs.push(index)
        }
        this.setState({ attributeIndex: attributeIndexs })
    }

    render() {
        const { isFetching, error } = this.props.all;
        const { errors } = this.state;
        const { category, riskCompliances, complianceRequired, applicationValue, currentName, applicantInformation, businessInformation, entityIds, checked, showModal, documentLink, percentCircle, attributeIndex, businessName } = this.state;
        const businessType = [];
        if (riskCompliances !== null && Object.keys(riskCompliances).length === 0 && !isFetching && error === undefined)
            return <Loader />
        return (
            <div className="col-sm-12 col-12 d-flex p-0">
                <div className="leftSide">
                    <img src='/images/Group-2805.png' alt="img" />
                </div>
                <div className="col-sm-12 col-12 p-0 pl80">
                    <div className="mainDiv pt-2 pl-3 pr-3">
                        <p className="mb-0 pl-3 mainHeader">{businessName} <img src="/images/menuIcon.png" className="ml-3" alt="Verified" width="20px" className="mt_2" /></p>
                        {error && Object.keys(error).length ?
                            'message' in error ? <div style={{ textAlign: 'center', position: 'relative', bottom: 60 }}>
                                {
                                    <div className="col-md-12 d-flex mb-2">
                                        <div className="col-md-4 m-auto">
                                            <span className="col-md-4 m-auto" style={{ color: "crimson", fontSize: "22px" }}>
                                                {error.message === "Network Error" ? "Technical server error! Please try after some time." : error.message === "Request failed with status code 401" ? "Invalid Auth Token" : "Error"}
                                            </span>
                                        </div>
                                    </div>
                                }
                            </div> :
                                error.errors.map(err => {
                                    return (
                                        <div key={err} style={{ textAlign: 'center', position: 'relative', bottom: 45 }}>
                                            {
                                                <div key={err} className="col-md-12 d-flex mb-2">
                                                    <div className="col-md-4 m-auto">
                                                        <span className="col-md-4 m-auto" style={{ color: "crimson", fontSize: "22px" }}>
                                                            {err.detail}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                }) : null
                        }
                        {
                            errors && Object.keys(errors).length ? Object.keys(errors).map(err => {
                                return (
                                    <div key={err} style={{ textAlign: 'center', position: 'relative', bottom: 60 }}>
                                        {
                                            <div key={err} className="col-md-12 d-flex mb-2">
                                                <div className="col-md-4 m-auto">
                                                    <span className="col-md-4 m-auto" style={{ color: "crimson", fontSize: "22px" }}>
                                                        {errors[err]}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </div>)
                            })
                                : null
                        }
                    </div>
                    <div className="col-sm-12 col-10 p-0 row ml-0 mr-0">
                        <div className="col-sm-10 col-12 p-0">
                            <div className="fullBorder col-sm-12 p-0">
                                <nav className="navbar navbar-expand-lg headDiv">
                                    <ul className="navbar-nav pl-3 pr-3 pt-3">
                                        <li className="nav-item pr-5">
                                            <a className={`${category === "Applicant" ? "actColor" : "lightGray"} headerText`} onClick={() => this.setState({ category: 'Applicant' })}>Applicant Information</a>
                                            {category === 'Applicant' && <hr className="hrCss" />}
                                        </li>
                                        <li className="nav-item pr-5">
                                            <a className={`${category === "Business" ? "actColor" : "lightGray"} headerText`} onClick={() => this.setState({ category: 'Business' })}>Business Information</a>
                                            {category === 'Business' && <hr className="hrCss" />}
                                        </li>
                                        <li className="nav-item pr-5">
                                            <a className={`${category === "Key" ? "actColor" : "lightGray"} headerText`} onClick={() => this.setState({ category: 'Key' })}>Key</a>
                                            {category === 'Key' && <hr className="hrCss" />}
                                        </li>
                                    </ul>
                                    <div className="navbar-nav ml-auto">
                                        <label className="headrSwitch mt-3 mr-3">Compliance Only</label>
                                        <label className="mt-1">
                                            <Switch
                                                onChange={this.handleChange}
                                                checked={checked}
                                                onColor='#1DB154'
                                                checkedIcon={<p className="yesText">Yes</p>}
                                                uncheckedIcon={<p className="noText">No</p>}
                                                height={40}
                                                width={112}
                                            />
                                        </label>
                                    </div>
                                </nav>
                            </div>
                            {
                                category === "Applicant" && applicationValue && applicationValue.length ?
                                    <div className="fullBorder col-sm-12 col-12 p-0 row ml-0 mr-0">
                                        {applicationValue.map(elem => {
                                            return (
                                                <div key={elem.appValue} className="nameBox text-center">
                                                    <label className={`${currentName === elem.appValue ? "actColor" : "lightGray"} font26Bold`} onClick={() => this.getAttributeName(elem)}>{elem.appValue}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : null
                            }
                            {checked === false ? Object.keys(category === "Applicant" ? applicantInformation : category === "Business" && businessInformation).map((elements, i) => {
                                let entityID = "";
                                let result = "";
                                return (
                                    <div key={`${elements}_${i}`}>
                                        <div className="plusForm fullBorder topBrdrNone col-sm-12 col-12 row ml-0 mr-0 mb-3 crbgColor">
                                            <div className="col-md-6 row ml-0 mr-0 d-flex section-row">
                                                <img src={attributeIndex.indexOf(i) !== -1 ? "/images/Component9.png" : "/images/Component-14.png"} onClick={() => this.showAttributes(i)} className="mr-2" width="65" height="58" />
                                                <div className="smallDivider ml-4 mr-2" />
                                                <p className="orangeColor font34Bold mb-0 pt-2 mr-3">{elements}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row ml-0 mr-0 float-right aiCenter">
                                                    <p className="crText mb-0 pt-1 mr-3">Complaince Requirement</p>
                                                    {
                                                        result = complianceRequired && complianceRequired.length && complianceRequired[i] !== undefined ? complianceRequired[i].some(el => el === true) : null
                                                    }
                                                    {
                                                        result ?
                                                            <>
                                                                <img src="/images/Group 2392.png" className="mr-2 mt-1" width="36" height="36" />
                                                                <p className="font26Bold greenColor mb-0 pt-1 mr-3">Yes</p>
                                                            </> : <>
                                                                <img src="/images/Group 2393.png" className="mr-2 mt-1" width="30" height="30" />
                                                                <p className="font26Bold redColor mb-0 mr-3">No</p>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {attributeIndex.indexOf(i) !== -1 && (category === "Applicant" ? applicantInformation[elements] : businessInformation[elements]).map((e, j) => {
                                            if (elements === "Business Type" && businessType.length)
                                                return
                                            return riskCompliances && Object.keys(riskCompliances).length ? riskCompliances['data'].map(k => {
                                                const { attributes, relationships } = k;
                                                if (category === "Applicant" && attributes.application_value === currentName || attributes.entity_id === entityIds) {
                                                    entityID = attributes.entity_id;
                                                }
                                                if (category === "Business")
                                                    entityID = attributes.entity_id;
                                                const entityType = category === "Applicant" ? "User" : "Company";
                                                if (attributes.entity_type === entityType && attributes.attribute_name === e && attributes.entity_id === entityID && currentName !== "" && entityID !== "") {
                                                    elements === "Business Type" && businessType.push(e)
                                                    return (
                                                        <>
                                                            <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                                                <div className="col-md-4 leftRightBorder pt-4 topBorder">
                                                                    <div className="pb-4">
                                                                        <p className="font26Bold blueColor mb-2">{e === "Name" ? "First Name" : e === "Home Address - Owned" ? e.slice(0, e.indexOf('-') - 1) : e === "Home Address - Rented" ? e.slice(0, e.indexOf('-') - 1) : e}</p>
                                                                        <p className="font34Bold grayColor mb-0">{e === "Name" ? attributes.application_value.split(' ')[0] : attributes.application_value}</p>
                                                                    </div>
                                                                    {e === "Name" ?
                                                                        <div className="pb-4">
                                                                            <p className="font26Bold blueColor mb-2">Last Name</p>
                                                                            <p className="font34Bold grayColor mb-0">{attributes.application_value.split(' ')[1]}</p>
                                                                        </div> : null
                                                                    }
                                                                    {
                                                                        e.slice(0, e.indexOf('-') - 1) === "Home Address" ? <div className="pb-4">
                                                                            <p className="font26Bold blackColor mb-2">Address Type</p>
                                                                            <div className="d-flex">
                                                                                <p className="font26Bold mb-0 grayColor pt-1 mr-3">{e.slice(e.indexOf('-') + 2)}</p>
                                                                            </div>
                                                                        </div> : null
                                                                    }
                                                                    {attributes.document_link !== null ?
                                                                        <>
                                                                            <div className="pb-4">
                                                                                <p className="font26Bold blackColor mb-2">Document Available</p>
                                                                                <div className="d-flex">
                                                                                    <img src="/images/Group 2392.png" className="mr-2 mt-1" width="36" height="36" />
                                                                                    <p className="font26Bold greenColor mb-0 pt-1 mr-3">Yes</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="pb-4">
                                                                                <p className="font26Bold blackColor mb-2">Document Type</p>
                                                                                <div className="d-flex">
                                                                                    <p className="font26Bold grayColor mb-0 pt-1 mr-3">{attributes.document_type}</p>
                                                                                    {
                                                                                        documentLink.slice(documentLink.lastIndexOf(".") + 1).split('?')[0] === "csv" || documentLink.slice(documentLink.lastIndexOf(".") + 1).split('?')[0] === "xlsx" ?
                                                                                            <div onClick={() => this.setState({ documentLink: attributes.document_link })}>
                                                                                                <a href={documentLink} download>
                                                                                                    <img src="/images/Group 2186.png" className="mr-2 mt-1" width="36" height="36" />
                                                                                                </a>
                                                                                            </div> :
                                                                                            <img src="/images/Group 2186.png" onClick={() => this.setState({ showModal: true, documentLink: attributes.document_link })} className="mr-2 mt-1" width="36" height="36" />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </> : null
                                                                    }
                                                                    {percentCircle && percentCircle.length ?
                                                                        <>
                                                                            <p className="font26Bold blackColor mb-2">Mintifi Score</p>
                                                                            <div className="d-flex padBottom">
                                                                                {percentCircle[i][j]}
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                    }
                                                                </div>
                                                                <div className="col-md-8 p-0 key-background">
                                                                    <div className="row ml-0 mr-0">
                                                                        <div className="col-md-4 fullBorder pt-4 pb-4">
                                                                            <p className="mb-0 font26Bold darkGray">Source</p>
                                                                        </div>
                                                                        <div className="col-md-4 fullBorder pt-4 pb-4">
                                                                            <p className="mb-0 font26Bold darkGray">Value</p>
                                                                        </div>
                                                                        <div className="col-md-4 fullBorder pt-4 pb-4">
                                                                            <p className="mb-0 font26Bold darkGray">Match Risk</p>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        relationships.validation_secondary_informations.data.map(d => {
                                                                            const { id } = d;
                                                                            return riskCompliances['included'].map(include => {
                                                                                if (include.id === id) {
                                                                                    const { attributes } = include;
                                                                                    return (
                                                                                        <div key={id} className="row ml-0 mr-0">
                                                                                            <div className="col-md-4 fullBorder pt-4 pb-4">
                                                                                                <p className="mb-0 font26Bold lightGray">{attributes.data_source_name}</p>
                                                                                                {attributes.data_source_name === "Employee Check" ? attributes.verified_by ? <p className="mb-0 font26Bold emailText">{attributes.verified_by}</p> : null : null}
                                                                                            </div>
                                                                                            <div className="col-md-4 fullBorder pt-4 pb-4 aiCenter">
                                                                                                {
                                                                                                    attributes.data_source_value !== null ?
                                                                                                        <p className="w-100 m-0 font26Bold mb-0 mr-3">{attributes.data_source_value}</p> :
                                                                                                        <div className="divider1" />
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-md-4 d-flex fullBorder pt-4 pb-4">
                                                                                                {attributes.match_percentage !== null
                                                                                                    ?
                                                                                                    <div className="font26Bold mb-0 mr-3">
                                                                                                        {attributes.match_percentage}
                                                                                                    </div>
                                                                                                    :
                                                                                                    attributes.match_result !== null || attributes.verified_by !== null ?
                                                                                                        attributes.match_result === "Yes" || attributes.verified_by !== null ?
                                                                                                            <>
                                                                                                                <img src="/images/Group 2392.png" className="mr-2 mt-1" width="30" height="30" />
                                                                                                                <p className="font26Bold greenColor mb-0 mr-3">Yes</p>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <img src="/images/Group 2393.png" className="mr-2 mt-1" width="30" height="30" />
                                                                                                                <p className="font26Bold redColor mb-0 mr-3">No</p>
                                                                                                            </>
                                                                                                        : <div className="aiCenter">
                                                                                                            <div className="divider1" />
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            })
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="mb-3"></div>
                                                        </>
                                                    )
                                                }
                                            })
                                                : null
                                        })
                                        }
                                    </div>
                                )
                            }) : null
                            }
                            {
                                checked ? category !== "Key" && complianceRequired && complianceRequired !== undefined && complianceRequired.length && complianceRequired.map((cat, i) => {
                                    const result = cat.some(c => c);
                                    if (result) {
                                        const info = Object.keys(category === "Applicant" ? applicantInformation : category === "Business" && businessInformation);
                                        let entityID = "";
                                        let result = "";
                                        return (
                                            <>
                                                <div className="plusForm fullBorder topBrdrNone col-sm-12 col-12 row ml-0 mr-0 mb-3 crbgColor">
                                                    <div className="col-md-6 row ml-0 mr-0 d-flex section-row">
                                                        <img src={attributeIndex.indexOf(i) !== -1 ? "/images/Component9.png" : " /images/Component-14.png"} onClick={() => this.showAttributes(i)} className="mr-2" width="65" height="58" />
                                                        <div className="smallDivider ml-4 mr-2" />
                                                        <p className="orangeColor font34Bold mb-0 pt-2 mr-3">{info[i]}</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row ml-0 mr-0 float-right aiCenter">
                                                            <p className="crText mb-0 pt-1 mr-3">Complaince Requirement</p>
                                                            {
                                                                result = complianceRequired[i] && complianceRequired[i] !== undefined ? complianceRequired[i].some(el => el === true) : null
                                                            }
                                                            {
                                                                result ?
                                                                    <>
                                                                        <img src="/images/Group 2392.png" className="mr-2 mt-1" width="36" height="36" />
                                                                        <p className="font26Bold greenColor mb-0 pt-1 mr-3">Yes</p>
                                                                    </> : <>
                                                                        <img src="/images/Group 2393.png" className="mr-2 mt-1" width="30" height="30" />
                                                                        <p className="font26Bold redColor mb-0 mr-3">No</p>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {info && info[i] !== undefined && attributeIndex.indexOf(i) !== -1 && (category === "Applicant" ? applicantInformation : category === "Business" && businessInformation)[info[i]].map((e, j) => {
                                                    if (cat === "Business Type" && businessType.length)
                                                        return
                                                    return riskCompliances && Object.keys(riskCompliances).length ? riskCompliances['data'].map(k => {
                                                        const { attributes, relationships } = k;
                                                        if (category === "Applicant" && attributes.application_value === currentName || attributes.entity_id === entityIds) {
                                                            entityID = attributes.entity_id;
                                                        }
                                                        if (category === "Business")
                                                            entityID = attributes.entity_id;
                                                        const entityType = category === "Applicant" ? "User" : "Company";
                                                        if (attributes.entity_type === entityType && attributes.attribute_name === e && attributes.entity_id === entityID && currentName !== "" && entityID !== "") {
                                                            cat === "Business Type" && businessType.push(e)
                                                            return (
                                                                <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                                                    <div className="col-md-4 leftRightBorder topBorder pt-4">
                                                                        <div className="pb-4">
                                                                            <p className="font26Bold blueColor mb-2">{e === "Name" ? "First Name" : e === "Home Address - Owned" ? e.slice(0, e.indexOf('-') - 1) : e === "Home Address - Rented" ? e.slice(0, e.indexOf('-') - 1) : e}</p>
                                                                            <p className="font34Bold grayColor mb-0">{e === "Name" ? attributes.application_value.split(' ')[0] : attributes.application_value}</p>
                                                                        </div>
                                                                        {e === "Name" ?
                                                                            <div className="pb-4">
                                                                                <p className="font26Bold blueColor mb-2">Last Name</p>
                                                                                <p className="font34Bold grayColor mb-0">{attributes.application_value.split(' ')[1]}</p>
                                                                            </div> : null
                                                                        }
                                                                        {
                                                                            e.slice(0, e.indexOf('-') - 1) === "Home Address" ? <div className="pb-4">
                                                                                <p className="font26Bold blackColor mb-2">Address Type</p>
                                                                                <div className="d-flex">
                                                                                    <p className="font26Bold mb-0 grayColor pt-1 mr-3">{e.slice(e.indexOf('-') + 2)}</p>
                                                                                </div>
                                                                            </div> : null
                                                                        }
                                                                        {attributes.document_link !== null ?
                                                                            <>
                                                                                <div className="pb-4">
                                                                                    <p className="font26Bold blackColor mb-2">Document Available</p>
                                                                                    <div className="d-flex">
                                                                                        <img src="/images/Group 2392.png" className="mr-2 mt-1" width="36" height="36" />
                                                                                        <p className="font26Bold greenColor mb-0 pt-1 mr-3">Yes</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="pb-4">
                                                                                    <p className="font26Bold blackColor mb-2">Document Type</p>
                                                                                    <div className="d-flex">
                                                                                        <p className="font26Bold grayColor mb-0 pt-1 mr-3">{attributes.document_type}</p>
                                                                                        {
                                                                                            documentLink.slice(documentLink.lastIndexOf(".") + 1).split('?')[0] === "csv" || documentLink.slice(documentLink.lastIndexOf(".") + 1).split('?')[0] === "xlsx" ?
                                                                                                <div onClick={() => this.setState({ documentLink: attributes.document_link })}>
                                                                                                    <a href={documentLink} download>
                                                                                                        <img src="/images/Group 2186.png" className="mr-2 mt-1" width="36" height="36" />
                                                                                                    </a>
                                                                                                </div> :
                                                                                                <img src="/images/Group 2186.png" onClick={() => this.setState({ showModal: true, documentLink: attributes.document_link })} className="mr-2 mt-1" width="36" height="36" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </> : null
                                                                        }
                                                                        {percentCircle && percentCircle.length ?
                                                                            <>
                                                                                <p className="font26Bold blackColor mb-2">Mintifi Score</p>
                                                                                <div className="d-flex padBottom">
                                                                                    {percentCircle[i][j]}
                                                                                </div>
                                                                            </>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-8 p-0 key-background">
                                                                        <div className="row ml-0 mr-0">
                                                                            <div className="col-md-4 fullBorder pt-4 pb-4">
                                                                                <p className="mb-0 font26Bold darkGray">Source</p>
                                                                            </div>
                                                                            <div className="col-md-4 fullBorder pt-4 pb-4">
                                                                                <p className="mb-0 font26Bold darkGray">Value</p>
                                                                            </div>
                                                                            <div className="col-md-4 fullBorder pt-4 pb-4">
                                                                                <p className="mb-0 font26Bold darkGray">Match Risk</p>
                                                                            </div>
                                                                        </div>

                                                                        {
                                                                            relationships.validation_secondary_informations.data.map(d => {
                                                                                const { id } = d;
                                                                                return riskCompliances['included'].map(include => {
                                                                                    if (include.id === id) {
                                                                                        const { attributes } = include;
                                                                                        return (
                                                                                            <div key={id} className="row ml-0 mr-0">
                                                                                                <div className="col-md-4 fullBorder pt-4 pb-4">
                                                                                                    <p className="mb-0 font26Bold lightGray">{attributes.data_source_name}</p>
                                                                                                    {attributes.data_source_name === "Employee Check" ? attributes.verified_by ? <p className="mb-0 font26Bold emailText">{attributes.verified_by}</p> : null : null}
                                                                                                </div>
                                                                                                <div className="col-md-4 fullBorder pt-4 pb-4 aiCenter">
                                                                                                    {
                                                                                                        attributes.data_source_value !== null ?
                                                                                                            <p className="w-100 m-0 font26Bold mb-0 mr-3">{attributes.data_source_value}</p> :
                                                                                                            <div className="divider1" />
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="col-md-4 d-flex fullBorder pt-4 pb-4">
                                                                                                    {attributes.match_percentage !== null
                                                                                                        ?
                                                                                                        <div className="font26Bold mb-0 mr-3">
                                                                                                            {attributes.match_percentage}
                                                                                                        </div>
                                                                                                        :
                                                                                                        attributes.match_result !== null || attributes.verified_by !== null ?
                                                                                                            attributes.match_result === "Yes" || attributes.verified_by !== null ?
                                                                                                                <>
                                                                                                                    <img src="/images/Group 2392.png" className="mr-2 mt-1" width="30" height="30" />
                                                                                                                    <p className="font26Bold greenColor mb-0 mr-3">Yes</p>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <img src="/images/Group 2393.png" className="mr-2 mt-1" width="30" height="30" />
                                                                                                                    <p className="font26Bold redColor mb-0 mr-3">No</p>
                                                                                                                </>
                                                                                                            : <div className="divider1" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                        : null
                                                })
                                                }
                                            </>
                                        )
                                    }
                                }) : null
                            }

                            {
                                documentLink.slice(documentLink.lastIndexOf(".") + 1).split('?')[0] === "pdf" || documentLink.slice(documentLink.lastIndexOf(".") + 1).split('?')[0] === "png" || documentLink.slice(documentLink.lastIndexOf(".") + 1).split('?')[0] === "jpg" ?
                                    <Modal
                                        isModalOpen={showModal}
                                        closeModal={() => this.setState({ showModal: false })}
                                        style={modalStyle}
                                    >
                                        <div className="modal-close-button">
                                            <p className="close-text">Close</p>
                                            <img src="/images/close.png" onClick={() => this.setState({ showModal: false })} style={{ height: 19, width: 19, marginTop: 31 }} />
                                        </div>
                                        <hr style={{ marginTop: 20 }} />
                                        <div id="image-view">
                                            <div>
                                                <FileViewer
                                                    fileType={documentLink.slice(documentLink.lastIndexOf(".") + 1).split('?')[0]}
                                                    filePath={documentLink}
                                                    ref={this.showFile}
                                                />
                                            </div>
                                        </div>
                                    </Modal> : null
                            }

                            {/* -------------------------- */}

                            {
                                category === "Key" ?
                                    <>
                                        <div className="plusForm fullBorder topBrdrNone col-sm-12 col-12 row ml-0 mr-0 mb-3 crbgColor">
                                            <div className="col-md-6 row ml-0 mr-0">
                                                <div className="smallDivider ml-4 mr-2" />
                                                <p className="orangeColor font34Bold mb-0 pt-2 mr-3">List of Accepted Documents</p>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder fullBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">KYC of Individuals - </p>
                                                    <p className="font26Bold blueColor mb-2">Name of the Individual</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">PAN</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Aadhar</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Driving License</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Passport</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Non - Individual KYC </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Company / Partnership PAN</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Residence Address Proof for </p>
                                                    <p className="font26Bold blueColor mb-2">Owned Property</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Electicity Bill</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Property Tax Bill</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Sale Deed with Index ll</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Residence Address Proof for </p>
                                                    <p className="font26Bold blueColor mb-2">Rented Property</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Sale Deed with Index ll</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">{'Registered / Notorised & Valid Rent Agreement'}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Office Address - Owned </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Electicity Bill</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Property Tax Bill</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Sale Deed with Index ll</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Office Address - Rented </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">{'Registered / Notorised & Valid Rent Agreement'}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Entity Proof for Sole Proprietorship </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">GST Certification</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">COI</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Service Tax or VAT Registration Certificate</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">{'Shop & Establishment Licence'}</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Food Licence</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Bank Account Number</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Bank Statement</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Udhyog Addhar</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Gumasta Licence</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Entity Proof for Partnership Firm </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Partnership Deed</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Shareholding Pattern with letterhead</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Authorisation with letterhead</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Entity Proof for Private Limited Company </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">MOA</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">AOA</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Shareholding Pattern with letterhead</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Authorisation with letterhead</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Credit Analysis </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Transaction Data</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Bureau Report</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Bureau Score</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Bank Statement Analysis</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">CAM</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Credit Analysis </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Transaction Data</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Bureau Report</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Bureau Score</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Bank Statement Analysis</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">KYC Report</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">GST Return Status</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">GST Analysis Report</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Bank Statement - Current Account </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Last 6 Months of Transaction Data</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Financials for above Rs. 15 </p>
                                                    <p className="font26Bold blueColor mb-2">Lakh loans</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">ITR acknowledgement copy for Last 2 years alongwith Income Computation</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Auditor Report</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">{'Profit & Loss , Balance sheet'}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Post Sanction Letter Requirements </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">PF (Received/ Not Received)</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">CPV - Office</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">CPV - Residence for all the applicant</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Sanction Letter</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Loan Application Form Signed</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Reference details (no relatives)</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Loan Details Sheet Signed</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">{'T & C Agreement Signed'}</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">SPDC - 4 ( If CC a/c - 13 pdc Required )</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">NACH form signed - 4 COPIES</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 p-0 row ml-0 mr-0 leftRightBorder bottomBorder">
                                            <div className="col-md-4 leftRightBorder pt-4">
                                                <div className="pb-3">
                                                    <p className="font26Bold blueColor mb-2">Other Requirements </p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row ml-0 key-background mr-0">
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Vernacular declaration for signature in local language other than English (Complete Filled From) - If applicable )</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Bank signature verification, If Signature mismatch with Pan or any other signature proof (Complete Filled Form ) - If applicable )</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">{'GST Declaration (If there is no GST Certificate & Compelete Filled form - If applicable )'}</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">RBI Negative List</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Phone Number</div>
                                                    <div className="col-md-12 fullBorder key-text pt-4 pb-4">Email ID</div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    : null
                            }

                            {/* -------------------------- */}
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}

const mapStateToProps = state => ({
    all: state.all
});

const mapDispatchToProps = {
    getRiskCompliances,
};

export default connect(mapStateToProps, mapDispatchToProps)(RiskCompliances);