import React, { useState } from 'react';
import { Row, Col, Container, Modal, ModalBody } from 'reactstrap';
import ReactTooltip from "react-tooltip";
import Select from 'react-select';

import InputTag from '../InputTag/InputTag';
import './BankStatementReport.css';
import Loading from '../Loading/Loading';

const dateToDMY = date => {
  const DATE = new Date(date).getDate();
  const MONTH = new Date(date).getMonth() + 1;
  const YEAR = new Date(date).getFullYear();
  return '' + (DATE <= 9 ? '0' + DATE : DATE) + '-' + (MONTH <= 9 ? '0' + MONTH : MONTH) + '-' + YEAR;
}

const BankStatementReport = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const statement = statements => {
    const STATEMENT = statements.map(statement => ({
      value: statement,
      label: <div><img className="pl-3 pr-3" src='/images/Group 2193.png' alt="documentIcon" />{statement}</div>
    }));
    return (
      <div className="col-md-12" style={{ width: 300 }}>
        <Select options={STATEMENT} value={STATEMENT[0]} isSearchable={false}/>
      </div>
    )
  }

  return (
    <Container fluid={true} className="reportBoxBank mb-3">
      <Row className="bottomBrdr ml-1 mr-1 pt-2 pb-2">
        <Col md="7" sm="12" xs="12">
          {props.isFetching && <Loading />}
          <Row>
            {
              props.analyseReport.status == "initiated" ?
                <div className="mt-3 fixDivWidth185">
                  <button className='inprogressBtn pt-1 cpDefault bfNone'>In progress<img className="ml-3" src={'/images/Group 2181.png'} alt="loading" /></button>
                </div>
                :
                props.analyseReport.status == "completed" ?
                  <div className="mt-3 fixDivWidth185">
                    <button className='inprogressBtn pt-1 cpDefault bfNone'>Completed</button>
                  </div>
                  :
                  <div className="mt-3 fixDivWidth185">
                    <button className='failedBtn pt-1 cpDefault bfNone'>Failed<img className="ml-5" src={'/images/Group 2393.png'} alt="loading" width="20px" /></button>
                  </div>
            }
            <span className='dividerRP' />
            <div md="3" sm="11" className="fixDivWidth">
              <span className='reportText'>{dateToDMY(props.analyseReport.analysed_on)}</span>
              <span className='reportText float-right'><img className="ml-3" src={'/images/Group 2189.png'} alt="calendar" /></span>
            </div>
            {!!props.analyseReport.file_name && <span className='dividerRP' />}
            {
              !!props.analyseReport.file_name &&
              <div className="pdfText ">
                {props.analyseReport.file_name}
              </div>
            }
            <span className='dividerRP' />
            <div md="3" sm="12">
              <span className='reportText'>Ref ID: {props.analyseReport.reference_id}</span>
            </div>
          </Row>
        </Col>
        <Col md="5" sm="12" xs="12" className="pr-0">
          <div className="float-right" style={{ display: 'flex' }}>
            {props.analyseReport.status == "failed" && props.analyseReport.statements && <span data-tip={props.analyseReport.remark} data-for='statements' className="progressText selectrptView mr-2 mb-3"> {statement(props.analyseReport.statements)}</span>}
            <ReactTooltip id='statements' place="top" type="dark" effect="solid" />
            {
              props.analyseReport.status == "completed" ?
                <>
                  <span className='reportText mr-2'>View</span>
                  <span onClick={toggle} className='reportText mr-5 cp'><img src={'/images/Group 2186.png'} alt="view" /></span>
                  {modal &&
                    <div>
                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalBody>
                          <iframe src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + props.analyseReport.document_url} style={{ width: 600, height: 500 }} frameBorder="0"></iframe>
                        </ModalBody>
                      </Modal>
                    </div>}
                  <a download href={props.analyseReport.document_url} className="ulNone">
                    <span className='downloadText mr-2'>Download</span>
                    <span className='reportText'><img src={'/images/Group 2184.png'} alt="download" /></span>
                  </a>
                </> : null
            }
          </div>
        </Col>
      </Row>
      <Row className="ml-1 mr-1 pt-3 pb-1">
        <div className="pl-3">
          <Row>
            <div className="fixDivWidth185">
              <p className='reportTextBankName'>{props.analyseReport.bank_name}</p>
            </div>
            <span className='dividerRP' />
            <div className="fixDivWidth">
              <span className='reportText'>{props.analyseReport.account_type}</span>
            </div>
            <span className='dividerRP' />
            <div className="fixDivWidth">
              <span className='reportText'>{props.analyseReport.account_number}</span>
            </div>
          </Row>
        </div>
        <Col className="mt-2 pl3em pr-5">
          <InputTag tags={props.analyseReport.inhouse_keywords} {...props} status={props.analyseReport.status} />
        </Col>
        <div className="p-0 ml-2">
          {props.analyseReport.status == "completed" && <button
            onClick={() => props.submitReAnalyseStatement(props.analyseReport.document_id)}
            disabled={props.analyseReport.document_id == props.statementId && props.doDisabled}
            className={props.analyseReport.document_id == props.statementId ? "ReanyaliseBtnDisable mt-2 float-right" : "ReanyaliseBtn mt-2 float-right"}> Reanalyse
          <img src={props.analyseReport.document_id == props.statementId ? '/images/Path 3147.png' : '/images/Arrow.png'} className="ml-3" alt="back arrow" />
          </button>}
        </div>
      </Row>
    </Container>
  )
};

export default BankStatementReport;
