import React, { Component } from 'react';
import GstRuleCreation from './GstRuleCreation';
import GstReport from './GstReport';
import './GstRuleEngine.css';

class GstRuleEngine extends Component {
    render() {
        return (
            <>
                {
                    window.location.pathname === "/gst-rule-engine/gst-rule-create" ?
                        <GstRuleCreation /> : window.location.pathname === "/gst-rule-engine/gst-report" ?
                            <GstReport /> : <></>
                }
            </>
        )
    }
}

export default GstRuleEngine;