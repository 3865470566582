import React from "react";
import {
  regexTrim,
  preventFloat,
  validateKeyStrokes
} from "../../shared/common_logic";
// import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

const InputWrapper = props => {
  const {
    validation,
    onChangeHandler,
    localState,
    isPhone,
    isListType,
    alertObj,
    addText,
    isSubmitted,
    isNumber,
    isText,
    index
  } = props;
  const VALIDATION = validation;
  const { showError = {}, slug } = alertObj;
  let phoneClass = isPhone ? "input-group" : "";
  let disabled = VALIDATION.disabled ? VALIDATION.disabled : false;
  // let keyPress = isPhone ? preventFloat() : "";

  //   const datePickerFunction = props => {
  //     return (
  //       <>
  //         <div className="form-group mb-3">
  //           <label htmlFor={VALIDATION.id} className="bmd-label-floating">
  //             {VALIDATION.label}
  //           </label>
  //           <div className={"d-block"}>
  //             <DatePicker
  //               className="form-control font_weight"
  //               // placeholderText={"Date of Birth"}
  //               selected={
  //                 localState[VALIDATION.slug] === null
  //                   ? null
  //                   : new Date(localState[VALIDATION.slug])
  //               }
  //               id={VALIDATION.id}
  //               pattern={regexTrim(VALIDATION.pattern)}
  //               scrollableYearDropdown
  //               showMonthDropdown
  //               dropdownMode={"scroll"}
  //               required={VALIDATION.required}
  //               showYearDropdown
  //               dateFormat={VALIDATION.dateFormat}
  //               onChange={e => onChangeHandler(VALIDATION, e)}
  //             />
  //           </div>
  //           {showError && slug === VALIDATION.slug ? (
  //             <small style={{ color: "crimson", fontSize: "11px" }}>
  //               {VALIDATION.error}
  //             </small>
  //           ) : (
  //             ""
  //           )}
  //           {addText && <span className="bmd-help">{addText}</span>}
  //         </div>
  //       </>
  //     );
  //   };

  const listSelectionFunction = () => {
    return (
      <>
        <div className="form-group mb-3">
          <label htmlFor={VALIDATION.id} className={"bmd-label-floating selectText"}>
            {VALIDATION.label} <span className="colorRed">*</span>
          </label>
          <Select
            className="inputSelectText"
            options={VALIDATION.options}
            required={VALIDATION.required}
            id={VALIDATION.id}
            inputId={VALIDATION.inputId}
            value={localState}
            onChange={e => onChangeHandler(VALIDATION, e, index)}
            isSearchable={false}
          />
          {showError && slug === VALIDATION.slug ? (
            <small style={{ color: "crimson", fontSize: "11px" }}>
              {VALIDATION.error}
            </small>
          ) : (
              ""
            )}
          {addText && <span className="bmd-help">{addText}</span>}
        </div>
      </>
    );
  };
  // selectText">Account Number<span className="colorRed
  const inputFunction = () => {
    return (
      <>
        <div className="form-group mb-2">
          <label htmlFor={VALIDATION.id} className={"bmd-label-floating selectText"}>
            {VALIDATION.label} <span className="colorRed">*</span>
          </label>
          <div className={phoneClass}>
            {isPhone ? (
              <div className="">
                <span className="" id="basic-addon3">
                  +91
                </span>
              </div>
            ) : (
                ""
              )}
            <input
              type={VALIDATION.type}
              className={`input  col-12 p-0 inputSelectText${
                isPhone ? "input prependInput col-12" : ""
                }`}
              // placeholder="10 digit PAN Number"
              pattern={regexTrim(VALIDATION.pattern)}
              title={VALIDATION.title}
              autoCapitalize={VALIDATION.autoCapitalize}
              id={VALIDATION.id}
              disabled={isSubmitted ? false : disabled}
              required={VALIDATION.required}
              value={localState[VALIDATION.slug]}
              onChange={e => onChangeHandler(VALIDATION, e.target.value, index)}
              onKeyPress={e =>
                isNumber
                  ? preventFloat(e)
                  : isText
                    ? validateKeyStrokes(e)
                    : e.target.value
              }
            />
          </div>
          {/* {showError && slug === VALIDATION.slug ? (
            <small style={{ color: "crimson", fontSize: "11px" }}>
              {VALIDATION.error}
            </small>
          ) : (
            ""
          )} */}
          {addText && <span className="bmd-help">{addText}</span>}
        </div>
      </>
    );
  };

  // return isDatepicker ? datePickerFunction() : inputFunction();

  //   if (isDatepicker) {
  //     return datePickerFunction();
  //   } else if (isListType) {
  //     return listSelectionFunction();
  //   } else {
  //     return inputFunction();
  //   }
  return isListType ? listSelectionFunction() : inputFunction();
};

InputWrapper.defaultProps = {
  alertObj: { showError: false, slug: "", message: null },
  validation: {},
  onChangeHandler: () => { },
  localState: {},
  addText: "",
  isPhone: false,
  isSubmitted: false,
  isDatepicker: false,
  isListType: false,
  isNumber: false,
  isText: false
};

const mapStateToProp = state => ({
  alertObj: state.all
});

export default connect(mapStateToProp, {})(InputWrapper);
