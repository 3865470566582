import React from 'react';
import Header from '../header/header'
import { Row, Col, Card } from 'reactstrap';
import AnchorData from "../summarySection/AnchorData";
import CibilSummary from "../summarySection/CibilSummary";
import GstSummary from "../summarySection/GstSummary";
import BankingAnalysis from "../summarySection/BankingAnalysis";
import ItrData from "../summarySection/ItrData";
import TriangulationSummary from "../summarySection/TriangulationSummary";


const Summary = ({camData,metaData, loanId}) => {

    return (
        <>
        <Col md="12" className="pb-3">
                <Card>
                    <Header headerText="Summary" headerImg="/images/cam_version_3/black_file.svg" />
                </Card>
        </Col>
        {metaData.program_details.program_band_enabled_cam_components.includes('cibil_panel') &&
            <CibilSummary camData={camData} ID={loanId} metaData={metaData}/>
        }
        {metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel') &&
            <AnchorData camData={camData} ID={loanId} metaData={metaData}/>
        }
        {metaData.program_details.program_band_enabled_cam_components.includes('bankind_data_panel') &&
            <BankingAnalysis camData={camData} ID={loanId} metaData={metaData}/>
        }
        {metaData.program_details.program_band_enabled_cam_components.includes('gst_data_panel') &&
            <GstSummary camData={camData} ID={loanId} metaData={metaData}/>
        }
        {metaData.program_details.program_band_enabled_cam_components.includes('itr_panel') &&
            <ItrData camData={camData} ID={loanId} metaData={metaData}/>
        }
        {metaData.program_details.program_band_enabled_cam_components.includes('triangulation_panel') &&
            <TriangulationSummary camData={camData} ID={loanId} metaData={metaData}/>
        }
        </>
    )
}
export default Summary;
