import React, { useEffect, useState }from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap'
import Demographics from "./Demographics";
import AnchorMerch from "./AnchorMerchent/AnchorMerchant"
import Banking from "./Banking";
import GST from "./GST/GST";
import ITR from "./ITR/ITR";
import CIBIL from "./Cibil/Cibil";
import './LoanEligible.scss';
import Header from '../header/header'


const LoanEligibility = ({camData,metaData,loanId}) => {
    const [onDataLoad,setOnDataLoad] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const toggle = (tab) => {
        if(activeTab !== tab){
            setOnDataLoad(tab);
        }        
        else{
            setOnDataLoad(false);
        }

        if (activeTab !== tab){
             setActiveTab(tab);
        }
    }
    
    
    const Comps = [
        <Demographics cam_rule_result={camData.cam_rule_result} />,
        <CIBIL camData = {camData} loanId={loanId} onDataLoad={onDataLoad} bureau_analysis = {camData.bureau_analysis} bureau_rules={camData.cam_rule_result.bureau_rules} co_applicants={camData.co_applicants[0]}/>, 
        <AnchorMerch loanId= {loanId} onDataLoad={onDataLoad} cam_rule_result={camData.cam_rule_result} origination_score={camData.origination_score}/>, 
        <Banking loanId={loanId} onDataLoad={onDataLoad} banking_rules={camData.cam_rule_result.banking_rules}/>, 
        <GST gst_profile_details={camData.gst_profile_details} loanId={loanId}/>, 
        <ITR loanId={loanId} onDataLoad={onDataLoad} gst_profile_details={camData.gst_profile_details}/>,
    ];

    const Tabsss = [
        {
            name: "Demographics",
            component: Comps[0],
            pro_condition : metaData.program_details.program_band_enabled_cam_components.includes('demographic_panel'),
        },
        {
            name: "CIBIL",
            component: Comps[1],
            pro_condition : metaData.program_details.program_band_enabled_cam_components.includes('loan_eligibility_cibil_loan_eligibility'),

        },
        {
            name: "Anchor Transaction",
            component: Comps[2],
            pro_condition : metaData.program_details.program_band_enabled_cam_components.includes('anchor_transaction_data'),

        },
        {
            name: "Banking",
            component: Comps[3],
            pro_condition : metaData.program_details.program_band_enabled_cam_components.includes('banking_data_panel'),

        },
        {
            name: "GST",
            component: Comps[4],
            pro_condition : metaData.program_details.program_band_enabled_cam_components.includes('loan_eligibility_gst_loan_eligibility'),

        },
        {
            name: "ITR",
            component: Comps[5],
            pro_condition : metaData.program_details.program_band_enabled_cam_components.includes('loan_eligibility_itr_loan_eligibility'),

        }
    ];

    useEffect(() => {
        const testArray = ["demographic_panel","loan_eligibility_cibil_loan_eligibility","anchor_transaction_data","banking_data_panel","loan_eligibility_gst_loan_eligibility","loan_eligibility_itr_loan_eligibility"];

        var flag = false;

        if(metaData) {
            for(var data in testArray) {
               if(Tabsss[data].pro_condition && flag === false) {
                    flag = true;
                    setOnDataLoad(data)
                    setActiveTab(data); 

               }
            }
        }
        
    }, [metaData]);
  
    return (
      <>
       <Col md="12 pb-4">
        <Card>
          <Header
            headerText="Loan Eligibility"
            headerImg="/images/cam_version_3/LoanEligible.svg"
          />
        </Card>
      </Col>
        <div className="col-md-12 pb-4 LoanEligible">
          {camData &&
         <>  
            <Nav className="printScreenTab" tabs style={{ border: "none" }}>
                {
                    Tabsss.map((Tab, index) =>
                    (
                        <>
                        { Tab.pro_condition &&
                        <NavItem key={index} className="pointer ">
                            <NavLink 
                                className={`navLink p-3 ${Number(activeTab) === index ? 'active' : ""}`}
                                
                                onClick={() => { toggle(index); }}
                            >
                                {Tab.name}
                            </NavLink>
                        </NavItem>
                        }
                        </>
                    )
                    )
                }


            </Nav>


            <TabContent className="printScreenTab" activeTab={Number(activeTab)}>
                {
                    Tabsss.map((Tab, index) =>
                    (   
                        <>
                        <TabPane key={index} tabId={index}>
                        { Tab.pro_condition &&
                            <Row>
                                {Tab.component}
                            </Row>
                        }
                        </TabPane>
                        </>
                    )
                    )
                }

            </TabContent>
            </>
            }
        </div >
        </>
    )
}
export default LoanEligibility;