import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'reactstrap';
import RoundProcess from './roundProcess'
import './summarySection.scss';
import {useSelector,useDispatch} from 'react-redux';
import {getAnchorData,getGstCamData} from '../../../actions/AllActions';
import Loader from '../../Loader/Loader';
import Moment from 'moment';


function GstSummary({camData,metaData,ID}) {

    const [gstUser,setGstUser] = useState('');
    const [gstKey,setGstKey] = useState('');
    const dispatch = useDispatch();    
    const defaultGst = camData.gst_profile_details && Object.keys(camData.gst_profile_details);
    const gst_cam = useSelector(state => state.all.gst_cam_data);
     const gst_cam_data = gst_cam && gst_cam.data;
    useEffect(()=>{
        dispatch(getGstCamData(ID));

        if(camData.gst_profile_details!==undefined){
     Object.keys(camData.gst_profile_details).map((key,i) => {
            setGstUser(camData.gst_profile_details[defaultGst[0]]);
            setGstKey(defaultGst[0]);
        })
    }
    },[]) 

    const ValueCheck = ( x) => {
        if(x !== null){
            
            var stringValue = String(x).toLowerCase();
            if(stringValue === "true") {
                return "Yes";
            }
            else if(stringValue === "false") {
                return "No";
            }
            else {
                return x;
            }

        } else {
            return "-";
        }
    }
    
    const BocData =  {
        name: "GST",
        icon: "/images/cam_version_3/file.svg",
        list: {
            "Month/Year of GST Registration": [metaData.program_details.program_band_enabled_cam_components.includes('gst_data_panel_gst_registration'),
                    (ValueCheck(gst_cam_data && gst_cam_data.attributes.data && gst_cam_data.attributes.data.gst_registration.month_year[0]+"/")+ValueCheck(gst_cam_data && gst_cam_data.attributes.data && gst_cam_data.attributes.data.gst_registration.month_year[1]+","))+ (ValueCheck(gst_cam_data && gst_cam_data.attributes.data && gst_cam_data.attributes.data.gst_registration && gst_cam_data.attributes.data.gst_registration.month_count) || "-")],
            "Last 12 Months GST Filed status":[metaData.program_details.program_band_enabled_cam_components.includes('gst_data_panel_last_12_months_gst_filed_status'),
                    (ValueCheck(gst_cam_data && gst_cam_data.attributes.data && gst_cam_data.attributes.data.gst_filing.no_of_month_filed +"/")+ValueCheck(gst_cam_data && gst_cam_data.attributes.data && gst_cam_data.attributes.data.gst_filing.actual_montn)|| "-") ] ,
            "Anchor Purchase As A % Of Total Purchases":[metaData.program_details.program_band_enabled_cam_components.includes('gst_data_panel_precent_of_total_purchases'),
                     (ValueCheck(gst_cam_data && gst_cam_data.attributes.data && gst_cam_data.attributes.data.anchor_purchaes) || "-")],
            "Average monthly sales as per GST in last 12 months":[metaData.program_details.program_band_enabled_cam_components.includes('gst_data_panel_avg_monthly_sales_last_12_months'),
                     (ValueCheck(gst_cam_data && gst_cam_data.attributes.data && gst_cam_data.attributes.data.avg_monthly_sales) || "-")],
            "No of Times Refund Claimed in the Last 12 Months ":[metaData.program_details.program_band_enabled_cam_components.includes('gst_data_panel_no_of_claimed_last_12_months'),
                     (ValueCheck(gst_cam_data && gst_cam_data.attributes.data && gst_cam_data.attributes.data.refund_claimed_count) || "-")]
        },
        // score: camData && camData.actyv_scores && camData.actyv_scores.gst_score && camData.actyv_scores.gst_score.score || "N/A"
    }
   
    return (
        <Col md="12" lg="6" className="mb-3" >
            <Card className="summarySection">
                <Row className="summarySectionHead">
                    <Col md="12">
                        <Row className='align-items-center' style={{height:'90px'}}>
                        <Col md="4" sm="4">
                                <div className="float-left p-4 ">
                                    <div className="d-flex headerText">
                                        <img src={BocData.icon} />
                                        <p className="pl-2">{BocData.name}</p>
                                    </div>
                                </div>
                            </Col>                            
                            {/* <Col md="8" sm="8">
                                <div className="float-right">
                                    <div className=" d-flex flex-row-reverse bd-highlight">
                                        <RoundProcess width="70" height="70" scx="37%" scy="62%" x="50" y="45" score={BocData.score} hml={BocData.hml} />
                                    </div>                                    
                                </div>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
                <hr className="mb-2 mt-0" />
                <Row>
                    <Col md="12">                       
                        {gst_cam && (gst_cam.data || gst_cam.error) &&
                        <div className="dataFont">
                            {Object.keys(BocData.list).map((key, i) => (
                                
                                <p key={i} className="d-flex px-3 py-2 ">                                  
                                    <span className="mr-auto cibilKey">{key}</span>
                                    <span className="cibilValue">{BocData.list[key][1]}</span>
                                </p>
                            ))
                            }
                        </div>
                        }                        
                        
                    </Col>
                </Row>
                {gst_cam && gst_cam.loading && 
                            <div className="Loadergif">
                            <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                        </div>
                        }
            </Card>
        </Col>
    )
}

export default GstSummary;
