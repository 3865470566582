import React, { Component } from 'react';
import {
  Container, Row, Col
} from 'reactstrap';
import Steps, { Step } from 'rc-steps';
import { connect } from 'react-redux';
import { getCamReport,getMlScore } from '../../actions/AllActions';
import './Cam.css'
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import ViewCamSummary from '../../components/ViewCamSummary/ViewCamSummary';
import CamSummary from '../../components/CamSummary/CamSummary';
import Deviations from '../../components/Deviations/Deviations';
import CoApplicant from '../../components/CoApplicant/CoApplicant';
import Proposal from '../../components/Proposal/Proposal';
import Bureau from '../../components/Bureau/Bureau';
import OriginationRisk from '../../components/OriginationRisk/OriginationRisk';
import LoanEligibility from '../../components/LoanEligibility/LoanEligibility';
import GST from '../../components/GST/GST';
import LoanSplitup from '../../components/LoanSplitup/LoanSplitup';
//import Documents from '../../components/Documents/Documents';
//import MLscore from '../../components/MLScore/MLScore';

class Cam extends Component {

  state = {
    isPopup: false,
    camReport: {},
    current: 0,
    step: ["CAM Overview Report", "CAM Summary", "Deviations", "Applicant's Details", "Proposal Details", "Bureau Analysis", "Origination Risk", "GST Details", "Historical loan split up", "Loan Eligibility Offering"],
    nestedStep: [],
    insideCurrent: 0,
    nestedStepB: [],
    insideCurrentB: '',
    nestedStepL:[],
    insideCurrentL:'',
    nestedStepG: [],
    insideCurrentG: '',
    itemID: null,
    loan_id: null,
  }


  UNSAFE_componentWillMount() {

    const { match } = this.props;
    const ID = match.params.id;
    var that = this;
    this.setState({loan_id: ID},() => {
      that.props.getCamReport(ID);
      that.props.getMlScore(ID)
    });    
  }

  
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { cam_report } = nextProps.all;

    if (cam_report !== this.props.all.cam_report && cam_report.data && cam_report.data.attributes) {
      const camDATA = cam_report && cam_report.data && cam_report.data.attributes;
      let nestedStep = [];
      let nestedStepB = [];
      let insideCurrentB = '';
      let nestedStepG = [];
      let insideCurrentG = '';
      let nestedStepL = [];
      let insideCurrentL = '';
      for(var coapp in camDATA.co_applicants) {
        if(camDATA.co_applicants[coapp].is_primary) {
          nestedStep.push(camDATA.co_applicants[coapp].name);
        }
      }
      for(var coapp1 in camDATA.co_applicants) {
        if(!camDATA.co_applicants[coapp1].is_primary) {
          nestedStep.push(camDATA.co_applicants[coapp1].name);
        }
      }

      for(var coapp2 in camDATA.bureau_analysis) {
        if(camDATA.bureau_analysis[coapp2].is_primary) {
          if(insideCurrentB === "") {
            insideCurrentB = coapp2;
          }
          nestedStepB.push(coapp2);
        }
      }
      for(var coapp21 in camDATA.bureau_analysis) {
        if(!camDATA.bureau_analysis[coapp21].is_primary) {
          nestedStepB.push(coapp21);
        }
      }
       for(var coapp7 in camDATA.gst_profile_details) {
        if(camDATA.gst_profile_details[coapp7].is_primary) {
          if(insideCurrentG === "") {
            insideCurrentG = coapp7;
          }
          nestedStepG.push(coapp7);
        }
      }
     
       for(var coapp71 in camDATA.gst_profile_details) {
        if(!camDATA.gst_profile_details[coapp71].is_primary) {
          nestedStepG.push(coapp71);
        }
      }
      for(var coapp3 in camDATA.bureau_analysis){
        if(camDATA.bureau_analysis[coapp3].is_primary){
          if(insideCurrentL === ""){
            insideCurrentL = coapp3;
          }
          nestedStepL.push(coapp3); 
        }
      }
      for(var coapp31 in camDATA.bureau_analysis){
        if(!camDATA.bureau_analysis[coapp31].is_primary){
          nestedStepL.push(coapp31);
        }
      }
      this.setState({ camReport: camDATA,nestedStep: nestedStep,insideCurrentB: insideCurrentB,nestedStepB: nestedStepB,insideCurrentG: insideCurrentG,nestedStepG: nestedStepG,insideCurrentL:insideCurrentL,nestedStepL:nestedStepL });
    }
    
  }

  changePage = (val) => {
    var state = this.state;
    this.setState({ current: val,insideCurrent: 0,insideCurrentB: state.nestedStepB[0],insideCurrentL:state.nestedStepL[0],insideCurrentG: state.nestedStepG[0]},() => {
      window.scrollTo(0, 0);
    });
    
  }
  

  getHeading = () => {
    var state = this.state;
    const { camReport } = this.state;
    if(state.current === 3) {
      if(camReport && camReport.co_applicants && camReport.co_applicants[state.insideCurrent] && camReport.co_applicants[state.insideCurrent].is_primary) {
        return "Primary Applicant's Details"
      }
      else {
        return "CO-Applicant's Details"
      }
    }
    else {
      return state.step[state.current];
    }

  }

  render() {
    const { error } = this.props.all;
    const { current, step, nestedStep, insideCurrent, nestedStepB, insideCurrentB,nestedStepL,insideCurrentL,nestedStepG, insideCurrentG, camReport,loan_id } = this.state;
    return (
      <Container fluid>
        <div style={{ paddingLeft: 4, height: 50, paddingTop: 12 }}>
          <p className="mb-0 menuTxt">{camReport && camReport.co_applicants && camReport.co_applicants[0]['name']} <img src="/images/menuIcon.png" className="ml-3" alt="Verified" width="20px" /></p>
        </div>
        <Row style={{ background: "#FAFAFA" }}>
          {this.state.isPopup &&
            <div className="popupBox arrow-top arrow-left">
              <div className="leftArrow"></div>
              <div className="rightArrow"></div>
              <div className="borderLine pl-3 pr-3" style={{ height: 50, display: 'flex' }}>
                <p className="popupTitle" style={{ width: "100%", marginTop: 13 }}>Icon Meaning</p>
                <img src="/images/Path 3164.png" className="float-right mt_2 pointerc" alt="Verified" width="20px" height="20px" onClick={() => this.setState({ isPopup: false })} style={{ marginTop: 15 }} />
              </div>
              <div className="pl-3 pr-3 pt-3">
                <img src="/images/Group 2391.png" alt="Verified" width="20px" className="mt_2" />
                <span className="popupText">More Information</span>
              </div>
              <div className="pl-3 pr-3 pt-3">
                <img src="/images/Group 2392.png" alt="Verified" width="20px" className="mt_2" />
                <span className="popupText">Value Match</span>
              </div>
              <div className="pl-3 pr-3 pt-3">
                <img src="/images/Group 2393.png" alt="Verified" width="20px" className="mt_2" />
                <span className="popupText">Value Mismatch</span>
              </div>
              <div className="pl-3 pr-3 pt-3">
                <img src="/images/Group 2394.png" alt="Verified" width="20px" className="mt_2" />
                <span className="popupText">Verification Pending/Unverified</span>
              </div>
              <div className="pl-3 pr-3 pt-3">
                <img src="/images/Group 2395.png" alt="Verified" width="20px" className="mt_2" />
                <span className="popupText">Deviation Requested</span>
              </div>
              <div className="pl-3 pr-3 pt-3">
                <img src="/images/Group 2396.png" alt="Verified" width="20px" className="mt_2" />
                <span className="popupText">Deviation Accepted</span>
              </div>
              <div className="p-3">
                <img src="/images/Group 2397.png" alt="Verified" width="20px" className="mt_2" />
                <span className="popupText">Deviation Rejected</span>
              </div>
            </div>
          }
        </Row>
        <div style={{ height: 1, backgroundColor: '#EDEDED' }} />
        <Row style={{ background: "#FAFAFA" }}>
          <Col lg="2">
            <div className="CAM-step">
              {
                current === 3 && nestedStep.map((ns, i) =>
                  <div key={"3"+i} style={{ display: 'flex' }} onClick={() => this.setState({ insideCurrent: i },() => { window.scrollTo(0, 0)})} >
                    <Step key={ns} title={ns} className={insideCurrent === i ? 'currentInside' : 'currentInsideElse'}  />
                    {insideCurrent === i ? <div className="arrow11"><div className="line11"></div><div className="point11"></div></div> : null}
                  </div>
                )
              }
            </div>
            <div className="CAM-step CAM-stepB">
              {
                current === 5 && nestedStepB.map((ns, i) =>
                  <div  key={"5"+i}  style={{ display: 'flex' }} onClick={() => this.setState({ insideCurrentB: ns },() => { window.scrollTo(0, 0)})} >
                    <Step key={ns} title={ns} className={insideCurrentB === ns ? 'currentInside' : 'currentInsideElse'}  />
                    {insideCurrentB === ns ? <div className="arrow11"><div className="line11"></div><div className="point11"></div></div> : null}
                  </div>
                )
              }
            </div>
            <div className="CAM-step CAM-stepG">
              {
                current === 7 && nestedStepG.map((ns, i) =>
                  <div  key={"7"+i}  style={{ display: 'flex' }} onClick={() => this.setState({ insideCurrentG: ns },() => { window.scrollTo(0, 0)})} >
                    <Step key={ns} title={ns} className={insideCurrentG === ns ? 'currentInside' : 'currentInsideElse'}/>
                    {insideCurrentG === ns ? <div className="arrow11"><div className="line11"></div><div className="point11"></div></div> : null}
                  
                  </div>
                )
              }
            </div>
            <div className="CAM-step CAM-StepL">
              {
                current === 8 && nestedStepL.map((ns, i) =>
                  <div  key={"8"+i}  style={{ display: 'flex' }} onClick={() => this.setState({ insideCurrentL: ns },() => { window.scrollTo(0, 0)})} >
                    <Step key={ns} title={ns} className={insideCurrentL === ns ? 'currentInside' : 'currentInsideElse'}  />
                    {insideCurrentL === ns ? <div className="arrow11"><div className="line11"></div><div className="point11"></div></div> : null}
                  </div>
                )
              }
            </div>
            <Steps direction="vertical" size="small" className="CAM-stepPadding" onChange={val => this.changePage(val)} current={current}>
              {
                step.map((s, i) => <Step
                  key={s} title={s} icon={current === i ? <div className="CAM-currentDot" /> : <div className="CAM-nextDot" />} />)
              }
            </Steps>
          </Col>
          <Col lg="10">
           <Col lg="12" className="pr-0 pl-0 pl-2 borderBottom" style={{ height: 50 }}>
              <div className="HeadingOrange float-left">
                <p className="mainTitle d-flex pl-2 colorDark ">|
                {this.getHeading()}{current === 8 ?
                    <p style={{ color: "#5D6F83" }}>&nbsp;-&nbsp;{insideCurrentL}</p>
                    : ""
                }
                {current===7 ? 
                	<span className="subTitle" style={{color: "#5D6F83"}}>&nbsp;GSTIN:&nbsp;{insideCurrentG}</span>: "" 
                }
                </p>
              </div>
              {current !== 1 && current !== 5 && current !== 4 && current !== 7 &&
                <div className="float-right" style={{ display: 'flex' }}>
                  {/* <div>
                    <button className="downloadBtn">
                      <span className="pr-2">Download</span>
                      <img src="/images/Path 3165.png" alt="Verified" width="13px" className="mt_2" />
                    </button>
                  </div> */}
                  {current === 0 &&
                    <div>
                      <button className="legendBtn" onClick={() => this.setState({ isPopup: !this.state.isPopup })}>
                        <span className="pr-2">Legend</span>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M10.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L12.793 8l-2.647-2.646a.5.5 0 0 1 0-.708z"/>
                          <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8z"/>
                        </svg>
                      </button>
                    </div>
                  }
                  {/* <div className="ml-3">
                    <button className="updateBtn">
                      <span className="pr-2">Update Cam</span>
                      <img src="/images/Path 3166.png" alt="Verified" width="13px" className="mt_2" />
                    </button>
                  </div> */}
                </div>
              }
            </Col>
            {
              current === 0 && <ViewCamSummary loanID={loan_id} {...this.props} camReport={camReport} error={error} />
            }
            {
              current === 1 && <CamSummary loanID={loan_id} camReport={camReport} error={error} />
            }
            {
              current === 2 && <Deviations camReport={camReport} error={error} />
            }
            {
              current === 3 && <CoApplicant insideCurrent={insideCurrent} camReport={camReport} error={error}/>
            }
            {
              current === 4 && <Proposal camReport={camReport} error={error}/>
            }
            {
              current === 5 && <Bureau insideCurrent={insideCurrentB} camReport={camReport} error={error}/>
            }            
            {
              current === 6 && <OriginationRisk camReport={camReport} error={error}/>
            }
            {
              current === 7 && <GST insideCurrent={insideCurrentG} camReport={camReport} error={error}/>
            }
            {
              current === 8 && <LoanSplitup insideCurrent={insideCurrentL} camReport={camReport} error={error}/>
            }
            {/*
              current === 9 && <Documents camReport={camReport} error={error}/>
            */}
            {/*
              current === 10 && <MLscore loanID={loan_id} camReport={camReport} error={error}/>
            */}
            {
              current === 9 && <LoanEligibility camReport={camReport} error={error}/>
            }
          </Col>
          {/* <Col lg="2" className="pl-0" style={{ background: "#FAFAFA" }}>
            <Col lg="12" className="borderBottom" style={{ background: "#ffffff", height: 50 }}>
              <p className="pt-3 AddtoCartText">Add to Card</p>
            </Col>
            <div>
              <Col className="tabBtn">
                <p className="sidePanelText mb-0"><img src="/images/Group 1961.png" alt="Verified" width="20px" className="mt_4 mr-3" />Cam Summary</p>
              </Col>
              <Col className="tabBtndsbl">
                <p className="sidePanelText mb-0"><img src="/images/Group 2073.png" alt="Verified" width="20px" className="mt_4 mr-3" />Documents</p>
              </Col>
              <Col className="tabBtndsbl">
                <p className="sidePanelText mb-0"><img src="/images/Group 2028.png" alt="Verified" width="20px" className="mt_4 mr-3" />Activity</p>
              </Col>
            </div>
          </Col> */}
        </Row>
      </Container >
    )
  }
}

const mapStateToProps = state => ({
  all: state.all
});

const mapDispatchToProps = {
  getCamReport,
  getMlScore
};

export default connect(mapStateToProps, mapDispatchToProps)(Cam);
