import React, { useEffect, useState } from "react";
import {useSelector,useDispatch} from 'react-redux';
import { getAnchorMerchantEligibilityData} from '../../../../actions/AllActions';
import '../LoanEligible.scss';
import RoleResult from '../ruleResult/ruleResult';
import Loader from '../../../../components/Loader/Loader';
import "./anchorMerchant.scss";

const AnchorMerch = ({onDataLoad,loanId,cam_rule_result,origination_score}) => {  
    const dispatch = useDispatch();
    const anchorMerchantEligibilityData = useSelector(state => state.all.anchor_merchant_eligibility_data.data);
    const anchor_error = useSelector(state => state.all.error_anchor);
    
    useEffect(()=>{        
       if(anchorMerchantEligibilityData === undefined ){
           //if(Number(onDataLoad)===2){  
            dispatch(getAnchorMerchantEligibilityData(loanId));
           //}
       }
       
    },[onDataLoad]);

    const commaSeparatRs = (value) => {
        if(typeof(value) === 'string'){
            return value;
        }
        else if(typeof(value) === 'number'){
            return Number(value).toLocaleString('en-IN', { minimumFractionDigits: 2 })
        }
       
    }
    
    return (
        <div className="col-md-12 LoanEligible">
            <div className="py-2 tabPrint text-center text-uppercase">
                Anchor Transaction
            </div>
           
            <div className="row m-0 card" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "10px 10px 30px" }}>
            <div className="col-md-12">
                <div className="table-responsive">
                {anchor_error?.response?.data && anchor_error.response.data.errors.map((data,i)=>(
                         <div key={i} className="text-center py-5" style={{color:"red"}}>
                            <div>{data.detail} !</div>                         
                         </div>
                ))
               }
                {anchorMerchantEligibilityData ?
                    <table className="overflow-hidden">
                        <thead>
                        <tr >
                            <th className="tablehead font-weight-bold">Program Conditions</th>
                            <th className="font-weight-bold text-center">Rule Value</th>
                            <th className="font-weight-bold text-center">Rule Result</th>
                        </tr>
                        </thead>
                        <tbody>
                            {anchorMerchantEligibilityData.attributes !== undefined ? 
                            <>
                            {Object.keys(anchorMerchantEligibilityData.attributes).map((data,index)=>(
                                <>{anchorMerchantEligibilityData && anchorMerchantEligibilityData.attributes && anchorMerchantEligibilityData.attributes[data]?.title &&
                                <tr key={index}>                                   
                                    <td className="tablehead">{anchorMerchantEligibilityData.attributes[data]?.title || "-"}</td>
                                    <td className="text-center">{anchorMerchantEligibilityData.attributes[data]?.value != null ? String(anchorMerchantEligibilityData.attributes[data].value): "-"}</td>
                                    <RoleResult rule={anchorMerchantEligibilityData.attributes[data]?.result} 
                                    
                                    id={index}/>
                                </tr>
                                }
                                </>
                            ))}
                            </>
                            :
                            <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                            }
                        </tbody>
                    </table>
                    :
                    <div className="Loadergif">
                        <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                    </div> 
                    }
                    </div>
                </div>
            </div>
      
        </div>
    );
};
export default AnchorMerch;
