import React, { useState, useEffect } from "react";
import '../LoanEligible.scss';
import './cibil.scss';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap'
import RoleResult from '../ruleResult/ruleResult';
import { getCibilRuleEligibilityData } from '../../../../actions/AllActions';
import { useSelector, useDispatch } from 'react-redux';


const CIBIL = ({ onDataLoad, loanId, camData, bureau_rules, co_applicants }) => {
    const cibilRuleEligibility = useSelector(state => state.all.cibil_rule_eligibility_data);
    const cibilRuleEligibilityData = cibilRuleEligibility && cibilRuleEligibility.data;

    const camReport = useSelector(state => state.all.cam_report);
    const camReportData = camReport && camReport.data && camReport.data.attributes && camReport.data.attributes.co_applicants;

    const cibil_error = useSelector(state => state.all.error_cibil);
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(0);
    const [Tabsss, setTabsss] = useState([]);

    useEffect(() => {
        if (cibilRuleEligibilityData === undefined) {
            //if (Number(onDataLoad) === 1) {
                dispatch(getCibilRuleEligibilityData(loanId));
            //}
        }
    }, [onDataLoad])

    useEffect(() => {
        var tempTabsss = [];
        if (cibilRuleEligibilityData && cibilRuleEligibilityData.attributes && cibilRuleEligibilityData.attributes.cibil_rule_eligibility_data && camReportData) {

            // To add main applicant first 
            for (var data in cibilRuleEligibilityData.attributes.cibil_rule_eligibility_data) {
                if (cibilRuleEligibilityData.attributes.cibil_rule_eligibility_data[data].is_primary === true) {


                    let new_pri_add = null;

                    for(var data2 in camReportData){
                        if(parseInt(camReportData[data2].id) === parseInt(data)){
                            new_pri_add = camReportData[data2].residence_address;
                        }
                    }                    

                    tempTabsss.push({ name: cibilRuleEligibilityData.attributes.cibil_rule_eligibility_data[data].user_name.value, key: data, type: 'Applicant', obj: cibilRuleEligibilityData.attributes.cibil_rule_eligibility_data[data], residence_address: new_pri_add })                    


                }
            }

            for (var data in cibilRuleEligibilityData.attributes.cibil_rule_eligibility_data) {
                if (cibilRuleEligibilityData.attributes.cibil_rule_eligibility_data[data].is_primary === false) {

                    let new_oth_add = null;

                    for(var data2 in camReportData){
                        if(parseInt(camReportData[data2].id) === parseInt(data)){
                            new_oth_add = camReportData[data2].residence_address;
                        }
                    }  
                    
                    tempTabsss.push({ name: cibilRuleEligibilityData.attributes.cibil_rule_eligibility_data[data].user_name.value, key: data, type: 'Co-Applicant', obj: cibilRuleEligibilityData.attributes.cibil_rule_eligibility_data[data], residence_address: new_oth_add })

                }
            }

        }
        setTabsss(tempTabsss);
    }, [cibilRuleEligibilityData && cibilRuleEligibilityData.attributes]);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const valuecheck = (value) => {
        if (value) {
            return (value);
        } else {
            return "-";
        }
    }

    const checkValue = (x) => {
        var stringValue = String(x).toLowerCase();
        if (stringValue === "true") {
            return "Yes";
        }
        else if (stringValue === "false") {
            return "No";
        }
        else {
            return x;
        }
    };

    return (
        <div className="col-md-12 LoanEligible cibil">
            <div className="py-2 tabPrint text-center">
                CIBIL
            </div>
            <div className="row m-0 card" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "10px 10px 30px" }}>
                <div className="col-md-12">
                    {cibilRuleEligibility && cibilRuleEligibility.error?.response?.data && cibil_error.response.data.errors.map((data, i) => (
                        <div key={i} className="text-center py-5" style={{ color: "red" }}>
                            <div>{data.detail} !</div>
                        </div>
                    ))
                    }
                    {cibilRuleEligibility.loading &&
                        <div className="Loadergif pt-4">
                            <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                        </div>
                    }
                    {cibilRuleEligibilityData &&
                        <div className="kycTabs mt-3">
                            <Nav tabs style={{ border: "none" }} className="loanCibilDisplay">
                                {
                                    Tabsss.map((Tab, index) =>
                                    (
                                        <NavItem key={index} style={{ cursor: "pointer" }}>

                                            <NavLink className={`navLink p-3 ${activeTab === index ? 'active' : ""} text-capitalize`} onClick={() => { toggle(index); }}>
                                                {(Tab.name).toLowerCase()}
                                            </NavLink>
                                        </NavItem>
                                    )
                                    )
                                }


                            </Nav>


                            <TabContent activeTab={activeTab}>
                                {
                                    Tabsss.map((Tab, index) =>
                                    (
                                   <>
                                        <TabPane key={index} tabId={index}>
                                            <div className="table-responsive">
                                                {Tab.residence_address &&
                                                    <div className="d-flex residence_address">
                                                        <div className="text-left pl-1 pr-1">Residence Address :</div>
                                                        <div className="text-capitalize">
                                                            {Tab.residence_address}
                                                        </div>
                                                    </div>
                                                }
                                                <table className="overflow-hidden">
                                                    {/* {index === 0 ?
                                                        <> */}
                                                    <thead>
                                                        <tr>
                                                            <th className="font-weight-bold text-left">Program Condition</th>
                                                            <th className="font-weight-bold text-center">Rule Value</th>
                                                            <th className="font-weight-bold text-center">Rule result</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cibilRuleEligibilityData && cibilRuleEligibilityData.attributes !== undefined ?
                                                            <>
                                                                {Object.keys(Tab && Tab.obj).map((data, index) =>
                                                                    <>
                                                                        {Tab.obj[data] && Tab.obj[data].title &&
                                                                            <tr key={index}>
                                                                                <td className="tablehead">{(Tab.obj[data] && Tab.obj[data].title === "User Name" ? Tab.type : Tab.obj[data].title) || "-"}</td>
                                                                                <td className="text-center">{Tab.obj[data] && Tab.obj[data].value !== null ? checkValue(Tab.obj[data] && Tab.obj[data].value) : "-"}</td>
                                                                                <RoleResult rule={Tab.obj[data] && Tab.obj[data].result} id={index} />
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )}
                                                                {Tab.obj['cibil_report_url'] && <>
                                                                    {/* {data === "cibil_report_url" && */}
                                                                    <tr>
                                                                        <td colspan="3">
                                                                            <a className="reportDwnld printNotShow" href={Tab.obj['cibil_report_url']} target="_blank">
                                                                                <span style={{ paddingRight: "10px" }}>
                                                                                    <img src="/images/KYC/CibilDownload.svg" width="30px" />
                                                                                </span>
                                                                                <span>CIBIL Report</span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    {/* } */}

                                                                </>
                                                                }


                                                            </>
                                                            :
                                                            <tr>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>-</td>
                                                            </tr>
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </TabPane>
                                    </>
                                    )
                                    )
                                }

                            </TabContent>

                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
export default CIBIL;
