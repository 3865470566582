import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './BankStatement.css';
import Loading from '../Loading/Loading';

const dateToDMY = date => {
  const DATE = new Date(date).getDate();
  const MONTH = new Date(date).getMonth() + 1;
  const YEAR = new Date(date).getFullYear();
  return '' + (DATE <= 9 ? '0' + DATE : DATE) + '-' + (MONTH <= 9 ? '0' + MONTH : MONTH) + '-' + YEAR;
}

const BankStatement = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <div className="col-md-12 col-sm-12 col-12 p-0">
      <div className="col-md-12 col-sm-12 bcFlex pt-3 pb-3 pl-2 pr-5">
        <div className="col-md-8 col-sm-8 pr-0 pl-0 pt-1 d-flex">
          <div className="pt-2 pr-0">
            <img className="fileIcon" src='/images/Group 2193.png' alt="documentIcon" />
          </div>
          <div>
            <p className="mb-0 progressText">{props.statement.file_name}</p>
            <p className="mb-0 progressDate">{dateToDMY(props.statement.created_at)}</p>
          </div>
        </div>
        {props.isFetching && <Loading/>}
        <div className=" col-md-4 col-sm-4 iconWidth pr-0">
          <div className="pt-2 d-flex float-right ml-5">
            <div onClick={toggle} md="3" sm="3" className="mr-4 mb-1"><img src={'/images/Group 2186.png'} alt="view" className="pt-1 cp" width="32px" /></div>
            {
              modal &&
              <div>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalBody>
                    <iframe title="Document" src={props.statement.document_url} style={{ width: 600, height: 500 }} frameBorder="0"></iframe>
                  </ModalBody>
                </Modal>
              </div>
            }
            <div onClick={props.doCheck(props.statement.document_id, props.bankAccount)}>
              <input type="checkbox" readOnly checked={props.id.indexOf(props.statement.document_id) >= 0} className="ml-3 myinput cp" />
            </div>
          </div>
        </div>
      </div>
      <div className="bottomBrdr"> </div>
    </div>
  )
}

export default BankStatement