import React, {useState,useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
// import { getRules } from '../../../../actions/AllActions';
import "./GST.scss";

const GSTRules = ({gstUser }) => {
    return (
        <div className="col-md-12 maingst gst">
            <div className="py-2 tabPrint text-center text-uppercase">
                GST Return Details
            </div>
            <div className="row m-0" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "0px 0px 30px" }}>
                <div className="col-md-12">
                {gstUser.gst_filing_statuses !== undefined ? 
                    <div className="table-responsive">
                        
                        <table className="overflow-hidden tablereturn">
                            <thead>
                                <tr>
                                    <th className="font-weight-bold" style={{ width: "25%" }}>File return</th>
                                    <th className="font-weight-bold" style={{ width: "25%" }}>Month</th>
                                    <th className="font-weight-bold" style={{ width: "25%" }}>GSTR1</th>
                                    <th className="font-weight-bold" style={{ width: "25%" }}>GSTR3B</th>
                                </tr>
                            </thead>

                            <tbody >
                                {gstUser.gst_filing_statuses && gstUser.gst_filing_statuses.length === 0 && 
                                    <tr className="ruleclass">
                                        <td colspan="4"><div className="text-center">No return status</div></td>
                                    </tr>
                                }
                                {gstUser.gst_filing_statuses && gstUser.gst_filing_statuses.length > 0 && gstUser.gst_filing_statuses.map((data, index) => (
                                <tr className="ruleclass">
                                    <td>{index+1}</td>
                                    <td>{data.month}</td>
                                    <td>{data.return_type_GSTR1 === undefined ? "NA" : data.return_type_GSTR1 === "not_filed" ? "Not Filled" : "Filled"}</td>
                                    <td>{data.return_type_GSTR3B === undefined ? "NA" : data.return_type_GSTR3B === "not_filed" ? "Not Filled" : "Filled"}</td>
                                </tr>
                                ))}
                                
                            </tbody>

                        </table>

                    </div>
                    :
                    <>
                        <div className="text-center py-2" style={{ color: "red" }}>
                                <div>No File Return's !</div>
                            </div>
                    </>
                                }
                </div>
            </div>


        </div>
    );
};
export default GSTRules;
