import React, { Component, Fragment } from "react";
import { Container, Col, Row } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";

import {
  getEligibleBank,
  getBankStatement,
  getAnalysisReports,
  postParseStatement,
  postReAnalyseStatement,
  showAlert,
  fieldAlert,
  enabledBankingIntegrations,
} from "../../actions/AllActions";
import Loading from "../../components/Loading/Loading";
import { validationBank } from "../../shared/validations";
import BankStatementReport from "../../components/BankStatementReport/BankStatementReport";
import InputWrapper from "../../components/InputWrapper/InputWrapper";
import Header from "../../components/Header/Header";
import InputTag from "../../components/InputTag/InputTag";
import BankStatement from "../../components/BankStatement/BankStatement";
import Perfios from "../Perfios";

import "./Banking.css";

class Banking extends Component {
  state = {
    statements: [],
    analyseReport: {},
    bankOptions: [],
    showStatement: 1,
    expanded: false,
    id: [],
    errors: {},
    doDisabled: false,
    statementId: "",
    bankAccounts: [
      {
        bank_name: "",
        acc_type: "",
        acc_number: "",
        tags: "",
        id: [],
        accNo: 1,
      },
    ],
    disable: false,
    type: "",
    paramId: "",
  };

  UNSAFE_componentWillMount() {
    const { match } = this.props;
    const ID = match.params.id;
    this.setState({ paramId: ID });
    var that = this;
    this.props.enabledBankingIntegrations().then((res) => {
      if (
        res &&
        res.data &&
        res.data.attributes &&
        res.data.attributes.integrate_scoreme
      ) {
        that.props.getEligibleBank();
      }

      that.props.getBankStatement(ID);
      that.props.getAnalysisReports(ID);
    });
  }

  onChangeHandler = (field, value, idx) => {
    const newBankAccounts = this.state.bankAccounts.map((bankAccount, sidx) => {
      if (idx !== sidx) return bankAccount;
      const { errors } = this.state;
      const { BANK_NAME, ACCOUNT_NUMBER, ACCOUNT_TYPE } = validationBank;
      errors[bankAccount.accNo] = errors[bankAccount.accNo] || {};
      switch (field) {
        case ACCOUNT_NUMBER:
          if (!isNaN(value))
            if (value.length <= 9) {
              errors[bankAccount.accNo]["acc_number"] =
                value.length < 9 && value.length > 0 && ACCOUNT_NUMBER.error;
              return { ...bankAccount, acc_number: value };
            } else if (value.length > 9) {
              errors[bankAccount.accNo]["acc_number"] = value.length > 9;
              return { ...bankAccount, acc_number: value };
            } else {
              errors[bankAccount.accNo]["acc_number"] = "";
              return { ...bankAccount };
            }
          return { ...bankAccount };
        case BANK_NAME:
          if (value.value.length) {
            errors[bankAccount.accNo]["bank_name"] = "";
            return {
              ...bankAccount,
              bank_name: value.value,
              bank_option: value,
            };
          } else {
            errors[bankAccount.accNo]["bank_name"] =
              value.value.length < 3 && value.value.length > 0
                ? BANK_NAME.error
                : "";
            return { ...bankAccount };
          }
        case ACCOUNT_TYPE:
          if (value.value.length) {
            errors[bankAccount.accNo]["acc_type"] = "";
            return {
              ...bankAccount,
              acc_type: value,
            };
          } else {
            errors[bankAccount.accNo]["acc_type"] = ACCOUNT_TYPE.error;
            return { ...bankAccount };
          }
        default:
          [field.slug] = value;
          return { ...bankAccount };
      }
    });

    this.setState({ bankAccounts: newBankAccounts });
  };

  selectedTags = (idx) => (evt) => {
    const newBankAccounts = this.state.bankAccounts.map((bankAccount, sidx) => {
      if (idx !== sidx) return bankAccount;
      return { ...bankAccount, tags: evt.join(",") };
    });
    this.setState({ bankAccounts: newBankAccounts });
  };

  addTags = (tags) => {
    const newBankAccounts = this.state.bankAccounts.map((bankAccount) => {
      return { ...bankAccount, tags };
    });
    this.setState({ bankAccounts: newBankAccounts });
  };

  showMore = () => {
    const { showStatement, analyseReport } = this.state;
    const { match } = this.props;
    showStatement === 1
      ? this.setState(
          {
            showStatement: analyseReport && analyseReport.length,
            expanded: true,
          },
          async () => {
            await this.props.getAnalysisReports(match.params.id);
          }
        )
      : this.setState({ showStatement: 1, expanded: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      eligible_bank,
      bank_statement,
      analysis_report,
      enabled_banking_integrations,
    } = nextProps.all;
    if (eligible_bank != this.props.all.eligible_bank) {
      let bank_list =
        eligible_bank &&
        eligible_bank.data &&
        eligible_bank.data.attributes &&
        eligible_bank.data.attributes.banks;
      const bankOptions = bank_list.map((bank) => ({
        label: `<div><img src={'/images/icici.png'} width="30px" alt={bank} /> {' '} {bank} </div>`,
        value: bank,
      }));
      this.setState({ bankOptions });
    }

    if (
      enabled_banking_integrations &&
      enabled_banking_integrations.data &&
      enabled_banking_integrations.data.attributes &&
      enabled_banking_integrations.data.attributes
    ) {
      let type = "";
      if (enabled_banking_integrations.data.attributes.integrate_perfios) {
        type = "perfios";
      } else if (
        enabled_banking_integrations.data.attributes.integrate_scoreme
      ) {
        type = "scoreme";
      } else {
        type = "";
      }
      this.setState({ tabApiData: enabled_banking_integrations, type });
    }

    if (bank_statement != this.props.all.bank_statement) {
      this.setState({
        statements:
          bank_statement &&
          bank_statement.data &&
          bank_statement.data.attributes &&
          bank_statement.data.attributes.bank_statements,
      });
    }

    if (analysis_report != this.props.all.analysis_report) {
      this.setState({
        analyseReport:
          analysis_report &&
          analysis_report.data &&
          analysis_report.data.attributes,
      });
    }
  }

  submitStatement = () => {
    const { match } = this.props;
    let statements = [];

    this.state.bankAccounts.map((acc) => {
      const { bank_name, acc_type, acc_number, tags, id, accNo } = acc;
      const { errors } = this.state;
      const { BANK_NAME, ACCOUNT_NUMBER, ACCOUNT_TYPE } = validationBank;
      errors[accNo] = errors[accNo] || {};

      if (!isNaN(acc_number))
        if (acc_number.length == 0)
          errors[accNo]["acc_number"] = ACCOUNT_NUMBER.error;

      if (bank_name.length == 0) errors[accNo]["bank_name"] = BANK_NAME.error;

      if (!acc_type) errors[accNo]["acc_type"] = ACCOUNT_TYPE.error;

      if (id.length == 0)
        errors[accNo]["id"] = "Please Select the Bank Statement";

      if (Object.values(errors[accNo]).every((k) => k != "")) {
        this.setState({ errors });
        return;
      }

      statements.push({
        document_ids: id,
        bank_name: bank_name,
        account_type: acc_type.value,
        account_number: acc_number,
        inhouse_Keywords: tags,
      });
    });

    const parseStatement = {
      data: {
        type: "bank-analysis",
        id: null,
        attributes: {
          loan_application_id: match.params.id,
          statements: statements,
        },
      },
    };
    const arr = [];
    Object.values(this.state.errors).map((e) =>
      arr.push(e.bank_name, e.acc_type, e.acc_number)
    );
    if (
      arr.every(
        (e) =>
          e != "Select Bank Name" &&
          e != "Select Account Type" &&
          e != "Invalid Account Number"
      )
    ) {
      this.setState({ disable: !this.state.disable }, () => {
        this.props.postParseStatement(parseStatement).then((res) => {
          this.setState({
            bankAccounts: [
              {
                bank_name: "",
                acc_type: "",
                acc_number: "",
                tags: "",
                id: [],
                accNo: 1,
              },
            ],
            showStatement: 1,
            expanded: false,
            disable: !this.state.disable,
          });
        });
      });
    }
  };

  submitReAnalyseStatement = (documentID) => {
    const { analyseReport } = this.state;
    const { match } = this.props;
    let newTags = [];

    this.state.bankAccounts.map((acc) => {
      const { tags } = acc;
      newTags.push(tags);
    });
    if (newTags.every((tag) => tag == "") || !newTags.length) {
      newTags.push(
        analyseReport.analysis_reports.find((a) => a.document_id == documentID)
          .inhouse_keywords
      );
    }
    const updatedTags = newTags.filter((el) => el != "");
    const reAnalyseStatement = {
      data: {
        type: "re-analyse",
        id: null,
        attributes: {
          loan_application_id: match.params.id,
          document_id: documentID,
          inhouse_keywords: updatedTags.join(""),
        },
      },
    };

    this.props
      .postReAnalyseStatement(reAnalyseStatement)
      .then(() => this.setState({ showStatement: 1, expanded: false }));
    this.setState((prevState) => ({
      statementId: documentID,
      doDisabled: !prevState.doDisabled,
    }));
  };

  doCheck = (stmtNo, accNo) => () => {
    let { bankAccounts, errors } = this.state;
    let accountIndex = bankAccounts.findIndex((acc) => acc.accNo == accNo);
    let account = { ...bankAccounts[accountIndex] };
    let isChecked = account.id.indexOf(stmtNo) >= 0;
    errors[accNo] = errors[accNo] || {};

    if (!isChecked) {
      account.id.push(stmtNo);
    } else {
      account.id.splice(account.id.indexOf(stmtNo), 1);
    }

    if (!bankAccounts[accountIndex].id.length) {
      errors[accNo]["id"] = "Please Select the Bank Statement";
    } else {
      errors[accNo]["id"] = "";
    }

    // if (Object.values(errors[accNo]).every(k => k != "")) {
    //   this.setState({ errors });
    //   return;
    // }

    bankAccounts[accountIndex] = { ...account };
    this.setState({ bankAccounts });
  };

  appendStatement = () => {
    const newBankAccount = {
      bank_name: "",
      acc_type: "",
      acc_number: "",
      tags: "",
      id: [],
    };

    let { bankAccounts } = this.state;
    let last = bankAccounts[bankAccounts.length - 1];

    newBankAccount["accNo"] = last.accNo + 1;
    this.setState((prevState) => ({
      bankAccounts: prevState.bankAccounts.concat([newBankAccount]),
    }));
  };

  removeSection = (index) => {
    let { bankAccounts } = this.state;
    this.setState({ bankAccount: bankAccounts.splice(index, 1) });
  };

  render() {
    const {
      statements,
      expanded,
      showStatement,
      bankOptions,
      errors,
      statementId,
      doDisabled,
      bankAccounts,
      type,
      paramId,
      tabApiData,
    } = this.state;
    const analysis_reports = this.state.analyseReport
      ? this.state.analyseReport
      : null;
    const { match, location } = this.props;
    const { isFetching } = this.props.all;
    const query = new URLSearchParams(location.search);
    const { BANK_NAME, ACCOUNT_TYPE, ACCOUNT_NUMBER } = validationBank;
    return (
      <>
        <Helmet>
          <title>Banking</title>
        </Helmet>
        {isFetching && <Loading />}
        <div className="d-flex">
          {/* <div className="p-3" style={{ width: 75 }}>
            <img src={'/images/Group-2805.png'} alt="back arrow" width="43px" height="43px" />
          </div> */}
          <Col className="p-0">
            {/* <Col className="p-0">
              <Header />
            </Col> */}
            <ToastContainer autoClose={2000} />
            <Container fluid={true} className="main main-dcmt pt-0">
              <div style={{ background: "white" }} className="pb30">
                <Col
                  md="12"
                  xs="12"
                  className="flex bottomBrdrHeader pt-2 pb-1 p-0"
                >
                  {/* <div className="mt-2 mr-3">
                    <button
                      type="button"
                      className="btn mt--8 border-0 btn-primary-outline pl-0 pt-2 pr-1"
                    >
                      <img src={"/images/Group 2191.png"} alt="back arrow" />
                    </button>
                  </div> */}
                  <span className="titleText">Banking</span>
                  <span className="divider" />
                  <span className="titleText">
                    Loan Application ID: {match.params.id}
                  </span>
                  <span className="dividerB" />
                  <span className="titleText">{query.get("name")} </span>
                  {(match.params.id && match.params.id.length === 0) ||
                    (match.params.id === "" && (
                      <span style={{ color: "crimson", fontSize: "16px" }}>
                        Loan Application Id is required
                      </span>
                    ))}
                </Col>
                <section className="banking">
                <div className="py-2 tabPrint text-center text-uppercase">
                Demographics
                </div>
                    <div className="score">
                      <ul className="m-0">
                        {tabApiData &&
                          tabApiData.data &&
                          tabApiData.data.attributes &&
                          tabApiData.data.attributes.integrate_perfios && (
                            <li>
                              <a
                                onClick={() =>
                                  this.setState({ type: "perfios" })
                                }
                              >
                                Perfios
                              </a>
                              {type === "perfios" ? (
                                <a className="type-selected"></a>
                              ) : null}
                            </li>
                          )}
                        {tabApiData &&
                          tabApiData.data &&
                          tabApiData.data.attributes &&
                          tabApiData.data.attributes.integrate_scoreme && (
                            <li>
                              <a
                                onClick={() =>
                                  this.setState({ type: "scoreme" })
                                }
                              >
                                Scoreme
                              </a>
                              {type === "scoreme" ? (
                                <a className="type-selected"></a>
                              ) : null}
                            </li>
                          )}
                      </ul>
                    </div>
                </section>
              </div>
              {type === "scoreme" ? (
                <>
                  {analysis_reports && analysis_reports.length ? (
                    <Col className="mt-5 p-0">
                      <p className="bankReportText">Bank Statement Reports</p>
                      {analysis_reports && analysis_reports.length
                        ? analysis_reports
                            .slice(0, showStatement)
                            .map((report, index) => (
                              <BankStatementReport
                                key={index}
                                analyseReport={report}
                                selectedTags={this.addTags}
                                showStatement={showStatement}
                                submitReAnalyseStatement={
                                  this.submitReAnalyseStatement
                                }
                                statementId={statementId}
                                doDisabled={doDisabled}
                                isFetching={isFetching}
                              />
                            ))
                        : null}
                    </Col>
                  ) : null}
                  {analysis_reports &&
                  analysis_reports.length &&
                  analysis_reports.length > 1 ? (
                    <Col className="mt-4 mb-4 center cpDefault">
                      <div className="loadMoreText cpDefault">
                        <a className="loadMoreText" onClick={this.showMore}>
                          {expanded ? (
                            <span className="loadMoreText">Show less</span>
                          ) : (
                            <span className="loadMoreText">Load more</span>
                          )}
                          {expanded ? (
                            <p>
                              <img
                                src={"/images/Group 2196.png"}
                                alt="plus"
                                className="rotateimg180"
                              />
                            </p>
                          ) : (
                            <p className="loadMoreText">
                              <img src={"/images/Group 2196.png"} alt="plus" />
                            </p>
                          )}
                        </a>
                      </div>
                    </Col>
                  ) : null}
                  <Col
                    md="12"
                    style={{ marginTop: "1rem" }}
                    className="flex mb-3"
                  >
                    <Col md="12" sm="12">
                      <button
                        className="addNewBtn float-right flex pl-3 pr-3"
                        onClick={() => (
                          this.appendStatement(),
                          toast.success("Bank statement loaded successfully")
                        )}
                      >
                        <p className="mt-1 mb-0">Add New Bank Account</p>
                        <div className="addNewPlusBtn ml-2">
                          <span className="addNewPlusText">
                            <img
                              src={"/images/Group 2182.png"}
                              alt="plus"
                              style={{ marginTop: -3 }}
                            />
                          </span>
                        </div>
                      </button>
                    </Col>
                  </Col>
                  <Col className="reportBox p-0 pb-3">
                    {bankAccounts && bankAccounts.length
                      ? bankAccounts.map((bankAccount, i) => {
                          return (
                            <Fragment key={i}>
                              <Row className="pl-1 pr-1">
                                <Col
                                  md="6"
                                  sm="12"
                                  className="pl-4 pr-4 pt-2 pb-2 mt-3 mb-1"
                                >
                                  <Col md="11" sm="12">
                                    <Col md="10" sm="12" className="mt-1">
                                      <p className="mb-0 selectText">
                                        Select Bank Name
                                        <span className="colorRed">*</span>
                                      </p>
                                      <Select
                                        className="inputSelectText"
                                        value={
                                          bankAccount.bank_option || {
                                            label: "Select...",
                                            value: "",
                                          }
                                        }
                                        onChange={(e) =>
                                          this.onChangeHandler(BANK_NAME, e, i)
                                        }
                                        options={bankOptions}
                                      />
                                      {errors[bankAccount.accNo] &&
                                        errors[bankAccount.accNo].bank_name &&
                                        errors[bankAccount.accNo].bank_name
                                          .length > 0 && (
                                          <span
                                            style={{
                                              color: "crimson",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors[bankAccount.accNo][
                                                "bank_name"
                                              ]
                                            }
                                          </span>
                                        )}
                                    </Col>
                                    <Col
                                      md="10"
                                      sm="12"
                                      style={{ marginTop: 60 }}
                                    >
                                      <InputWrapper
                                        className="inputSelectText"
                                        validation={ACCOUNT_TYPE}
                                        localState={
                                          bankAccount.acc_type || {
                                            value: "",
                                            label: "Select...",
                                          }
                                        }
                                        onChangeHandler={this.onChangeHandler}
                                        isListType={true}
                                        index={i}
                                      />
                                      {errors[bankAccount.accNo] &&
                                        errors[bankAccount.accNo].acc_type &&
                                        errors[bankAccount.accNo].acc_type
                                          .length > 0 && (
                                          <span
                                            style={{
                                              color: "crimson",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors[bankAccount.accNo][
                                                "acc_type"
                                              ]
                                            }
                                          </span>
                                        )}
                                    </Col>
                                    <Col
                                      md="10"
                                      sm="12"
                                      style={{ marginTop: 60 }}
                                    >
                                      <InputWrapper
                                        validation={ACCOUNT_NUMBER}
                                        localState={bankAccount}
                                        onChangeHandler={this.onChangeHandler}
                                        isNumber={true}
                                        index={i}
                                      />
                                      {errors[bankAccount.accNo] &&
                                        errors[bankAccount.accNo].acc_number &&
                                        errors[bankAccount.accNo].acc_number
                                          .length > 0 && (
                                          <span
                                            style={{
                                              color: "crimson",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {
                                              errors[bankAccount.accNo][
                                                "acc_number"
                                              ]
                                            }
                                          </span>
                                        )}
                                    </Col>
                                    <Col
                                      md="10"
                                      sm="12"
                                      xs="12"
                                      style={{ marginTop: 60 }}
                                    >
                                      <p className="mb-3 keywordText">
                                        Keywords
                                      </p>
                                      <InputTag
                                        Keyword="keyword"
                                        selectedTags={this.selectedTags(i)}
                                        tags={bankAccount.tags}
                                      />
                                    </Col>
                                  </Col>
                                </Col>
                                <Col md="6" sm="12" className="p-0 mb-3">
                                  <Col
                                    md="11"
                                    sm="11"
                                    xs="10"
                                    className="float-right pr-4"
                                  >
                                    <Col
                                      md="11"
                                      sm="12"
                                      xs="12"
                                      className="rightPanelBox float-right p-0 mt-3 mr-4 ml-2"
                                    >
                                      {statements && statements.length ? (
                                        statements.map((statement, index) => {
                                          let isCheck =
                                            bankAccount.id &&
                                            bankAccount.id.some(
                                              (id) =>
                                                id == statement.document_id
                                            );
                                          return (
                                            <BankStatement
                                              key={index}
                                              statement={statement}
                                              isCheck={isCheck}
                                              doCheck={this.doCheck}
                                              bankAccount={bankAccount.accNo}
                                              id={bankAccount.id}
                                              isFetching={isFetching}
                                            />
                                          );
                                        })
                                      ) : (
                                        <div className="text-center">
                                          <img
                                            src={"/images/Group-2804.png"}
                                            alt="back arrow"
                                            width="80px"
                                            height="80px"
                                            style={{ marginTop: 70 }}
                                          />
                                          <p className="text-center reportText">
                                            No Data Found
                                          </p>
                                        </div>
                                      )}
                                    </Col>
                                    <Col
                                      md="11"
                                      sm="11"
                                      xs="10"
                                      className="float-right pl-5"
                                    >
                                      {errors[bankAccount.accNo] &&
                                        errors[bankAccount.accNo].id &&
                                        errors[bankAccount.accNo].id.length >
                                          0 && (
                                          <p
                                            style={{
                                              color: "crimson",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {errors[bankAccount.accNo]["id"]}
                                          </p>
                                        )}
                                    </Col>
                                  </Col>
                                </Col>
                              </Row>
                              {i < this.state.bankAccounts.length - 1 && (
                                <div className="col-md-12">
                                  <div className="bottomBrdr mt-5 mb-5"></div>
                                  <div
                                    className=" col-md-12 float-right"
                                    onClick={() => this.removeSection(i)}
                                    style={{ marginTop: -21, marginBottom: 12 }}
                                  >
                                    <img
                                      src={"/images/Group 2393.png"}
                                      className="float-right"
                                      alt="plus"
                                      style={{ right: 0 }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          );
                        })
                      : null}
                  </Col>
                  <Col md="12" className="flex mt-3">
                    <Col md="10" />
                    <Col md="2" className="pr-0">
                      <button
                        className="analyseBtn float-right"
                        disabled={this.state.disable}
                        onClick={this.submitStatement}
                      >
                        Analyse
                        <img
                          src={"/images/Path 3147.png"}
                          className="ml-3"
                          alt="back arrow"
                        />
                      </button>
                    </Col>
                  </Col>
                </>
              ) : (
                <Perfios
                  statements={statements}
                  analysisReports={analysis_reports}
                  paramId={paramId}
                />
              )}
            </Container>
          </Col>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  all: state.all,
});

const mapDispatchToProps = {
  getEligibleBank,
  getBankStatement,
  getAnalysisReports,
  postParseStatement,
  postReAnalyseStatement,
  showAlert,
  fieldAlert,
  enabledBankingIntegrations,
};

export default connect(mapStateToProps, mapDispatchToProps)(Banking);
