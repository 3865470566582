import React,{useEffect} from "react";
import './LoanEligible.scss';
import {useSelector,useDispatch} from 'react-redux';
import RoleResult from './ruleResult/ruleResult';
import { getBankingAnalysis} from '../../../actions/AllActions';


const Banking = ({onDataLoad,loanId,banking_rules}) => {
    const dispatch = useDispatch();
    const banking_analysis_data = useSelector(state => state.all.banking_analysis.data);


    useEffect(()=>{
        if(banking_analysis_data === undefined ){
            //if(Number(onDataLoad)===3){         
             dispatch(getBankingAnalysis(loanId));
            //}
        }
    },[onDataLoad])


    const bankingDataSet = (data) =>{
       
        if (data) {
            return data; 
        }
        else {
            return "-"
        }

    }
    const boxData = [
       { "Total Value of Credits Received in the last 12 Months":bankingDataSet(banking_analysis_data && banking_analysis_data.attributes.net_cash_analysis_value.total_value_of_credits)},
       { "Less: Total Value of New Loans Availed":bankingDataSet(banking_analysis_data && banking_analysis_data.attributes.net_cash_analysis_value.loan_availed)},
        {"Less: Total EMI paid in the year ":bankingDataSet(banking_analysis_data && banking_analysis_data.attributes.net_cash_analysis_value.total_emi_paid)},
        {"Less: Estimated Monthly Expenses (40%) ":bankingDataSet(banking_analysis_data && banking_analysis_data.attributes.net_cash_analysis_value.monthly_expensed)},
        {"Nt Cash Flow Generated in the last 12 Months ":bankingDataSet(banking_analysis_data && banking_analysis_data.attributes.net_cash_analysis_value.net_cash)},
       {"Loan Value Proposed":bankingDataSet(banking_analysis_data && banking_analysis_data.attributes.net_cash_analysis_value.loan_value_proposed)},
        {"Loan value as a % of Free Cash Flows  ":bankingDataSet(banking_analysis_data && banking_analysis_data.attributes.net_cash_analysis_value.loan_value_percent)}
    ]
    return (
        <div className="col-md-12 LoanEligible">
            <div className="py-2 tabPrint text-center text-uppercase">
                Banking
            </div>
           
            <div className="row m-0 card" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "30px 10px 30px" }}>
                <div className="col-md-12">
                <div className="table-responsive">
                        {banking_analysis_data &&
                        <div className="mainBankDiv">
                           {boxData.map((data,index)=>(
                               <div className="bankAmountDiv" key={index}>
                                   <div className="text-capitalize">{Object.keys(data)}</div>
                                   <div className="amountDiv text-capitalize">{Object.values(data)}</div>                 
                               </div>   
                           ))}
                       </div>
                        }
                       {banking_analysis_data && banking_analysis_data.attributes.banking_rule ?
                            <table className="overflow-hidden">
                                <thead>
                                    <tr >
                                        <th className="tablehead font-weight-bold">Program Conditions</th>
                                        <th className="font-weight-bold text-center">Rule Value</th>
                                        {/*<th className="font-weight-bold text-center">Rule result</th>*/}
                                    </tr>
                                </thead>
                                <tbody >
                                    {banking_analysis_data && banking_analysis_data.attributes.banking_rule !== undefined ?
                                        <>
                                            {Object.keys(banking_analysis_data.attributes.banking_rule).map((data,index)=>(

                                                <tr key={index}>
                                                    <td className="tablehead">{banking_analysis_data.attributes.banking_rule[data].title}</td>
                                                    <td className="text-center">{banking_analysis_data.attributes.banking_rule[data].value}</td>
                                                    {/*<RoleResult rule={banking_analysis_data.attributes.banking_rule[data].result} comment={data.comments} id={index} />*/}
                                                </tr>

                                            ))}
                                        </>
                                        :
                                        <tr>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            :
                            <div className="Loadergif">
                                <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                            </div>
                            
                        }
                    </div>
                </div>
            </div>
            
        </div>
    );
};
export default Banking;
