import React from 'react';
import { Card, CardImg, CardBody } from 'reactstrap';

const Comments = ({ comment }) => (
  <Card style={{ width: 300 }}>
    <CardBody className="padding10 profileBody d-flex">
      <CardImg src="/images/profile2.png" className="profileImg" />
      <div>
        <p className="profileText mb-0">{comment.comment_by}</p>
        <p className="mb-0 pl-2 commentAt">{comment.comment_at}</p>
      </div>
    </CardBody>
    <CardBody className="">
      <div className="cam-inpuText mb-2 pl-2 pr-2">{comment.comment}</div>
      {/* <div className="pl-2 pr-2">
        <button className="Cam-Summary-comment-button Cam-Summary-comment-text">Comment <i className="fa fa-paper-plane" aria-hidden="true"></i></button>
        <button className="Cam-Summary-cancel-button Cam-Summary-cancel-text">Cancel <i className="fa fa-paper-plane" aria-hidden="true"></i></button>
      </div> */}
    </CardBody>
  </Card>
)

export default Comments;