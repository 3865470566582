import React, { Component, Fragment } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import './LoanSplitup.css';

am4core.useTheme(am4themes_animated);

class App extends Component {
  
  commonCall(props) {
    var bureau_analysis = props.camReport.bureau_analysis;
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    let data = [];
      for (var graph in bureau_analysis[props.insideCurrent].bureau_loans.graph_data) {
       
        var dataLoan = bureau_analysis[props.insideCurrent].bureau_loans.graph_data[graph];
        data.push({
          category: dataLoan.year,
          value: dataLoan.total_loan_amount,
          auto_loan_amount: dataLoan.auto_loan_amount,
          gold_loan_amount: dataLoan.gold_loan_amount,
          home_loan_amount: dataLoan.home_loan_amount
        });
      }
    
    chart.data = data;
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "category";
    categoryAxis.numberFormatter.numberFormat = "# a";
    chart.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+5, "suffix": "Lk" }
    ];
    categoryAxis.renderer.minGridDistance = 15;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.grid.template.strokeDasharray = "1,3";
    categoryAxis.renderer.labels.template.rotation = -90;
    categoryAxis.renderer.labels.template.horizontalCenter = "left";
    categoryAxis.renderer.labels.template.location = 0.5;

    categoryAxis.renderer.labels.template.adapter.add("dx", function (dx, target) {
      return -target.maxRight / 2;
    })

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.axisFills.template.disabled = true;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "category";
    series.dataFields.valueY = "value";
    series.tooltipHTML = `
    <div class='tooltipMain'>
    <div class="tooltipHead">Rs.{valueY.value}</div>
    <div class="tooltipCh">
      <div class='col-6'>Gold Loans</div>
      <div class='col-6'>{gold_loan_amount}</div>
    </div>
    <div class="tooltipCh">
      <div class='col-6'>Home Loans</div>
      <div class='col-6'>{home_loan_amount}</div>
    </div>
    <div class="tooltipCh">
      <div class='col-6'>Auto Loans</div>
      <div class='col-6'>{auto_loan_amount}</div>
    </div>
    </div>`;
    series.tooltip.getFillFromObject = false;
    series.tooltip.label.width = 300;
    series.tooltip.background.fill = am4core.color("#000000");
    series.tooltip.background.fillOpacity = 0.7;

    series.sequencedInterpolation = true;
    series.fillOpacity = 0;
    series.strokeOpacity = 1;
    series.strokeDashArray = "1,3";
    series.columns.template.width = 0.01;
    series.tooltip.pointerOrientation = "vertical";
    series.stroke = am4core.color("#149364");
    series.strokeWidth = 8;

    chart.cursor = new am4charts.XYCursor();

    /*chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();*/

    this.chart = chart;
  }


  componentDidMount() {
    this.commonCall(this.props);
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentWillReceiveProps(new_props) {
    this.commonCall(new_props);
  }
  render() {
    var insideCurrent = this.props.insideCurrent;
    var loans = this.props.camReport.bureau_analysis && this.props.camReport.bureau_analysis[insideCurrent].bureau_loans && this.props.camReport.bureau_analysis[insideCurrent].bureau_loans.loans;
    return (
      <div className="loanSplitUP">

        <div id="chartdiv" className="loanSplitUPGrap" style={{ width: "100%", height: "500px" }}></div>
        {loans !== undefined ?

          <div>
            {loans && Object.keys(loans).map(loanList =>
              <div key={loanList} className="hlsuMain">
                <div className="hlsuHead">| {loanList.replace(/_/g, ' ')}</div>
                <div className="hlsuData">
                  <div className="hlsuTable">
                    <div className="hlsuTableHead">
                      <div>SNo</div>
                      <div>Loan Amount</div>
                      <div>Account Status</div>
                      <div>Dated Opened</div>
                      <div>Credit Limit Amount</div>
                      <div>Amount Overdue</div>
                    </div>
                    {loans[loanList] && Object.keys(loans[loanList]).map(loanListRow =>
                      <div key={loanListRow} className="hlsuTableData">
                        <div>{Number(loans[loanList][loanListRow].sr_no) + 1}</div>
                        <div>{loans[loanList][loanListRow].loan_amount && loans[loanList][loanListRow].loan_amount.toLocaleString("en-IN")}</div>
                        <div>{String(loans[loanList][loanListRow].account_status)}</div>
                        <div>{loans[loanList][loanListRow].account_open_date}</div>
                        <div>{loans[loanList][loanListRow].credit_limit_amount && loans[loanList][loanListRow].credit_limit_amount.toLocaleString("en-IN")}</div>
                        <div>{loans[loanList][loanListRow].amount_overdue && loans[loanList][loanListRow].amount_overdue.toLocaleString("en-IN")}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          : <div>No Data Found</div>}
      </div>




    );
  }
}

export default App;