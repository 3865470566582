import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Card, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Tooltip } from 'reactstrap';
import { setDeviationComment, updateCamDeviations } from '../../../actions/AllActions';
import Header from '../header/header';
import './deviations.scss';
import Moment from 'moment';
import { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import {
    returnError
} from "../../../shared/common_logic";
import _ from 'lodash';
import JoditEditor from "jodit-react";

const stripHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
};

const Deviations = ({ camData, loanId, props }) => {

    const dispatch = useDispatch();
    const url = document.getElementById("frame_id") && document.getElementById("frame_id").src;
    const params = new URLSearchParams(window.location.search !== '' ? window.location.search : url);
    var source = params.get('source');

    const [comm_arr, setComm_arr] = useState([]);
    const [selectedDeviationVar, setSelectedDeviationVar] = useState(null);
    const [tempDeviations, setTempDeviations] = useState(null);
    const [tempRules, setTempRules] = useState(null);
    const [tempRulMin, setTempRulMin] = useState(null);
    const [tempRulLend, setTempRulLend] = useState(null);
    const [deviationCommentsData, setDeviationCommentsData] = useState();
    const [lendersComments, setLendersComments] = useState(null);
    const [modal, setModal] = useState(false);
    const [validationError, setValidationError] = useState(null);

    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: '',
            toolbar: false,
            disablePlugins: 'add-new-line',
            statusbar: false,
            height: 'auto',
            allowResizeX: false,
            allowResizeY: false
        }),
        []
    );

    const deviationDataReducer = useSelector(state => state.all && state.all.cam_report && state.all.cam_report.data && state.all.cam_report.data.attributes)
    const deviationComment = useSelector(state => state.all.deviation_details)

    const unmetDeviationDetails = deviationDataReducer.unmet_deviations_details;
    const unmetCAMRules = deviationDataReducer.unmet_cam_rules;

    useEffect(() => {
        if (validationError) {
            setValidationError(null);
        }
    },[deviationComment]) 

    useEffect(() => {

        let tempDeviationData = { ...unmetDeviationDetails }
        let found_elem;
        let found_elem1;

        if (unmetDeviationDetails && unmetCAMRules) {
            Object.keys(tempDeviationData).forEach((key) => unmetCAMRules.includes(key) || (delete tempDeviationData[key]));
        }

        for (var data in tempDeviationData) {
            if (source !== "lender") {
                found_elem = { ...found_elem, [data]: _.findKey(tempDeviationData[data], "mintifi_comment") };
            } else {
                found_elem1 = { ...found_elem1, [data]: _.findKey(tempDeviationData[data], "lender_comment") };
            }
        }

        setTempRulMin(found_elem);
        setTempRulLend(found_elem1);
        setTempDeviations(tempDeviationData);

    }, [unmetDeviationDetails, unmetCAMRules])

    useEffect(() => {

        let devRules = { ...tempDeviations }
        let devRulesComments = { ...tempDeviations }

        let mintifi_comment_arr = [];
        let lenders_comment_arr = [];


        const tempUnmetCAMRules = deviationDataReducer.unmet_cam_rules;
        var difference = tempUnmetCAMRules && tempUnmetCAMRules.filter(x => !Object.keys(devRules).includes(x));
        var finalDeviationObj = {};
        for (var data in devRulesComments) {
            var tempMintifiComments = devRulesComments[data].filter(x => x.mintifi_comment)
            var tempLendersComments = devRulesComments[data].filter(x => x.lender_comment)

            var tepmDeviData = { [data]: { countLender: tempLendersComments.length, count: tempMintifiComments.length > tempLendersComments.length ? tempMintifiComments.length : tempLendersComments.length, data : {mintifi_comment: tempMintifiComments, lender_comment: tempLendersComments } } };
            finalDeviationObj = Object.assign(finalDeviationObj, tepmDeviData);
        }
        setDeviationCommentsData(finalDeviationObj);
        setTempRules(difference);

    }, [tempDeviations])

    const toggle = () => setModal(!modal);

    useEffect(() => {
        let comm_check = [];

        for (let data in camData?.cam_rule_result) {
            for (let cam_rule_result_data in camData.cam_rule_result[data]) {

                //  camData.cam_rule_result[data][cam_rule_result_data].result === "Unmet"
                if (camData.cam_rule_result[data][cam_rule_result_data].comments && camData.cam_rule_result[data][cam_rule_result_data].comments.length > 0) {
                    comm_check.push(camData.cam_rule_result[data][cam_rule_result_data].comments)
                }
            }
        }

        setComm_arr(comm_check);

    }, [camData])

    const setComment = (commentData) => {
        setModal(true);
        setSelectedDeviationVar(commentData);
    }

    const postDeviationComment = () => {

        if (stripHtml(deviationComment || "").length < 10) {
            setValidationError('kindly mention at least 10 characters');
            return;
        }

        let update_comm_req = {
            "deviation_variable": selectedDeviationVar,
            [source !== "lender" ? "mintifi_comment" : "lender_comment"]: deviationComment
        }

        dispatch(updateCamDeviations(update_comm_req, loanId)).then(
            function (res) {
                let search = props.location.search;
                let params = new URLSearchParams(search);
                var newurl = window.location.origin + props.location.pathname + "?user_id=" + params.get('user_id') + "&source=" + source + "&version_id=" + params.get('version_id') + "&token=" + params.get('token');
                confirmAlert({
                    title: '',
                    message: 'CAM Devation updated successfully.',
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => window.location.href = newurl
                        }
                    ]
                });

            }
            , function (err) {
                confirmAlert({
                    title: 'Error',
                    message: returnError(err),
                    buttons: [
                        {
                            label: 'OK',
                            onClick: null
                        }
                    ]
                });
            }).then(
                toggle()
            );
    }

    const handleEditorChange = (newContent) => {
        dispatch(setDeviationComment(newContent));
    };
    
    return (
        <>
            {camData.deviations_detail &&
                <>
                    <Modal className='commentModel' isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>{selectedDeviationVar}</ModalHeader>
                        <ModalBody>
                            <JoditEditor
                                config={config}
                                onChange={newContent => handleEditorChange(newContent)}
                            />
                            {validationError && <div className="text-danger mt-2">{validationError}</div>}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => postDeviationComment()}>
                                Submit
                            </Button>
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Col md="12 pb-4">
                        <Card>
                            <Header headerText="Deviations" headerImg="/images/cam_version_3/deviation.svg" />
                        </Card>
                    </Col>
                    {camData &&
                        <Col md="12">
                            <div className=" deviation mb-4">

                                <Card className="p-4 card">

                                    <Table responsive className="mb-0 overflow-hidden">
                                        <thead>
                                            <tr>
                                                <th className='borBack' style={{borderBottom:'none'}}></th>
                                                {source !== 'lender' &&
                                                    <th colSpan={3} className="text-center borBack">Mintifi Comment</th>
                                                }
                                                    <th colSpan={3} className="text-center borBack">Lender Comment</th>
                                            </tr>
                                            <tr>
                                                <th className='borBack py-0' style={{borderTop:'none', verticalAlign: 'top'}}>Variable Name</th>
                                                {source !== 'lender' &&
                                                <>
                                                <th className='borBack'>Comment</th>
                                                <th className='borBack'>Commented By</th>
                                                <th className='borBack'>Commented At</th>
                                                </>
                                                }
                                                <th className='borBack'>Comment</th>
                                                <th className='borBack'>Commented By</th>
                                                <th className='borBack'>Commented At</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {tempRules && tempRules.map((data,index)=>(
                                                <tr key={data+index} style={{cursor:"pointer"}} onClick={()=> setComment(data)}>
                                                    <td className="mainTd link">{data}</td>
                                                    {source !== 'lender' &&
                                                        <>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        </>
                                                    }
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                </tr>
                                            ))} 
                                            {deviationCommentsData && Object.keys(deviationCommentsData).map((data, index) =>
                                                <>
                                                    {Array( source !== 'lender' ? deviationCommentsData[data].count : deviationCommentsData[data].countLender === 0 ? deviationCommentsData[data].countLender+1 : deviationCommentsData[data].countLender).fill(1).map((el, i) =>(
                                                        <tr style={{cursor:"pointer"}} onClick={()=> setComment(data)}>{console.log("i",i)}
                                                            {i === 0 &&
                                                                <td className="mainTd link" rowSpan={source !== 'lender' ? deviationCommentsData[data].count : deviationCommentsData[data].countLender === 0 ? deviationCommentsData[data].countLender+1 : deviationCommentsData[data].countLender}>{data}</td>
                                                            }
                                                            {source !== 'lender' &&
                                                            <>
                                                                <td>
                                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: deviationCommentsData[data] &&deviationCommentsData[data].data && deviationCommentsData[data].data.mintifi_comment && deviationCommentsData[data].data.mintifi_comment[i]?.mintifi_comment || "-" }}/>
                                                                </td>
                                                                <td>{deviationCommentsData[data]?.data.mintifi_comment[i]?.mintifi_comment ? deviationCommentsData[data] &&deviationCommentsData[data].data && deviationCommentsData[data].data.mintifi_comment && deviationCommentsData[data].data.mintifi_comment[i] && deviationCommentsData[data].data.mintifi_comment[i].user : ""}</td>
                                                                <td style={{whiteSpace: 'pre', textAlign:"center"}}>
                                                                <div>
                                                                    <span>{deviationCommentsData[data]?.data.mintifi_comment[i]?.mintifi_comment ? Moment(deviationCommentsData[data] &&deviationCommentsData[data].data && deviationCommentsData[data].data.mintifi_comment && deviationCommentsData[data].data.mintifi_comment[i] && deviationCommentsData[data].data.mintifi_comment[i].timestamp).utc().subtract(1, 'hour').format('DD / MM /YY  hh : mm : ss').replace('  ', '\n') : ""}</span>
                                                                </div>
                                                                </td>
                                                            </>
                                                            }
                                                                <td>
                                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: deviationCommentsData[data] && deviationCommentsData[data].data && deviationCommentsData[data].data.lender_comment && deviationCommentsData[data].data.lender_comment[i] && deviationCommentsData[data].data.lender_comment[i]?.lender_comment || "-" }}/>                                                                </td>
                                                                <td>{deviationCommentsData[data]?.data.lender_comment[i]?.lender_comment ? deviationCommentsData[data] && deviationCommentsData[data].data && deviationCommentsData[data].data.lender_comment && deviationCommentsData[data].data.lender_comment[i] && deviationCommentsData[data].data.lender_comment[i].user : ""}</td>
                                                                <td style={{ whiteSpace: 'pre', textAlign: "center" }}><span>{ deviationCommentsData[data]?.data.lender_comment[i]?.lender_comment ? Moment( deviationCommentsData[data] && deviationCommentsData[data].data && deviationCommentsData[data].data.lender_comment && deviationCommentsData[data].data.lender_comment[i] && deviationCommentsData[data].data.lender_comment[i].timestamp ).utc().subtract(1, 'hour').format('DD / MM /YY  hh : mm : ss').replace('  ', '\n') : "" }</span></td>
                                                        </tr>
                                                    )
                                                    )}
                                                </>
                                            )}
{/* 
                                            {source !== "lender" && Object.keys(camData.cam_rule_result).map((data, index) => (
                                                <>
                                                    {camData.cam_rule_result[data].map((cam_rule_result_data, cam_rule_result_index) => (
                                                        cam_rule_result_data.comments && cam_rule_result_data.comments.length > 0 &&
                                                        <>

                                                            {cam_rule_result_data.comments.map((cmtData, cmtIndex) => (
                                                                <tr key={cam_rule_result_index}>
                                                                    {cmtIndex === 0 &&
                                                                        <td className="mainTd" rowspan={cam_rule_result_data.comments.length}>{cam_rule_result_data.title}</td>
                                                                    }
                                                                    <td>
                                                                        {cmtData.comment}
                                                                    </td>
                                                                    <td>
                                                                        {cmtData.comment_by}
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'pre', textAlign: "center" }}>
                                                                        <div>
                                                                            <span>{Moment(cmtData.comment_at).format('DD / MM /YY  hh : mm : ss').replace('  ', '\n')}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td style={{ whiteSpace: 'pre', textAlign: "center" }}>
                                                                        <div>
                                                                            <span></span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}

                                                        </>
                                                    ))}
                                                </>
                                            ))} */}

                                            {/* {camData.deviations_detail.length > 0 &&
                                                <>
                                                    {camData.deviations_detail.map((data, index) => (

                                                        <tr key={index}>
                                                            <td className="mainTd">Final Eligibility</td>
                                                            <td>{data.comment}</td>
                                                            <td>{data.comment_by}</td>
                                                            <td style={{ whiteSpace: 'pre', textAlign: "center" }}><span>{Moment(data.comment_at).format('DD / MM /YY  hh : mm : ss').replace('  ', '\n')}</span></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td style={{ whiteSpace: 'pre', textAlign: "center" }}>
                                                                <div>
                                                                    <span></span>
                                                                </div>
                                                            </td>
                                                        </tr>


                                                    ))}
                                                </>

                                            } */}

                                            {
                                                (!camData.deviations_detail.length && !comm_arr.length && !unmetCAMRules?.length && !unmetDeviationDetails?.length) &&
                                                <tr>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    {source !== 'lender' &&
                                                    <>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    </>
                                                    }
                                                </tr>

                                            }


                                        </tbody>
                                    </Table>

                                </Card>
                            </div>

                        </Col>
                    }
                </>
            }

        </>
    )
}
export default Deviations;