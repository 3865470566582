import React, { Fragment } from 'react';
import './Bureau.css';
import Loader from '../Loader/Loader';

const Bureau = ({ camReport, error,insideCurrent }) => {
  if (camReport && Object.keys(camReport).length === 0) {
    return <Loader />;
  }
  if (error && Object.keys(error).length) {
    return <span style={{ color: "crimson", fontSize: "16px" }}>Request failed </span>
  }
  const { bureau_analysis } = camReport;
  return (
    <div style={{ background: 'white' }} className="borderLeft">
      {
        Object.keys(bureau_analysis).map((bureauAnalysis, index) => {
        return (
            <Fragment key={index}>
              {insideCurrent === bureauAnalysis &&
              <div className="Bureau">
              <div className="Bureau-flex">
                <div className="col-md-4 pl-4 pt-3 pb-3 Bureau-text-main">Parameter</div>
                <div className="col-md-7 pt-3 pb-3 Bureau-text-main">Value</div>
              </div>
              <div className="Bureau-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="Bureau-text">{bureau_analysis[bureauAnalysis].is_primary ? "Primary Applicant's" : "CO-Applicant's"} name</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pt-3 pb-3">
                  <div className="col-md-12 Bureau-text">{bureauAnalysis}</div>
                </div>
              </div>
              <div className="Bureau-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="Bureau-text">Bureau score</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pt-3 pb-3">
                  <div className="col-md-12 Bureau-text">{bureau_analysis[bureauAnalysis].bureau_score}</div>
                </div>
              </div>
              <div className="Bureau-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="Bureau-text">Suit filed / Willful Default</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pt-3 pb-3">
                  <div className="col-md-12 Bureau-text">{bureau_analysis[bureauAnalysis].is_suit_filed_willful_default}</div>
                </div>
              </div>
              <div className="Bureau-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="Bureau-text">Last 3 month unsecured enquiries</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pt-3 pb-3">
                  <div className="col-md-12 Bureau-text">{bureau_analysis[bureauAnalysis].three_months_unsecured_enquiries}</div>
                </div>
              </div>
              <div className="Bureau-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="Bureau-text">Last 6 month unsecured enquiries</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pt-3 pb-3">
                  <div className="col-md-12 Bureau-text">{bureau_analysis[bureauAnalysis].six_months_unsecured_enquiries}</div>
                </div>
              </div>
              <div className="Bureau-flex bordertop707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="Bureau-text">Last 3 month borrowing</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pt-3 pb-3">
                  <div className="col-md-12 Bureau-text">{typeof bureau_analysis[bureauAnalysis].three_months_borrowing == 'string' ? bureau_analysis[bureauAnalysis].three_months_borrowing : bureau_analysis[bureauAnalysis].three_months_borrowing.count}</div>
                </div>
              </div>
              <div className="Bureau-flex bordertopBottom707070">
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="Bureau-text">Last 6 month borrowing</p>
                </div>
                <div className="col-md-7 pt-3 pr-0 pt-3 pb-3">
                  <div className="col-md-12 Bureau-text">{typeof bureau_analysis[bureauAnalysis].six_months_borrowing == 'string' ? bureau_analysis[bureauAnalysis].six_months_borrowing : bureau_analysis[bureauAnalysis].six_months_borrowing.count}</div>
                </div>
              </div>
            </div>
          }
          </Fragment>
          )
        })}
    </div>
  )
}

export default Bureau;