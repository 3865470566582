import React, { useState,useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap'
import KYC from "./KYC";
import Financials from "./Financials";
import { useSelector, useDispatch } from 'react-redux';
import { getDocumentDetails,getCamReport } from '../../../actions/AllActions';
import './kyc.scss';
import _ from 'lodash';

const KYCFin = ({token,camData,loanId,metaData}) => {

  const [activeTab, setActiveTab] = useState(0);
  const [camProgramDoc, setcamProgramDoc] = useState(null);
  const [Tabsss, setTabsss] = useState([]);
  const [tempDoc, setTempDoc] = useState([]);
  const [documentsDownload, setDocumentsDownload] = useState([]);
  const useToken = "?"+token;

  const document_details = useSelector(state => state.all.document_details);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDocumentDetails(loanId));
  },[]);

  useEffect(() => {

    if(metaData && metaData.program_details){
        var verifyDocObj = {  
            companyName : metaData?.program_details?.company_name,           
            companyKYC: metaData?.program_details?.program_band_enabled_documents?.company_document?.kyc,
            companyFinance: metaData?.program_details?.program_band_enabled_documents?.company_document?.finance,
            userKYC: metaData?.program_details?.program_band_enabled_documents?.user_document?.kyc,
            userFinance: metaData?.program_details?.program_band_enabled_documents?.user_document?.finance,
            loanKYC: metaData?.program_details?.program_band_enabled_documents?.loan_document?.kyc,
            loanFinance: metaData?.program_details?.program_band_enabled_documents?.loan_document?.finance,

        };    
    setcamProgramDoc(verifyDocObj);
    }
  },[metaData]);


  useEffect(() => {   

    if(document_details && document_details.data && document_details.data.attributes && document_details.data.attributes.documents && camProgramDoc && camProgramDoc.companyName) {


    var companyDocFinal = {};
    var loanDocFinal = {};

    var all_Doc = document_details.data?.attributes.documents;
    var companyName = camProgramDoc.companyName;

    
    var companyDoc = companyName && all_Doc[companyName];
    delete all_Doc[companyName];

    var loanDoc = all_Doc["Post-Approval"];   
    delete all_Doc["Post-Approval"];

    var userListwithDoc = all_Doc;


    for(var comapny_i in companyDoc) {

        var company_Ques = {};

        if(comapny_i === "kyc") {
            company_Ques = camProgramDoc.companyKYC;
        }

        if(comapny_i === "finance") {
            company_Ques = camProgramDoc.companyFinance;
        }
        for(var company_Ques_i  in company_Ques) {
          var find_doc = {};     
                                    
          if(!companyDocFinal[comapny_i]) {
            companyDocFinal[comapny_i] = [];
          }

          for(var data in companyDoc[comapny_i]){
            if(companyDoc[comapny_i][data].document_type === company_Ques[company_Ques_i].doc_type && companyDoc[comapny_i][data].document_category === company_Ques[company_Ques_i].doc_category){

              if(data) {
                find_doc = {...companyDoc[comapny_i][data],uploaded: true};
              }
              else {
                find_doc = {document_category: company_Ques[company_Ques_i].doc_category,document_filename: company_Ques[company_Ques_i].doc_category,document_type: company_Ques[company_Ques_i].doc_type,url: null,verified: false,uploaded: false};
              }

              companyDocFinal[comapny_i].push(find_doc)

            }
          }

        }
    }

    for(var loan_i in loanDoc) {

        var loan_Ques = {};

        if(loan_i === "kyc") {
            loan_Ques = camProgramDoc.loanKYC;   
        }

        if(loan_i === "finance") {
            loan_Ques = camProgramDoc.loanFinance;
        }

        for(var loan_Ques_i  in loan_Ques) {

          if(!loanDocFinal[loan_i]) {
            loanDocFinal[loan_i] = [];
          }  

          var find_doc_l = {};

          for(var data in loanDoc[loan_i]){
            if(loanDoc[loan_i][data].document_type === loan_Ques[loan_Ques_i].doc_type && loanDoc[loan_i][data].document_category === loan_Ques[loan_Ques_i].doc_category){

            if(data) {
              find_doc_l = {...loanDoc[loan_i][data],uploaded: true};
            }
            else {
              find_doc_l = {document_category: loan_Ques[loan_Ques_i].doc_category,document_filename: loan_Ques[loan_Ques_i].doc_category,document_type: loan_Ques[loan_Ques_i].doc_type,url: null,verified: false,uploaded: false};
            }
            loanDocFinal[loan_i].push(find_doc_l)
            }
          }
      }
    }

    var new_user_doc_final = {};
    
    for(var userList in userListwithDoc) {


      for(var user_i in userListwithDoc[userList]) {

        var user_Ques = {};

        if(user_i === "kyc") {
            user_Ques = camProgramDoc.userKYC;
        }

        if(user_i === "finance") {
            user_Ques = camProgramDoc.userFinance;
        }
        
        for(var user_Ques_i  in user_Ques) {

          if(!new_user_doc_final[userList]) {
            new_user_doc_final[userList] = {};
          }
  
          if(!new_user_doc_final[userList][user_i]) {
            new_user_doc_final[userList][user_i] = [];
          }

          var find_doc_u = {};
          for(var data in userListwithDoc[userList][user_i]){
            if(userListwithDoc[userList][user_i][data].document_type === user_Ques[user_Ques_i].doc_type && userListwithDoc[userList][user_i][data].document_category === user_Ques[user_Ques_i].doc_category){

              if(data) {
                find_doc_u = {...userListwithDoc[userList][user_i][data],uploaded: true};
              }
              else {
                find_doc_u = {document_category: user_Ques[user_Ques_i].doc_category,document_filename: user_Ques[user_Ques_i].doc_category,document_type: user_Ques[user_Ques_i].doc_type,url: null,verified: false,uploaded: false};
              }
              new_user_doc_final[userList][user_i].push(find_doc_u)
            }
          }

        }
      }


    }
    
    var setDoc = {};
    if(companyDocFinal['kyc'] || companyDocFinal['finance']) {
      setDoc[companyName] = companyDocFinal;
    }
    
    if(Object.keys(new_user_doc_final).length > 0) {
      setDoc = {...setDoc,...new_user_doc_final};
    }

    if(loanDocFinal['kyc'] || loanDocFinal['finance']) {
      setDoc["Post-Approval"] = loanDocFinal;
    }
        
    var tempDoc_t = [];
    for(var data in setDoc){
      tempDoc_t.push({name: data, obj: setDoc[data]});
    }
    
    setTempDoc(tempDoc_t);

    var tempTabsss = [];
    for(var data in setDoc) {
      tempTabsss.push({name: data,key: data ,type:'user',obj: setDoc[data]})
    }
    setTabsss(tempTabsss);
    setDocumentsDownload(setDoc)

    }

  },[document_details,camProgramDoc]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const downloadAllDocuments = () =>{
    
    for(var docDownload in documentsDownload) {
      for(var docs in documentsDownload[docDownload]) {
        if(documentsDownload[docDownload][docs].length > 0 ){
          for(var finalDocDownload in documentsDownload[docDownload][docs]){
            if(documentsDownload[docDownload][docs][finalDocDownload].url){
              // var downloadLink = document.createElement("a");
              // downloadLink.id = "LoanApp_" + loanId + "_" + documentsDownload[docDownload][docs][finalDocDownload].document_type + "_" + documentsDownload[docDownload][docs][finalDocDownload].document_category + "_" + documentsDownload[docDownload][docs][finalDocDownload].document_filename;
              // downloadLink.href = documentsDownload[docDownload][docs][finalDocDownload].url+useToken;
              // downloadLink.download = "LoanApp_" + loanId + "_" + documentsDownload[docDownload][docs][finalDocDownload].document_type + "_" + documentsDownload[docDownload][docs][finalDocDownload].document_category + "_" + documentsDownload[docDownload][docs][finalDocDownload].document_filename;
              // document.body.appendChild(downloadLink);
              // downloadLink.click();
              // document.body.removeChild(downloadLink);
              window.open(documentsDownload[docDownload][docs][finalDocDownload].url+useToken , "_blank")
            }
          }
        }
      }
    }
  }

  return (
    <div className="col-md-12 p-3 kycTabs">
    {/* {documentsDownload && documentsDownload.length > 0 &&  */}
    {/*<Col md="12" className="col p-2">
            <div className="float-right ">
                  <button className="btn btn-secondary btnExit ml-2"
                  onClick={() => downloadAllDocuments()}
                  >Download All Documents</button>
              
            </div>
        </Col>*/}
    {/* } */}
    {document_details?.data?.attributes?.documents?.all_documents?.url &&
      <div className='float-right d-flex align-items-center' style={{height:"39px"}}>
      <a className='btn btn-danger' href={document_details?.data?.attributes?.documents?.all_documents?.url + useToken} target="_blank">Download All Documents</a>
      </div>
      }
      <Nav className="printScreenTab" tabs style={{ border: "none" }}>
        {
         Tabsss.length > 0 && Tabsss.map((Tab, index) =>(
            <NavItem key={index} style={{ cursor : "pointer" }}>

              <NavLink className={`navLink p-3 ${activeTab === index ? 'active' : ""} text-capitalize`} onClick={() => { toggle(index); }}>
                {(Tab.name).toLowerCase()}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
      <TabContent className="printScreenTab" activeTab={activeTab}>
        { Tabsss.length > 0 && Tabsss.map((Tab, index) =>(
            <TabPane key={index} tabId={index}>
             <Row className="printMintifi">
                <Col md="6" className="pr-0">
                  <KYC tName={Tab?.name} token={token} docData={tempDoc} docSelected={tempDoc[index]} />
                </Col>
                <Col md="6" className="pl-0">
                  <Financials token={token} docData={tempDoc} docSelected={tempDoc[index]}/>
                </Col>
              </Row>
            </TabPane>
          ))
        }
      </TabContent>
    </div>
  )
}
export default KYCFin;
