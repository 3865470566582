import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getReport, postGstPolicy } from '../../actions/AllActions';
import './GstRuleEngine.css';

class GstReport extends Component {

    state = {
        report: {},
        gstin: "",
        errors: [],
        comment: ""
    }

    componentDidMount() {
        this.props.getReport()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { report } = nextProps.all;
        if (Object.keys(report).length) {
            this.setState({ report, gstin: this.state.gstin !== "" ? this.state.gstin : report.attributes.gst_profiles[0].gstin })
        }
    }

    submitGstin = (versionId, gstin, status) => {
        const { error } = this.props.all;

        if (error && error.length)
            return

        const search = window.location.search;
        const params = new URLSearchParams(search);
        this.props.postGstPolicy({
            data: {
                type: "loan_application_gst_policy_result",
                attributes: {
                    version_id: versionId,
                    loan_application_id: params.get('loan_application_id'),
                    gstin,
                    status,
                    comment: this.state.comment
                }
            }
        }).then(res => {
            if ('payload' in res)
                this.props.getReport()
            this.setState({ errors: res })
        })
    }

    getTotalValue = (a,b) => {
        var dataTotal = 0;
        for(var data in a) {
            dataTotal += a[data][b] ? parseInt(a[data][b],10) : 0; 
        }
        return dataTotal.toLocaleString('en-IN');
    }

    render() {
        const { error } = this.props.all;
        const { report, gstin, errors } = this.state;
        let isGstRuleResult = true;
        let status = null;
        return (
            <div>
               <div className="p-3 " style={{background:"#9e9e9e0f"}}>
                    <img src="https://blog.mintifi.com/wp-content/uploads/2021/07/logo.png" alt="logo" width="150px"/>
                </div>
            <div className="pl50">
                <div className="main-div-gst">
                    <header>
                        <div className="heading-id d-flex pt-4">
                            <p className="m-0">GST RULE ENGINE</p>
                            <h4>Loan ID: <strong>{report && report.id}</strong></h4>
                        </div>
                    </header>
                    {
                        errors && errors.length ? errors.map(err => {
                            return (
                                <div key={err} style={{ textAlign: 'center', position: 'relative', bottom: 45 }}>
                                    {
                                        <div key={err} className="col-md-12 d-flex mb-2">
                                            <div className="col-md-4 m-auto">
                                                <span className="col-md-4 m-auto" style={{ color: "crimson", fontSize: "22px" }}>
                                                    {err.detail}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        }) : null
                    }
                    {
                        error && error.length ? error.map(err => {
                            return (
                                <div key={err} style={{ textAlign: 'center', position: 'relative', bottom: 45 }}>
                                    {
                                        <div key={err} className="col-md-12 d-flex mb-2">
                                            <div className="col-md-4 m-auto">
                                                <span className="col-md-4 m-auto" style={{ color: "crimson", fontSize: "22px" }}>
                                                    {err.detail}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        }) : null
                    }
                    <div className="gst-number row m-0">
                        {
                            Object.keys(report).length ? report.attributes.gst_profiles.map(gst => {
                                return (
                                    <div key={gst.gstin} style={{ cursor: 'pointer' }} onClick={() => this.setState({ gstin: gst.gstin })} className={`col-md-4 gst-number-data ${gstin === gst.gstin ? "back-light" : ""}`}>
                                        <p>{gst.gstin}</p>
                                    </div>
                                )
                            }) : null
                        }
                    </div>
                    <div className="main-div1 d-flex">
                        <div className="main-data">
                            <div className="div-data-content">
                                {
                                    Object.keys(report).length ? report.attributes.gst_profiles.map((gst, i) => {
                                        if (gst.gstin === gstin && gst.gst_rule_result.length !== 0)
                                            return (
                                                <div key={gst.gstin + '-' + i} className="d-flex title-data row m-0">
                                                    <h5 className="m-0 w-l-1 pr-50">SNo</h5>
                                                    <h5 className="m-0 w-58 pl-0">GST Rule</h5>
                                                    <h5 className="m-0 w-l-2">Result</h5>
                                                </div>
                                            )
                                    }) : null
                                }
                                {
                                    Object.keys(report).length ? report.attributes.gst_profiles.map(gstProfile => {
                                        if (gstProfile.gstin === gstin) {
                                            isGstRuleResult = gstProfile.gst_rule_result.length !== 0
                                            status = gstProfile.status;
                                            return gstProfile.gst_rule_result.map(gstRule => {
                                                const chars = gstRule.rule.split('%x').filter(f => f !== "");
                                                return (
                                                    <div key={gstRule.id} className="d-flex gst-row align-items-center">
                                                        <p className="m-0 w-l-1 pr-50">{gstRule.id}</p>
                                                        <div className="d-flex align-items-center gst-data w-58 pl-1">
                                                            {
                                                                chars.map((char, j) => <p key={gstRule.id + '-' + j} className="m-0">{char}{char.endsWith(" ") ? <span>{gstRule.values[j]}</span> : null}</p>)
                                                            }
                                                        </div>
                                                        <div className={`${gstRule.result === true ? "met-btn  w-l-2 text-center" : "unmet-btn  w-l-2 text-center"}`}>
                                                            <p className="met-btn">{gstRule.result === true ? "met" : "unmet"}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    }) : null
                                }
                                {
                                    Object.keys(report).length ? report.attributes.gst_profiles.map(gstProfile => {
                                        if (gstProfile.gstin === gstin && gstProfile.gst_rule_result.length !== 0) {
                                            const lastGstRuleResult = gstProfile.gst_rule_result.length ? gstProfile.gst_rule_result[gstProfile.gst_rule_result.length - 1] : 1;
                                            return gstProfile.other_rule_data.map(gstRule => (
                                                <div key={gstRule.id} className="d-flex gst-row">
                                                    <p className="m-0 w-l-1 pr-50">{lastGstRuleResult === 1 ? 1 : lastGstRuleResult.id + 1}</p>
                                                    <div className="d-flex gst-data w-58 pl-1">
                                                        <p className="m-0">{gstRule.rule}</p>
                                                    </div>
                                                    <div>{gstRule.data.map(d => <p key={d.code}>{`${d.code},`}</p>)}</div>
                                                </div>
                                            )
                                            )
                                        }
                                    }) : null
                                }
                                {
                                    Object.keys(report).length ? report.attributes.gst_profiles.map(gstProfile => {
                                        if (gstProfile.gstin === gstin && gstProfile.gst_rule_result.length !== 0) {
                                            const lastGstRuleResult = gstProfile.gst_rule_result.length ? gstProfile.gst_rule_result[gstProfile.gst_rule_result.length - 1] : 1;
                                            return gstProfile.tax_credit_rule_data.map(gstRule => (
                                                <div key={gstRule.id} className="d-flex gst-row">
                                                    <p className="m-0 w-l-1 pr-50">{lastGstRuleResult === 1 ? 1 : lastGstRuleResult.id + 2}</p>
                                                    <div className="d-flex gst-data w-58a pl-1">
                                                        <p className="m-0">{gstRule.rule}</p>
                                                        {Object.keys(gstRule.data).map(e => 
                                                        <div className="tableGst">
                                                            <div className="tableHeading">{e}</div>
                                                            <div className="tableHeader">
                                                                <div className="col-l-1 col-l-1a">
                                                                    <span>Month</span>
                                                                </div>
                                                                <div className="col-l-1">
                                                                    <span>Opening Balance</span>
                                                                </div>
                                                                <div className="col-l-1">
                                                                    <span>Transitional Credit</span>
                                                                </div>
                                                                <div className="col-l-1">
                                                                    <span>Input Tax Available</span>
                                                                </div>
                                                                <div className="col-l-1">
                                                                    <span>Input Tax Reversed</span>
                                                                </div>
                                                                <div className="col-l-1">
                                                                    <span>Input Tax Eliglible</span>
                                                                </div>
                                                                <div className="col-l-1">
                                                                    <span>Input Tax Availed</span>
                                                                </div>
                                                                 <div className="col-l-1">
                                                                    <span>Closing Balance</span>
                                                                </div>
                                                                <div className="col-l-1">
                                                                    <span>Input Tax Available a per 2A</span>
                                                                </div>
                                                            </div>
                                                            <div className="tableBody">
                                                            {gstRule.data[e].map(d => 
                                                                <div className={parseInt(d.tax_eligible,10) < parseInt(d.tax_availed,10) ?  "tableBodyDiv redBackrond" : "tableBodyDiv"} key={d.closing_balance}>
                                                                    <div className="col-l-1 col-l-1a">
                                                                        {d.month}
                                                                    </div>
                                                                    <div className="col-l-1">
                                                                        {d.opening_balance !== null &&
                                                                            <>
                                                                            <sup>₹</sup>{d.opening_balance.toLocaleString('en-IN')}
                                                                            </>
                                                                        }
                                                                        {d.opening_balance === null &&
                                                                            <>
                                                                            <sup>₹</sup>0
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    <div className="col-l-1">
                                                                        {d.traditional_credit}
                                                                    </div>
                                                                    <div className="col-l-1">
                                                                        {d.tax_available === null &&
                                                                            <>
                                                                            <sup>₹</sup>0
                                                                            </>
                                                                        }
                                                                        {d.tax_available !== null &&
                                                                            <>
                                                                            <sup>₹</sup>{d.tax_available.toLocaleString('en-IN')}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        {d.tax_reversed === null &&
                                                                            <>
                                                                            <sup>₹</sup>0
                                                                            </>
                                                                        }
                                                                        {d.tax_reversed !== null &&
                                                                            <>
                                                                            <sup>₹</sup>{d.tax_reversed.toLocaleString('en-IN')}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        {d.tax_eligible === null &&
                                                                            <>
                                                                            <sup>₹</sup>0
                                                                            </>
                                                                        }
                                                                        {d.tax_eligible !== null &&
                                                                            <>
                                                                            <sup>₹</sup>{d.tax_eligible.toLocaleString('en-IN')}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        {d.tax_availed === null &&
                                                                            <>
                                                                            <sup>₹</sup>0
                                                                            </>
                                                                        }
                                                                        {d.tax_availed !== null &&
                                                                            <>
                                                                            <sup>₹</sup>{d.tax_availed.toLocaleString('en-IN')}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        {d.closing_balance === null &&
                                                                            <>
                                                                            <sup>₹</sup>0
                                                                            </>
                                                                        }
                                                                        {d.closing_balance !== null &&
                                                                            <>
                                                                            <sup>₹</sup>{d.closing_balance.toLocaleString('en-IN')}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        {d.input_tax_available_per_2a === null &&
                                                                            <>
                                                                            <sup>₹</sup>0
                                                                            </>
                                                                        }
                                                                        {d.input_tax_available_per_2a !== null &&
                                                                            <>
                                                                            <sup>₹</sup>{d.input_tax_available_per_2a.toLocaleString('en-IN')}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                             <div className="tableBodyDiv extraBottom">
                                                                    <div className="col-l-1 col-l-1a">
                                                                        Total
                                                                    </div>
                                                                    <div className="col-l-1">
                                                                        &nbsp;
                                                                    </div>
                                                                    <div className="col-l-1">
                                                                        &nbsp;
                                                                    </div>
                                                                    <div className="col-l-1">
                                                                        <sup>₹</sup>{this.getTotalValue(gstRule.data[e],'tax_available')}
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        <sup>₹</sup>{this.getTotalValue(gstRule.data[e],'tax_reversed')}
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        &nbsp;
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        <sup>₹</sup>{this.getTotalValue(gstRule.data[e],'tax_availed')}
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        &nbsp;
                                                                    </div>
                                                                     <div className="col-l-1">
                                                                        <sup>₹</sup>{this.getTotalValue(gstRule.data[e],'input_tax_available_per_2a')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                            )
                                        }
                                    }) : null
                                }
                                {
                                    Object.keys(report).length ? report.attributes.gst_profiles.map((gstProfile, i) => {
                                        if (gstProfile.gstin === gstin && gstProfile.gst_rule_result.length !== 0)
                                            return <div className="text-center"><textarea key={i} placeholder="YOUR COMMMENT" readOnly={gstProfile.status !== null} value={gstProfile.comment} onChange={event => this.setState({ comment: event.target.value })} style={{ width: '65%', height: 60, marginTop: 15 }}></textarea></div>
                                    }) : null
                                }
                            </div>
                            {
                                Object.keys(report).length ? report.attributes.gst_profiles.map(gstProfile => {
                                    if (gstProfile.status === null && gstProfile.gstin === gstin && gstProfile.gst_rule_result.length)
                                        return (
                                            <div key={gstProfile.gstin} className="bottom-btn">
                                                <button onClick={() => this.submitGstin(gstProfile.version_id, gstProfile.gstin, "rejected")} className="reject-btn">REJECT</button>
                                                <button onClick={() => this.submitGstin(gstProfile.version_id, gstProfile.gstin, "approved")} className="update-btn">APPROVE</button>
                                            </div>
                                        )
                                    else if (gstProfile.status === null && gstProfile.gstin === gstin)
                                        return <p key={gstProfile.gstin} style={{ textAlign: 'center', position: 'relative', bottom: 60 }}>GST Rule Result Not Present For This Gst Profile.</p>
                                    else if (gstProfile.status === "approved" && gstProfile.gstin === gstin)
                                        return <label key={gstProfile.gstin} className="approve">APPROVED</label>
                                    else if (gstProfile.status === "rejected" && gstProfile.gstin === gstin)
                                        return <label key={gstProfile.gstin} className="reject">REJECTED</label>
                                }) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>

        )
    }
}

const mapStateToProps = state => ({
    all: state.all
});

const mapDispatchToProps = {
    getReport,
    postGstPolicy
};

export default connect(mapStateToProps, mapDispatchToProps)(GstReport);