import {
  GET_ELIGIBLE_BANK,
  GET_BANK_STATEMENT,
  GET_ANALYSIS_REPORTS,
  POST_PARSE_STATEMENT,
  POST_RE_ANALYSE_STATEMENT,
  SHOW_ALERT,
  CHANGE_LOADER,
  FIELD_ALERT,
  GET_CONTACT_POINT_REPORT,
  GET_CREDIT_ASSESSMENT_REPORT,
  GET_DISBURSEMENT_REPORT,
  INITIAL_LOADING,
  FAILED_LOADING,
  GET_CAM_REPORT,
  GET_RISK_COMPLAINCES,
  POST_PARSE_STATEMENTS,
  GET_RULES,
  POST_RULES,
  POST_GST_POLICY,
  GET_REPORT,
  GET_ML_SCORE,
  ENABLED_BANKING_INTEGRATIONS,
  GET_ANCHOR_MERCHANT_ELIGIBILITY_DATA,
  GET_ANCHOR_DATA,
  GET_ITR_SUMMARY,
  GET_CIBIL_RULE_ELIGIBILITY_DATA,
  GET_ITR_DETAILS,
  GET_CIBIL_SUMMARY,
  GET_LOAN_ELIGIBILTY_CALCULATOR,
  GET_BANKING_ANALYSIS,
  GET_BANKING_DATA_SUMMARY,
  GET_GST_CAM_DATA,
  GET_ANCHOR_INDUSTRY_SCOREBOARD,
  GET_TRIANGULATION_SUMMARY,
  GET_DOCUMENT_DETAILS,
  GET_LOAN_DETAILS,
  SET_DEVIATION_COMMENT,
  POST_DEVIATION_COMMENT
} from '../actions/types';

const initialState = {
  eligible_bank: {},
  bank_statement: {},
  parseStatement: {},
  analysis_report: {},
  contact_point_report: {},
  credit_assessment_report: {},
  disbursement_report: {},
  cam_report: {},
  risk_compliances: {},
  parseStatements: {},
  rules: {},
  report: {},
  GstPolicy: {},
  ml_score: {},
  anchor_merchant_eligibility_data:{},
  anchor_data:{},
  itr_summary:{},
  cibil_rule_eligibility_data:{},
  itr_details:{},
  cibil_summary_data:{},
  loan_eligibilty_calculator_data:{},
  banking_analysis:{},
  banking_data_summary : {},
  gst_cam_data:{},
  anchor_industry_scoreboard:{},
  isFetching: true,
  get_triangulation_summary: {},
  document_details:{},
  loan_details:{},
  deviation_details:null,
  deviation_update_response:{}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case INITIAL_LOADING:
      return {
        ...state,
        isFetching: true
      }
    case FAILED_LOADING:
      return {
        ...state,
        isFetching: false
      }
    case GET_ELIGIBLE_BANK:
      return {
        ...state,
        isFetching: false,
        eligible_bank: { ...action.payload }
      }
     case ENABLED_BANKING_INTEGRATIONS:
      return {
        ...state,
        isFetching: false,
        enabled_banking_integrations: { ...action.payload }
      }
    case GET_BANK_STATEMENT:
      return {
        ...state,
        isFetching: false,
        bank_statement: { ...action.payload }
      }
    case GET_ANALYSIS_REPORTS:
      return {
        ...state,
        isFetching: false,
        analysis_report: { ...action.payload }
      }
    case POST_PARSE_STATEMENT:
      return {
        ...state,
        isFetching: false,
        parseStatement: { ...action.payload }
      }
    case POST_RE_ANALYSE_STATEMENT:
      return {
        ...state,
        isFetching: false,
        reAnalyseStatement: { ...action.payload }
      }
    case SHOW_ALERT:
      return {
        ...state,
        alertMsg: action.alertMsg,
        alertType: action.alertType
      };
    case CHANGE_LOADER:
      return { ...state, loader: action.loader };
    case FIELD_ALERT:
      return { ...state, alertObj: action.alertObj };
    case GET_CONTACT_POINT_REPORT:
      return {
        ...state,
        isFetching: false,
        contact_point_report: { ...action.payload }
      }
    case GET_CREDIT_ASSESSMENT_REPORT:
      return {
        ...state,
        isFetching: false,
        credit_assessment_report: { ...action.payload }
      }
    case GET_DISBURSEMENT_REPORT:
      return {
        ...state,
        isFetching: false,
        disbursement_report: { ...action.payload }
      }
    case GET_CAM_REPORT:
      return {
        ...state,
        isFetching: false,
        cam_report: { ...action.payload },
        error: { ...action.errPayload }
      }
    case GET_RISK_COMPLAINCES:
      return {
        ...state,
        isFetching: false,
        risk_compliances: { ...action.payload },
        error: action.errPayload !== undefined ? action.errPayload.response && action.errPayload.response.data === undefined ? ["Failed"] : action.errPayload : action.errPayload
      };
    case POST_PARSE_STATEMENTS:
      return {
        ...state,
        isFetching: false,
        parseStatements: { ...action.payload },
        error: action.errPayload !== undefined ? action.errPayload.response && action.errPayload.response.data === undefined ? ["Failed"] : action.errPayload : action.errPayload
      }
    case GET_RULES:
      return {
        ...state,
        isFetching: false,
        rules: { ...action.payload },
        error: action.errPayload
      };
    // case POST_RULES:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     postRules: { ...action.payload },
    //     error: action.errPayload
    //   },
    case GET_REPORT:
      return {
        ...state,
        isFetching: false,
        report: { ...action.payload },
        error: action.errPayload
      }
    case POST_GST_POLICY:
      return {
        ...state,
        isFetching: false,
        GstPolicy: { ...action.payload },
        error: action.errPayload
      }
    case GET_ML_SCORE:
      return {
        ...state,
        isFetching: false,
        ml_score: { ...action.payload },
        ml_error: { ...action.errPayload }
      };
      case GET_ANCHOR_MERCHANT_ELIGIBILITY_DATA:
      return {
        ...state,
        isFetching: false,
        anchor_merchant_eligibility_data: { ...action.payload },
        error_anchor: { ...action.errPayload }
      };
      
    case GET_ANCHOR_DATA:
        return {
          ...state,
          isFetching: false,
          anchor_data: { ...action.payload }
        }
    case GET_ITR_SUMMARY:
        return {
          ...state,
          itr_summary: {...action.payload}
          /*itrFetching: { ...action.itrpayload },
          itr_summary: { ...action.payload },
          error_itr: { ...action.errPayload }*/
        }
    case GET_CIBIL_RULE_ELIGIBILITY_DATA:
        return {
          ...state,
          isFetching: false,
          cibil_rule_eligibility_data: { ...action.payload },
          error_cibil: { ...action.errPayload }
        }
    case GET_ITR_DETAILS:
          return {
            ...state,
            // isFetching: false,
            itr_details: { ...action.payload }
          }    

    case  GET_CIBIL_SUMMARY:
      return {
        ...state,
          isFetching: false,
          cibil_summary_data: { ...action.payload }
      }
      case  GET_TRIANGULATION_SUMMARY:
      return {
        ...state,
          isFetching: false,
          get_triangulation_summary: { ...action.payload }
      }
      case  GET_LOAN_ELIGIBILTY_CALCULATOR:
        return {
          ...state,
            isFetching: false,
            loan_eligibilty_calculator_data: { ...action.payload }
        }
        case GET_BANKING_ANALYSIS:
        return {
          ...state,
          isFetching: false,
          banking_analysis: { ...action.payload },
          error_cibil: { ...action.errPayload }
        }

      case  GET_BANKING_DATA_SUMMARY:
        return {
         ...state,
         isFetching: false,
         banking_data_summary: { ...action.payload }
      }
      case  GET_GST_CAM_DATA:
        return {
         ...state,
         isFetching: false,
         gst_cam_data: { ...action.payload }
      }
      case  GET_ANCHOR_INDUSTRY_SCOREBOARD:
        return {
         ...state,        
         anchor_industry_scoreboard: { ...action.payload }
      }
      case GET_DOCUMENT_DETAILS:
        return {
          ...state,
          isFetching: false,
          document_details: { ...action.payload }
        }
      case GET_LOAN_DETAILS:
        return{
          ...state,
          isFetching: false,
          loan_details: { ...action.payload }
        }
      case SET_DEVIATION_COMMENT:
        return{
          ...state,
          deviation_details: action.payload.value
        }
      case POST_DEVIATION_COMMENT:
        return {
          ...state,
          isFetching: false,
          deviation_update_response: { ...action.payload }
        }
    default:
      return state;
  }
}
