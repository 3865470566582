import React from 'react';
import './Proposal.css';
import Loader from '../Loader/Loader';
import {
  rupeeDesign
} from "../../shared/common_logic";

const Proposal = ({ camReport, error }) => {
  if (camReport && Object.keys(camReport).length === 0) {
    return <Loader />;
  }
  if (error && Object.keys(error).length) {
    return <span style={{ color: "crimson", fontSize: "16px" }}>Request failed </span>
  }
  const { summary, eligibility_offer, origination_score } = camReport;
  return (
    <div style={{ background: 'white' }} className="borderLeft">
      <div className="proposal-flex">
        <div className="col-md-4 pl-4 pt-3 pb-3 proposal-text-main">Parameter</div>
        <div className="col-md-7 pt-3 pb-3 proposal-text-main">Value</div>
      </div>
      <div className="proposal-flex borderPBottom707070 borderPTop707070">
        <div className="col-md-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">Loan Amount proposed</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{rupeeDesign(summary.requested_loan_amount)}</div>
        </div>
      </div>
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">Anchor</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{summary.anchor_name}</div>
        </div>
      </div>
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">Product offered</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{eligibility_offer.product_type}</div>
        </div>
      </div>
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">Tenure in Months</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{eligibility_offer.tenure}</div>
        </div>
      </div>
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">ROI</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{eligibility_offer.roi}</div>
        </div>
      </div>
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">Processing fees (Exl GST 18%)</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{eligibility_offer.processing_rate}</div>
        </div>
      </div>
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">Over-all business vintage (In Months)</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{origination_score.business_vintage.value}</div>
        </div>
      </div>
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">{`Property status - residence ${summary.co_applicant_residence_address.length ? summary.residence_address.user_name : ""} (In Years)`}</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{summary.residence_address.years_in_address}</div>
        </div>
      </div>
      {summary.co_applicant_residence_address.map((coApplicant, index) => (
        <div key={index} className="proposal-flex borderPBottom707070">
          <div className="col-md-4 pl-4 pt-3 pb-3">
            <p className="proposal-text">{`Property status - residence ${coApplicant.user_name} (In Years)`}</p>
          </div>
          <div className="col-md-7 pt-3 pr-0 pb-3">
            <div className="col-md-12 proposal-text">{coApplicant.years_in_address}</div>
          </div>
        </div>
      ))
      }

      {summary.three_months_avg_anchor_transaction &&
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pb-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">Avg Merchant Transaction in last 3 Months</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{summary.three_months_avg_anchor_transaction}</div>
        </div>
      </div>
      }
      {summary.six_months_avg_anchor_transaction &&
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pb-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">Avg Merchant Transaction in last 6 Months</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{summary.six_months_avg_anchor_transaction}</div>
        </div>
      </div>
      }
      {summary.twelve_months_avg_anchor_transaction &&
      <div className="proposal-flex borderPBottom707070">
        <div className="col-md-4 pb-4 pl-4 pt-3 pb-3">
          <p className="proposal-text">Avg Merchant Transaction in last 12 Months</p>
        </div>
        <div className="col-md-7 pt-3 pr-0 pb-3">
          <div className="col-md-12 proposal-text">{summary.twelve_months_avg_anchor_transaction}</div>
        </div>
      </div>
      }
    </div>
  )
}

export default Proposal;