import { image } from 'd3-fetch';
import React,{useState,useEffect} from 'react';

const RoundProcess = (props) => {
    const [color,setColor]= useState("black") ;

    useEffect(()=>{
        setColor(props.color);
    },[color])

    let color1 = {
        color: "",
        riskType:"",
    };

    if(props.score >= 700) {
        color1.color = "#3ED68D";
        color1.riskType = "L"
    }
    else if(props.score >= 600 && props.score <= 699) {
        color1.color = "#ee964f";
        color1.riskType="M"
    }
    else if(props.score < 600) {
        color1.color = "#EE5C5C";
            color1.riskType="H"
            
    }
    else {
        color1.color = "#000000";
        color1.riskType="-"
    }


    if(props.hml !== undefined) {
        if(props.hml && props.hml.toLowerCase() === "low risk") {
            color1.color = "#3ED68D";
            color1.riskType = "L"
        }
        else if(props.hml && props.hml.toLowerCase() === "high risk") {
                color1.color = "#EE5C5C";
                color1.riskType="H"
        }
        else if(props.hml && props.hml.toLowerCase() === "medium risk") {
                color1.color = "#ee964f";
                color1.riskType="M"
        }
        else {
            color1.color = "#000000";
            color1.riskType="-"
        }

    }
   

    return(
        <div className="circleProgress" >
        <svg width={props.width} height={props.height} viewBox="0 0 75 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse opacity="0.05" cx="38.3217" cy="33.7346" rx="33.1142" ry="32.7956" fill="#6DD230" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.89111 35.0427C2.89111 53.725 18.5308 68.87 37.8234 68.87C57.1159 68.87 72.7557 53.725 72.7557 35.0427C72.7557 16.3603 57.1159 1.21533 37.8234 1.21533C18.5308 1.21533 2.89111 16.3603 2.89111 35.0427ZM71.2038 35.0434C71.2038 52.8954 56.2592 67.3673 37.8241 67.3673C19.3889 67.3673 4.44433 52.8954 4.44433 35.0434C4.44433 17.1914 19.3889 2.71948 37.8241 2.71948C56.2592 2.71948 71.2038 17.1914 71.2038 35.0434Z" fill={color1.color}/>
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="1" width="71" height="68">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.89111 35.0427C2.89111 53.725 18.5308 68.87 37.8234 68.87C57.1159 68.87 72.7557 53.725 72.7557 35.0427C72.7557 16.3603 57.1159 1.21533 37.8234 1.21533C18.5308 1.21533 2.89111 16.3603 2.89111 35.0427ZM71.2038 35.0434C71.2038 52.8954 56.2592 67.3673 37.8241 67.3673C19.3889 67.3673 4.44433 52.8954 4.44433 35.0434C4.44433 17.1914 19.3889 2.71948 37.8241 2.71948C56.2592 2.71948 71.2038 17.1914 71.2038 35.0434Z" fill="white" />
            </mask>
            <g mask="url(#mask0)">
                <path d="M65.7957 69.1747L38.2912 42.1167L4.55371 69.1747L65.7957 74.2221V72.0918V69.1747Z" fill="white" />
               
            </g>
            <text x={props.x+"%"} y={props.y+"%"} fill={'#4F565E'} style={{fontSize:"14.58px",textTransform: 'Capitalize'}} textAnchor="middle">{props.score}</text>
            <rect x="0.691895" y="54.8696" width="73.7544" height="21.8637" fill="white"/>
                <circle cx="49%" cy="75%" r="11" fill={color1.color}> </circle>
                <text x="36" y="63" textAnchor="middle" height="18" width="18" fill="#fff" style={{fontSize: "13px"}}>{color1.riskType}</text>
        </svg>
        </div>
    )
}

export default RoundProcess;


