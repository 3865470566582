import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import './InputTag.css';

const InputTag = props => {
  const [tagButton, setTagButton] = useState(false);
  const [tags, setTags] = useState(typeof props.tags == "string" ? props.tags.split(',').filter(t => t != "") : (props.tags || []));

  let textInput = React.createRef();

  const removeTags = indexToRemove => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  useEffect(() => {
    textInput.current && textInput.current.focus()
  })

  useEffect(() => {
    let tags = typeof props.tags == "string" ? props.tags.split(',').filter(t => t != "") : props.tags
    setTags(tags)
  }, [props.tags])

  const addTags = event => {
    if (event.target.value !== "") {
      tags.push(event.target.value)
      setTags(tags);
      props.selectedTags(tags);
      event.target.value = "";
    }
  };

  return (
    <Col md="12" sm="12" xs="12" className={`${props.Keyword ? 'tagsKey-input pl-3' : 'tags-input pl-4'} "flex pr-4"`}>
      <ul id="tags">
        {tags && tags.length ? tags.map((tag, index) => (
          <li key={index} className={props.Keyword == "keyword" ? "tagKey" : "tag"}>
            <span className='tag-title'>{tag}</span>
            <span className={props.status == "initiated" ? 'tagKey-no-pointer' : props.Keyword == "keyword" ? 'tagKey-close-icon' : 'tag-close-icon'}
              onClick={() => (props.status == "completed" || props.Keyword == "keyword") && removeTags(index)}
            >
              x
            </span>
          </li>
        )) : null}
        {
          (props.status == "completed" || props.Keyword == "keyword") ?
            !tagButton &&
            <button onClick={() => setTagButton(!tagButton)} className="btn mt--8 border-0 btn-primary-outline">
              <img src={'/images/Group 2187.png'} alt="back arrow" />
            </button> : null
        }
      </ul>
      <Col>
        {tagButton &&
          <input
            className="focusNone inputBG"
            style={{ width: "100%" }}
            type="text"
            ref={textInput}
            onKeyUp={event => event.key === "Enter" ? (setTagButton(false), addTags(event)) : null}
            disabled={props.status != "completed" && !props.Keyword}
          />
        }
      </Col>
    </Col>
  )
}

export default InputTag;