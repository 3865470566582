import React, { useState } from 'react';
import './OriginationRisk.css';
import Loader from '../Loader/Loader';

const OriginationRisk = ({ camReport, error }) => {
  const [flag, setFlag] = useState(false);
  const [list, setList] = useState([]);

  if (camReport && Object.keys(camReport).length === 0) {
    return <Loader />;
  }
  if (error && Object.keys(error).length) {
    return <span style={{ color: "crimson", fontSize: "16px" }}>Request failed </span>
  }



  const { origination_score } = camReport;
  if(!flag) {
    var dataList = list;
    for(var data in origination_score) {
      if(data !== "origination_risk_calculation") {
        dataList.push(data);
      }
      setList(dataList);
      setFlag(true);
    }
  }
  var loopHash = {
    'bureau_score': ['bureau_value','Bureau Origination Risk','Bureau Points'],
    'mt_score': ['mt_value','Merchant Transaction','Merchant Points'],
    'banking_score': ['banking_value','Banking Origination Risk','Banking Points'],
    'aip_score': ['aip_value','AIP Risk','AIP Points'],
    'covid19_score': ['covid19_value','COVID19 Risk','COVID19 Points'],
    'eurokids_score': ['eurokids_value','Eurokids Origination Risk','Eurokids Points'],
  }
  var lengthValue = origination_score && origination_score.origination_risk_calculation  && ((Object.keys(origination_score.origination_risk_calculation).length)/2)*200;
  return (
      <div>
            <div className="row">
              <div className="col-md-4">
                <div className="heightEq3 or_Head_1">

                </div>
                <div className="heightEq20">

                </div>
                 <div className="heightEq or_Head_3 topborderDiv">
                 Parameter
                </div>
                {Object.keys(list).map(key => (
                  <div className="heightEq or_Head_3">
                   {list[key].replace(/_/g, ' ')}
                  </div>
                ))}

              </div>
              <div className="col-md-8 gutterLeft">
                 <div className="row">
                  <div className="col-md-4 gutterBoth">
                    <div className="heightEq valueDiv1">&nbsp;</div>
                    <div className="heightEq valueDiv1">Score</div>
                    <div className="heightEq valueDiv1">Risk Status</div>
                    <div className="heightEq20">&nbsp;</div>
                    <div className="heightEq or_Head_3 topborderDiv">Value</div>
                    {Object.keys(list).map(key => (
                      <div className="heightEq contentColorDiv valueDivLabel">{origination_score && origination_score[list[key]].value ? origination_score && origination_score[list[key]].value : 'N/A'}</div>
                    ))}
                  </div>

                   <div className="col-md-8 gutterBoth">
                    <div className="mainScrollDiv">
                      <div style={{width: lengthValue + 'px'}}>
                    {Object.keys(loopHash).map(key1 => (
                      <>
                      {origination_score && origination_score.origination_risk_calculation && origination_score.origination_risk_calculation[key1] &&
                        <div className="mainScrollDivInnerList">
                          <div className="heightEq valueDiv1">{loopHash[key1][1]}</div>

                          <div className="heightEq valueDiv" style={{ backgroundColor: '#FFF5F5' }}>{origination_score && origination_score.origination_risk_calculation && origination_score.origination_risk_calculation[key1]}</div>

                          <div className="heightEq valueDiv" style={{ backgroundColor: '#FFF5F5' }}>{origination_score && origination_score.origination_risk_calculation && origination_score.origination_risk_calculation[loopHash[key1][0]]}</div>
                          <div className="heightEq20">&nbsp;</div>
                          <div className="heightEq or_Head_3 topborderDiv">{loopHash[key1][2]}</div>

                          {Object.keys(list).map(key => (
                            <div className="heightEq contentColorDiv valueDiv">{origination_score && origination_score[list[key]][key1]}</div>
                          ))}
                        </div>
                        }
                      </>
                    ))}
                  </div>
                  </div>
                    </div>

                </div>
              </div>
            </div>
      </div>
  )
}

export default OriginationRisk;