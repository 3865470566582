import {
  GET_ELIGIBLE_BANK, GET_BANK_STATEMENT, GET_ANALYSIS_REPORTS, POST_PARSE_STATEMENT, POST_RE_ANALYSE_STATEMENT, SHOW_ALERT,
  CHANGE_LOADER,
  FIELD_ALERT,
  GET_CONTACT_POINT_REPORT,
  GET_CREDIT_ASSESSMENT_REPORT,
  GET_DISBURSEMENT_REPORT,
  INITIAL_LOADING,
  FAILED_LOADING,
  GET_CAM_REPORT,
  GET_RISK_COMPLAINCES,
  POST_PARSE_STATEMENTS,
  GET_RULES,
  POST_RULES,
  GET_REPORT,
  POST_GST_POLICY,
  GET_ML_SCORE,
  ENABLED_BANKING_INTEGRATIONS,
  GET_ANCHOR_MERCHANT_ELIGIBILITY_DATA,
  GET_ANCHOR_DATA,
  GET_ITR_SUMMARY,
  GET_CIBIL_RULE_ELIGIBILITY_DATA,
  GET_ITR_DETAILS,
  GET_CIBIL_SUMMARY,
  GET_TRIANGULATION_SUMMARY,
  GET_LOAN_ELIGIBILTY_CALCULATOR,
  GET_BANKING_ANALYSIS,
  GET_BANKING_DATA_SUMMARY,
  GET_GST_CAM_DATA,
  GET_ANCHOR_INDUSTRY_SCOREBOARD,
  GET_DOCUMENT_DETAILS,
  GET_LOAN_DETAILS,
  SET_DEVIATION_COMMENT,
  POST_DEVIATION_COMMENT
} from './types';

import { config } from "../config";
import { toast } from "react-toastify";
import axios from "axios";

const { REACT_APP_CREDIT_SERVICE_API_BASE_URL, REACT_APP_LOS_API_BASE_URL } = process.env;

function getBankList() {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/banking/perfios/bank_list`)
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        toast.error('err')
        console.log(err)
      })
  }
}

function getEligibleBank() {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_LOS_API_BASE_URL}/api/v1/banking/analysis/eligible-banks`)
      .then(({ data }) => {
        dispatch({
          type: GET_ELIGIBLE_BANK,
          payload: data
        });
      })
      .catch(err => {
        dispatch({ type: INITIAL_LOADING })
        toast.error('err')
        console.log(err)
      })
  }
}

function enabledBankingIntegrations() {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_LOS_API_BASE_URL}/api/v1/banking/analysis/enabled-banking-integrations`)
      .then(({ data }) => {
        dispatch({
          type: ENABLED_BANKING_INTEGRATIONS,
          payload: data
        });
        return data;
      })
      .catch(err => {
        dispatch({ type: INITIAL_LOADING })
        toast.error('err')
        console.log(err)
      })
  }
}

function getBankStatement(id) {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_LOS_API_BASE_URL}/api/v1/banking/analysis/statements/${id}`)
      .then(({ data }) => {
        return dispatch({
          type: GET_BANK_STATEMENT,
          payload: data
        })
      })
      .then(({ payload }) => {
        if (payload.data.attributes.bank_statements.length) {
          toast.success("Bank Statements loaded Successfully");
        }
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        toast.error('err')
        console.log(err)
      })
  }
}

function getAnalysisReports(id) {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_LOS_API_BASE_URL}/api/v1/banking/analysis/reports/${id}`)
      .then(({ data }) => {
        return dispatch({
          type: GET_ANALYSIS_REPORTS,
          payload: data
        })
      })
      .then(({ payload }) => {
        if (payload.data.attributes.analysis_reports.length) {
          toast.success("Bank Statement Reports Loaded Successfully");
        }
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        toast.error('err')
        console.log(err)
      })
  }
}

function postParseStatement(parseStatement) {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.post(`${REACT_APP_LOS_API_BASE_URL}/api/v1/banking/analysis/statements/parse`, parseStatement)
      .then(({ data }) => {
        const { error } = data;
        if (!!error) {
          toast.error(error.message)
          dispatch({ type: FAILED_LOADING })
        }
        else {
          dispatch({
            type: POST_PARSE_STATEMENT,
            payload: data
          })
          toast.success("Bank statements Successfully Submitted for Analysis.");
        }
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        toast.error('err')
        console.log(err)
      })
  };
}

function postReAnalyseStatement(reAnalyseStatement) {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.post(`${REACT_APP_LOS_API_BASE_URL}/api/v1/banking/analysis/statements/re_analyse`, reAnalyseStatement)
      .then(data => {
        dispatch({
          type: POST_RE_ANALYSE_STATEMENT,
          payload: data
        })
        toast.success("Report Successfully Submitted for Reanalyse.");
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        toast.error('err')
        console.log(err)
      })
  };
}

function showAlert(
  alertMsg = "",
  alertType = "danger"
) {
  return {
    type: SHOW_ALERT,
    alertMsg,
    alertType
  }
};

function fieldAlert(alertObj) { return { type: FIELD_ALERT, alertObj } }

function changeLoader(loader) {
  return {
    type: CHANGE_LOADER,
    loader
  }
};

function getContactPointReport(ID, TOKEN) {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/document_report/contact_point_document_report/${ID}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": TOKEN,
          "Charset": "utf-8"
        }
      })
      .then(({ data }) => {
        dispatch({
          type: GET_CONTACT_POINT_REPORT,
          payload: data
        });
        toast.success("Contact Point Verification Report Loaded Successfully");
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        toast.error('err')
        console.log(err)
      })
  }
}

function getCreditAssessmentReport(ID, TOKEN) {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/document_report/credit_document_report/${ID}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": TOKEN,
          "Charset": "utf-8"
        }
      })
      .then(({ data }) => {
        dispatch({
          type: GET_CREDIT_ASSESSMENT_REPORT,
          payload: data
        });
        toast.success("Credit Assessment Report Loaded Successfully");
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        toast.error('err')
        console.log(err)
      })
  }
}

function getDisbursementReport(ID, TOKEN) {
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/document_report/disbursment_document_report/${ID}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": TOKEN,
          "Charset": "utf-8"
        }
      })
      .then(({ data }) => {
        dispatch({
          type: GET_DISBURSEMENT_REPORT,
          payload: data
        });
        toast.success("Disbursement Report Loaded Successfully");
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        toast.error('err')
        console.log(err)
      })
  }
}

function getCamReport(id) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const AuthToken = 'Bearer '.concat(params.get('token'));
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/cam_report?version_id=${params.get('version_id')}`, { headers: { Authorization: AuthToken } })
      .then(({ data }) => {
        return dispatch({
          type: GET_CAM_REPORT,
          payload: data
        })
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        dispatch({ type: GET_CAM_REPORT, errPayload: err })
        console.log(err)
        
      })
  }
}

function updateData(data,id) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', token: params.get('token') },
    body: JSON.stringify(data)
  };
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return fetch(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/update_cam_report`, requestOptions)
      .then(handleResponse)
      .then(( {meta} ) => {
        return meta;
      })
      .catch(err => {
        return Promise.reject(err);
      })
  }
}

function getRiskCompliances() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/risk_and_compliances/get_summary_report?loan_application_id=${params.get('loan_application_id')}&version=${params.get('version')}`, { headers: { token: params.get('token') } })
      .then(({ data }) => {
        return dispatch({
          type: GET_RISK_COMPLAINCES,
          payload: data
        })
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        dispatch({ type: GET_RISK_COMPLAINCES, errPayload: err })
        console.log(err);
      })
  }
}

function postParseStatements(parseStatement, token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', token },
    body: JSON.stringify(parseStatement)
  };
  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return fetch(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/banking/perfios/parse-statements`, requestOptions).then(handleResponse)
      .then(({ data }) => {
        const { error } = data;
        if (!!error) {
          toast.error(error.message)
          dispatch({ type: FAILED_LOADING })
        } else {
          dispatch({
            type: POST_PARSE_STATEMENTS,
            payload: data
          })
          toast.success("Bank statements Successfully Submitted.");
        }
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        dispatch({ type: POST_PARSE_STATEMENTS, errPayload: err })
      })
  }
}

function getRules() {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', token: params.get('token') }
  };

  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return fetch(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/gst_rules/rules?loan_application_id=${params.get('loan_application_id')}`, requestOptions).then(handleResponse)
      .then(({ data }) => {
        return dispatch({
          type: GET_RULES,
          payload: data
        })
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        dispatch({ type: GET_RULES, errPayload: err })
      })
  }
}

function postRules(updatedRules) {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', token: params.get('token') },
    body: JSON.stringify(updatedRules)
  };

  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return fetch(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/gst_rules/update_rules`, requestOptions).then(handleResponse)
      .then(({ data }) => {
        return dispatch({
          type: GET_RULES,
          payload: data
        })
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        dispatch({ type: GET_RULES, errPayload: err })
      })
  }
}

function getReport(loan_id) {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', token: params.get('token') },
  };

  var loanID = null;

  if(loan_id) {
    loanID = loan_id;
  }
  else if(params.get('loan_application_id')) {
    loanID = params.get('loan_application_id');
  }

  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return fetch(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/gst_rules/gst_policy_report?loan_application_id=${loanID}`, requestOptions).then(handleResponse)
      .then(({ data }) => {
        return dispatch({
          type: GET_REPORT,
          payload: data
        })
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        dispatch({ type: GET_REPORT, errPayload: err })
      })
  }
}


function postGstPolicy(updatedGst) {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', token: params.get('token') },
    body: JSON.stringify(updatedGst)
  };

  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return fetch(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/gst_rules/update_status`, requestOptions).then(handleResponse)
      .then(({ data }) => {
        return dispatch({
          type: POST_GST_POLICY,
          payload: data
        })
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        dispatch({ type: POST_GST_POLICY, errPayload: err })
        return err
        // console.log(err);
      })
  }
}

function getMlScore(ID) {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  var generateUrl = '';

  if(ID) {
    generateUrl = `${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/credit_score/score?loan_application_id=${ID}&user_id=${params.get('user_id')}&version=${params.get('version_id')}`;
  }
  else {
    generateUrl = `${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/credit_score/score?loan_application_id=${params.get('loan_application_id')}&user_id=${params.get('user_id')}&version=${params.get('version_id')}`;
  }

  return dispatch => {
    dispatch({ type: INITIAL_LOADING })
    return axios.get(generateUrl, { headers: { token: params.get('token') } })
      .then(({ data }) => {
        return dispatch({
          type: GET_ML_SCORE,
          payload: data
        })
      })
      .catch(err => {
        dispatch({ type: FAILED_LOADING })
        dispatch({ type: GET_ML_SCORE, errPayload: err && err.response && err.response.data })
      })
  }
}

function getAnchorMerchantEligibilityData(id) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const AuthToken = params.get('token');
  return dispatch => {
    dispatch({
      type: GET_ANCHOR_MERCHANT_ELIGIBILITY_DATA,
      payload: {data: null,loading:true,error:null}
    })
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/anchor_merchant_eligibility_data?version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
      .then(({ data }) => {
        return dispatch({
          type: GET_ANCHOR_MERCHANT_ELIGIBILITY_DATA,
          payload: {data: data.data,loading:false,error:null}
        })
      })
      .catch(err => {
        dispatch({
          type: GET_ANCHOR_MERCHANT_ELIGIBILITY_DATA,
          payload: {data: null,loading:false,error:err.response.data}
        })
      })
  }
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.errors) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function getAnchorData(id) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const AuthToken = params.get('token');
  return dispatch => {
    dispatch({
      type: GET_ANCHOR_DATA,
      payload: {data: null,loading:true,error:null}
    }) 
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/anchor_merchant_summary?version_id=${params.get('version_id')} `, { headers: { token: AuthToken } })
      .then(({ data }) => {
        return dispatch({
          type: GET_ANCHOR_DATA,
          payload: {data: data.data,loading:false,error:null}
        })
      })
      .catch(err => {
        dispatch({
          type: GET_ANCHOR_DATA,
          payload: {data: null,loading:false,error:err.response.data}
        })
      })
  }
}



function getCibilRuleEligibilityData(id) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const AuthToken = params.get('token');
  return dispatch => {
    dispatch({
      type: GET_CIBIL_RULE_ELIGIBILITY_DATA,
      payload: {data: null,loading:true,error:null}
    })
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/cibil_rule_eligibility_data?version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
      .then(({ data }) => {
        return dispatch({
          type: GET_CIBIL_RULE_ELIGIBILITY_DATA,
          payload: {data: data.data,loading:false,error:null}
        })
      })
      .catch(err => {
        dispatch({
          type: GET_CIBIL_RULE_ELIGIBILITY_DATA,
          payload: {data: null,loading:false,error: err.response.data}
        })
      })
  }
}
function getCibilSummaryData(id) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const AuthToken = params.get('token');
  return dispatch => {
    dispatch({
      type: GET_CIBIL_SUMMARY,
      payload: {data: null,loading:true,error:null}
    })    
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/cibil_summary?version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
      .then(({ data }) => {
        return dispatch({
          type: GET_CIBIL_SUMMARY,
          payload: {data: data.data,loading:false,error:null}
        })
      })
      .catch(err => {
        return dispatch({
          type: GET_CIBIL_SUMMARY,
          payload: {data: null,loading:false,error: err.response.data}
        })
      })
  }
}

function getTriangulationSummaryData(id) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const AuthToken = params.get('token');
  return dispatch => {
    dispatch({
      type: GET_TRIANGULATION_SUMMARY,
      payload: {data: null,loading:true,error:null}
    }) 
    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/triangulation_of_data_set?version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
      .then(({ data }) => {
        return dispatch({
          type: GET_TRIANGULATION_SUMMARY,
          payload: {data: data.data,loading:false,error:null}
        })
      })
      .catch(err => {
        dispatch({
          type: GET_TRIANGULATION_SUMMARY,
          payload: {data: null,loading:false,error:err.response.data}
        })
      })
  }
}

function getItrSummary(id,year) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const AuthToken = params.get('token');
  return dispatch => {
    
    dispatch({
      type: GET_ITR_SUMMARY,
      payload: {itrFetching: null,loading:true,itrError: null}
    })

    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/itr_summary?financial_year=${year}&version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
      .then(({ data }) => {
        return dispatch({
          type: GET_ITR_SUMMARY,
          payload: {itrFetching: data.data,loading:false,itrError: null}
        })
      })
      .catch(err => {
         return dispatch({
            type: GET_ITR_SUMMARY,
            payload: {itrFetching: null,loading:false,itrError: err.response.data}
          })
      })
  }
}

function getItrDetails(id,year) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const AuthToken = params.get('token');  

  return dispatch => {
    
    dispatch({
      type: GET_ITR_DETAILS,
      payload: {itrFetching: null,loading:true,error:null}
    })

    return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/itr_details?financial_year=${year}&version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
      .then(({ data }) => {
        return dispatch({
          type: GET_ITR_DETAILS,
          payload: {itrFetching: data.data,loading:false,error:null}
        })
      })
      .catch(err => {
         return dispatch({
            type: GET_ITR_DETAILS,
            payload: {itrFetching: null,loading:false,error:err.response.data}
          })
      })
  }
 

}

  function getLoanEligibiltyCalculatorData(id) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const AuthToken = params.get('token');
    return dispatch => {
      dispatch({ type: INITIAL_LOADING })
      return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/cam_loan_eligibility_calculator?version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
        .then(({ data }) => {
          return dispatch({
            type: GET_LOAN_ELIGIBILTY_CALCULATOR,
            payload: data
          })
        })
        .catch(err => {
          dispatch({ type: FAILED_LOADING })
          // dispatch({ type: GET_LOAN_ELIGIBILTY_CALCULATOR, errPayload: err })
          console.log(err)
        })
    }
  }

  function getBankingAnalysis(id) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const AuthToken = params.get('token');
    return dispatch => {
      dispatch({ type: INITIAL_LOADING })
      return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/banking/banking_analysis?loan_application_id=${id}&version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
        .then(({ data }) => {
          return dispatch({
            type: GET_BANKING_ANALYSIS,
            payload: data
          })
        })
        .catch(err => {
          dispatch({ type: FAILED_LOADING })
          dispatch({ type: GET_BANKING_ANALYSIS, errPayload: err })
      })
    }
  }
  
  

  function getBankingDataSummary(id) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const AuthToken = params.get('token');
    return dispatch => {
      dispatch({
        type: GET_BANKING_DATA_SUMMARY,
        payload: {data: null,loading:true,error:null}
      })
      return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/banking/banking_data?loan_application_id=${id}&account_number&version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
        .then(({ data }) => {
          return dispatch({
            type: GET_BANKING_DATA_SUMMARY,
            payload: {data: data.data,loading:false,error:null}
          })
        })
        .catch(err => {
          dispatch({
            type: GET_BANKING_DATA_SUMMARY,
            payload: {data: null,loading:false,error:err.response.data}
          })
        })
    }
  }
  function getGstCamData(id) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const AuthToken = params.get('token');
    return dispatch => {
      dispatch({
        type: GET_GST_CAM_DATA,
        payload: {data: null,loading:true,error:null}
      })
      return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/gst/gst_cam_data?loan_application_id=${id}&version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
        .then(({ data }) => {
          return dispatch({
            type: GET_GST_CAM_DATA,
            payload: {data: data.data,loading:false,error:null}
          })
        })
        .catch(err => {
          dispatch({
            type: GET_GST_CAM_DATA,
            payload: {data: null,loading:false,error:err.response.data}
          })
      })
    }
  }
  function getAnchorIndustryScoreboard(id) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const AuthToken = params.get('token');
    return dispatch => {
      dispatch({
        type: GET_ANCHOR_INDUSTRY_SCOREBOARD,
        payload: {anchorFetching: null,loading:true,anchorError:null}
      })
      return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/anchor_industry_scoreboard?version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
        .then(({ data }) => {
          return dispatch({
            type: GET_ANCHOR_INDUSTRY_SCOREBOARD,
            payload: {anchorFetching: data.data,loading:false,anchorError:null}
          })
        })
        .catch(err => {
          return dispatch({
            type: GET_ANCHOR_INDUSTRY_SCOREBOARD,
            payload: {anchorFetching: null,loading:false,anchorError: err.response.data}
          })
      })
    }
  }

  function getKYCFile(url,filename) {   
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const AuthToken = params.get('token');      
    return dispatch => {
      return axios.get(url, { headers: { token: AuthToken } })
        .then((response) => {          
           const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              filename
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  function getDocumentDetails(id) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const AuthToken = params.get('token');
    return dispatch => {
      dispatch({
        type: GET_DOCUMENT_DETAILS,
        payload: {data: null,loading:true,error:null}
      }) 
      return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/document_details?version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
        .then(({ data }) => {
          return dispatch({
            type: GET_DOCUMENT_DETAILS,
            payload: {data: data.data,loading:false,error:null}
          })
        })
        .catch(err => {
          dispatch({
            type: GET_DOCUMENT_DETAILS,
            payload: {data: null,loading:false,error:err.response.data}
          })
        })
    }
  }

  function getLoanDetails(id) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const AuthToken = params.get('token');
    return dispatch => {
      dispatch({ type: GET_LOAN_DETAILS,
        payload: {data: null,loading:true,error:null}
       })
      return axios.get(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/offer?version_id=${params.get('version_id')}`, { headers: { token: AuthToken } })
        .then(({ data }) => {
          return dispatch({
            type: GET_LOAN_DETAILS,
            payload: {data: data.data,loading:false,error:null}
          })
        })
        .catch(err => {
          dispatch({
            type: GET_LOAN_DETAILS,
            payload: {data: null,loading:false,error:err.response.data}
          })
        })
    }
  }

  function setDeviationComment(value){
    return {
      type: SET_DEVIATION_COMMENT,
      payload: {
        value
      },
    };
  }


  function updateCamDeviations(data,id) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    console.log('data',data)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', token: params.get('token') },
      body: JSON.stringify(data)
    };
    return dispatch => {
      dispatch({ type: INITIAL_LOADING })
      return fetch(`${REACT_APP_CREDIT_SERVICE_API_BASE_URL}/api/v1/loan_applications/${id}/update_cam_deviations?version_id=${params.get('version_id')}`, requestOptions)
        .then(handleResponse)
        .then(( {meta} ) => {
          return meta;
        })
        .catch(err => {
          return Promise.reject(err);
        })
    }
  }

export {
  getEligibleBank, getBankStatement, getAnalysisReports, postParseStatement, postReAnalyseStatement, showAlert, fieldAlert, changeLoader,
  getContactPointReport,
  getCreditAssessmentReport,
  getDisbursementReport,
  getCamReport,
  getRiskCompliances,
  postParseStatements,
  getRules,
  postRules,
  getReport,
  postGstPolicy,
  getMlScore,
  getBankList,
  enabledBankingIntegrations,
  updateData,
  getAnchorMerchantEligibilityData,
  getAnchorData,
  getItrSummary,
  getCibilRuleEligibilityData,
  getItrDetails, 
  getCibilSummaryData,
  getLoanEligibiltyCalculatorData,
  getBankingAnalysis,
  getBankingDataSummary,
  getGstCamData,
  getKYCFile,
  getAnchorIndustryScoreboard,
  getTriangulationSummaryData,
  getDocumentDetails,
  getLoanDetails,
  setDeviationComment,
  updateCamDeviations
};
