import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Helmet from 'react-helmet';

import Loading from '../../components/Loading/Loading';
import { getContactPointReport, getCreditAssessmentReport, getDisbursementReport } from '../../actions/AllActions';
import './CpvReport.css';
import '../../App.css';

import { Table, Container } from 'reactstrap';

class CpvReport extends Component {

  componentWillMount() {
    const { location, match } = this.props;
    const ID = match.params.id
    const TOKEN = 'Bearer ' + location.search.split('=')[1]
    this.props.getContactPointReport(ID, TOKEN);
    this.props.getCreditAssessmentReport(ID, TOKEN);
    this.props.getDisbursementReport(ID, TOKEN);
  }

  render() {
    const { contact_point_report, credit_assessment_report, disbursement_report, isFetching } = this.props.all;
    return (
      <>
        <Helmet>
          <title>Cpv Report</title>
        </Helmet>
        <Container>
          <ToastContainer autoClose={2000} />
          <p className="report-name">CONTACT POINT VERIFICATION REPORT</p>
          <hr className="report-hr" />
          <Table>
            <thead>
              <tr className="cpv-table-image-header">
                <th className="width31 p-0"><img src="/images/codes-18.png" width="100px" alt="codes-18" /></th>
                <th className="width23 p-0"><img src="/images/codes-19.png" width="100px" alt="codes-19" /></th>
                <th className="width23 p-0"><img src="/images/codes-20.png" width="100px" alt="codes-20" /></th>
                <th className="width23 p-0"><img src="/images/codes-21.png" width="100px" alt="codes-21" /></th>
              </tr>
              <tr>
                <th className="cpv-table-header width31">ITEM</th>
                <th className="cpv-table-header width23">VERIFICATION STATUS</th>
                <th className="cpv-table-header width23">VERIFIED BY</th>
                <th className="cpv-table-header width23">REMARK</th>
              </tr>
            </thead>
            <tbody className="cpv-table-body">
              {
                Object.keys(contact_point_report) && Object.keys(contact_point_report).length && contact_point_report.data && contact_point_report.data.attributes.length ? contact_point_report.data.attributes.map((cpvReport, index) => {
                  return (
                    <Fragment key={`${cpvReport.item_type}_${index}`}>
                      {cpvReport.documents && cpvReport.documents.length ? <tr>
                        <th colSpan="4" scope="row">{cpvReport.item_type ? cpvReport.item_type.toUpperCase() : cpvReport.item_doc_type.toUpperCase()}</th>
                      </tr> : null}
                      {
                        cpvReport.documents.map(docs => {
                          return (
                            <tr key={docs.item}>
                              <td className="table-light-card">{docs.item}</td>
                              <td className="table-light-card">{docs.verified === true ? "Verified" : "Not Verified"}<img src={docs.verified === true ? "/images/codes-12.png" : "/images/codes-13.png"} alt="Verified" width="50px" /></td>
                              <td className="table-light-card">{docs.verified_by_email}</td>
                              <td className="table-light-card">{docs.remark}</td>
                            </tr>
                          )
                        })
                      }
                    </Fragment>
                  )
                }) : isFetching && <Loading />
              }
            </tbody>
          </Table>
          <p className="report-name">CREDIT ASSESSMENT REPORT</p>
          <hr className="report-hr" />
          <Table>
            <tbody className="cpv-table-body">
              {
                Object.keys(credit_assessment_report) && Object.keys(credit_assessment_report).length && credit_assessment_report.data && credit_assessment_report.data.attributes.length ? credit_assessment_report.data.attributes.map((creditReport, index) => {
                  return (
                    <Fragment key={`${creditReport.item_type}_${index}`}>
                      {creditReport.documents && creditReport.documents.length ? <tr>
                        <th colSpan="4" scope="row">{creditReport.item_type && creditReport.item_type.toUpperCase()}</th>
                      </tr> : null}
                      {creditReport.documents.map(docs => {
                        return (
                          <tr key={docs.item}>
                            <td className="table-light-card width31">{docs.item}</td>
                            <td className="table-light-card width23">{docs.verified === true ? "Verified" : "Not Verified"}<img src={docs.verified === true ? "/images/codes-12.png" : "/images/codes-13.png"} alt="Verified" width="50px" /></td>
                            <td className="table-light-card width23">{docs.verified_by_email}</td>
                            <td className="table-light-card width23">{docs.remark}</td>
                          </tr>
                        )
                      })}
                    </Fragment>
                  )
                }) : isFetching && <Loading />
              }
            </tbody>
          </Table>
          <p className="report-name">DISBURSEMENT REPORT</p>
          <hr className="report-hr" />
          <Table>
            <tbody className="cpv-table-body">
              {
                Object.keys(disbursement_report) && Object.keys(disbursement_report).length && disbursement_report.data && disbursement_report.data.attributes.length ? disbursement_report.data.attributes.map((disbursementReport, index) => {
                  return (
                    <Fragment key={`${disbursementReport.item_type}_${index}`}>
                      {disbursementReport.documents && disbursementReport.documents.length ? <tr>
                        <th colSpan="4" scope="row">{disbursementReport.item_type && disbursementReport.item_type.toUpperCase()}</th>
                      </tr> : null}
                      {
                        disbursementReport.documents.map(docs => {
                          return (
                            <tr key={docs.item}>
                              <td className="table-light-card width31" style={{lineHeight: 1.2}}>{docs.item}</td>
                              <td className="table-light-card width23 p-2">{docs.verified === true ? "Verified" : "Not Verified"}<img src={docs.verified === true ? "/images/codes-12.png" : "/images/codes-13.png"} alt="Verified" width="50px" /></td>
                              <td className="table-light-card width23">{docs.verified_by_email}</td>
                              <td className="table-light-card width23">{docs.remark}</td>
                            </tr>
                          )
                        })
                      }
                    </Fragment>
                  )
                }) : isFetching && <Loading />
              }
            </tbody>
          </Table>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  all: state.all
})

const mapDispatchToProps = {
  getContactPointReport,
  getCreditAssessmentReport,
  getDisbursementReport
}

export default connect(mapStateToProps, mapDispatchToProps)(CpvReport);