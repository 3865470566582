import React from "react";
import './ITR.scss';
import RoleResult from '../ruleResult/ruleResult';
import {commaSeparatRs} from '../../../../shared/common_logic';

const CriticalRatios = ({criticalRatios}) => {   
    
    return (
            <div className="col-md-12 criticalRatios">
            <div className="row m-0 card" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "10px 10px 30px" }}>
            <div className="col-md-12">
                <div className="table-responsive">
                    
                    <table className="overflow-hidden">
                        <thead>
                        <tr >
                            <th className="tablehead font-weight-bold">Program Conditions</th>
                            <th className="font-weight-bold text-center">Rule Value</th>
                            <th className="font-weight-bold text-center">Rule result</th>
                        </tr>
                        </thead>
                        <tbody>
                            {criticalRatios !== undefined ? 
                            <>
                            {Object.keys(criticalRatios).map((data,index)=>(
                                
                                <tr key={index}>                                   
                                    <td className="tablehead">{criticalRatios[data].title || "-"}</td>
                                    <td className="text-center">{criticalRatios[data].value !== null ? String(criticalRatios[data].value): "-"}</td>
                                    <RoleResult rule={criticalRatios[data].result} id={index}/> 
                                    
                                    
                                </tr>
                            ))}
                            </>
                            :
                            <tr>
                            <td>-</td>
                            <td>-</td>   
                            <td>-</td>     
                        </tr>
                            }
                        </tbody>
                        {/* <tbody>
                       {1 > 0 ?
                        <>
                        {CriticalRatios.map((data,index)=>(
                        <tr key={index}>
                            <td className="tablehead">{data.title}</td>
                            <td className="text-center">{data.value !== undefined ? data.value : "-"}</td>
                            <RoleResult rule={data.result} comment={data.comments} id={index}/>
                        </tr>
                        ))}
                        </>
                        :
                        <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>}
                     </tbody> */}
                    </table>
                    
                    </div>
                </div>
            </div> 
        </div>   
    );
};
export default CriticalRatios;
