import React, { useState, Fragment } from 'react';
import './GST.css';
import Loader from '../Loader/Loader';


const GST = ({ camReport, error, insideCurrentG, insideCurrent }) => {
  const [coloumSales, SetColoumSales] = useState([]);
  const [coloumPurchase, SetColoumPurchase] = useState([]);
  const coloumName = ["name", "pan", "shares", "total_invoices", "turnover"];

  if (camReport && Object.keys(camReport).length === 0) {
    return <Loader />;
  }

  if (error && Object.keys(error).length) {
    return <span style={{ color: "crimson", fontSize: "16px" }}>Request failed </span>
  }
  const { gst_profile_details } = camReport;
  const yearly_sales_purchases = gst_profile_details[insideCurrent] && gst_profile_details[insideCurrent].yearly_sales_purchases ? gst_profile_details[insideCurrent].yearly_sales_purchases : [];
  const yearly_top_suppliers_purchasers = gst_profile_details[insideCurrent] && gst_profile_details[insideCurrent].yearly_top_suppliers_purchasers ? gst_profile_details[insideCurrent].yearly_top_suppliers_purchasers : [];


  for (var user in gst_profile_details) {
    var gstUser = gst_profile_details[user];
  }

  const flag = yearly_sales_purchases.length !== 0 ? true : false;

  const salesList = () => {
    var dataUpdate = [];
    for (var dataYear in yearly_sales_purchases) {
      var ListData = [];
      for (var data in yearly_sales_purchases[dataYear].monthly_sales[0].transactions) {
        ListData.push(data);
      }
      dataUpdate[dataYear] = [];
      dataUpdate[dataYear] = ListData;
    }
    SetColoumSales(dataUpdate);
  }

  const purchaseList = () => {
    var dataUpdate = [];
    for (var dataYear in yearly_sales_purchases) {
      var ListData = [];
      for (var data in yearly_sales_purchases[dataYear].monthly_purchases[0].transactions) {
        ListData.push(data);
      }
      dataUpdate[dataYear] = [];
      dataUpdate[dataYear] = ListData;
    }
    SetColoumPurchase(dataUpdate);
  }

  if (flag && coloumSales.length === 0) {
    salesList();
  }

  if (flag && coloumPurchase.length === 0) {
    purchaseList();
  }

  const indianrsConvertr = (a) => {
    return (a).toLocaleString('en-IN');
  };

  return (


    <div style={{ background: 'white' }} className="borderLeft">
      {
        Object.keys(gst_profile_details).map((gstAnalysis, index) => {
          return (

            <Fragment key={index}>
              {insideCurrent === gstAnalysis &&

                <div className="row">
                  <div className="col-lg-12 bg">

                    {/* <div>
                <div className="col-md-4 pl-4 pt-3 pb-3">
                  <p className="Gst-head">{gst_profile_details[gstAnalysis].is_primary ? "Primary Applicant's" : "GSTIN"}:  &nbsp;{gstUser.profile.gstin}</p>
                </div>
              </div> */}
                    <div>
                      <div className="col-md-4 pl-4 pt-3 pb-3">
                        <p className="Gst-head">Entity Profile</p>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Legal Name of Business</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.legal_business_name ? gstUser.profile.legal_business_name : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Trade Name</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.trade_name ? gstUser.profile.trade_name : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Status</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div  className={"col-md-12 Gst-text Gst-status" + (gstUser.profile.is_active === true ? 'Green' : 'Red')}>{gstUser.profile.is_active === true ? "Active" : "Inactive"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Business Category</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.business_category ? gstUser.profile.business_category : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Company ID</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.company_id ? gstUser.profile.company_id : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Compliance Rating</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.compliance_rating ? gstUser.profile.compliance_rating : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3">
                        <p className="Gst-text">Authorized signatories</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.authorised_signatories ? gstUser.profile.authorised_signatories : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Nature Of Business</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.nature_of_business ? gstUser.profile.nature_of_business : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Taxpayer Name</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.taxpayer_name ? gstUser.profile.taxpayer_name : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Taxpayer Type</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.taxpayer_type ? gstUser.profile.taxpayer_type : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Compliance Rating</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.compliance_rating ? gstUser.profile.compliance_rating : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3">
                        <p className="Gst-text">State Jurisdiction Code</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.state_juridiction_code ? gstUser.profile.state_juridiction_code : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">State Jurisdiction</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.state_juridiction ? gstUser.profile.state_juridiction : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Central Jurisdiction Code</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.central_juridiction_code ? gstUser.profile.central_juridiction_code : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Cental Jurisdiction</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.central_juridiction ? gstUser.profile.central_juridiction : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Date Of Registration</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.registration_date ? gstUser.profile.registration_date : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3 ">
                        <p className="Gst-text">Date of Cancellation</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.date_of_cancelation ? gstUser.profile.date_of_cancelation : "-"}</div>
                      </div>
                    </div>
                    <div className="Gst-flex bordertop707070">
                      <div className="col-md-4 pl-4 pt-3 pb-3">
                        <p className="Gst-text">Last Update</p>
                      </div>
                      <div className="col-md-6 pt-3 pr-0 pt-3 pb-3">
                        <div className="col-md-12 Gst-text">{gstUser.profile.last_update ? gstUser.profile.last_update : "-"}</div>
                      </div>
                    </div>



                    <div className="primaryBusiness">
                      <div className="col-md-12 pt-4 pl-4 pb-4 Gst-text-main">Primary Business Address</div>
                    </div>


                    <div className="col-md-12 p-4">
                      <p className="regadd py-2">Registered Address</p>
                      <p className="viewData">{gstUser.profile.address ? gstUser.profile.address : "-"}</p>
                    </div>


                    <div className="col-md-12 pl-2 pb-3 d-flex">

                      <div className="col-md-3">
                        <p className="regadd py-2"> Nature Of Business</p>
                        <p className="viewData">{gstUser.profile.nature_of_business ? gstUser.profile.nature_of_business : "-"}</p>
                      </div>
                      <div className="col-md-3">
                        <p className="regadd py-2">Mobile Number</p>
                        <p className="viewData">{gstUser.profile.contact_mobile_number ? gstUser.profile.contact_mobile_number : "-"}</p>
                      </div>
                      <div className="col-md-3">
                        <p className="regadd py-2">Registered Email</p>
                        <p className="viewData">{gstUser.profile.contact_email ? gstUser.profile.contact_email : "-"}</p>
                      </div>
                      <div className="col-md-3">
                        <p className="regadd py-2">Last Updates</p>
                        <p className="viewData">{gstUser.profile.last_update ? gstUser.profile.last_update : "-"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Fragment>
          )
        })}
      <div className="gstMain">
        <div className="gstmainHeading">| Top 5 customers</div>
        <div>
          {yearly_top_suppliers_purchasers && yearly_top_suppliers_purchasers.map((top5Data) =>
            <div key={"customer" + top5Data.financial_year}>
              <div className="gstyear col-md-3">{top5Data.financial_year}</div>
              <div>
                <div className="gstFlex">
                  {coloumName && coloumName.map((coloumNameData) =>
                    <div className="gstHeading" key={"customer1" + coloumNameData}>{coloumNameData}</div>
                  )}
                </div>
                <div className="headingData" >
                  {top5Data.purchasers.map((purchasersData) =>
                    <div className="gstFlex">
                      {coloumName && coloumName.map((coloumNameData) =>
                        <>
                          {purchasersData[coloumNameData] !== undefined &&
                            <>
                              {coloumNameData !== "turnover" ?
                                <div className="gstHeadingData" key={"customer3" + coloumNameData}>{purchasersData[coloumNameData]}</div>
                                :
                                <div className="gstHeadingData" key={"customer3" + coloumNameData}>{indianrsConvertr(purchasersData[coloumNameData])}</div>
                              }
                            </>
                          }

                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>


        <div className="gstmainHeading">| Top 5 suppliers</div>
        <div>
          {yearly_top_suppliers_purchasers && yearly_top_suppliers_purchasers.map((top5Data) =>
            <div key={"suppliers" + top5Data.financial_year}>
              <div className="gstyear col-md-3">{top5Data.financial_year}</div>
              <div>
                <div className="gstFlex">
                  {coloumName && coloumName.map((coloumNameData) =>
                    <div className="gstHeading" key={"customer1" + coloumNameData}>{coloumNameData}</div>
                  )}
                </div>
                <div className="headingData" >
                  {top5Data.suppliers.map((purchasersData) =>
                    <div className="gstFlex">
                      {coloumName && coloumName.map((coloumNameData) =>
                        <>
                          {purchasersData[coloumNameData] !== undefined &&
                            <>
                              {coloumNameData !== "turnover" ?
                                <div className="gstHeadingData" key={"customer3" + coloumNameData}>{purchasersData[coloumNameData]}</div>
                                :
                                <div className="gstHeadingData" key={"customer3" + coloumNameData}>{indianrsConvertr(purchasersData[coloumNameData])}</div>
                              }
                            </>
                          }
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="gstmainHeading">| Monthly Sales</div>
        <div>
          {yearly_sales_purchases && yearly_sales_purchases.map((top5DataList, index) =>
            <div key={"purchase" + top5DataList.financial_year}>
              <div className="gstyear col-md-3">{top5DataList.financial_year}</div>
              <div>
                <div className="gstFlex gstheading2">
                  <div className="gstheading2Inner">Month</div>
                  {coloumSales.length !== 0 && coloumSales[index].map((coloumNameData) =>
                    <div className="gstheading2Gst" key={"purchase1" + coloumNameData}>
                      <div className="gstheading2GstInner1">
                        {coloumNameData}
                      </div>
                      <div className="gstFlex">
                        <div className="gstheading2GstInner2">Invoice value</div>
                        <div className="gstheading2GstInner2">Taxable value</div>
                      </div>
                    </div>
                  )}
                </div>
                {top5DataList.monthly_sales.map((purchasersData) =>
                  <div className="gstFlex gstheadingData">
                    <div className={purchasersData.month.indexOf('Total') !== -1 ? "gstheadingData1 txtBold" : "gstheadingData1"}>{purchasersData.month}</div>
                    {coloumSales.length !== 0 && coloumSales[index].map((coloumNameData) =>
                      <>
                        {purchasersData.transactions[coloumNameData] &&
                          <>
                            <div className="gstheadingData2" key={"purchase3" + coloumNameData}>{purchasersData.transactions[coloumNameData].total_invoice_value !== "0" && purchasersData.transactions[coloumNameData].total_invoice_value !== null && purchasersData.transactions[coloumNameData].total_invoice_value !== "" ? indianrsConvertr(purchasersData.transactions[coloumNameData].total_invoice_value) : "-"}</div>
                            <div className="gstheadingData2" key={"purchase4" + coloumNameData}>{purchasersData.transactions[coloumNameData].total_tax_value !== "0" && purchasersData.transactions[coloumNameData].total_tax_value !== null && purchasersData.transactions[coloumNameData].total_tax_value !== "" ? indianrsConvertr(purchasersData.transactions[coloumNameData].total_tax_value) : "-"}</div>
                          </>
                        }
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="gstmainHeading">| Monthly Purchases</div>
        <div>
          {yearly_sales_purchases && yearly_sales_purchases.map((top5DataList, index) =>
            <div key={"purchase" + top5DataList.financial_year}>
              <div className="gstyear col-md-3">{top5DataList.financial_year}</div>
              <div>
                <div className="gstFlex gstheading2">
                  <div className="gstheading2Inner">Month</div>
                  {coloumPurchase.length !== 0 && coloumPurchase[index].map((coloumNameData) =>
                    <div className="gstheading2Gst" key={"purchase1" + coloumNameData}>
                      <div className="gstheading2GstInner1">
                        {coloumNameData}
                      </div>
                      <div className="gstFlex">
                        <div className="gstheading2GstInner2">Invoice value</div>
                        <div className="gstheading2GstInner2">Taxable value</div>
                      </div>
                    </div>
                  )}
                </div>
                {top5DataList.monthly_purchases.map((purchasersData) =>
                  <div className="gstFlex gstheadingData">
                    <div className={purchasersData.month.indexOf('Total') !== -1 ? "gstheadingData1 txtBold" : "gstheadingData1"}>{purchasersData.month}</div>
                    {coloumPurchase.length !== 0 && coloumPurchase[index].map((coloumNameData) =>
                      <>
                        {purchasersData.transactions[coloumNameData] &&
                          <>
                            <div className="gstheadingData2" key={"purchase3" + coloumNameData}>{purchasersData.transactions[coloumNameData].total_invoice_value !== "0" && purchasersData.transactions[coloumNameData].total_invoice_value !== null && purchasersData.transactions[coloumNameData].total_invoice_value !== "" ? indianrsConvertr(purchasersData.transactions[coloumNameData].total_invoice_value) : "-"}</div>
                            <div className="gstheadingData2" key={"purchase4" + coloumNameData}>{purchasersData.transactions[coloumNameData].total_tax_value !== "0" && purchasersData.transactions[coloumNameData].total_tax_value !== null && purchasersData.transactions[coloumNameData].total_tax_value !== "" ? indianrsConvertr(purchasersData.transactions[coloumNameData].total_tax_value) : "-"}</div>
                          </>
                        }
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>

  )
}

export default GST;