import React, { useState, useEffect } from 'react';
import './ruleResult.scss';
import { Button, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';

const RoleResult = ({ rule, comment,id }) => {  
  const [popoverOpen, setPopoverOpen] = useState(false);
  
   const toggle = () => setPopoverOpen(!popoverOpen);

  let class1 = "";
  let class2 = "";
  let class3 = "";

  let rule1 = rule !== null && rule !== "" ? String(rule).toLowerCase().trim() : rule;

  if(rule1 === 'met' || rule1 === 'low risk' || rule1 === 'high risk' || rule1 === 'medium risk' || rule1 === "unmet") {

    if(rule1 === 'unmet' || rule1 === 'high risk') {
      if(comment && comment.length > 0) {
        class1 = "d-flex is-unmet";
      }
      else {
       class1 = "is-unmet"
      }
    }
    else if(rule1 === 'medium risk') {
      class1= "is-metunmet"
    } 
    else {
       class1= "is-met"
    }
  }
  else {
    class1 ='is-default'
  }

  if(rule1 === 'met' || rule1 === 'low risk' || rule1 === 'high risk' || rule1 === 'medium risk' || rule1 === "unmet") {
      if(rule1 === 'met' || rule1 === 'low risk') {
        class2 = "is-met-size" 
      }
      else if(rule1 === 'medium risk') {
        class1= "is-metunmet-size"
      } 
      else {
          if(comment && comment.length > 0) {
            class2 = "is-unmet-size2 p-1"
          }
          else {
            class2 ="is-unmet-size"
          }
      }
  } 
  else {
    class2 = "is-default-size"
  }

  if(comment && comment.length > 0) {
    class3 = "unmet-icon";
  }

  return (

    <td className={class1}>

      <div className={class2}>
      <span className={class3+" text-capitalize"}>
        {rule !== "" && rule !== null && rule !== undefined  ? rule : "N/A" }
      </span>
        {comment && comment.length > 0 ?
          <>
            <UncontrolledPopover className="popover" trigger="legacy" placement="bottom" target={"PopoverClick-" + id}>
              {comment.map((cmtData,cmtIndex)=>(    
                <>
                <PopoverHeader >{cmtData.comment_by}
                  <div style={{ fontSize: "10px" }}>{cmtData.comment_at}</div>
                </PopoverHeader>
                <PopoverBody>{cmtData.comment}</PopoverBody>
                </>
              ))}
            </UncontrolledPopover>
            <span className="pointer float-right " type="button" id={"PopoverClick-" + id}><img src="/images/cam_version_3/Group-2391.png" width="28px" /></span>
          </>
          : ""}
      </div>

    </td>

  )
}
export default RoleResult;