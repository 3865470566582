import React, { Component } from 'react';

import CpvReport from './containers/CpvReport';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Banking from './containers/Banking';
import Cam from './containers/Cam';
import RiskCompliances from './containers/RiskCompliances';
import GstRuleEngine from './containers/GstRuleEngine';
import MLScore from './containers/MLScore';
import NewCam from './containers/newCam/newCam';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Route component={CpvReport} path='/cpv-report/:id?' />
          <Switch>
            <Route component={Banking} path="/banking/:id?" />
            <Route component={Cam} path="/cam/:id?" />
            <Route component={RiskCompliances} path="/risk-compliances" />
            <Route component={GstRuleEngine} path="/Gst-Rule-Engine" />
            <Route component={MLScore} path="/ml-score" />
            <Route component={NewCam} path="/cam-v3/:id?"/>
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
