import React, {useState,useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
// import { getRules } from '../../../../actions/AllActions';
import "./GST.scss";

const GSTRules = ({gstKey,error }) => {
    const [arrayKey,setArrayKey] = useState(null);
    const rulesData = useSelector(state => state.all.report);

    useEffect(() => {
        for(var data in rulesData?.attributes?.gst_profiles){
            if(gstKey === rulesData.attributes.gst_profiles[data].gstin){
                setArrayKey(data);
            }else{
                setArrayKey("");
            }
        }
    },[gstKey,rulesData,rulesData.id])

    const dataReplace = (data) =>  {

        if(data.rule.indexOf('%x') !== -1) {
                

            let dataS = data.rule.split('%x');
            let count = 0;
            var textReal = dataS[0];
            for(var item in dataS) {
                if(count > 0) {
                    textReal += '<div class="colorx">'+data.values[count-1] +"</div>"+ dataS[item];
                }
                count++;
            }
            return textReal;

        }
        else {
            return data.rule;
        }
    };

    return (
        <div className="col-md-12 maingst gst">
            <div className="py-2 tabPrint text-center text-uppercase">
                GST Rule
            </div>
            <div className="row m-0" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "0px 0px 30px" }}>
                <div className="col-md-12">
                {/* {!rulesData.attributes && !error[0] &&    
                        <div className="Loadergif pt-4">
                            <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                        </div>                 
                } */}
               {rulesData?.attributes?.gst_profiles[arrayKey]?.gst_rule_result !== undefined ? 
                    <div className="table-responsive">

                        <table className="overflow-hidden">
                            <thead>
                                <tr>
                                    <th className="tablehead font-weight-bold">Program Conditions</th>
                                    <th className="font-weight-bold text-center" style={{ width: "20%" }}>Rule result</th>
                                </tr>
                            </thead>

                            <tbody >
                                 
                                {rulesData?.attributes?.gst_profiles[arrayKey] && rulesData?.attributes?.gst_profiles[arrayKey]?.gst_rule_result.map((data, index) => (
                                <tr className="ruleclass">
                                    <td className="tablehead" dangerouslySetInnerHTML={{__html: dataReplace(data)}}></td>
                                     {data.result === true ?
                                      <td class="is-met"><div class="is-met-size"><span class="">Met</span></div></td>  
                                      : 
                                      <td class="is-unmet"><div class="is-unmet-size"><span class="">Unmet</span></div></td>
                                      }
                                </tr>
                                ))}
                                
                            </tbody>

                        </table>

                    </div>
                    :
                    <>
                        {error && error[0] && error[0]?.detail !== undefined ? error.map((data, i) => (
                            <div key={i} className="text-center py-2" style={{ color: "red" }}>
                                <div>{data.detail} !</div>
                            </div>
                            )) :
                            <div className="text-center py-2" style={{ color: "#6B788E"}}>
                                GST Rule Data is unavailable Currently.
                            </div>
                            }
                    </>
                                }
                </div>
            </div>


        </div>
    );
};
export default GSTRules;
