import { accountType } from "../shared/constants";

const validationBank = {
  ACCOUNT_NUMBER: {
    type: "number",
    id: "numberAccount",
    slug: "acc_number",
    title: "Enter Account Number",
    error: "Invalid Account Number",
    pattern: /^.{6,}$/,
    required: true,
    label: "Account Number "
  },
  ACCOUNT_TYPE: {
    type: "dropdown",
    id: "accountType",
    slug: "acc_type",
    title: "Select Account Type",
    error: "Select Account Type",
    pattern: /^[a-z]{2,}$/,
    options: accountType,
    required: true,
    label: "Account Type",
    selectedOption: { value: "", label: "Select Account Type" }
  },
  BANK_NAME: {
    id: "nameBank",
    type: "text",
    title: "Enter Bank Name",
    error: "Select Bank Name",
    pattern: /^[a-zA-Z0-9]{2,}[^]+$/,
    slug: "bank_name",
    autoCapitalize: "characters",
    disabled: true,
    required: true,
    label: "Bank Name *"
  }
};

export {
  validationBank
};
