import React, { useState, useEffect } from 'react';
import { Col, Card, Table } from 'reactstrap';
import Header from '../header/header';
import { updateData } from '../../../actions/AllActions';
import './creditManager.scss'
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import { returnError } from "../../../shared/common_logic";
import JoditEditor from "jodit-react";

const stripHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
};

const CreditManager = ({ props, camData, metaData }) => {
    const url = document.getElementById("frame_id") && document.getElementById("frame_id").src;
    const params = new URLSearchParams(window.location.search !== '' ? window.location.search : url );
    var source = params.get('source');
    const [isbureau_strength, setbureau_strength] = useState(false);
    const [isbusiness_consistency, setbusiness_consistency] = useState(false);
    const [isbusiness_experience, setbusiness_experience] = useState(false);
    const [isbusiness_need, setbusiness_need] = useState(false);
    const [isstability_and_ownership, setstability_and_ownership] = useState(false);
    const [other, setother] = useState(false);

    const [isbureau_strength2, setbureau_strength2] = useState(false);
    const [isbusiness_consistency2, setbusiness_consistency2] = useState(false);
    const [isbusiness_experience2, setbusiness_experience2] = useState(false);
    const [isbusiness_need2, setbusiness_need2] = useState(false);
    const [isstability_and_ownership2, setstability_and_ownership2] = useState(false);
    const [other2, setother2] = useState(false);

    const [edit, setEdit] = useState(true);
    const [errors, setErrors] = useState({});
    
    const config = {
        readonly: false,
        placeholder: '',
        toolbar: false,
        disablePlugins: 'add-new-line',
        statusbar: false,
        height: 'auto',
        allowResizeX: false,
        allowResizeY: false
    };

    const dispatch = useDispatch();
    const creditManagerComments = camData && camData.credit_manager_comments;
    
    const checkData = (data) => {
        if (data) {
            return data;
        } else {
            return null
        }
    }

    const checkDataM = (data) => {
        if (data && data.mintifi_comment !== undefined) {
            return data.mintifi_comment;
        }
        else if(data === null || data === "" || typeof data === "string") {
            return data;
        }
        else {
            return null
        }
    }

    const [data, setData] = useState({
        "bureau_strength": {
            "mintifi_comment": null,
            "lender_comment": null
        },
        "business_consistency": {
            "mintifi_comment": null,
            "lender_comment": null
        },
        "business_experience": {
            "mintifi_comment": null,
            "lender_comment": null
        },
        "business_need": {
            "mintifi_comment": null,
            "lender_comment": null
        },
        "stability_and_ownership": {
            "mintifi_comment": null,
            "lender_comment": null
        },
        "other": {
            "mintifi_comment": null,
            "lender_comment": null
        }
    })

    useEffect(() => {
        if(creditManagerComments) {
            setData({
                "bureau_strength": {
                    "mintifi_comment": checkDataM(creditManagerComments.business_need && creditManagerComments.bureau_strength),
                    "lender_comment": checkData(creditManagerComments.business_need && creditManagerComments.bureau_strength.lender_comment),
                },
                "business_consistency": {
                    "mintifi_comment": checkDataM(creditManagerComments.business_consistency && creditManagerComments.business_consistency),
                    "lender_comment": checkData(creditManagerComments.business_consistency && creditManagerComments.business_consistency.lender_comment)
                },
                "business_experience": {
                    "mintifi_comment": checkDataM(creditManagerComments.business_experience && creditManagerComments.business_experience),
                    "lender_comment": checkData(creditManagerComments.business_experience && creditManagerComments.business_experience.lender_comment)
                },
                "business_need": {
                    "mintifi_comment": checkDataM(creditManagerComments.business_need && creditManagerComments.business_need),
                    "lender_comment": checkData(creditManagerComments.business_need && creditManagerComments.business_need.lender_comment)
                },
                "stability_and_ownership": {
                    "mintifi_comment": checkDataM(creditManagerComments.stability_and_ownership && creditManagerComments.stability_and_ownership),
                    "lender_comment": checkData(creditManagerComments.stability_and_ownership && creditManagerComments.stability_and_ownership.lender_comment)
                },
                "other": {
                    "mintifi_comment": checkDataM(creditManagerComments.other && creditManagerComments.other),
                    "lender_comment": checkData(creditManagerComments.other && creditManagerComments.other.lender_comment)
                }
            });
        }        

    },[creditManagerComments]);

    const handleChange = (newContent, field, innerField) => {
        setData(prevState => ({
            ...prevState,
            [field]: { ...prevState[field], [innerField]: newContent }
        }));

        if(field === "other" && ((!other2 && source === "lender") || (!other && (source === "mintifi" || source === null)))){
            return
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [`${field}_${innerField}`]: ""
        }));
    };

    const updateComments = (data1) => {
        var commentData = {};
        commentData.credit_manager_comments = {};
        commentData.credit_manager_comments = data1;

        const newErrors = {};
        Object.entries(data1).forEach(([key, commentObj]) => {

            if (stripHtml(commentObj.mintifi_comment || "").length < 10 && (source === "mintifi" || source === null) && (key !== 'other' || other)) {
                newErrors[`${key}_mintifi_comment`] = "kindly mention at least 10 characters";
            }
            // if (stripHtml(commentObj.lender_comment || "").length < 10 && source === "lender") {
            //     newErrors[`${key}_lender_comment`] = "kindly mention at least 10 characters";
            // }
        });
        console.log(newErrors,'newErrors')
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }


        dispatch(updateData(commentData, props.match.params.id)).then(
            function (res) {
                let search = props.location.search;
                let params = new URLSearchParams(search);
                var newurl = window.location.origin + props.location.pathname + "?user_id=" + params.get('user_id') + "&source=" + source + "&version_id=" + res.version + "&token=" + params.get('token');
                confirmAlert({
                    title: '',
                    message: 'Cam updated successfully.',
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => window.location.href = newurl
                        }
                    ]
                });
            },
            function (err) {
                confirmAlert({
                    title: 'Error',
                    message: returnError(err),
                    buttons: [{ label: 'OK', onClick: null }]
                });
            }
        );
    };

    return (
        <>
            {camData.credit_manager_comments &&
                <>
                    <Col md="12 pb-4">
                        <Card>
                            <Header headerText="Credit Manager Comments" headerImg="/images/cam_version_3/message.svg" />
                        </Card>
                    </Col>
                    <Col md="12 pb-4" className="creditManager">
                        <Card className="p-4 card">
                            <Table bordered responsive className=" mb-0 overflow-hidden">
                                <thead>
                                    <tr>
                                        <th style={{ width: "24%" }}>Parameter</th>
                                        <th style={{ width: "38%" }}>Mintifi Comment
                                            {(source === "mintifi" || source === null) && (isbureau_strength === true || isbusiness_consistency === true || isbusiness_experience === true || isbusiness_need === true || isstability_and_ownership === true || other === true) ?
                                                <button className="btn btn-success float-right" onClick={() => updateComments(data)}>Update Comments</button>
                                                : ""
                                            }
                                        </th>
                                        <th style={{ width: "38%" }}>Lender Comment
                                            {source === "lender" && (isbureau_strength2 === true || isbusiness_consistency2 === true || isbusiness_experience2 === true || isbusiness_need2 === true || isstability_and_ownership2 === true || other2 === true) ?
                                                <button className="btn btn-success float-right" onClick={() => updateComments(data)}>Update Comments</button>
                                                : ""
                                            }
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Business Experience</td>
                                        <td>
                                            {isbusiness_experience ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.business_experience.mintifi_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "business_experience", "mintifi_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.business_experience.mintifi_comment || "-" }}/>
                                                    {(source === "mintifi" || source === null) &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setbusiness_experience(true)}><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {errors.business_experience_mintifi_comment && <div className="text-danger">{errors.business_experience_mintifi_comment}</div>}
                                        </td>
                                        <td>
                                            {isbusiness_experience2 ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.business_experience.lender_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "business_experience", "lender_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.business_experience.lender_comment || "-" }}/>
                                                    {source === "lender" &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setbusiness_experience2(true)} ><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {/* {errors.business_experience_lender_comment && <div className="text-danger">{errors.business_experience_lender_comment}</div>} */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Bureau Strength</td>
                                        <td>
                                            {isbureau_strength ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.bureau_strength.mintifi_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "bureau_strength", "mintifi_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.bureau_strength.mintifi_comment || "-" }}/>
                                                    {(source === "mintifi" || source === null) &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setbureau_strength(true)} ><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {errors.bureau_strength_mintifi_comment && <div className="text-danger">{errors.bureau_strength_mintifi_comment}</div>}
                                        </td>
                                        <td>
                                            {isbureau_strength2 ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.bureau_strength.lender_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "bureau_strength", "lender_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.bureau_strength.lender_comment || "-" }}/>
                                                    {source === "lender" &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setbureau_strength2(true)} ><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {/* {errors.bureau_strength_lender_comment && <div className="text-danger">{errors.bureau_strength_lender_comment}</div>} */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Business Consistency</td>
                                        <td>
                                            {isbusiness_consistency ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.business_consistency.mintifi_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "business_consistency", "mintifi_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.business_consistency.mintifi_comment || "-" }}/>
                                                    {(source === "mintifi" || source === null) &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setbusiness_consistency(true)}><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {errors.business_consistency_mintifi_comment && <div className="text-danger">{errors.business_consistency_mintifi_comment}</div>}
                                        </td>
                                        <td>
                                            {isbusiness_consistency2 ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.business_consistency.lender_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "business_consistency", "lender_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.business_consistency.lender_comment || "-" }}/>
                                                    {source === "lender" &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setbusiness_consistency2(true)}><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {/* {errors.business_consistency_lender_comment && <div className="text-danger">{errors.business_consistency_lender_comment}</div>} */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Business Need</td>
                                        <td>
                                            {isbusiness_need ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.business_need.mintifi_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "business_need", "mintifi_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.business_need.mintifi_comment || "-" }}/>
                                                    {(source === "mintifi" || source === null) &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setbusiness_need(true)}><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {errors.business_need_mintifi_comment && <div className="text-danger">{errors.business_need_mintifi_comment}</div>}
                                        </td>
                                        <td>
                                            {isbusiness_need2 ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.business_need.lender_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "business_need", "lender_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.business_need.lender_comment || "-" }}/>
                                                    {source === "lender" &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setbusiness_need2(true)}><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>

                                            }
                                            {/* {errors.business_need_lender_comment && <div className="text-danger">{errors.business_need_lender_comment}</div>} */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Stability and Ownership</td>
                                        <td>
                                            {isstability_and_ownership ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.stability_and_ownership.mintifi_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "stability_and_ownership", "mintifi_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.stability_and_ownership.mintifi_comment || "-" }}/>
                                                    {(source === "mintifi" || source === null) &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setstability_and_ownership(true)}><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {errors.stability_and_ownership_mintifi_comment && <div className="text-danger">{errors.stability_and_ownership_mintifi_comment}</div>}
                                        </td>
                                        <td>
                                            {isstability_and_ownership2 ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.stability_and_ownership.lender_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "stability_and_ownership", "lender_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.stability_and_ownership.lender_comment || "-" }}/>
                                                    {source === "lender" &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setstability_and_ownership2(true)}><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {/* {errors.stability_and_ownership_lender_comment && <div className="text-danger">{errors.stability_and_ownership_lender_comment}</div>} */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Other</td>
                                        <td>
                                            {other ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.other.mintifi_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "other", "mintifi_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.other.mintifi_comment || "-" }}/>
                                                    {(source === "mintifi" || source === null) &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setother(true)}><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {errors.other_mintifi_comment && <div className="text-danger">{errors.other_mintifi_comment}</div>}
                                        </td>
                                        <td>
                                            {other2 ?
                                                <>
                                                    <JoditEditor
                                                        config={config}
                                                        value={data.other.lender_comment || ""}
                                                        onBlur={newContent => handleChange(newContent, "other", "lender_comment")}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: data.other.lender_comment || "-" }}/>
                                                    {source === "lender" &&
                                                        <button className="btn btn-danger float-right btnExit" onClick={() => setother2(true)}><img src="/images/pencil.svg" /></button>
                                                    }
                                                </>
                                            }
                                            {/* {errors.other_lender_comment && <div className="text-danger">{errors.other_lender_comment}</div>} */}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </>
            }
        </>
    )
}
export default CreditManager;
