import React,{ useEffect, useState } from "react";
import './LoanEligible.scss';
import RoleResult from './ruleResult/ruleResult';


const Demographics = ({cam_rule_result}) => {
        
    return (
        <div className="col-md-12 LoanEligible">
            <div className="py-2 tabPrint text-center text-uppercase">
                Demographics
            </div>
            
            <div className="row m-0 card" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "10px 10px 30px" }}>
                <div className="col-md-12">
                <div className="table-responsive">
                
                    <table className="overflow-hidden">
                        <thead>
                            <tr>
                            <th className="tablehead font-weight-bold">Program Conditions</th>
                            <th className="font-weight-bold text-center">Rule Value</th>
                            <th className="font-weight-bold text-center">Rule Result</th>
                            </tr>
                        </thead>
                        <tbody >
                        {cam_rule_result && cam_rule_result.anchor_rules && cam_rule_result.anchor_rules.length > 0 ? 
                        <>
                        {cam_rule_result && cam_rule_result.anchor_rules.map((data,index)=>(
                        
                        <tr key={index}>
                            <td className="tablehead">{data.title}</td>
                            <td className="text-center">{data.value}</td>
                            <RoleResult rule={data.result} comment={data.comments} id={index}/>
                        </tr>
                       ))}
                       {cam_rule_result && cam_rule_result.demographics_rules.map((data,index)=>(
                        
                        <tr key={index}>
                            <td className="tablehead">{data.title}</td>
                            <td className="text-center">{data.value}</td>
                            <RoleResult rule={data.result} comment={data.comments} id={index}/>
                        </tr>
                        ))}
                        </>
                        :
                        <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr> 
                       }
                       </tbody>
                    </table>

                    </div>
                </div>
            </div>

          
        </div>
    );
};
export default Demographics;
