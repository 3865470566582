import React, { useEffect, useState } from 'react';
import { Col, Card,Row } from 'reactstrap';
import Header from '../header/header';
import './mintifiLEC.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getLoanEligibiltyCalculatorData } from '../../../actions/AllActions';
const MintifiLEC = ({camData, loanId}) => {


    const loan_eligibilty_calculator_data = useSelector(state => state.all.loan_eligibilty_calculator_data.data);
    const error = useSelector(state => state.all.error);
    const dispatch = useDispatch();   

    useEffect(() => {
        dispatch(getLoanEligibiltyCalculatorData(loanId));
    }, []);

    return (
        <>
            {/* <Col md="12 pb-4">
                <Card>
                    <Header headerText="Mintifi Loan Eligibility Calculator" headerImg="/images/cam_version_3/calculator.svg" />
                </Card>
            </Col>
            {camData && 
            <Col md="12 pb-4" className="mintifiLEC">
                <Card className="p-4 card">
                    <Row>
                        <Col className="mintifilecC bg py-4 mx-2">
                        <div className="text-center ">
                            <p className="p1">Origination Risk</p>
                            <p className="p2 text-capitalize">{loan_eligibilty_calculator_data?.attributes.origination_risk ? loan_eligibilty_calculator_data.attributes.origination_risk.toLowerCase().replace('risk','') : "-"}</p>
                            </div>    
                        </Col>
                        <Col className="mintifilecC bg py-4 mx-2">
                        <div className="text-center ">
                            <p className="p1">Transaction Risk</p> 
                            <p className="p2 text-capitalize">{loan_eligibilty_calculator_data && loan_eligibilty_calculator_data.attributes.transaction_risk !== null ? loan_eligibilty_calculator_data.attributes.transaction_risk.toLowerCase().replace('risk','') :"-" }</p>  
                            </div>   
                        </Col>
                        <Col className="mintifilecC bg py-4 mx-2">
                        <div className="text-center ">
                            <p className="p1">Overall Risk Multiplier</p>
                            <p className="p2">{loan_eligibilty_calculator_data && loan_eligibilty_calculator_data.attributes.overall_risk_multipler ? loan_eligibilty_calculator_data.attributes.overall_risk_multipler : "-"}</p>
                            </div>     
                        </Col>
                        <Col className="mintifilecC bg py-4 mx-2">
                        <div className="text-center ">
                            <p className="p1">Eligibility</p>
                            <p className="p2">{loan_eligibilty_calculator_data && (loan_eligibilty_calculator_data.attributes.eligibility || Number(loan_eligibilty_calculator_data.attributes.eligibility === 0))  ? <span>&#8377;</span> : "-"}{loan_eligibilty_calculator_data && loan_eligibilty_calculator_data.attributes.eligibility && Number(loan_eligibilty_calculator_data.attributes.eligibility).toLocaleString("en-IN")}</p> 
                            </div>    
                        </Col>
                        <Col className="mintifilecC bg py-4 mx-2">
                        <div className=" text-center ">
                            <p className="p1">Risk Adjusted Pricing</p>
                            <p className="p2">{loan_eligibilty_calculator_data?.attributes.risk_adjusted_pricing ? loan_eligibilty_calculator_data.attributes.risk_adjusted_pricing : "-"}</p> 
                            </div>    
                        </Col>
                    </Row>
                </Card>
            </Col>
} */}
        </>
    )
}
export default MintifiLEC;
