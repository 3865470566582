const GET_ELIGIBLE_BANK = 'GET_ELIGIBLE_BANK';
const GET_BANK_STATEMENT = 'GET_BANK_STATEMENT';
const GET_ANALYSIS_REPORTS = 'GET_ANALYSIS_REPORTS';
const POST_PARSE_STATEMENT = 'POST_PARSE_STATEMENT';
const POST_RE_ANALYSE_STATEMENT = 'POST_RE_ANALYSE_STATEMENT';
const POST_STATEMENT_REPORT = 'POST_STATEMENT_REPORT';
const SHOW_ALERT = "SHOW_ALERT";
const CHANGE_LOADER = "CHANGE_LOADER";
const FIELD_ALERT = "FIELD_ALERT";
const GET_CONTACT_POINT_REPORT = "GET_CONTACT_POINT_REPORT";
const GET_CREDIT_ASSESSMENT_REPORT = "GET_CREDIT_ASSESSMENT_REPORT";
const GET_DISBURSEMENT_REPORT = "GET_DISBURSEMENT_REPORT";
const INITIAL_LOADING = "INITIAL_LOADING";
const FAILED_LOADING = "FAILED_LOADING";
const GET_CAM_REPORT = "GET_CAM_REPORT";
const GET_RISK_COMPLAINCES = "GET_RISK_COMPLAINCES";
const POST_PARSE_STATEMENTS = 'POST_PARSE_STATEMENTS';
const GET_RULES = 'GET_RULES';
const POST_RULES = 'POST_RULES';
const GET_REPORT = 'GET_REPORT';
const POST_GST_POLICY = 'POST_GST_POLICY';
const GET_ML_SCORE = "GET_ML_SCORE";
const ENABLED_BANKING_INTEGRATIONS = "ENABLED_BANKING_INTEGRATIONS";
const GET_ANCHOR_MERCHANT_ELIGIBILITY_DATA = "GET_ANCHOR_MERCHANT_ELIGIBILITY_DATA";
const GET_ANCHOR_DATA = "GET_ANCHOR_DATA";
const GET_ITR_SUMMARY = "GET_ITR_SUMMARY";
const GET_CIBIL_RULE_ELIGIBILITY_DATA ="GET_CIBIL_RULE_ELIGIBILITY_DATA";
const GET_ITR_DETAILS ="GET_ITR_DETAILS";
const GET_CIBIL_SUMMARY = " GET_CIBIL_SUMMARY";
const GET_LOAN_ELIGIBILTY_CALCULATOR = "GET_LOAN_ELIGIBILTY_CALCULATOR";
const GET_BANKING_ANALYSIS = "GET_BANKING_ANALYSIS";
const GET_BANKING_DATA_SUMMARY = "GET_BANKING_DATA_SUMMARY";
const GET_GST_CAM_DATA = "GET_GST_CAM_DATA" ;
const GET_ANCHOR_INDUSTRY_SCOREBOARD = "GET_ANCHOR_INDUSTRY_SCOREBOARD";
const GET_TRIANGULATION_SUMMARY = "GET_TRIANGULATION_SUMMARY";
const GET_DOCUMENT_DETAILS = "GET_DOCUMENT_DETAILS";
const GET_LOAN_DETAILS = "GET_LOAN_DETAILS";
const SET_DEVIATION_COMMENT = "SET_DEVIATION_COMMENT";
const POST_DEVIATION_COMMENT = "POST_DEVIATION_COMMENT";
export {
  GET_ELIGIBLE_BANK,
  GET_BANK_STATEMENT,
  GET_ANALYSIS_REPORTS,
  POST_PARSE_STATEMENT,
  POST_RE_ANALYSE_STATEMENT,
  POST_STATEMENT_REPORT,
  SHOW_ALERT,
  CHANGE_LOADER,
  FIELD_ALERT,
  GET_CONTACT_POINT_REPORT,
  GET_CREDIT_ASSESSMENT_REPORT,
  GET_DISBURSEMENT_REPORT,
  INITIAL_LOADING,
  FAILED_LOADING,
  GET_CAM_REPORT,
  GET_RISK_COMPLAINCES,
  POST_PARSE_STATEMENTS,
  GET_RULES,
  POST_RULES,
  GET_REPORT,
  POST_GST_POLICY,
  GET_ML_SCORE,
  ENABLED_BANKING_INTEGRATIONS,
  GET_ANCHOR_MERCHANT_ELIGIBILITY_DATA,
  GET_ANCHOR_DATA,
  GET_ITR_SUMMARY,
  GET_CIBIL_RULE_ELIGIBILITY_DATA,
  GET_ITR_DETAILS,
  GET_CIBIL_SUMMARY,
  GET_LOAN_ELIGIBILTY_CALCULATOR,
  GET_BANKING_ANALYSIS,
  GET_BANKING_DATA_SUMMARY,
  GET_GST_CAM_DATA,
  GET_ANCHOR_INDUSTRY_SCOREBOARD,
  GET_TRIANGULATION_SUMMARY,
  GET_DOCUMENT_DETAILS,
  GET_LOAN_DETAILS,
  SET_DEVIATION_COMMENT,
  POST_DEVIATION_COMMENT
};
