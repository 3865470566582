import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'reactstrap';
import RoundProcess from './roundProcess'
import './summarySection.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getAnchorData } from '../../../actions/AllActions';
import Loader from '../../Loader/Loader';

function AnchorData({ camData,metaData,ID }) {

    const anchor_data = useSelector(state => state.all.anchor_data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAnchorData(ID));
    }, []);
   
    const ValueCheck = ( x) => {
        if(x !== null){
            
            var stringValue = String(x).toLowerCase();
            if(stringValue === "true") {
                return "Yes";
            }
            else if(stringValue === "false") {
                return "No";
            }
            else {
                return x;
            }

        } else {
            return "-";
        }
    }

    const TurnoverValue = ( six_month_avg, twelve_month_avg) => {
        if((six_month_avg && six_month_avg !==null) && (twelve_month_avg && twelve_month_avg!==null)){
          let turnover=parseInt((six_month_avg.replace(/[^0-9.]/g, "")/twelve_month_avg.replace(/[^0-9.]/g, "")),10);
            if(turnover > 0){
                return "Increasing";
            }else{
                return "Decreasing";
            }
        }else{
            return "-";
        }
    }    
    const BocData = {       
        name: "Anchor Transaction Data",
        icon: "/images/cam_version_3/Anchor_icon.svg",
        list: {
            "Vintage with Anchor in months":[metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel_vintage_with_anchor'),
                ValueCheck(anchor_data && anchor_data.data && anchor_data.data.attributes.vintage_with_anchor_in_months)],
            "Monthly purchases for last 12 months":[metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel_monthly_purchases_last_12_months'),
                ValueCheck(anchor_data && anchor_data.data && anchor_data.data.attributes.avarage_purchases_of_last_12_month)],
            "Monthly purchases for last 6 months":[metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel_monthly_purchases_last_6_months'),
                ValueCheck(anchor_data && anchor_data.data && anchor_data.data.attributes.avarage_purchases_of_last_6_month)],
            "Monthly purchases for last 3 months":[metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel_monthly_purchases_last_3_months'),
                ValueCheck(anchor_data && anchor_data.data && anchor_data.data.attributes.avarage_purchases_of_last_3_month)],
            "Outstanding amount as on date":[metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel_outstanding_amount_as_on_date'),
                ValueCheck(anchor_data && anchor_data.data && anchor_data.data.attributes.outstanding)],
            "Average sales for the last 3 months vs last 6 months ratio":[metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel_avg_sales_for_last_3_months_vs_6_months_ratio'),
                ValueCheck(anchor_data && anchor_data.data && anchor_data.data.attributes.last_three_to_six_month_ratio)],
            "Average sales for the last 6 months vs last 12 months ratio":[metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel_avg_sales_for_last_6_months_vs_12_months_ratio'),
                ValueCheck(anchor_data && anchor_data.data && anchor_data.data.attributes.last_twelve_to_six_month_ratio)],
            "Increase/decrease in turnover in 6 / 12 months":[metaData.program_details.program_band_enabled_cam_components.includes('anchor_data_panel_increase_decrease_turnover_in_6_12_months'),
                 TurnoverValue((anchor_data && anchor_data.data && anchor_data.data.attributes.avarage_purchases_of_last_6_month),(anchor_data && anchor_data.data && anchor_data.data.attributes.avarage_purchases_of_last_12_month))],
        },
        // score: camData && camData.transaction_risk && camData.transaction_risk.transaction_risk && camData.transaction_risk.transaction_risk.value !== null ?   camData.transaction_risk.transaction_risk.value : "N/A",
        hml: camData && camData.transaction_risk && camData.transaction_risk.transaction_risk && camData.transaction_risk.transaction_risk.result,
    }

    return (
        <Col md="12" lg="6" className="mb-3" >
            <Card className="summarySection">
                <Row className="summarySectionHead">
                    <Col md="12">
                        <Row className='align-items-center' style={{height:'90px'}}>
                            <Col md="5" sm="4">
                                <div className="float-left p-4 ">
                                    <div className="d-flex headerText">
                                        <img src={BocData.icon} />
                                        <p className="pl-2">{BocData.name}</p>
                                    </div>
                                </div>
                            </Col>                            
                            {/* <Col md="7" sm="8">
                                <div className="float-right">
                                    <div className=" d-flex flex-row-reverse bd-highlight">
                                        <RoundProcess width="70" height="70" scx="37%" scy="62%" x="50" y="45" score={BocData.score} hml={BocData.hml} />
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
                <hr className="mb-2 mt-0" />
                <Row >
                    <Col md="12">                            
                            {anchor_data && (anchor_data.data || anchor_data.error) &&
                             <div className="dataFont">
                                {Object.keys(BocData.list).map((key, i) => (
                                    <>
                                    {BocData.list[key][0] === true && 
                                        <p key={i} className="d-flex px-3 py-2 ">
                                            <span className="mr-auto cibilKey">{key}</span>
                                            <span className="cibilValue">{BocData.list[key][1]}</span>
                                     </p>
                                    }
                                    </>
                                ))
                                }
                            </div>
                            }                            
                    </Col>
                </Row>
                {anchor_data && anchor_data.loading &&
                    <div className="Loadergif">
                        <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                    </div>
                 } 
            </Card>
        </Col>
    )
}

export default AnchorData;
