import React, { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { getItrSummary } from '../../../../actions/AllActions';
import CriticalRatios from "./CriticalRatios";
import BalanceSheet from "./BalanceSheet";
import Profitability from "./Profitability";
import WorkingCapital from "./WorkingCapital";
import './ITR.scss';
import moment from "moment";
import { concat } from "async";


const ITR = ({ loanId, onDataLoad }) => {
    const arrYear = [1, 2, 3, 4, 5];
    let fiveYear = [];
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();
    const itrSummaryData = useSelector(state => state.all.itr_summary);
    
    const [currentFlag, SetcurrentFlag] = useState(0);
    const [flag, setFlag] = useState(false);

    arrYear.map((y) => {
        var year2 = moment().subtract({ y }, 'years').year();
        var yre = `${year2}-${year2 + 1}`;
        fiveYear.push(yre);
    })

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle1 = () => setDropdownOpen(prevState => !prevState);
    const [lastClicked, setLastClicked] = useState(fiveYear[4]);

    useEffect(() => {
        //if (Number(onDataLoad) === 5) {
            dispatch(getItrSummary(loanId, fiveYear[currentFlag]));
        //}
    }, [onDataLoad])

    useEffect(() => {
        if (lastClicked !== null && flag === true)
            dispatch(getItrSummary(loanId, lastClicked));
    }, [lastClicked]);

    useEffect(() => {
        if (flag === false) {
            if (itrSummaryData && itrSummaryData.itrError && currentFlag < 4) {
                var currentFlagTemp = currentFlag + 1;
                SetcurrentFlag(currentFlag + 1)
                dispatch(getItrSummary(loanId, fiveYear[currentFlagTemp]));
            }
            else if (itrSummaryData && itrSummaryData.itrFetching) {
                setFlag(true);
                if (lastClicked !== currentFlag) {
                    setLastClicked(fiveYear[currentFlag]);
                }
            }

        }
    }, [itrSummaryData]);
   
    const toggle = tab => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }
    const itrData = (data) => {
        if (data) {
            return data;
        }
        else {
            return "";
        }

    }

    const Comps = [
        <CriticalRatios criticalRatios={itrData(itrSummaryData.itrFetching && itrSummaryData.itrFetching.attributes && itrSummaryData.itrFetching.attributes.critical_ratio)} />,
        <Profitability lastClicked={lastClicked} profitability={itrData(itrSummaryData.itrFetching && itrSummaryData.itrFetching.attributes && itrSummaryData.itrFetching.attributes.profitability)} />,
        <BalanceSheet lastClicked={lastClicked} balanceSheet={itrData(itrSummaryData.itrFetching && itrSummaryData.itrFetching.attributes && itrSummaryData.itrFetching.attributes.balance_sheet)} />,
        <WorkingCapital lastClicked={lastClicked} working_capital={itrData(itrSummaryData.itrFetching && itrSummaryData.itrFetching.attributes && itrSummaryData.itrFetching.attributes.working_capital)} />
    ];

    const Tabsss = [
        {
            name: "Critical Ratios",
            component: Comps[0],
        },
        {
            name: "Profitability",
            component: Comps[1],

        },
        {
            name: "Balance Sheet",
            component: Comps[2],
        },
        {
            name: "Working Capital",
            component: Comps[3],
        },


    ];

    const fixItrData =(d)=>{
        setLastClicked(d)
    }  
    
    return (

        <div className="col-md-12 gstmain itr">
            <div className="py-2 tabPrint text-center text-uppercase">
                ITR
            </div>
            <div className="row m-0 card" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "30px 10px" }}>
                <div className="col-md-12">
                        {itrSummaryData.loading === false &&
                                <div className="text-center" >
                                    <label className="pb-3">Select Finacial Year</label>
                                    <Dropdown isOpen={dropdownOpen} toggle={toggle1} >
                                        <DropdownToggle caret>

                                            {lastClicked}
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                            {fiveYear.map((data, i) => (

                                                <DropdownItem key={i} onClick={() => fixItrData(data)}>F.Y. {fiveYear[i]}</DropdownItem>
                                            ))}

                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                        }
                        

                        {itrSummaryData.loading === false && itrSummaryData.itrFetching &&
                        <>
                        <br />
                        <Nav tabs style={{ border: "none" }}>
                            {
                                Tabsss.map((Tab, index) =>
                                (
                                    <NavItem key={index} className="pointer ">

                                        <NavLink
                                            className={`navLink p-3 ${activeTab === index ? 'active' : ""}`}
                                            onClick={() => { toggle(index); }}
                                        >
                                            {Tab.name}
                                        </NavLink>
                                    </NavItem>
                                )
                                )
                            }
                        </Nav>


                        {<TabContent activeTab={activeTab}>
                            {
                                Tabsss.map((Tab, index) =>
                                (
                                    <TabPane key={index} tabId={index}>

                                        <Row>
                                            {Tab.component}
                                        </Row>

                                    </TabPane>
                                )
                                )
                            }
                        </TabContent>}
                        </>
                        }
                                    
                        {itrSummaryData.loading === false && itrSummaryData.itrError && itrSummaryData.itrError.errors.map((data, i) => (
                            <div key={i} dangerouslySetInnerHTML={{ __html: `${data.detail} ` }} className="text-center py-5" style={{ color: "red" }}>

                            </div>
                        ))}

                        {itrSummaryData.loading &&
                            <div className="Loadergif pt-4">
                                <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                            </div>
                        } 
                        {currentFlag === 4 && itrSummaryData.loading === false && itrSummaryData.itrFetching === null &&
                            <div style={{ padding: "30px 10px", textAlign: "center", color: "#666c72" }}>No Itr Data found</div>
                        }
                </div>
            </div>
        </div>
    );
};
export default ITR;
