import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRules, postRules } from '../../actions/AllActions';
import './GstRuleEngine.css';

class GstRuleCreation extends Component {

    state = {
        rules: {},
        versionID: "",
        checkError: {},
        FixVersionId: ""
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { rules } = nextProps.all;
        if (Object.keys(rules).length) {
            this.setState({ rules, versionID: rules.attributes.versions.length, FixVersionId: rules.attributes.versions.length })
        }
    }

    componentDidMount() {
        this.props.getRules();
    }

    updateValues = (event, index, values, versionID) => {
        const re = /^[0-9\b]+$/;
        const rules = JSON.parse(JSON.stringify(this.state.rules));
        const versionIndex = rules.attributes.versions.findIndex(vd => vd.version_id === versionID);
        const valueIndex = rules.attributes.versions[versionIndex].rules[index].values.indexOf(values);
        rules.attributes.versions[versionIndex].rules[index].values[valueIndex] = +event.target.value;
        if (event.target.value === '' || re.test(event.target.value))
            this.setState({ rules })
    }

    submitRules = versionID => {
        const { error, rules: propsRule } = this.props.all;
        if (error && error.length)
            return

        const errors = {};
        const { rules } = this.state;
        const Rules = [];
        const allRules = [];
        const versions = rules.attributes.versions.find(vers => vers.version_id === versionID);
        const propsVersions = propsRule.attributes.versions.find(vers => vers.version_id === versionID);
        versions.rules.map(r => allRules.push({ rule_id: r.id, values: r.values }))
        propsVersions.rules.map(rule => Rules.push({ rule_id: rule.id, values: rule.values }))

        const isChange = allRules.map((rule, i) => {
            return rule.values.every((value, j) => {
                return value === Rules[i].values[j]
            })
        })

        if (isChange.some(c => c === false))
            errors['change_rules'] = "";
        else
            errors['change_rules'] = "No Change In Rules";

        if (Object.values(errors).some(s => s !== "")) {
            this.setState({ checkError: errors })
            return
        }

        const updatedRules = {
            data: {
                type: "gst_credit_policy_rules",
                attributes: {
                    loan_application_id: rules.id,
                    rules: allRules
                }
            }
        }
        this.props.postRules(updatedRules)
        this.setState({ checkError: {} })
    }

    render() {
        const { error } = this.props.all;
        const { rules, versionID, checkError, FixVersionId } = this.state;
        return (
            <div >
                <div className="p-3 " style={{ background: "#9e9e9e0f" }}>
                    <img src="https://blog.mintifi.com/wp-content/uploads/2021/07/logo.png" alt="logo" width="150px" />
                </div>
                <div className="pl50">

                   
                    <header className="p-4">
                        <div className="heading pt-2 pl-2 ">
                            <p>GST RULE ENGINE</p>
                        </div>
                    </header>
                    {
                        error && error.length ? error.map(err => {
                            return (
                                <div key={err} style={{ textAlign: 'center', position: 'relative', bottom: 45 }}>
                                    {
                                        <div key={err} className="col-md-12 d-flex mb-2">
                                            <div className="col-md-4 m-auto">
                                                <span className="col-md-4 m-auto" style={{ color: "crimson", fontSize: "22px" }}>
                                                    {err.detail}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        }) : null
                    }
                    {
                        checkError && Object.keys(checkError).length && !('change_rules' in checkError) ? Object.keys(checkError).map(err => {
                            return (
                                <div key={err} style={{ textAlign: 'center', position: 'relative', bottom: 45 }}>
                                    {
                                        <div key={err} className="col-md-12 d-flex mb-2">
                                            <div className="col-md-4 m-auto">
                                                <span className="col-md-4 m-auto" style={{ color: "crimson", fontSize: "22px" }}>
                                                    {checkError[err]}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        }) : null
                    }
                    <>
                    {versionID!==""?
                    <div className="d-flex">
                        
                        <div className="div-left">
                            <div className="div-left-content">

                                <div className="d-flex title-content">
                                    <h5 className="m-0">SNo</h5>
                                    <h5 className="m-0">GST Rule</h5>
                                </div>
                                {
                                    Object.keys(rules).length ? rules.attributes.versions.map((version, i) => {
                                        if (version.version_id === versionID)
                                            return version.rules.map((rule, index) => {
                                                const chars = rule.rule.split('%x').filter(f => f !== "");
                                                return (
                                                    <div key={version.version_id + '-' + index} className="d-flex gst-row align-items-center">
                                                        <p className="pr50 m-0">{rule.id}</p>
                                                        <div className="d-flex align-items-center gst-data">
                                                            {
                                                                chars.map((char, j) => <div key={version.version_id + '-' + j} className="row m-0"><p>{char}{char.endsWith(" ") ? <input type="text" value={rule.values[j]} className={!(rules.attributes.versions.length - 1 === i) ? "text-cursor" : ""} readOnly={!(rules.attributes.versions.length - 1 === i)} onChange={event => this.updateValues(event, index, rule.values[j], version.version_id)} placeholder="Enter no. of month" /> : null}</p></div>)
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                    }) : null
                                }
                            </div>
                            <div className="update-btn">
                                {
                                    Object.keys(rules).length ? rules.attributes.versions.map((version, i) => {
                                        if (version.version_id === FixVersionId && versionID === FixVersionId)
                                            return <button onClick={() => this.submitRules(versionID)}>UPDATE</button>
                                    }) : null
                                }
                            </div>
                            {
                                checkError && checkError['change_rules'] !== undefined ?
                                    <div style={{ textAlign: 'center', position: 'relative', bottom: 80 }}>
                                        {
                                            <div className="col-md-12 d-flex">
                                                <div className="col-md-4 m-auto">
                                                    <span className="col-md-4 m-auto" style={{ color: "crimson", fontSize: "22px" }}>
                                                        {checkError['change_rules']}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                        
                        <div className="div-right">
                            <p className="version-blank m-0"></p>
                            {
                                Object.keys(rules).length ? rules.attributes.versions.map(version => <p key={version.version_id} onClick={() => this.setState({ versionID: version.version_id })} className={`${version.version_id === versionID ? "version1" : "version2"} m-0`}>Version {version.version_id}</p>) : null
                            }
                        </div>
                    </div>
                    :null}
                    </>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => ({
    all: state.all
});

const mapDispatchToProps = {
    getRules,
    postRules
};

export default connect(mapStateToProps, mapDispatchToProps)(GstRuleCreation);