import React, { useState } from 'react';
import './CamSummary.css';
import { updateData } from '../../actions/AllActions';
import Comments from '../Comments/Comments';
import Loader from '../Loader/Loader';
import { useDispatch,useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  rupeeDesign,
  checkData,
  returnError
} from "../../shared/common_logic";

const CamSummary = ({ camReport, error,loanID }) => {
  const [show, setShow] = useState(false);
  const [DemographicsImageId, setDemographicsImageId] = useState();
  const [DemographicsCommentBox, setDemographicsCommetBox] = useState();

  const [AnchorImageId, setAnchorImageId] = useState();
  const [AnchorCommentBox, setAnchorCommetBox] = useState();

  const [BureauImageId, setBureauImageId] = useState();
  const [BureauCommentBox, setBureauCommetBox] = useState();

  const [BankingImageId, setBankingImageId] = useState();
  const [BankingCommentBox, setBankingCommetBox] = useState();

  const [breif, setBreif] = useState('');
  const [startF, setStartF] = useState(false);
  const dispatch = useDispatch();
  const isEditable = useSelector(state => state.all && state.all.cam_report && state.all.cam_report.meta && state.all.cam_report.meta.is_editable);
  const changeText = (e) => {
    setBreif(e.target.value);
  }

  const checkComments = () => {
    if(breif === "" || breif === null) {
      return true;
    }

    return false;
  }

  const updateComments = () => {
    var data = {summary: {}};
    data.summary.proposal_brief = breif;
    dispatch(updateData(data,loanID)).then(
      function(res) {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        var newurl = window.location.origin+window.location.pathname+"?user_id="+params.get('user_id')+"&version_id="+res.version+"&token="+params.get('token');
        confirmAlert({
          title: '',
          message: 'Cam updated successfully.',
          buttons: [
            {
              label: 'OK',
              onClick: () => window.location.href = newurl
            }
          ]
        });

      }
      ,function(err) {
        confirmAlert({
          title: 'Error',
          message: returnError(err),
          buttons: [
            {
              label: 'OK',
              onClick: null
            }
          ]
        });
      });
  }
  
  if (camReport && Object.keys(camReport).length == 0) {
    return <Loader />;
  }
  if (error && Object.keys(error).length) {
    return <span style={{ color: "crimson", fontSize: "16px" }}>Request failed </span>
  }
  const { summary, cam_rule_result, transaction_risk } = camReport;
  if(!startF) {
    setStartF(true);
    setBreif(checkData(summary.proposal_brief));
  }
  const { address_1 = "", address_2 = "", city = "", state = "", pincode = "" } = summary && summary.business_address;
  return (
    <div style={{ background: 'white' }} className="borderLeft">
      {
        summary && Object.keys(summary).length &&
        <>
          <div className="Cam-flex bordertop707070">
            <div className="col-md-4 pl-4 pt-3 pb-3 textFontMain">
              Parameter
            </div>
            <div className="col-md-7 pt-3 pb-3 textFontMain">
              Value
            </div>
          </div>
          <div className="Cam-flex">
            <div className="col-md-3 mt-1 mb-5 pl-4 pt-3 pr-0 pb-5">
              <p className='textFont pt-4'>Brief about the Proposal</p>
            </div>
            <div className="col-md-8 mt-1 mb-5 pt-3 borderleft707070">
              <div className="col-md-12 pt-4 labelrightText">
              <textarea rows="5" readOnly={!isEditable} className="inputEditDiv px-3 py-3" name="stability_and_ownership" onChange={changeText} value={breif} ></textarea>
              </div>
              {isEditable &&
              <div className="col-md-12 pt-4 pb-5 labelrightText text-right">
                 <button className="updateBtn Comments" disabled={checkComments()} onClick={()=> updateComments() }>Update Brief</button>
              </div>
              }
            </div>
          </div>
          <div className="Cam-flex bordertop707070">
            <div className="col-md-4 pl-4 pt-3 pb-3">
              <p className='textFont'>Application Id</p>
            </div>
            <div className="col-md-7 pt-3 pb-3">
              <div className="col-md-12 textFont">{summary.application_id}</div>
            </div>
          </div>
          <div className="Cam-flex bordertop707070">
            <div className="col-md-4 pl-4 pt-3 pb-3">
              <p className='textFont'>Business Entity name</p>
            </div>
            <div className="col-md-7 pt-3 pb-3">
              <div className="col-md-12 textFont">{summary.business_entity_name}</div>
            </div>
          </div>
          <div className="Cam-flex bordertop707070">
            <div className="col-md-4 pl-4 pt-3 pb-3">
              <p className='textFont'>Business PAN</p>
            </div>
            <div className="col-md-7 pt-3 pb-3">
              <div className="col-md-12 textFont">{summary.business_pan}</div>
            </div>
          </div>
          <div className="Cam-flex bordertop707070">
            <div className="col-md-4 pl-4 pt-3 pb-3">
              <p className='textFont'>Business Entity</p>
            </div>
            <div className="col-md-7 pt-3 pb-3">
              <div className="col-md-12 textFont">{summary.business_entity_constitution}</div>
            </div>
          </div>
          <div className="Cam-flex bordertop707070">
            <div className="col-md-4 pl-4 pt-3 pb-3">
              <p className='textFont'>No of Partners / Directors</p>
            </div>
            <div className="col-md-7 pt-3 pb-3">
              <div className="col-md-12 textFont">{summary.number_of_partners}</div>
            </div>
          </div>
          <div className="Cam-flex bordertop707070">
            <div className="col-md-4 pl-4 pt-3 pb-3">
              <p className='textFont'>Proposal Amount</p>
            </div>
            <div className="col-md-7 pt-3 pb-3">
              <div className="col-md-12 textFont">{rupeeDesign(summary.requested_loan_amount)}</div>
            </div>
          </div>
          <div className="Cam-flex bordertop707070">
            <div className="col-md-4 pl-4 pt-3 pb-3">
              <p className='textFont'>Anchor</p>
            </div>
            <div className="col-md-7 pt-3 pb-3">
              <div className="col-md-12 textFont">{summary.anchor_name}</div>
            </div>
          </div>
          <div className="Cam-flex bordertop707070">
            <div className="col-md-4 pl-4 pt-3 pb-3">
              <p className='textFont'>Business Address</p>
            </div>
            <div className="col-md-7 pt-3 pb-3">
              <div className="col-md-12 textFont">{address_1 + address_2 + city + state + pincode}</div>
            </div>
          </div>
          <div className="Cam-flex bordertopBottom707070">
            <div className="col-md-4 pl-4 pt-3 pb-3">
              <p className='textFont'>Property status - business (In Years)</p>
            </div>
            <div className="col-md-7 pt-3 pb-3">
              <div className="col-md-12 textFont">{summary.business_address.years_in_address}</div>
            </div>
          </div>          
        </>
      }
      {
        cam_rule_result && Object.keys(cam_rule_result).length &&
        <div>
          <div className="Cam-flex Cam-Summary-header pt-4">
            <div className="col-md-7 Cam-Summary-header-text">Program conditions</div>
            <div className="col-md-2 Cam-Summary-header-text">Rule Value</div>
            <div className="col-md-2 Cam-Summary-header-text">Rule Result</div>
          </div>
          {cam_rule_result.hasOwnProperty('anchor_rules') && cam_rule_result.anchor_rules.length ?
            <div className="Cam-flex">
              <div className="col-md-4 mAuto Cam-Summary-left-content-text">Details of dealing <p className="mb-0">Anchor</p></div>
              <div className="col-md-8 p-0 Cam-Summary-middle-background">
                {cam_rule_result.anchor_rules.map((anchorRules, index) => {
                  return (
                    <div className={index > 0 ? "Cam-flex borderTopDFE8F0": "Cam-flex"} key={index}>
                      <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">{anchorRules.title}</div>
                      {/* <div className="col-md-3 pt-4 pb-4 Cam-Summary-content-text"><p className="mb-0">{anchorRules.content}</p></div> */}
                      <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">
                        <div className="col-md-12">{anchorRules.value}</div>
                      </div>
                      <div className={`col-md-4 pt-4 pb-4 Cam-Summary-content-text posRelative d-flex ${anchorRules['comments'] in anchorRules && anchorRules.comments.length && "cam-bgColor"}`}>
                        {
                          anchorRules['comments'] in anchorRules && anchorRules.comments.length ? <div className="topRightCaret"></div> : <></>
                        }
                        <div className={anchorRules.result == 'Met' ? 'Cam-Summary-Met Cam-Summary-Met-Center pt-2' : 'Cam-Summary-UnMet Cam-Summary-UnMet-Center pt-2'}>{anchorRules.result}</div>
                        {
                          anchorRules['comments'] in anchorRules && anchorRules.comments.length ? <span>
                            <img onClick={() => (setAnchorImageId(index), setShow(!show))} className="pl-5 Cam-Summary-show-deviation" src='/images/Group-2391.png' alt="icon" />
                            {
                              AnchorImageId == index && AnchorCommentBox != index && show && <div className='mrgn20 commentBox'>
                                {
                                  anchorRules.comments.map((comment, index) => <Comments comment={comment} key={index} />)
                                }
                              </div>
                            }
                          </span> : null
                        }
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>
            : null}
          <div className='Cam-Summary-top' />
          {cam_rule_result.hasOwnProperty('demographics_rules') && cam_rule_result.demographics_rules.length ?
            <div className="Cam-flex">
              <div className="col-md-4 mAuto Cam-Summary-left-content-text">Demographics</div>
              <div className="col-md-8 p-0">
                {cam_rule_result.demographics_rules.map((demographicsRules, index) => {
                  return (
                    <div className={index > 0 ? "Cam-flex borderTopDFE8F0": "Cam-flex"} key={index}>
                      <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">{demographicsRules.title}</div>
                      {/* <div className="col-md-3 pt-4 pb-4 Cam-Summary-content-text"><p className="mb-0">{demographicsRules.content}</p></div> */}
                      <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">
                        <div className="col-md-12">{demographicsRules.value}</div>
                      </div>
                      <div className={`col-md-4 pt-4 pb-4 Cam-Summary-content-text posRelative d-flex ${demographicsRules.hasOwnProperty('comments') && demographicsRules.comments.length && "cam-bgColor"}`}>
                        {
                          demographicsRules.hasOwnProperty('comments') && demographicsRules.comments.length ? <div className="topRightCaret"></div> : <></>
                        }
                        <div className={demographicsRules.result == 'Met' ? 'Cam-Summary-Met Cam-Summary-Met-Center pt-2' : 'Cam-Summary-UnMet Cam-Summary-UnMet-Center pt-2'}>{demographicsRules.result}</div>
                        {
                          demographicsRules.hasOwnProperty('comments') && demographicsRules.comments.length ? <span> <img onClick={() => (setDemographicsImageId(index), setShow(!show))} className="pl-5 Cam-Summary-show-deviation" src='/images/Group-2391.png' alt="icon" />
                            {
                              DemographicsImageId == index && DemographicsCommentBox != index && show && <div className='mrgn20 commentBox'>
                                {
                                  demographicsRules.comments.map((comment, index) => <Comments comment={comment} key={index} />)
                                }
                              </div>
                            }
                          </span> : null
                        }
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>
            : null}
          <div className='Cam-Summary-top' />
          {cam_rule_result.hasOwnProperty('bureau_rules') && cam_rule_result.bureau_rules.length ?
            <div className="Cam-flex">
              <div className="col-md-4 mAuto Cam-Summary-left-content-text">Bureau</div>
              <div className="col-md-8 p-0">
                {cam_rule_result.bureau_rules.map((bureauRules, index) => {
                  return (
                    <div className={index > 0 ? "Cam-flex Cam-Summary-middle-background borderTopDFE8F0": "Cam-flex Cam-Summary-middle-background"} key={index}>
                      <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">{bureauRules.title}</div>
                      {/* <div className="col-md-3 pt-4 pb-4 Cam-Summary-content-text"><p className="mb-0"> {bureauRules.content}</p></div> */}
                      <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">
                        <div className="col-md-12">{typeof bureauRules.value === 'string' || typeof bureauRules.value === 'number' ? bureauRules.value : bureauRules.value.count}</div>
                      </div>
                      <div className={`col-md-4 pt-4 pb-4 Cam-Summary-content-text posRelative d-flex ${bureauRules.hasOwnProperty('comments') && bureauRules.comments.length && "cam-bgColor"}`}>
                        {
                          bureauRules.hasOwnProperty('comments') && bureauRules.comments.length ? <div className="topRightCaret"></div> : <></>
                        }
                        <div className={bureauRules.result == "Unmet" ? "Cam-Summary-UnMet Cam-Summary-UnMet-Center pt-2" : bureauRules.result == "N/A" ? "Cam-Summary-NA col-md-12 pt-2" : "Cam-Summary-Met Cam-Summary-Met-Center pt-2"}>{bureauRules.result}</div>
                        {
                          bureauRules.hasOwnProperty('comments') && bureauRules.comments.length ? <span> <img onClick={() => (setBureauImageId(index), setShow(!show))} className="pl-5 Cam-Summary-show-deviation" src='/images/Group-2391.png' alt="icon" />
                            {
                              BureauImageId == index && BureauCommentBox != index && show && <div className='mrgn20 commentBox'>
                                {
                                  bureauRules.comments.map((comment, index) => <Comments comment={comment} key={index} />)
                                }
                              </div>
                            }
                          </span> : null
                        }
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>
            : null}
          <div className='Cam-Summary-top' />
          {cam_rule_result.hasOwnProperty('banking_rules') && cam_rule_result.banking_rules.length ?
            <div className="Cam-flex">
              <div className="col-md-4 mAuto Cam-Summary-left-content-text">Banking Norms (Considering all the Bank account)</div>
              <div className="col-md-8 p-0">
                {cam_rule_result.banking_rules.map((bankingRules, index) => {
                  return (
                    <div className={index > 0 ? "Cam-flex borderTopDFE8F0": "Cam-flex"} key={index}>
                      <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">{bankingRules.title}</div>
                      <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">
                        <div className="col-md-12">{bankingRules.value}</div>
                      </div>
                      <div className={`col-md-4 pt-4 pb-4 Cam-Summary-content-text posRelative d-flex ${bankingRules.hasOwnProperty('comments') && bankingRules.comments.length && "cam-bgColor"}`}>
                        {
                          bankingRules.hasOwnProperty('comments') && bankingRules.comments.length ? <div className="topRightCaret"></div> : <></>
                        }
                        <div className={bankingRules.result == "Unmet" ? "Cam-Summary-UnMet Cam-Summary-UnMet-Center pt-2" : bankingRules.result == "N/A" ? "Cam-Summary-NA col-md-12 pt-2" : bankingRules.result == "" ? "" : "Cam-Summary-Met Cam-Summary-Met-Center pt-2"}>{bankingRules.result}</div>
                        {
                          bankingRules.hasOwnProperty('comments') && bankingRules.comments.length ? <span> <img onClick={() => (setBankingImageId(index), setShow(!show))} className="pl-5 Cam-Summary-show-deviation" src='/images/Group-2391.png' alt="icon" />
                            {
                              BankingImageId == index && BankingCommentBox != index && show && <div className='mrgn20 commentBox'>
                                {
                                  bankingRules.comments.map((comment, index) => <Comments comment={comment} key={index} />)
                                }
                              </div>
                            }
                          </span> : null
                        }
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>
            : null}
          <div className='Cam-Summary-top' />
          {
            Object.keys(transaction_risk).length ?
              <div className="Cam-flex">
                <div className="col-md-4 mAuto Cam-Summary-left-content-text">Merchant Transaction Data(TD)</div>
                <div className="col-md-8 p-0">
                  {/* {cam_rule_result.banking_rules.map((bankingRules, index) => {
                return ( */}
                  <div className='Cam-flex Cam-Summary-middle-background'>
                    <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">Last 3 To 6 Month Ratio</div>
                    <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">
                      <div className="col-md-12">{transaction_risk.last_three_to_six_month_ratio}</div>
                    </div>
                    {/* <div className={`col-md-4 pt-4 pb-4 Cam-Summary-content-text posRelative d-flex ${bankingRules.hasOwnProperty('comments') && bankingRules.comments.length && "cam-bgColor"}`}>
                  {
                    bankingRules.hasOwnProperty('comments') && bankingRules.comments.length ? <div className="topRightCaret"></div> : <></>
                  }
                  <div className={bankingRules.result == "Unmet" ? "Cam-Summary-UnMet Cam-Summary-UnMet-Center pt-2" : bankingRules.result == "N/A" ? "Cam-Summary-NA col-md-12 pt-2" : bankingRules.result == "" ? "" : "Cam-Summary-Met Cam-Summary-Met-Center pt-2"}>{bankingRules.result}</div>
                  {
                    bankingRules.hasOwnProperty('comments') && bankingRules.comments.length ? <span> <img onClick={() => setBankingImageId(index)} className="pl-5 Cam-Summary-show-deviation" src='/images/Group-2391.png' alt="icon" />
                      {
                        BankingImageId == index && BankingCommentBox != index && <div className='mrgn20 commentBox'>
                          {
                            bankingRules.comments.map((comment, index) => <Comments comment={comment} key={index} />)
                          }
                        </div>
                      }
                    </span> : null
                  }
                </div> */}
                  </div>
                  <div className="d-flex">
                    <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">Last 12 To 6 Month Ratio</div>
                    <div className="col-md-4 pt-4 pb-4 Cam-Summary-content-text">
                      <div className="col-md-12">{transaction_risk.last_twelve_to_six_month_ratio}</div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
          <div className='Cam-Summary-top' />
        </div>
      }
    </div>
  )
}

export default CamSummary;
