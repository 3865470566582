import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Select from "react-select";

const PerfiosBankStatement1 = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: 34,
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #a7c4c7",
      "border-radius": 4,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      "font-size": 14,

    }),

    input: (provided, state) => ({
      ...provided,
      "margin": "-10px 0 0 0",

    }),
    placeholder: (provided, state) => ({
      ...provided,
      "margin-top": 0,
    }),
  };
  return (
    <div>
      <div
        key={props.statement.document_id}
        className="stmnt-023 border d-table w-100"
      >
        <div className="pdf float-left position-relative pl75 pt-2">
          <p className="st-023 m-0">{props.statement.file_name}</p>
          <p className="date m-0">
            {props.dateToDMY(props.statement.created_at)}
          </p>
        </div>
        <span style={{ margin: "0 0 0 20px" }}>
          <img
            src="/images/Group 2186.png"
            onClick={toggle}
            className="mr-2 mt-2"
            width="28"
            height="28"
          />
        </span>

        <div className="float-right pwd-data">
          <ul className="d-flex m-0 align-items-center">
            <li className="p-2 true">
              <i
                onClick={() => props.handleCheck(props.statement.document_id)}
                className={`fa ${props.selectedId.indexOf(props.statement.document_id) >= 0
                  ? "fa-check-circle-o"
                  : "fa-circle-thin"
                  }`}
                aria-hidden="true"
              ></i>
            </li>
          </ul>
        </div>
        <div className="BankDiv float-right">
          <Select
            styles={customStyles}
            value={props.selectedBankId}
            onChange={(selected) =>
              props.selectedBankIdFn(props.statement.document_id, selected)
            }
            options={props.bankListOption}
            placeholder="Select Bank"
          />
          {props.bankError && (
            <span style={{ color: "crimson", fontSize: "16px" }}>
              {props.bankError}
            </span>
          )}
        </div>
      </div>
      {modal && (
        <div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalBody>
              <iframe
                src={props.statement.document_url}
                style={{ width: 600, height: 500 }}
                frameBorder="0"
              ></iframe>
            </ModalBody>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default PerfiosBankStatement1;
