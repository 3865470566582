import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCamReport,updateData } from '../../actions/AllActions';
import AmCharts from "@amcharts/amcharts3-react";
import './ViewCamSummary.css';
import Loader from '../Loader/Loader';
import {
  Container, Row, Col
} from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  rupeeDesign,
  checkData,
  returnError
} from "../../shared/common_logic";

class ViewCamSummary extends Component {

  state = {
    averageBankBalance: [],
    business_experience:'',
    bureau_strength: '',
    business_consistency: '',
    business_need: '',
    stability_and_ownership: '',
    updateErr:'',
    is_editable: false
  }

  changeText = (e) => {
    var state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  updateComments = () => {
    var  state = this.state;
    var that = this;
    var data = {credit_manager_comments: {}};
    data.credit_manager_comments.business_experience = state.business_experience;
    data.credit_manager_comments.bureau_strength = state.bureau_strength;
    data.credit_manager_comments.business_consistency = state.business_consistency;
    data.credit_manager_comments.business_need = state.business_need;
    data.credit_manager_comments.stability_and_ownership = state.stability_and_ownership;
    this.props.updateData(data,this.props.loanID).then(
      function(res) {
        let search = that.props.location.search;
        let params = new URLSearchParams(search);
        var newurl = window.location.origin+that.props.location.pathname+"?user_id="+params.get('user_id')+"&version_id="+res.version+"&token="+params.get('token');
        confirmAlert({
          title: '',
          message: 'Cam updated successfully.',
          buttons: [
            {
              label: 'OK',
              onClick: () => window.location.href = newurl
            }
          ]
        });

      }
      ,function(err) {
        confirmAlert({
          title: 'Error',
          message: returnError(err),
          buttons: [
            {
              label: 'OK',
              onClick: null
            }
          ]
        });
      });
  }

  checkComments = () => {
    var state = this.state;
    if((state.business_experience === "" || state.business_experience === null) && (state.bureau_strength === "" || state.bureau_strength === null) && (state.business_consistency === "" || state.business_consistency === null) && (state.business_need === "" || state.business_need === null) && (state.stability_and_ownership === "" || state.stability_and_ownership === null)) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.averageBankBalance !== this.state.averageBankBalance)
      this.displayCharts();
  }

  getvalueKey (score) {
    var data = score.split('_score');
    return data[0]+"_value";
  }

  displayCharts = () => {
    const { averageBankBalance } = this.state;
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const FinalData = [];
    const amchartsDiv = [];
    const bankAccount = [];
    
    if(averageBankBalance) {

      averageBankBalance.forEach((ele, i) => {
        let div = document.createElement("div");
        div.style.width = "90%";
        div.style.height = "200px";
        div.id = `chartdiv-${i + 1}`;
        document.getElementById("customCharts").appendChild(div);
        amchartsDiv.push(`chartdiv-${i + 1}`);
        bankAccount.push(ele.bank_account);
        const result = [];
        ele.month_wise_stats.forEach(element => {
          const tempObj = {};
          const monthYear = element.month.split('-');
          const month = months[+monthYear[0][0] === 0 ? monthYear[0][1] - 1 : monthYear[0] - 1];
          const year = monthYear[1].slice(2);
          tempObj['monthYear'] = month + '\'' + year;
          tempObj['amount'] = element.amount;
          result.push(tempObj);
        });
        FinalData.push(result);
        AmCharts.makeChart(`chartdiv-${i + 1}`, {
          "type": "serial",
          "theme": "none",
          "marginTop": 0,
          "marginRight": 80,
          "dataProvider": FinalData[i],
          "valueAxes": [{
            "axisAlpha": 0,
            "position": "left",
            "title": "Rupees"
          }],
          "graphs": [{
            "balloonText": "<b>[[amount]]</b>",
            "bullet": "round",
            "bulletSize": 15,
            "lineColor": "#52679e",
            "lineThickness": 2,
            "negativeLineColor": "#637bb6",
            "type": "smoothedLine",
            "valueField": "amount"
          }],
          "marginTop": 20,
          "marginLeft": 70,
          "marginBottom": 30,
          "categoryAxis": {
            "axisAlpha": 0,
            "gridAlpha": 0
          },
          "categoryField": "monthYear"
        });
      })
    }
    setTimeout(() => {
      const ccs = document.getElementById('customCharts');
      ccs.insertAdjacentHTML("beforebegin", "<hr/>");
      amchartsDiv.forEach((ad, i) => {
        if (document.querySelector(`#${ad}`) !== null) {
          const cc = document.getElementById(ad);
          if (i !== 0)
            cc.insertAdjacentHTML("beforebegin", "<hr/>");
          cc.insertAdjacentHTML("beforebegin", "<div class='headingGraph1'>Average Bank Balance</div>");
          cc.insertAdjacentHTML("beforebegin", `<div class='headingGraph2'>Account Number - ${bankAccount[i]} </div>`);
          document.querySelector(`#${ad}`).lastElementChild.lastElementChild.lastElementChild.remove()
        }
      })
    }, 1000)
  }

  componentWillMount() {
    const { camReport } = this.props.all;
    if (camReport && Object.keys(camReport).length) {
      const { banking_analysis } = camReport;
      const { average_bank_balance } = banking_analysis;
      const { credit_manager_comments } = camReport.data.attributes;
      const is_editable = camReport.meta && camReport.meta.is_editable;
      
      this.setState({ 
        averageBankBalance: average_bank_balance,
        business_experience: checkData(credit_manager_comments.business_experience),
        bureau_strength: checkData(credit_manager_comments.bureau_strength),
        business_consistency: checkData(credit_manager_comments.business_consistency),
        business_need: checkData(credit_manager_comments.business_need),
        stability_and_ownership: checkData(credit_manager_comments.stability_and_ownership),
        is_editable: is_editable
      }, () => this.displayCharts())
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { cam_report } = nextProps.all;
    var state = this.state;
    if (cam_report && Object.keys(cam_report).length) {
      const { banking_analysis } = cam_report.data.attributes;
      const { credit_manager_comments } = cam_report.data.attributes;
      const is_editable = cam_report.meta && cam_report.meta.is_editable;
      state.is_editable = is_editable;
      
      if (banking_analysis && Object.keys(banking_analysis).length) {
        const { average_bank_balance } = banking_analysis;
        state.averageBankBalance = average_bank_balance;
      }

      if (credit_manager_comments && Object.keys(credit_manager_comments).length) {
        state.business_experience = checkData(credit_manager_comments.business_experience);
        state.bureau_strength = checkData(credit_manager_comments.bureau_strength);
        state.business_consistency = checkData(credit_manager_comments.business_consistency);
        state.business_need = checkData(credit_manager_comments.business_need);
        state.stability_and_ownership = checkData(credit_manager_comments.stability_and_ownership);
      }

      this.setState(state);

    }
  }

  render() {
    const { error } = this.props;
    var state = this.state;
    const { cam_report } = this.props.all;
    if (error && Object.keys(error).length) {
      return <span style={{ color: "crimson", fontSize: "16px" }}>Request failed </span>
    }
    if (cam_report && Object.keys(cam_report).length == 0) {
      return <Loader />;
    }
    const { summary, eligibility_offer, credit_manager_comments, transaction_risk, co_applicants, origination_score, cam_rule_result, decument_verification, banking_analysis } = cam_report.data.attributes
    const anchorResult = cam_rule_result && cam_rule_result.anchor_rules && cam_rule_result.anchor_rules.find(anchor => anchor.title == "Anchor Vintage (In Months)");
    var score_list = origination_score.origination_risk_calculation;
    var textChanges = {"aip_score": "AIP Score", "covid19_score" : "COVID 19 Score"};
    return (
      <div>
        <div className="f1 padding40 pb-3 borderLeft">
          <p className="mainTitle pl-2">| Sector Summary</p>
          <div className="pt-2" style={{ display: "flex" }}>
            <div className="pl-2 w20">
              <p className="stText h35">Sector</p>
              <p className="tText">{summary.business_entity_name} </p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Sector Risk </p>
              <p className="tText">NA</p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Overall Anchor/Sector Risk</p>
              <p className="tText">NA </p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Current Portfolio Performance (Days)</p>
              <p className="tText">NA</p>
            </div>
          </div>
        </div>
        <div className="f2 padding40 pb-3 borderLeft">
          <p className="mainTitle pl-2">| Anchor Summary</p>
          <div className="pt-2" style={{ display: "flex" }}>
            <div className="pl-2 w20">
              <p className="stText h35">Name of the Anchor</p>
              <p className="tText">{summary.anchor_name} </p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Anchor Risk Rating </p>
              <p className="tText">NA </p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Anchor Current Exposure (Rs.)</p>
              <p className="tText">NA</p>
            </div>
          </div>
        </div>
        <div className="f1 padding40 pb-3 borderLeft">
          <p className="mainTitle pl-2">| Application Summary</p>
          <div className="pt-2" style={{ display: "flex" }}>
            <div className="pl-2 w20">
              <p className="stText h35">Loan ID</p>
              <p className="tText">{summary.application_id}</p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Type of Product </p>
              <p className="tText">{eligibility_offer.product_type}</p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Loan value (Rs.)</p>
              <p className="tText">{rupeeDesign(summary.requested_loan_amount)}</p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Tenure (Months)</p>
              <p className="tText">{eligibility_offer.tenure}</p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Interest %</p>
              <p className="tText">{eligibility_offer.roi}</p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">PF (Rs.) </p>
              <p className="tText">{rupeeDesign(eligibility_offer.processing_fee)}</p>
            </div>
            <div className="pl-2 w20">
              <p className="stText h35">Collection Mechanism</p>
              <p className="tText">{summary.collection_mechanism}</p>
            </div>
          </div>
        </div>
        <div className="f2 padding40 pb-3 borderLeft" style={{ display: 'flex' }}>
          <Container fluid>
          <Row>
          {Object.keys(score_list).map((key) => (
            <React.Fragment key={key}>
            {key.indexOf('score') !== -1 &&
              <Col lg="3" className="partScore">
                <p className="mainTitle pl-2">| {textChanges[key] !== undefined ? textChanges[key] : key.replace(/_/g, ' ')}</p>
                <div className="pt-4" >
                  <div className="pl-2">
                    <p className={`${origination_score.origination_risk_calculation[this.getvalueKey(key)] == "High Risk" ? "red" : origination_score.origination_risk_calculation[this.getvalueKey(key)] == "Low Risk" ? "green" : "orange"} tText pt-2 pb-2`} style={{ color: '#FFFFFF', textAlign: 'center' }}>{`${origination_score.origination_risk_calculation[key]}/100`}</p>
                  </div>
                </div>
              </Col>
            }
            </React.Fragment>
          ))}
          {this.props.all && this.props.all.ml_score && this.props.all.ml_score.data && this.props.all.ml_score.data.attributes &&
            <Col lg="3" className="partScore">
              <p className="mainTitle pl-2">| ML Score</p>
              <div className="pt-4" >
                <div className="pl-2">
                  <p className="orange tText pt-2 pb-2" style={{ color: '#FFFFFF', textAlign: 'center' }}>{`${this.props.all.ml_score.data.attributes.score}/900`}</p>
                </div>
              </div>
            </Col>
          }
        </Row>
        </Container>
      </div>
      <div className="f1 padding40 borderLeft">
        <p className="mainTitle pl-2">| Borrower Details</p>
        <div className="pt-2" style={{ display: "flex" }}>
          <div className="pl-2 w20">
            <p className="stText h35">Borrower Name</p>
            <p className="tText">{summary.business_entity_name} </p>
          </div>
          <div className="pl-4 w20">
            <p className="stText h35">Primary Applicant</p>
            <p className="tText">{co_applicants[0]['name']} </p>
          </div>
          <div className="pl-4 w20">
            <p className="stText h35">Nature of the firm</p>
            <p className="tText">{summary.business_entity_constitution}</p>
          </div>
          <div className="pl-4 w20">
            <p className="stText h35">Business Vintage (in Months)</p>
            <p className="tText">{origination_score.business_vintage.value}</p>
          </div>
          <div className="pl-4 w20">
            <p className="stText h35">Anchor Vintage (in Months)</p>
            <p className="tText">{anchorResult.value}</p>
          </div>
          <div className="pl-4 w20">
            <p className="stText h35">Anchor Dependency</p>
            <p className="tText">6.5</p>
          </div>
          <div className="pl-4 w20">
            <p className="stText h35">Region</p>
            <p className="tText">{summary.business_address.state}</p>
          </div>
        </div>
        </div>
        {/* <div className="f2 padding40 pb-3 borderLeft">
              <p className="mainTitle pl-2">| Verification Details</p>
              <div className="pt-2" style={{ display: "flex" }}>
                <div className="pl-2">
                  <p className="stText h35">Name Verification<img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="tText">NSDL <img src="/images/Group 2392.png" alt="Verified" width="20px" className="ml-2 mt_2" /></p>
                </div>
                <div className="pl-3">
                  <p className="stText h35">PAN <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="tText">Finfort <img src="/images/Group 2392.png" alt="Verified" width="20px" className="ml-2 mt_2" /></p>
                </div>
                <div className="pl-3">
                  <p className="stText h35">GST <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="tText">Unverified <img src="/images/Group 2394.png" alt="Verified" width="20px" className="ml-2 mt_2" /></p>
                </div>
                <div className="pl-3">
                  <p className="stText h35">Address <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="tText">Unverified <img src="/images/Group 2394.png" alt="Verified" width="20px" className="ml-2 mt_2" /></p>
                </div>
                <div className="pl-3">
                  <p className="stText h35">DOB <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="tText">Karza <img src="/images/Group 2392.png" alt="Verified" width="20px" className="ml-2 mt_2" /></p>
                </div>
                <div className="pl-3">
                  <p className="stText h35">Email ID<img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="tText">CIBIL <img src="/images/Group 2392.png" alt="Verified" width="20px" className="ml-2 mt_2" /></p>
                </div>
                <div className="pl-3">
                  <p className="stText h35">Mobile No<img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="tText">Unverified <img src="/images/Group 2394.png" alt="Verified" width="20px" className="ml-2 mt_2" /></p>
                </div>
              </div>
              <div className="" style={{ height: 1, backgroundColor: '#EDEDED' }} />
              <div className="mt-4" style={{ display: "flex" }}>
                <div className="pl-2">
                  <p className="stText mb-0">Any other <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="stText"> database</p>
                  <p className="tText">Mismatch <img src="/images/Group 2393.png" alt="Verified" width="20px" className="ml-2 mt_2" /></p>
                </div>
                <div className="pl-5">
                  <p className="stText mb-0">Google Negative<img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="stText">List</p>
                  <p className="tText">Google <img src="/images/Group 2392.png" alt="Verified" width="20px" className="ml-2 mt_2" /></p>
                </div>
                <div className="pl-5">
                  <p className="stText mb-0">Experian Adress <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="stText">Stability Index</p>
                  <p className="tText mb-0">Requested by <img src="/images/Group 2395.png" alt="Verified" width="20px" className="ml-2 mt_2" /> </p>
                  <p className="tText"><small>aman@mintifi.com</small></p>
                </div>
              </div>
            </div> */}
        <div className="f1 padding40 pb-3 borderLeft">
          <p className="mainTitle pl-2">| Documents Verified</p>
          <div className="pt-2" style={{ display: "flex" }}>
            <div className="pl-2">
              <p className="stText">PAN Card <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="tText">{decument_verification.pan_card.source} <img src={decument_verification.pan_card.verified ? "/images/Group 2392.png" : "/images/Group 2393.png"} alt="Verified" width="20px" className="ml-2 mt_2" /></p>
            </div>
            <div className="pl-5">
              <p className="stText">AADHAR <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="tText">{decument_verification.aadhar.source} <img src={decument_verification.aadhar.verified ? "/images/Group 2392.png" : "/images/Group 2393.png"} alt="Verified" width="20px" className="ml-2 mt_2" /></p>
            </div>
            <div className="pl-5">
              <p className="stText">Address <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="tText">{decument_verification.address.source} <img src={decument_verification.address.verified ? "/images/Group 2392.png" : "/images/Group 2393.png"} alt="Verified" width="20px" className="ml-2 mt_2" /></p>
            </div>
            <div className="pl-5">
              <p className="stText">GST <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="tText">{decument_verification.gst.source} <img src={decument_verification.gst.verified ? "/images/Group 2392.png" : "/images/Group 2393.png"} alt="Verified" width="20px" className="ml-2 mt_2" /></p>
            </div>
            <div className="pl-5">
              <p className="stText">Bank Account<img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="tText">{decument_verification.bank_account.source} <img src={decument_verification.bank_account.verified ? "/images/Group 2392.png" : "/images/Group 2393.png"} alt="Verified" width="20px" className="ml-2 mt_2" /></p>
            </div>
          </div>
        </div>
        <div className="f2 padding40 pb-3 borderLeft">
          <p className="mainTitle pl-2">| Banking Analysis</p>
          <div className="pt-2" style={{ display: "flex" }}>
            <div className="pl-2">
              <p className="stText mb-0">Cheque Bounce <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="stText">Ratio</p>
              <p className="tText">{banking_analysis.cheque_bounce_ratio}</p>
            </div>
            <div className="pl-4">
              <p className="stText mb-0">Loan Repayment<img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="stText extMrg">Amount (Rs.)</p>
              <p className="tText">{rupeeDesign(banking_analysis.loan_repayment_amount)}</p>
            </div>
            <div className="pl-4">
              <p className="stText mb-0">Is Repayment <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="stText extMrg">Bounce </p>
              <p className="tText">{String(banking_analysis.is_loanrepayment_bounce)}</p>
            </div>
            <div className="pl-4">
              <p className="stText mb-0">Penal Charges <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="stText">(Rs.) </p>
              <p className="tText">{banking_analysis.penal_charges}</p>
            </div>
          </div>
          <div id="customCharts" style={{ backgroundColor: 'white' }}></div>
        </div>
        <div className="f1 padding40 pb-3 borderLeft">
          <p className="mainTitle pl-2">| Merchant Data</p>
          <div className="pt-2" style={{ display: "flex" }}>
            <div className="pl-2">
              <p className="stText mb-0">Last 3 To<img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="stText">6 Month Ratio </p>
              <p className="tText">{transaction_risk.last_three_to_six_month_ratio}</p>
            </div>
            <div className="pl-5">
              <p className="stText mb-0">Last 12 To <img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
              <p className="stText">6 Month Ratio</p>
              <p className="tText">{transaction_risk.last_twelve_to_six_month_ratio}</p>
            </div>
          </div>
        </div>
        {/* <div className="f2 padding40 pb-3 borderLeft">
              <p className="mainTitle pl-2">| Income Estimator</p>
              <div className="pt-2" style={{ display: "flex" }}>
                <div className="pl-2">
                  <p className="stText h35">Income Earned (Rs.)<img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="tText">80,0999</p>
                </div>
                <div className="pl-5">
                  <p className="stText h35">Experian Leverage Score<img src="/images/Group 2391.png" alt="Verified" width="15px" className="ml-2 mt_2" /></p>
                  <p className="tText">40 Points</p>
                </div>
              </div>
            </div> */}
        <div className="f1 padding40 pb-3 borderLeft">
          <p className="mainTitle pl-2 pb-4">| Credit Manager Comments</p>
          <div style={{ background: 'white' }}>
            <div className="view-cam-summary-flex view-cam-summary-header d-flex">
              <div className="col-md-4 view-cam-summary-header-text pl-4">Parameter</div>
              <div className="col-md-7 view-cam-summary-header-text pl-3">Value</div>
            </div>
            <div className="Cam-flex bordertop707070">
              <div className="col-md-4 pl-4 pt-4 pb-4">
                <p className='textFont'>Business Experience</p>
              </div>
              <div className="col-md-7 pt-3 pb-3 textFont">
                 <textarea rows="2" className="inputEditDiv py-3" readOnly={!state.is_editable} name="business_experience" onChange={this.changeText} value={state.business_experience}></textarea>
              </div>
            </div>
            <div className="Cam-flex bordertop707070">
              <div className="col-md-4 pl-4 pt-4 pb-3">
                <p className='textFont'>Bureau Strength</p>
              </div>
              <div className="col-md-7 pt-3 pb-3 textFont">
                <textarea rows="2" className="inputEditDiv py-3" readOnly={!state.is_editable} name="bureau_strength" onChange={this.changeText} value={state.bureau_strength} ></textarea>
              </div>
            </div>
            <div className="Cam-flex bordertop707070">
              <div className="col-md-4 pl-4 pt-4 pb-3">
                <p className='textFont'>Business Consistency</p>
              </div>
              <div className="col-md-7 pt-3 pb-3 textFont">
                <textarea rows="2" className="inputEditDiv py-3" readOnly={!state.is_editable} name="business_consistency" onChange={this.changeText} value={state.business_consistency} ></textarea>
              </div>
            </div>
            <div className="Cam-flex bordertop707070">
              <div className="col-md-4 pl-4 pt-4 pb-3">
                <p className='textFont'>Business need</p>
              </div>
              <div className="col-md-7 pt-3 pb-3 textFont">
                <textarea rows="2" className="inputEditDiv py-3" readOnly={!state.is_editable} name="business_need" onChange={this.changeText} value={state.business_need} ></textarea>
              </div>
            </div>
            <div className="Cam-flex bordertop707070">
              <div className="col-md-4 pl-4 pt-4">
                <p className='textFont'>Stability and Ownership</p>
              </div>
              <div className="col-md-7 pt-3 textFont">
               <textarea rows="2" className="inputEditDiv py-3" readOnly={!state.is_editable} name="stability_and_ownership" onChange={this.changeText} value={state.stability_and_ownership} ></textarea>
              </div>
            </div>
            {state.is_editable &&
            <div className="Cam-flex bordertop707070">
             <div className="col-md-11 pl-4 pt-4 text-right">
              <button className="updateBtn Comments" disabled={this.checkComments()} onClick={()=> this.updateComments() }>Update Comments</button>
             </div>
            </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  all: state.all
});

const mapDispatchToProps = {
  getCamReport,
  updateData
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCamSummary);
