import React from "react";
import './ITR.scss';
import RoleResult from '../ruleResult/ruleResult';
import {commaSeparatRs} from '../../../../shared/common_logic'

const BalanceSheet = ({lastClicked,balanceSheet}) => {
    var year = lastClicked.split('-');
    var nextYear =  `${Number(year[0])+1}-${Number(year[1])+1}`;
   
    return (
        <div className="col-md-12 itrdetails">
           
        <div className="row m-0 card" style={{ width: "100%", backgroundColor: "#F3F6FB", padding: "10px 10px 30px" }}>
        <div className="col-md-12">
            <div className="table-responsive">
                
                <table className="overflow-hidden">
                    <thead>
                    <tr >
                        <th className="tablehead font-weight-bold">Program Conditions</th>
                        <th className="font-weight-bold text-center">{nextYear}</th>
                        <th className="font-weight-bold text-center">{lastClicked}</th>
                        <th className="font-weight-bold text-center">Percentage Change</th>
                    </tr>
                    </thead>
                    <tbody>
                            {balanceSheet !== undefined ? 
                            <>
                            {Object.keys(balanceSheet).map((data,index)=>(
                                
                                <tr key={index}>                                   
                                    <td className="tablehead">{balanceSheet[data].title || "-"}</td>
                                    <td className="text-center">{balanceSheet[data].value1 || "-"}</td>
                                    <td className="text-center">{balanceSheet[data].value2 || "-"}</td>
                                    <td className="text-center"><span>{balanceSheet[data].value !== null ? String(balanceSheet[data].value):"-"}</span></td>
                                    {/* <RoleResult rule={balanceSheet[data].result} id={index}/>  */}
                                    
                                    
                                </tr>
                            ))}
                            </>
                            :
                            <tr>
                            <td>-</td>
                            <td>-</td>  
                            <td>-</td>      
                        </tr>
                            }
                        </tbody>
                    {/* <tbody >
                    {1 > 0 ?
                        <>
                        {BalanceSheet.map((data,index)=>(
                        <tr key={index}>
                            <td className="tablehead">{data.title}</td>
                            <td className="text-center">
                            <div className="sub_text">
                            <div>{data.value !== undefined ? data.value : "-"}</div>
                            <div className={data.Sub_text !=="Increasing" ? "normal" :"increasing" }>{data.Sub_text !== undefined ? data.Sub_text : "-"}</div>
                        </div>
                            </td>
                            <RoleResult rule={data.result} comment={data.comments} id={index}/>
                        </tr>
                        ))}
                        </>
                        :
                        <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>}
                    </tbody> */}
                </table>
                
                </div>
            </div>
        </div>
  
    </div>

    );
};
export default BalanceSheet;
