import React, { Fragment } from 'react';
import './Deviations.css';
import Loader from '../Loader/Loader';

const Deviations = ({ camReport, error }) => {
  if (camReport && Object.keys(camReport).length === 0) {
    return <Loader />;
  }
  if (error && Object.keys(error).length) {
    return <span style={{ color: "crimson", fontSize: "16px" }}>Request failed </span>
  }
  const { deviations_detail } = camReport;
  return (
    <div style={{ background: 'white' }}>
      <div className="Deviations-flex Deviations-header">
        <div className="col-md-4 Deviations-header-text">Deviations</div>
        <div className="col-md-4 Deviations-header-text">Comments</div>
        <div className="col-md-4 Deviations-header-text">Approved By</div>
      </div>
      {
        deviations_detail.map((deviations, index) => {
          return (
            <Fragment key={index}>
              <div className={index === (Object.keys(deviations_detail).length-1) ? "Deviations-flex bordertopBottom707070" :"Deviations-flex bordertop707070"}>
                <div className="col-md-4 pt-3 pb-3 Deviations-content-text">Final Eligibility</div>
                <div className="col-md-4 pt-3 pb-3 Deviations-content-text"><p className="mb-0">{deviations.comment}</p></div>
                <div className="col-md-4 pt-3 pb-3 Deviations-content-text"><p className="mb-0">{deviations.comment_by}</p><p className="Deviations-content-text-date">{deviations.comment_at}</p></div>
              </div>
            </Fragment>
          )
        })
      }
    </div>
  )
}

export default Deviations;