import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Card } from 'reactstrap';
import RoundProcess from './roundProcess'
import './summarySection.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getItrDetails } from '../../../actions/AllActions';
import Loader from '../../Loader/Loader';
import moment from "moment";

function ItrData({ camData,metaData, ID }) {

    const itr_details = useSelector(state => state.all.itr_details);    

    const dispatch = useDispatch();
    const [currentFlag,SetcurrentFlag] = useState(0);
    const [flag,setFlag] = useState(false);
    
    const arrYear = [1, 2, 3, 4, 5];
    let fiveYear = [];
    arrYear.map((y) => {
        var year2 = moment().subtract({ y }, 'years').year();
        var yre = `${year2}-${year2 + 1}`;
        fiveYear.push(yre);
    })

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle1 = () => setDropdownOpen(prevState => !prevState);

    //const [lastClicked, setLastClicked] = useState(fiveYear[0]);
    const [lastClicked, setLastClicked] = useState(fiveYear[4]);

    useEffect(() => {
        if(lastClicked !== null && flag === true)
            dispatch(getItrDetails(ID, lastClicked));
    }, [lastClicked]);


    useEffect(() => {
        dispatch(getItrDetails(ID, fiveYear[currentFlag]));
    }, []);

    useEffect(() => {
        if(flag === false) {

             if(itr_details && itr_details.error && currentFlag < 4) {
                var currentFlagTemp = currentFlag + 1;
                SetcurrentFlag(currentFlag+1)
                dispatch(getItrDetails(ID, fiveYear[currentFlagTemp]));
            }
            else if(itr_details && itr_details.itrFetching) {
                 setFlag(true);
                 if(lastClicked !== currentFlag) {
                    setLastClicked(fiveYear[currentFlag]);
                 }
            }
            else if(currentFlag === 4) {
                setFlag(true);
            }

        } 
    }, [itr_details]);

    const ValueCheck = ( x) => {
        if(x !== null){
            
            var stringValue = String(x).toLowerCase();
            if(stringValue === "true") {
                return "Yes";
            }
            else if(stringValue === "false") {
                return "No";
            }
            else {
                return x;
            }

        } else {
            return "-";
        }
    }

    const BocData = {
        name: "ITR",
        icon: "/images/cam_version_3/file.svg",
        list: {
            "Last Filed ITR": [metaData.program_details.program_band_enabled_cam_components.includes('itr_panel_last_files_itr'),ValueCheck(itr_details && itr_details.itrFetching && itr_details.itrFetching.attributes.itr_filed_date.value)],
            "Was Company Profitable": [metaData.program_details.program_band_enabled_cam_components.includes('itr_panel_company_profitable'),ValueCheck(itr_details && itr_details.itrFetching && itr_details.itrFetching.attributes.profit_percentage.value)],
            "Growth in Sales between last 2 filed ITR": [metaData.program_details.program_band_enabled_cam_components.includes('itr_panel_growth_sale_in_last_2_filed'),ValueCheck(itr_details && itr_details.itrFetching && itr_details.itrFetching.attributes.percentage_growth_in_sales.value)],
            "Debt / Equity Ratio": [metaData.program_details.program_band_enabled_cam_components.includes('itr_panel_debt_to_equity_ratio'),ValueCheck(itr_details && itr_details.itrFetching && itr_details.itrFetching.attributes.debt_to_equity_ratio.value)],
            "Current Asset / Liability Ratio Norm": [metaData.program_details.program_band_enabled_cam_components.includes('itr_panel_current_assest_liability_ratio'),ValueCheck(itr_details && itr_details.itrFetching && itr_details.itrFetching.attributes.current_ratio_norm.value)],
            "Debt Service Coverage Ratio": [metaData.program_details.program_band_enabled_cam_components.includes('itr_panel_debt_service_coverage_ratio'),ValueCheck(itr_details && itr_details.itrFetching && itr_details.itrFetching.attributes.debt_service_coverage_ratio.value)]
        },
        score: itr_details && itr_details.transaction_risk && itr_details.transaction_risk.transaction_risk && itr_details.transaction_risk.transaction_risk.value || "N/A",
        hml: itr_details && itr_details.transaction_risk && itr_details.transaction_risk.transaction_risk && itr_details.transaction_risk.transaction_risk.result,
    }

    const fixItrData =(d)=>{
        setLastClicked(d)
    }
    return (
        <Col md="12" lg="6" className="mb-3" >
            <Card className="summarySection">

                <Row className="summarySectionHead">
                    <Col md="12">
                        <Row className='align-items-center' style={{height:'90px'}}>
                            <Col>
                                <div className="float-left p-4 ">
                                    <div className="d-flex headerText">
                                        <img src={BocData.icon} />
                                        <p className="pl-2">{BocData.name}</p>
                                    </div>
                                </div>
                            </Col>                            
                        </Row>
                    </Col>
                </Row>
                <hr className="mb-3 mt-0" />
                    {currentFlag !== 4 && itr_details.loading === false &&
                    <Row >
                    <Col md="12"> 
                            <div className="dataFont">
                                    <>
                                        <p className="d-flex px-3 py-2 align-items-center">
                                            <span className="mr-auto cibilKey">Select Finacial Year</span>
                                            <div className="text-center" >
                                                <Dropdown isOpen={dropdownOpen} toggle={toggle1} >
                                                    <DropdownToggle caret>
                                                        {lastClicked}
                                                    </DropdownToggle>
                                                    <DropdownMenu container="body">
                                                        {fiveYear.map((data, i) => (
                                                            <DropdownItem key={i} onClick={() => fixItrData(data)}>F.Y. {fiveYear[i]}</DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </p>                                        
                                        {Object.keys(BocData.list).map((key, i) => (
                                            <>
                                            {BocData.list[key][0] &&
                                                <p key={i} className="d-flex px-3 py-2 ">
                                                    <span className="mr-auto cibilKey">{key}</span>

                                                    <span className="cibilValue">
                                                        {BocData.list[key][1]}
                                                    </span>
                                                </p>
                                            }
                                            </>
                                        ))
                                        }
                                    </>
                            </div>
                    </Col>
                </Row>
                }
                {itr_details.loading &&
                    <div className="Loadergif pt-4">
                        <img src="/images/cam_version_3/Loader2.gif" width="55px" height="55px" />
                    </div>
                }

                {currentFlag === 4 && itr_details.loading === false && itr_details.itrFetching === null &&
                    <div style={{ padding: "30px 10px", textAlign: "center", color: "#666c72" }}>No Itr Data found</div>
                }
            </Card>

        </Col>
    )
}

export default ItrData;
